import { Button } from "@app/components/button";
import { useGoToTransaction } from "@app/hooks/use-go-to-transaction";

export const ViewButton = ({
	transactionId,
}: {
	transactionId: number;
}) => {
	const { onGoToTransaction, isLoading } = useGoToTransaction();
	return (
		<Button
			variant="secondary"
			size="sm"
			disabled={isLoading}
			onClick={() => onGoToTransaction(transactionId)}
		>
			View
		</Button>
	);
};
