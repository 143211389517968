import { Button } from "@app/components/button";
import { StatusCheckModal } from "@app/components/status-check-modal";
import { useSystemStatus } from "@app/hooks/use-system-status";
import { FiHome } from "react-icons/fi";

export const OutOfHoursModal = ({
	onClose,
	onConfirm,
	isOpen,
}: {
	onConfirm: () => void;
	onClose: () => void;
	isOpen: boolean;
}) => {
	const systemStatusResult = useSystemStatus();
	return (
		<StatusCheckModal
			isOpen={isOpen}
			onClose={onClose}
			data={{
				message_title: systemStatusResult.data?.message_title,
				message_body: systemStatusResult.data?.message_body,
			}}
			actions={
				<Button onClick={onConfirm} centered>
					<FiHome size={24} />
					Home
				</Button>
			}
		/>
	);
};
