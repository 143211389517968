import { api } from "./services";

export const fetcher = (path: string | any[]) => {
	if (Array.isArray(path)) {
		const [newPath, config] = path;
		if (config.method?.toLowerCase() === "options") {
			return api.options(newPath, config).then((res) => res.data);
		}
		return api.get(newPath, config).then((res) => res.data);
	}
	return api.get(path).then((res) => res.data);
};
