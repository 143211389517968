import { useMemo, useState } from "react";

import { FormBuilder, Typography } from "@app/components";
import { SelectButton } from "@app/components/controls";
import { type SwiftFeeOption, type TransactionDirection } from "@app/entities";

import type { FormBuilderProps } from "@app/components";
import type { DropdownOption, GetExchangeDetails } from "@app/entities";

import type { MappedReasons } from "@app/services";
import type { Control, FieldErrors } from "react-hook-form";
import { TransactionDetailsFooter } from "../transaction-details-footer";
import type { Payment } from "./use-payment";
import type { FeeTypeOption, PaymentOptions } from "./use-payment-options";
import type { PaymentStatus } from "./use-payment-status";

import { CustomLoader } from "@app/components/custom-loader";
import { MoreInfoTooltip } from "@app/components/more-info-tooltip";
import { Note } from "@app/components/note";
import { FiThumbsUp } from "react-icons/fi";
import styles from "./index.module.css";
import "./payment-details.css";
import { TransactionLayout, TransactionStep } from "../transaction-layout";

export const PaymentDetailsView = (props: {
	apnInputFields: FormBuilderProps.FormInputProps[][];
	displayApnDetails?: boolean;
	errors?: FieldErrors<any>;
	exchangeDetails?: GetExchangeDetails;
	formControl: Control<any, any>;
	inputFields: FormBuilderProps.FormInputProps[][];
	isValid: boolean;
	isDirty: boolean;
	loading?: boolean;
	mappedReasons?: MappedReasons;
	note?: string;
	payment?: Payment | null;
	paymentFeeTypeOptions?: PaymentOptions;
	paymentStatus?: PaymentStatus | null;
	selectedPaymentFeeTypeOption?: FeeTypeOption;
	selectedRecipient?: DropdownOption;
	showResumeLaterModal?: boolean;
	title: string;
	onBack: () => void;
	onNavigateTransaction: (navigationPath: string) => void;
	onResumeLater: () => Promise<void>;
	onChangeSelectedPaymentFeeTypeOption?: (value?: FeeTypeOption[]) => void;
}) => {
	const [showResumeLater, setShowResumeLater] = useState(false);

	const amountDue = useMemo(() => {
		if (
			!props.exchangeDetails?.zarAmount ||
			!props.selectedPaymentFeeTypeOption?.amount
		)
			return "ZAR -";

		try {
			const zarAmount = Number.parseFloat(
				props.exchangeDetails.zarAmount
					.replace("ZAR", "")
					.replaceAll(",", "")
					.trim(),
			);
			const swiftOptionAmount = Number.parseFloat(
				props.selectedPaymentFeeTypeOption.amount
					.split("+")[0]
					.replace("ZAR", "")
					.replaceAll(",", "")
					.trim(),
			);
			const amountDue = zarAmount + swiftOptionAmount;
			return `ZAR ${amountDue.toLocaleString(undefined, {
				maximumFractionDigits: 2,
				minimumFractionDigits: 2,
			})}`;
		} catch {
			return props.payment?.amount_due ?? "ZAR -";
		}
	}, [
		props.exchangeDetails?.zarAmount,
		props.selectedPaymentFeeTypeOption?.amount,
		props.payment?.amount_due,
	]);

	return (
		<>
			<TransactionLayout
				onResumeLater={() => setShowResumeLater(true)}
				step={TransactionStep.PaymentDetails}
				onStepNavigate={props.onNavigateTransaction}
				footer={
					<TransactionDetailsFooter
						showResumeLater={showResumeLater}
						onResumeLaterChange={setShowResumeLater}
						onResumeLater={props.onResumeLater}
						isResumeLaterSecondaryAction
						onBack={props.onBack}
					/>
				}
			>
				<div className={styles.container}>
					<h2 className={styles.title}>Payment Details</h2>

					<div className="mb-6 lg:my-6">
						<FormBuilder
							errors={props.errors}
							formTitle={props.title}
							formInputs={props.inputFields}
							formControl={props.formControl}
							mappedReasons={props.mappedReasons}
						/>
					</div>
					{props.displayApnDetails && (
						<div className={styles.apnSection}>
							<strong>APN (optional)</strong>
							<p className={styles.apnSectionDescription}>
								APNs (Advanced Payment Notifications) are only required for
								advanced import payments.
							</p>
							<FormBuilder
								errors={props.errors}
								formTitle={props.title}
								formInputs={props.apnInputFields}
								formControl={props.formControl}
								mappedReasons={props.mappedReasons}
							/>
						</div>
					)}
					{!props.displayApnDetails && <div className={styles.divider} />}
					{(props.exchangeDetails?.direction as TransactionDirection) ===
					"send" ? (
						<>
							<p className={styles.swiftFeeOptionsTitle}>
								SWIFT Fee Options
								<MoreInfoTooltip
									name="SWIFT Fee Options"
									maxWidth={745}
									hasIcon
								>
									<div className="w-fit">
										<Typography theme="textSm">
											Capitec charges a fee for sending funds via the SWIFT
											Network. There are two fee types which allow you to choose
											where the fees are charged, on the sender and/or receiver
											side:
										</Typography>
										<div className="mt-4 flex flex-col lg:flex-row items-start gap-x-2.5">
											<Typography
												theme="textSm"
												className="w-100% min-w-17 max-w-17 font-semibold"
											>
												OUR
											</Typography>
											<Typography theme="textSm">
												The fee is paid on the sender side of the transaction
												(I.e. all in ZAR).
											</Typography>
										</div>
										<div className="mt-3 lg:mt-1 flex flex-col lg:flex-row items-start gap-x-2.5">
											<Typography
												theme="textSm"
												className="w-100% min-w-17 max-w-17 font-semibold"
											>
												SHA / BEN
											</Typography>
											<Typography theme="textSm">
												<>
													The fee is “shared” between the sender and receiver
													side (I.e. in ZAR and foreign currency). As a result,
													your recipient{" "}
													<Typography
														theme="textSm"
														className="inline underline underline-offset-2"
													>
														receives less foreign currency
													</Typography>{" "}
													than sent.
												</>
											</Typography>
										</div>
										<Typography theme="textSm" className={"mt-4"}>
											We recommend{" "}
											<Typography
												theme="textSm"
												className={"inline font-semibold"}
											>
												OUR
											</Typography>
											, as it is more predictable and typically lower cost
											overall.
										</Typography>
									</div>
								</MoreInfoTooltip>
							</p>

							<div className={styles.swiftOptionsWrapper}>
								<SelectButton
									options={props.paymentFeeTypeOptions?.allowed_fee_types}
									className={styles.swiftFeeOptions}
									optionClassName={() => styles.optionContainer}
									optionTemplate={(option) => (
										<div className={styles.optionContent}>
											<p className={styles.optionTitle}>{option.label}</p>
											<div className={styles.verticalDivider} />
											<div className={styles.optionInnerContent}>
												<p className={styles.optionAmount}>{option.amount}</p>
												<p>{option.description}</p>
											</div>
											{(option.fee_type as SwiftFeeOption) === "OUR" && (
												<div className={styles.recommended}>
													<FiThumbsUp color="#262626" size={14} />
													Recommended
												</div>
											)}
										</div>
									)}
									value={
										props.selectedPaymentFeeTypeOption
											? [props.selectedPaymentFeeTypeOption]
											: props.selectedPaymentFeeTypeOption
									}
									onChange={props.onChangeSelectedPaymentFeeTypeOption}
								/>
								<div className={styles.totalCard}>
									<h3 className={styles.totalCardTitle}>Total amount due</h3>
									<div className="flex flex-col gap-y-4">
										<div className="flex flex-row flex-wrap items-end gap-x-6">
											<Typography
												theme="textXs"
												className="w-20 min-w-20 leading-6.5 text-gray-650"
											>
												Amount sent
											</Typography>
											<Typography
												theme="textXl"
												className={"min-w-40 leading-7.5 text-gray-650"}
											>
												{props.exchangeDetails?.zarAmount ?? "ZAR 0"}
											</Typography>
										</div>
										<div className="mb-4 border-b border-gray-110" />
										<div className="flex flex-row flex-wrap items-end gap-x-6">
											<Typography
												theme="textXs"
												className="w-20 min-w-20 leading-6.5 text-gray-650"
											>
												SWIFT fee
											</Typography>
											<Typography
												theme="textXl"
												className={"min-w-40 leading-7.5 text-gray-650"}
											>
												{props.selectedPaymentFeeTypeOption?.amount ?? "ZAR 0"}
											</Typography>
										</div>
										<div className="mb-4 border-b border-gray-110" />
										<div className="flex flex-row flex-wrap items-end gap-x-6">
											<Typography
												theme="textXs"
												className={"w-20 min-w-20 leading-6.5"}
											>
												Amount due
											</Typography>
											<Typography
												theme="textXl"
												className={"min-w-40 leading-7.5"}
											>
												{amountDue}
											</Typography>
										</div>
									</div>
								</div>
							</div>
						</>
					) : (
						<>
							<Typography theme="textLg" className="font-semibold">
								SWIFT Fee
							</Typography>
							{props.paymentFeeTypeOptions?.note && (
								<Note variant="fit" className={styles.note}>
									{props.paymentFeeTypeOptions?.note}
								</Note>
							)}
						</>
					)}
				</div>
				{props.loading && <CustomLoader />}
			</TransactionLayout>
		</>
	);
};
