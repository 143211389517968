import { useEffect } from "react";
import { useSelector } from "react-redux";
import { ToastPosition, toast } from "react-toastify";
import { twMerge } from "tailwind-merge";

import { useHideToast } from "@app/helpers";
import { useMediaQuery } from "@app/hooks/use-media-query";
import type { RootState } from "@app/redux";

import { types } from "./models/toast-theme";
import type { Properties } from "./properties";
import { ToastView } from "./toast-view";

export type { ToastType } from "./models/toast-theme";

export const Toast = (props: Properties) => {
	const [hideToast] = useHideToast();
	const isMobile = useMediaQuery();

	const { show, type, message, toastId } = useSelector(
		(state: RootState) => state.toast,
	);

	const onToastClose = () => hideToast();

	const viewProps = {
		...props,
		content: <span>{message}</span>,
		theme: types[(type as keyof typeof types) ?? "success"],
		type,
	};

	const toastOptions = {
		autoClose: props.autoClose,
		className: twMerge(viewProps.theme.className, props.className),
		closeButton: props.showCloseButton ?? false,
		hideProgressBar: true,
		icon: false,
		position: (isMobile ? "bottom-center" : "top-right") as ToastPosition,
		transition: props.transition,
		toastId,
		onClose: () => {
			onToastClose();
			props.onClose?.();
		},
	};

	useEffect(() => {
		if (show && toastId) {
			if (!toast.isActive(toastId)) {
				toast.info(<ToastView {...viewProps} />, toastOptions);
			}
		}
	}, [toastId, show]);

	return <></>;
};
