import { TransactionDirection } from "@app/entities";

import { CountryIcon } from "@app/components/country-icon";
import styles from "./transaction-summary.module.css";

import arrowRightSrc from "./arrow-right.svg";
import { formatNumber } from "./format-number";

const getFormattedValues = (str: string) => {
	const [countryCode, rawValue] = str.split(" ");
	const parsedValue = Number.parseFloat(rawValue.replaceAll(",", ""));

	return {
		countryCode,
		value: formatNumber(parsedValue),
	};
};

export const TransactionSummary = ({
	fxAmount,
	zarAmount,
	direction,
}: {
	fxAmount: string;
	zarAmount: string;
	direction: TransactionDirection;
}) => {
	const zarValues = getFormattedValues(zarAmount);
	const fxValues = getFormattedValues(fxAmount);
	return (
		<div className={styles.container}>
			<div className={styles.inner}>
				<CountryIcon
					currencyCode={zarValues.countryCode}
					width={16}
					height={16}
				/>
				{zarValues.countryCode} {zarValues.value}
			</div>
			<img
				src={arrowRightSrc}
				width={18}
				height={18}
				alt=""
				style={{
					transform: `rotate(${direction === "send" ? "0deg" : "180deg"})`,
				}}
			/>
			<div className={styles.inner}>
				<CountryIcon
					currencyCode={fxValues.countryCode}
					width={16}
					height={16}
				/>
				{fxValues.countryCode} {fxValues.value}
			</div>
		</div>
	);
};
