import { Calendar } from "primereact/calendar";
import { memo, useRef } from "react";
import { twMerge } from "tailwind-merge";

import type { ViewProperties } from "./properties";

import { joinClasses } from "@app/utils";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import "./date-picker.css";

export const DatePickerView = memo((props: ViewProperties) => {
	const parentRef = useRef<HTMLDivElement | null>(null);

	const getIconPaddingClass = () => {
		if (props.overrideShowIcon !== false) {
			if (props.iconPosition === "right") return "pr-10";
			return "pl-10";
		}

		return "";
	};

	const getIconPositionClass = () => {
		if (props.iconPosition) {
			if (props.iconPosition === "right") return "date-picker-icon-right";
			return "date-picker-icon-left";
		}
		return "date-picker-no-icon";
	};

	return (
		<div
			ref={(parentDiv) => {
				parentRef.current = parentDiv;
			}}
			className={joinClasses("relative", props.containerClassName)}
		>
			<Calendar
				appendTo={props.appendToParent ? parentRef.current : undefined}
				{...props.calendarTypeProps}
				className={twMerge(
					"date-picker",
					props.isShown && "date-picker-open",
					props.className,
					getIconPositionClass(),
				)}
				dateFormat="d M yy"
				dateTemplate={props.dateTemplate}
				pt={{
					weekDay: {
						className: "date-picker-weekday",
					},
					weekHeader: {
						className: "week-header",
					},
					day: props.getPassThroughForDay,
					panel: {
						className: props.panelClassName,
					},
				}}
				showIcon={props.overrideShowIcon !== false}
				icon={props.icon}
				iconPos={props.iconPosition}
				showOtherMonths
				selectOtherMonths
				enabledDates={props.enabledDates}
				disabled={props.disabled}
				disabledDates={props.disabledDates}
				disabledDays={props.disabledDays}
				inline={props.inline}
				inputClassName={twMerge(getIconPaddingClass(), props.inputClassName)}
				locale={props.locale}
				maxDate={props.maxDate}
				maxDateCount={props.maxDateCount}
				minDate={props.minDate}
				numberOfMonths={props.numberOfMonths ?? 1}
				panelClassName="date-picker-panel"
				placeholder={props.placeholder}
				readOnlyInput={props.readOnlyInput}
				required={props.required}
				showButtonBar={props.showButtonBar}
				showWeek={props.showWeek}
				view={props.view ?? "date"}
				viewDate={props.viewDate}
				onShow={props.onShow}
				onHide={props.onHide}
				onViewDateChange={props.onViewSelectionChange}
			/>
			{props.showChevron && (
				<div className="date-picker-chevron-container">
					{props.isShown ? (
						<FiChevronDown size={20} color="#888" />
					) : (
						<FiChevronUp size={20} color="#888" />
					)}
				</div>
			)}
		</div>
	);
});
