export const TransactionsIcon = () => {
	return (
		<svg
			role="presentation"
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
		>
			<path
				d="M16 7.99836V4.49902C16 3.66728 16 3.25141 15.8248 2.99584C15.6717 2.77254 15.4346 2.62086 15.1678 2.57544C14.8623 2.52346 14.4847 2.69774 13.7295 3.04628L4.85901 7.14036C4.18551 7.45121 3.84875 7.60663 3.60211 7.84768C3.38406 8.06078 3.21762 8.32091 3.1155 8.60819C3 8.93315 3 9.30404 3 10.0458V14.9984M16.5 14.4984H16.51M3 11.1984L3 17.7984C3 18.9185 3 19.4785 3.21799 19.9063C3.40973 20.2827 3.71569 20.5886 4.09202 20.7804C4.51984 20.9984 5.07989 20.9984 6.2 20.9984H17.8C18.9201 20.9984 19.4802 20.9984 19.908 20.7804C20.2843 20.5886 20.5903 20.2827 20.782 19.9063C21 19.4785 21 18.9185 21 17.7984V11.1984C21 10.0783 21 9.51821 20.782 9.09038C20.5903 8.71406 20.2843 8.4081 19.908 8.21635C19.4802 7.99836 18.9201 7.99836 17.8 7.99836L6.2 7.99836C5.0799 7.99836 4.51984 7.99836 4.09202 8.21635C3.7157 8.4081 3.40973 8.71406 3.21799 9.09038C3 9.51821 3 10.0783 3 11.1984ZM17 14.4984C17 14.7745 16.7761 14.9984 16.5 14.9984C16.2239 14.9984 16 14.7745 16 14.4984C16 14.2222 16.2239 13.9984 16.5 13.9984C16.7761 13.9984 17 14.2222 17 14.4984Z"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
