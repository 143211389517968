import { useUserProfile } from "@app/screens/user-settings/use-user-profile";
import { useEffect, useState } from "react";

export const useTutorial = () => {
	const { data: userProfile } = useUserProfile();

	const [isEnabled, setIsEnabled] = useState(false);

	useEffect(() => {
		if (!userProfile) return;
		const key = `tutorial-completed-${userProfile.email}`;
		setIsEnabled(!window.localStorage.getItem(key));
	}, [userProfile]);

	return {
		isEnabled,
		disable: () => {
			if (!userProfile) return;
			window.localStorage.setItem(
				`tutorial-completed-${userProfile.email}`,
				"true",
			);
			setIsEnabled(false);
		},
	};
};
