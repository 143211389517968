import { api } from "@app/services";

export const useQuote = () => {
	return {
		confirmQuote: async (
			quoteId: number,
			{
				valueDate,
			}: {
				valueDate: string;
			},
		) => {
			try {
				const { data } = await api.post<{
					transaction_id: number;
				}>(`transactions/quotes/${quoteId}/confirm/`, {
					value_date: valueDate,
				});
				return [data, null] as const;
			} catch (error: any) {
				try {
					const errorDetail = JSON.parse(error.data.detail);
					return [null, errorDetail] as const;
				} catch (error: any) {
					return [null, error.data?.detail ?? error.message] as const;
				}
			}
		},
	};
};
