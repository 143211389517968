import {
	NavigationStep,
	NavigationSteps,
} from "@app/components/navigation-steps";
import { EntityType } from "@app/types";
import { useLocation } from "react-router-dom";
import styles from "./index.module.css";

export type OnboardingStep = {
	title: string;
	active: boolean;
	completed: boolean;
	path: string;
	description?: string;
	disabled?: boolean;
	key?: string;
	subSteps?: Array<{
		name: string;
		id: string;
		linkId?: string;
		iconSrc: string;
	}>;
};

export const OnboardingSteps = ({
	steps,
	type,
	onStepNavigate,
}: {
	steps: Array<NavigationStep>;
	variant: EntityType;
	type?: string;
	onStepNavigate?: (path: string, targetId?: string | undefined) => void;
}) => {
	const location = useLocation();
	const isPrimaryShareholder = location.pathname.includes(
		"primary-shareholders",
	);

	return (
		<NavigationSteps steps={steps} onStepNavigate={onStepNavigate}>
			{type && (
				<>
					<p>{type}</p>
					<p className={styles.title}>
						{isPrimaryShareholder ? "Primary shareholders" : "Onboarding form"}
					</p>
				</>
			)}
		</NavigationSteps>
	);
};
