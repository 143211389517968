import { useDispatch } from "react-redux";

import type { GetSettlementAccounts } from "@app/entities";
import type { AppDispatch } from "@app/redux";
import { getSettlementAccountsAction } from "@app/redux/transactions/transactions-actions";

export const useGetSettlementAccounts = () => {
	const dispatch: AppDispatch = useDispatch();

	const confirmQuote = (
		clientId: number,
		callback?: (
			settlementAccounts?: GetSettlementAccounts | string[] | undefined,
		) => void,
	) => {
		dispatch(getSettlementAccountsAction(clientId, callback));
	};

	return [confirmQuote];
};
