export const formatFileSize = (
	size: number,
	addSpace?: boolean,
	overrideOptions?: string[],
) => {
	const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));

	const sizeOptions = overrideOptions ?? ["B", "kB", "MB", "GB", "TB"];

	const sizeText = (size / Math.pow(1024, i)).toFixed(2);

	const spacing = addSpace ? " " : "";

	if (i > sizeOptions.length - 1) {
		return size + spacing + sizeOptions[0];
	}

	return sizeText + spacing + sizeOptions[i];
};
