import { getActiveClientFromSession } from "@app/hooks/use-clients";
import { captureWithContext } from "@app/utils/capture-with-context";

export const pathParams = {
	token: "paramToken",
	id: "paramId",
	uid: "paramUid",
};

export const pathSchema = {
	onboarding: {
		userSettings: "/:clientId/onboarding/user-settings",
		individual: {
			personalInformation: "/:clientId/onboarding/individual/identity",
			addressInformation: "/:clientId/onboarding/individual/address",
			additionalDetails: "/:clientId/onboarding/individual/additional-details",
		},
		business: {
			companyBasics: "/:clientId/onboarding/business/company-basics",
			companyDetails: "/:clientId/onboarding/business/company-details",
			primaryShareholders:
				"/:clientId/onboarding/business/primary-shareholders",
			directors: "/:clientId/onboarding/business/directors",
			signatories: "/:clientId/onboarding/business/signatories",
			shareholders: "/:clientId/onboarding/business/shareholders",
		},
	},
	login: "/login",
	notVerified: "/not-verified",
	register: "/register",
	selectService: "/select-service",
	selectForgotPassword: "/select-forgot-password",
	forgotPassword: "/forgot-password",
	resetPassword: "/reset-password",
	passwordReset: `/password-reset/:${pathParams.uid}/:${pathParams.token}`,
	addRecipient: "/:clientId/add-recipient",
	balanceOfPayment: `/:clientId/transaction-details/:${pathParams.id}/balance-of-payment`,
	editRecipient: `/:clientId/edit-recipient/:${pathParams.id}`,
	error: {
		general: "/error",
		generalError: "/error/general",
		fundConfirmBankClosed: `/error/:${pathParams.id}/bank-not-open`,
	},
	dashboard: "/:clientId",
	documents: `/:clientId/transaction-details/:${pathParams.id}/documents`,
	notFound: "/404",
	paymentDetails: `/:clientId/transaction-details/:${pathParams.id}/payment-details`,
	recipients: "/:clientId/recipients/",
	userSettings: `/:clientId/user-settings/:${pathParams.token}?`,
	sendReceiveFunds: `/:clientId/send-receive-funds/:${pathParams.id}?`,
	transactions: "/:clientId/transactions/",
	viewRecipient: `/:clientId/recipients/:${pathParams.id}`,
	viewTransaction: `/:clientId/transactions/:${pathParams.id}`,
	confirmPayment: `/:clientId/confirm/:${pathParams.id}?`,
	reviewTransaction: `/:clientId/review-transaction/:${pathParams.id}`,
};

export const paths = () => ({
	onboarding: {
		userSettings: `/${getActiveClientFromSession()}/onboarding/user-settings`,
		individual: {
			personalInformation: `/${getActiveClientFromSession()}/onboarding/individual/identity`,
			addressInformation: `/${getActiveClientFromSession()}/onboarding/individual/address`,
			additionalDetails: `/${getActiveClientFromSession()}/onboarding/individual/additional-details`,
		},
		business: {
			primaryShareholders: `/${getActiveClientFromSession()}/onboarding/business/primary-shareholders`,
			companyBasics: `/${getActiveClientFromSession()}/onboarding/business/company-basics`,
			companyDetails: `/${getActiveClientFromSession()}/onboarding/business/company-details`,
			directors: `/${getActiveClientFromSession()}/onboarding/business/directors`,
			signatories: `/${getActiveClientFromSession()}/onboarding/business/signatories`,
			shareholders: `/${getActiveClientFromSession()}/onboarding/business/shareholders`,
		},
	},
	addRecipient: `/${getActiveClientFromSession()}/add-recipient`,
	selectService: "/select-service",
	selectForgotPassword: "/select-forgot-password",
	forgotPassword: "/forgot-password",
	balanceOfPayment: (id: number) =>
		`/${getActiveClientFromSession()}/transaction-details/${id}/balance-of-payment`,
	editRecipient: (id?: number) =>
		`/${getActiveClientFromSession()}/edit-recipient/${id ?? ""}`,
	error: {
		general: "/error",
		generalError: (data?: any) => {
			if (data) captureWithContext(new Error("General Error"), data, "error");
			return "/error/general";
		},
		fundConfirmBankClosed: (id: number) => `/error/${id}/bank-not-open`,
	},
	root: "/",
	dashboard: `/${getActiveClientFromSession()}`,
	documents: (id: number) =>
		`/${getActiveClientFromSession()}/transaction-details/${id}/documents`,
	login: "/login",
	logout: "/logout",
	notFound: "/404",
	notVerified: "/not-verified",
	paymentDetails: (id: number) =>
		`/${getActiveClientFromSession()}/transaction-details/${id}/payment-details`,
	recipients: `/${getActiveClientFromSession()}/recipients/`,
	register: "/register",
	userSettings: (token?: string) =>
		token
			? `/${getActiveClientFromSession()}/user-settings/${token}`
			: `/${getActiveClientFromSession()}/user-settings`,
	sendReceiveFunds: (id?: number) =>
		id
			? `/${getActiveClientFromSession()}/send-receive-funds/${id}`
			: `/${getActiveClientFromSession()}/send-receive-funds/`,
	transactions: () => `/${getActiveClientFromSession()}/transactions/`,
	viewRecipient: (id?: number) =>
		`/${getActiveClientFromSession()}/recipients/${id ?? ""}`,
	viewTransaction: (id: number) =>
		`/${getActiveClientFromSession()}/transactions/${id}`,
	confirmPayment: ({
		id,
		duplicateTransactionId,
	}: { id?: number; duplicateTransactionId?: number | null } = {}) => {
		const path = id
			? `/${getActiveClientFromSession()}/confirm/${id}`
			: `/${getActiveClientFromSession()}/confirm`;
		if (duplicateTransactionId) {
			return `${path}?duplicateTransactionId=${duplicateTransactionId}`;
		}
		return path;
	},
	reviewTransaction: (id: number) =>
		`/${getActiveClientFromSession()}/review-transaction/${id}`,
	resetPassword: (variant: "forex" | "arb" = "forex") =>
		`/reset-password?variant=${variant}`,
	passwordReset: (uid: string, token: string) =>
		`/password-reset/${uid}/${token}`,
});
