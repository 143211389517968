import useSWR from "swr";

export type WithdrawalBankAccountOptions = {
	local_banks: Array<{
		id: number;
		name: string;
	}>;
	account_types: Array<{
		name: string;
		value: string;
	}>;
};
export const useWithdrawalBankAccountOptions = () =>
	useSWR<WithdrawalBankAccountOptions>("/withdrawals/bank-account/options/");
