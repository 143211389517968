import { useState } from "react";

import type { CurrencyOption } from "@app/entities";

import { Button } from "@app/components/button";

import { Dialog } from "@app/components/dialog";
import { useCountries } from "@app/hooks/use-countries";
import { MappedCurrency, useCurrencies } from "@app/hooks/use-currencies";
import { useMediaQuery } from "@app/hooks/use-media-query";
import styles from "./index.module.css";
import { Label } from "@app/components/label";
import { MultiSelectNew } from "@app/screens/onboarding/business-company-details/multi-select-new";

export interface FilterByModalState {
	selectedCurrencies?: CurrencyOption[];
	selectedCountries?: string[];
}

interface CurrencyCountryFilters {
	selectedCountries?: string[];
	selectedCurrencies?: CurrencyOption[];
}

export const FilterByModal = (props: {
	isOpen: boolean;
	className?: string;
	selectedCountries?: string[];
	selectedCurrencies?: MappedCurrency[];
	onApplyFilters: () => void;
	onChangeCountryFilters: (value: string[]) => void;
	onChangeCurrencyFilters: (value: MappedCurrency[]) => void;
	onClose: (selectedFilters?: CurrencyCountryFilters) => void;
	onClearFilters: () => void;
	onRemoveCountryFilterTag: (option?: string, runFilter?: boolean) => void;
	onRemoveCurrencyFilterTag: (
		option?: MappedCurrency,
		runFilter?: boolean,
	) => void;
}) => {
	const isMobile = useMediaQuery();
	const { data: countries } = useCountries();
	const { data: currencies, orderedMappedCurrencies } = useCurrencies();

	const defaultState: FilterByModalState = {
		selectedCurrencies: undefined,
		selectedCountries: undefined,
	};

	const [state, setState] = useState<FilterByModalState>(defaultState);

	const handleApplyFilter = () => {
		props.onApplyFilters();
		onClose();
	};

	const onClearFilters = () => {
		setState(defaultState);
		props.onClearFilters();
	};

	const onClose = () => {
		props.onClose({
			selectedCountries: state.selectedCountries,
			selectedCurrencies: state.selectedCurrencies,
		});
	};

	const countriesValue =
		countries && props.selectedCountries && props.selectedCountries?.length > 0
			? props.selectedCountries.map((countryCode) => ({
					name: countryCode,
					id: countryCode,
				}))
			: [];

	const currenciesValue =
		currencies &&
		props.selectedCurrencies &&
		props.selectedCurrencies?.length > 0
			? props.selectedCurrencies.map((currency) => ({
					name: currency.verboseName,
					id: currency.currencyCode,
					countryCode: currency.countryCode,
				}))
			: [];

	return (
		<Dialog
			isOpen={props.isOpen}
			onClose={onClose}
			onBack={isMobile ? onClose : undefined}
			fullscreen={isMobile}
			showTopbar={isMobile}
			title={isMobile ? "Filter" : "Filter by"}
			actions={
				<>
					<Button variant="secondary" onClick={onClearFilters}>
						Clear all
					</Button>
					<Button onClick={handleApplyFilter}>Apply</Button>
				</>
			}
		>
			<div className={styles.column}>
				<div>
					<Label htmlFor="currency">Currency</Label>
					<MultiSelectNew
						mobileHeading="Select currencies"
						placeholder="Select currencies"
						options={
							orderedMappedCurrencies.map((current) => ({
								id: current.currency_code,
								countryCode: current.country_code,
								name: current.verbose_name,
							})) ?? []
						}
						onChange={(value) => {
							const values = value.map((value) => ({
								currencyCode: value.id.toString(),
								countryCode: value.countryCode!,
								verboseName: value.name,
							}));
							props.onChangeCurrencyFilters(values);
						}}
						value={currenciesValue}
					/>
				</div>
				<div>
					<Label htmlFor="countries">Country</Label>
					<MultiSelectNew
						mobileHeading="Select countries"
						placeholder="Select countries"
						options={
							countries?.map((country) => ({ id: country, name: country })) ??
							[]
						}
						onChange={(value) => {
							const values = value.map((country) => country.id.toString());
							props.onChangeCountryFilters(values);
						}}
						value={countriesValue}
					/>
				</div>
			</div>
		</Dialog>
	);
};
