import { ResendEmail } from "@app/screens/auth/resend-email";
import { FiAlertCircle } from "react-icons/fi";

import styles from "./index.module.css";

export const NewEmailNotVerfiedWarning = ({
	email,
	onResend,
}: {
	email: string;
	onResend: () => Promise<boolean>;
}) => {
	return (
		<div className={styles.container}>
			<FiAlertCircle className={styles.icon} color="#FEC84B" size={24} />
			<div>
				<h3 className={styles.title}>New email not verified</h3>
				<p>
					We have sent an email to <strong>{email}</strong> to verify your new
					email address.
				</p>
				<ResendEmail variant="change-email" email={email} onResend={onResend} />
			</div>
		</div>
	);
};
