import { ReactNode } from "react";

import styles from "./index.module.css";

export const FieldDescription = ({
	children,
}: {
	children: ReactNode;
}) => {
	return <p className={styles.text}>{children}</p>;
};
