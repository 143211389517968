import { useShowToast } from "@app/helpers";
import copy from "copy-to-clipboard";
import { useState } from "react";

import { Button, TabButtons } from "@app/components";
import { Dialog } from "@app/components/dialog";
import { useMediaQuery } from "@app/hooks/use-media-query";
import { useBankAccountDetails } from "./use-bank-account-details";

import { MoreInfoTooltip } from "@app/components/more-info-tooltip";
import { ReceivableCurrenciesTooltip } from "../../receivable-currencies-tooltip";
import styles from "./index.module.css";

enum Tabs {
	Local = 1,
	International = 2,
}

export const AddFundsModal = (props: {
	bankAccountId?: number;
	onClose: () => void;
}) => {
	const [showToast] = useShowToast();
	const [tab, setTab] = useState<Tabs>(Tabs.Local);
	const isMobile = useMediaQuery("only screen and (max-width: 50em)");

	const { data: bankAccountDetails, isLoading } = useBankAccountDetails(
		props.bankAccountId,
	);

	const details = isLoading
		? []
		: tab === Tabs.Local
			? [
					{
						label: "Account Holder",
						value: bankAccountDetails?.account_holder,
					},
					{
						label: "Brand Code",
						value: bankAccountDetails?.branch_code,
					},
					{
						label: "Account Number",
						value: bankAccountDetails?.account_number,
					},
					{
						label: "Account Type",
						value: bankAccountDetails?.account_type,
						tooltip:
							'Your account type is actually a "Trading account", but for purposes of depositing funds from other banks you should select "Current” as the account option.',
					},
				]
			: [
					{
						label: "Account Holder",
						value: bankAccountDetails?.account_holder,
					},
					{
						label: "Account Number",
						value: bankAccountDetails?.account_number,
					},
					{
						label: "SWIFT",
						value: bankAccountDetails?.international_details.swift,
					},
					{
						label: "Address",
						value: [
							bankAccountDetails?.international_details.line_1,
							bankAccountDetails?.international_details.line_2,
							bankAccountDetails?.international_details.city,
							bankAccountDetails?.international_details.country,
							bankAccountDetails?.international_details.postal_code,
						]
							.filter((current) => !!current?.trim())
							.join(", "),
					},
				];

	return (
		<Dialog
			isOpen
			fullscreen={isMobile}
			onClose={props.onClose}
			title="Add Funds"
			description={
				isMobile
					? "Use your account details below:"
					: "You can add funds to your bank account using the following details:"
			}
			actions={
				<Button disabled={isLoading} centered onClick={props.onClose}>
					Close
				</Button>
			}
		>
			<TabButtons
				fullWidth
				className={styles.tabButtons}
				active={tab}
				options={[
					{
						label: "Local EFT",
						onClick: () => setTab(Tabs.Local),
						value: Tabs.Local,
					},
					{
						label: "International SWIFT",
						onClick: () => setTab(Tabs.International),
						value: Tabs.International,
					},
				]}
			/>
			{tab === Tabs.International && (
				<div className={styles.whatCurrencies}>
					<p>What currencies can I receive?</p>
					<ReceivableCurrenciesTooltip />
				</div>
			)}
			<ul className={styles.list}>
				{details.map((detail) => (
					<li className={styles.item} key={detail.label}>
						<div>
							<p className={styles.label}>{detail.label}</p>
							<div className={styles.value}>
								<p>{detail.value}</p>
								{detail.tooltip && (
									<MoreInfoTooltip hasIcon maxWidth={340} name={detail.value}>
										{detail.tooltip}
									</MoreInfoTooltip>
								)}
							</div>
						</div>

						<Button
							inline
							size="sm"
							variant="secondary"
							onClick={() => {
								if (detail.value) copy(detail.value);
								showToast("Copied to clipboard", "success");
							}}
						>
							Copy
						</Button>
					</li>
				))}
				<li className={styles.item}>
					{tab !== Tabs.Local && (
						<div className={styles.whatsNext}>
							<p>What’s next?</p>
							<MoreInfoTooltip hasIcon maxWidth={340} name="What’s next?">
								Your Account Manager will notify you when funds arrive via
								SWIFT. You can then exchange the foreign currency via the
								Send/Receive Funds page.
							</MoreInfoTooltip>
						</div>
					)}

					<Button
						centered={tab === Tabs.Local}
						inline
						variant="secondary"
						size="sm"
						disabled={isLoading}
						onClick={() => {
							try {
								if (bankAccountDetails)
									copy(details.map((current) => current.value).join("\n"));
								showToast("Copied to clipboard", "success");
							} catch (error) {
								showToast(
									"An error occurred while copying the details. Please try again.",
									"error",
								);
							}
						}}
					>
						Copy all details
					</Button>
				</li>
			</ul>
		</Dialog>
	);
};
