import { captureException } from "@sentry/react";
import { useEffect } from "react";
import useSWR from "swr";

export type SystemStatus = {
	transactions_enabled: boolean;
	operating_hours: {
		open: string;
		close: string;
	};
	message_title: string;
	message_body: string;
};

export const useSystemStatus = () => {
	const { data, isLoading, error } = useSWR<SystemStatus>(
		"/utilities/system-status/",
		{
			revalidateOnMount: true,
			revalidateOnFocus: true,
			revalidateOnReconnect: true,
		},
	);

	useEffect(() => {
		if (error) {
			captureException(error);
		}
	}, [error]);

	return {
		data: data
			? {
					...data,
					transactions_enabled:
						typeof data?.transactions_enabled === "string"
							? data?.transactions_enabled === "True"
							: data?.transactions_enabled,
				}
			: data,
		isLoading,
		error,
	};
};
