import LayersIcon from "@app/assets/images/3-layers.svg";
import EditIcon from "@app/assets/images/edit-pencil.svg";
import { Button, IconButton } from "@app/components";
import type { ListTransactionInProgress } from "@app/entities";
import { useState } from "react";

import { useMediaQuery } from "@app/hooks/use-media-query";
import { usePaymentBop } from "@app/hooks/use-payment-bop";
import { useTransaction } from "@app/hooks/use-transaction";
import styles from "./index.module.css";

export const BopDetailsBlock = (props: {
	transaction?: ListTransactionInProgress;
	onEdit?: () => void;
}) => {
	const isMobile = useMediaQuery();

	const [showBopDetails, setShowBopDetails] = useState(false);

	const { activePaymentId } = useTransaction(props.transaction?.id);
	const { data: paymentBopDetails } = usePaymentBop(activePaymentId);

	return (
		<div className={styles.container}>
			<div className={styles.heading}>
				<div className={styles.inner}>
					<img src={LayersIcon} width={24} height={24} alt="" />
					<h3 className={styles.title}>BoP Details</h3>
				</div>

				{props.onEdit ? (
					<IconButton
						className={styles.editButton}
						aria-label="Edit"
						onClick={() => {
							if (props.onEdit) props.onEdit();
						}}
					>
						<img src={EditIcon} width={24} height={24} alt="" />
					</IconButton>
				) : (
					<div />
				)}
			</div>

			<div className={`${styles.row} ${styles.contentRow}`}>
				<div className={styles.inner}>
					{paymentBopDetails && (
						<>
							<p className={styles.content}>{paymentBopDetails?.bop_code}</p>
							<p className={styles.content}>{paymentBopDetails?.category}</p>
						</>
					)}
				</div>

				{!isMobile && (
					<Button
						noPadding
						className={styles.showHideButton}
						variant="tertiary"
						inline
						size="sm"
						disabled={!paymentBopDetails}
						onClick={() => setShowBopDetails(!showBopDetails)}
					>
						{showBopDetails ? "Hide details" : "Show details"}
					</Button>
				)}
			</div>

			{showBopDetails && <p>{paymentBopDetails?.description}</p>}

			{isMobile && (
				<Button
					noPadding
					className={styles.showHideButton}
					variant="tertiary"
					inline
					size="sm"
					disabled={!paymentBopDetails}
					onClick={() => setShowBopDetails(!showBopDetails)}
				>
					{showBopDetails ? "Hide details" : "Show details"}
				</Button>
			)}
		</div>
	);
};
