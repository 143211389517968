import { paths } from "@app/constants/paths";
import { fetcher } from "@app/fetcher";
import { useUserProfile } from "@app/screens/user-settings/use-user-profile";
import { api } from "@app/services";
import { EntityType } from "@app/types";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useSWR, { preload } from "swr";
import { useClientProfile } from "./use-client-profile";

export type OnboardingSection =
	| "basic_details"
	| "company_details"
	| "directors"
	| "signatories"
	| "shareholders"
	| "personal_details"
	| "address"
	| "additional_details";

type OnboardingPageOptions = {
	individual_pages: Array<string>;
	business_pages: Array<string>;
};

type OnboardingFormProgress = {
	status_tag: string;
	awaiting_submission_details: {
		steps_completed: Array<string>;
		current_page: OnboardingSection | null;
		note: string;
	};
};

export const getOnboardingRouteFromPage = (
	page: OnboardingSection | null,
	userEntityType: EntityType,
) => {
	if (page === "basic_details")
		return paths().onboarding.business.companyBasics;
	if (page === "company_details")
		return paths().onboarding.business.companyDetails;
	if (page === "directors") return paths().onboarding.business.directors;
	if (page === "signatories") return paths().onboarding.business.signatories;
	if (page === "shareholders") return paths().onboarding.business.shareholders;
	if (page === "personal_details")
		return paths().onboarding.individual.personalInformation;
	if (page === "address")
		return paths().onboarding.individual.addressInformation;
	if (page === "additional_details")
		return paths().onboarding.individual.additionalDetails;

	return userEntityType === "individual"
		? paths().onboarding.individual.personalInformation
		: paths().onboarding.business.companyBasics;
};

export const preloadFormProgress = (clientId: number | string) =>
	preload<OnboardingFormProgress>(
		`/onboarding/${clientId}/onboarding-form-progress/`,
		fetcher,
	);

export const useOnboardingStatus = (
	clientId?: number,
	currentPage?: string,
) => {
	const navigate = useNavigate();
	const { data: userProfile } = useUserProfile();
	const { data: clientProfile } = useClientProfile();

	const { data: formProgress, error } = useSWR<OnboardingFormProgress>(
		clientId ? `/onboarding/${clientId}/onboarding-form-progress/` : null,
	);
	const { data: pageOptions } = useSWR<OnboardingPageOptions>(
		"/onboarding/pages/options/",
	);

	const handleUpdateCurrentPage = useCallback(
		(clientId: number, currentPage: string) =>
			api.put(`/onboarding/${clientId}/current-page/`, {
				current_page: currentPage,
			}),
		[],
	);

	useEffect(() => {
		if (currentPage && clientId) {
			handleUpdateCurrentPage(clientId, currentPage);
		}
	}, [currentPage, handleUpdateCurrentPage, clientId]);

	return {
		pageOptions,
		formProgress,
		error,
		update: handleUpdateCurrentPage,
		goToOnboardingPage: () => {
			if (!userProfile || !formProgress) return;
			const entityType =
				clientProfile?.entity_type ?? userProfile.register_entity_type;
			const path = getOnboardingRouteFromPage(
				formProgress.awaiting_submission_details.current_page,
				entityType,
			);
			navigate(path);
		},
	};
};
