import { FiBriefcase, FiUser, FiUserPlus } from "react-icons/fi";
import styles from "./index.module.css";

const getIcon = (iconName: string) => {
	if (iconName === "FiUser")
		return <FiUser size={32} color="#56A7A2" className="stroke-2 pb-1" />;
	if (iconName === "FiUserPlus")
		return <FiUserPlus size={32} color="#56A7A2" className="stroke-2 pb-1" />;
	if (iconName === "FiBriefcase")
		return <FiBriefcase size={32} color="#56A7A2" className="stroke-2 pb-1" />;
	return null;
};

export const TypeOptions = ({
	options,
	value,
	onChange,
}: {
	options: {
		label: string;
		icon: string;
		type: string;
	}[];
	value?: string;
	onChange: (value: string) => void;
}) => {
	return (
		<ul className={styles.container}>
			{options.map((option) => {
				return (
					<li key={option.label} className={styles.item}>
						<button
							className={styles.button}
							type="button"
							data-active={option.type === value}
							onClick={() => onChange(option.type)}
						>
							{option.icon.startsWith("/static") ? (
								<img width={32} height={32} alt="" src={option.icon} />
							) : (
								getIcon(option.icon)
							)}
							{option.label}
						</button>
					</li>
				);
			})}
		</ul>
	);
};
