import { type ReactNode } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import logoBright from "@app/assets/images/logo-bright.svg";
import logo from "@app/assets/images/logo-home.svg";
import { TabButtons, Toast } from "@app/components";
import { links } from "@app/constants/links";
import { paths } from "@app/constants/paths";
import { useMediaQuery } from "@app/hooks/use-media-query";

import { Graphic } from "./graphic";
import styles from "./index.module.css";
import { NeedAssistance } from "./need-assistance";

enum Page {
	Login = 0,
	Register = 1,
	Verify = 2,
}

export const LoginLayout = ({ children }: { children: ReactNode }) => {
	const isMobile = useMediaQuery();
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const onTabChange = (page: Page) => {
		if (page === Page.Login) {
			navigate(paths().login);
			return;
		}
		navigate(paths().register);
	};

	const currentPage =
		[
			paths().resetPassword,
			paths().login,
			paths().notVerified,
			paths().selectForgotPassword,
			paths().forgotPassword,
		].includes(pathname) || pathname.includes("/password-reset")
			? 0
			: 1;

	return (
		<>
			<div className={styles.container}>
				<main className={styles.main}>
					<header className={styles.header}>
						<a href={links.home} target="_blank" rel="noreferrer">
							<img
								src={isMobile ? logoBright : logo}
								className={styles.logo}
								alt="Future Forex"
							/>
						</a>
					</header>
					<div className={styles.content}>
						<div className={styles.inner}>
							<TabButtons
								active={currentPage}
								options={[
									{
										label: "Log in",
										onClick: () => onTabChange(Page.Login),
										value: Page.Login,
									},
									{
										label: "Register",
										onClick: () => onTabChange(Page.Register),
										value: Page.Register,
									},
								]}
							/>
							{children}
						</div>
					</div>
					<NeedAssistance />
				</main>
				<Graphic />
			</div>
			<Toast autoClose={2000} />
		</>
	);
};
