import { PrimeReactProvider } from "primereact/api";
import { ReactNode } from "react";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import { PersistGate } from "redux-persist/integration/react";
import { SWRConfig } from "swr";

import { store, storePersist } from "@app/redux";

import "react-toastify/dist/ReactToastify.min.css";
import "./index.css";

import { fetcher } from "./fetcher";

export const Providers = ({ children }: { children: ReactNode }) => (
	<SWRConfig
		value={{
			revalidateOnMount: true,
			revalidateOnFocus: false,
			shouldRetryOnError: false,
			dedupingInterval: 5000,
			fetcher,
		}}
	>
		<Provider store={store}>
			<PrimeReactProvider>
				<PersistGate loading={null} persistor={storePersist}>
					{children}
				</PersistGate>
			</PrimeReactProvider>
			<ToastContainer className={"toast-container-position"} />
		</Provider>
	</SWRConfig>
);
