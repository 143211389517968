import useSWR from "swr";

export const useBalance = (backAccountId?: number) => {
	return useSWR<{
		balance: string;
		last_updated: string;
		last_updated_message: string;
		warning_message: string;
	}>(backAccountId ? `/bank-accounts/${backAccountId}/balance/` : null);
};
