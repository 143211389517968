import { ReactNode } from "react";

import styles from "./index.module.css";

import { NotificationsItem } from "./notifications-item";
import { UserItem } from "./user-item";

export const TopBar = ({
	variant = "default",
	isSettings = false,
	leftSection,
}: {
	variant?: "default" | "onboarding";
	isSettings?: boolean;
	leftSection?: ReactNode;
}) => {
	return (
		<div className={styles.container}>
			{leftSection ?? <div />}
			<div className={styles.actions}>
				{variant === "default" && <NotificationsItem />}
				<UserItem variant={variant} isSettings={isSettings} />
			</div>
		</div>
	);
};
