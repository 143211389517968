import { clearCsrfToken, getCrsfToken } from "@app/hooks/use-csrf-token";
import { store } from "@app/redux";
import { captureWithContext } from "@app/utils/capture-with-context";
import axios, {
	AxiosRequestHeaders,
	type AxiosError,
	type AxiosResponse,
} from "axios";
import { apiUrl } from "../config/env";

let controller = new AbortController();

const apiErrors = {
	unauthorized: "Unauthorized",
};

type ResponseInterceptorSuccess = (
	response: AxiosResponse<any, any>,
) => AxiosResponse | Promise<AxiosResponse>;

type ResponseInterceptorError = (
	response: AxiosError<any, any>,
) => void | Promise<void>;

const api = axios.create({
	baseURL: `${apiUrl}api/`,
	signal: controller.signal,
	withCredentials: true,
});

export const handleGeneralErrorRedirect = (error: AxiosError) => {
	if (error.response) {
		const isLoginPath = error.config?.url?.includes("auth/login/");
		const isHTMLResponse =
			error.response?.headers?.["content-type"]?.includes("text/html") &&
			!isLoginPath;
		const isServerError = error.response.status >= 500 && isLoginPath;
		const isBlockedRequest = error.response.status === 0;

		if (error.response.status >= 500) {
			captureWithContext(error, error.response, "response");
		}

		if (isHTMLResponse || isServerError || isBlockedRequest) {
			error.response.data = "An unexpected error occurred. Please try again.";
			window.location.href = "/error";
		}
	}
};

api.interceptors.request.use(async (config) => {
	if (!config.headers?.["X-CSRFToken"]) {
		if (!config.headers) {
			config.headers = {} as AxiosRequestHeaders;
		}

		const isAuthUrl =
			config.url?.includes("users/resend-confirmation-email") ||
			config.url?.includes("auth/login/") ||
			config.url?.includes("users/register") ||
			config.url?.includes("users/send-password-reset-link") ||
			config.url?.includes("users/reset-password");

		if (isAuthUrl) {
			try {
				const result = await axios.get("auth/get-csrf-token/", {
					baseURL: `${apiUrl}api/`,
					withCredentials: true,
				});
				if (result.data.csrf_token) {
					config.headers["X-CSRFToken"] = result.data.csrf_token;
					clearCsrfToken();
				}
			} catch (error) {
				handleGeneralErrorRedirect(error as AxiosError);
				return config;
			}
		} else {
			const storedToken = getCrsfToken();
			if (storedToken) {
				config.headers["X-CSRFToken"] = storedToken;
			}
		}
	}

	return config;
});

export function setToken(headerCsrfToken: string) {
	api.defaults.headers.common["X-CSRFToken"] = headerCsrfToken;
}

export function abortRequests() {
	controller.abort();
	controller = new AbortController();
	api.defaults.signal = controller.signal;
}

const formatSuccessResponseInterceptor: ResponseInterceptorSuccess = (
	response,
) => {
	try {
		// if returned data is a string try convert to object
		if (typeof response.data === "string")
			response.data = JSON.parse(response.data);
		return response;
	} catch {
		return response;
	}
};

const formatErrorResponseInterceptor: ResponseInterceptorError = async (
	error,
) => {
	if (error.code === "ECONNABORTED" || !error.response) {
		captureWithContext(error, { message: "Network error" }, "network");
	}

	if (error.response?.status && error.response.status >= 500) {
		captureWithContext(error, error.response, "response");
	}

	try {
		if (error.response) {
			handleGeneralErrorRedirect(error);

			if (error.request.responseType === "blob") {
				error.response.data = await error.response.data.text();
			}

			if (
				error.response.status === 401 ||
				[apiErrors.unauthorized].indexOf(error.response.data.detail) > -1
			) {
				abortRequests();
				store.dispatch({ type: "RESET" });
			}

			if (typeof error.response.data === "string")
				error.response.data = JSON.parse(error.response.data);
		}
	} catch {
		if (error.response?.status && error.response.status >= 500)
			error.response.data = {
				genericErrors: ["Internal server error."],
			};
	}

	throw error.response;
};

api.interceptors.response.use(
	formatSuccessResponseInterceptor,
	formatErrorResponseInterceptor,
);
export { api };
