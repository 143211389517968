import { Button } from "@app/components/button";
import { Title } from "@app/components/title";

import { useMediaQuery } from "@app/hooks/use-media-query";
import { ResendEmail } from "../../resend-email";
import styles from "./index.module.css";

export const RegisterComplete = ({
	email,
	onRegisterAgain,
	onVerified,
	onResendEmail,
}: {
	email?: string;
	onVerified: () => void;
	onRegisterAgain: () => void;
	onResendEmail: () => Promise<boolean>;
}) => {
	const isMobile = useMediaQuery();
	return (
		<>
			<Title variant={isMobile ? "default" : "small"}>
				{isMobile ? "Verify email address" : "Verify your email address"}
			</Title>
			<div className={styles.description}>
				<p>
					We have sent an email to <strong>{email}</strong> to verify your
					account.
				</p>
			</div>
			<Button centered inline onClick={onVerified}>
				I’ve verified my email
			</Button>
			<div className={styles.divider} />
			<p className={styles.notReceivedYet}>Haven't received an email yet? </p>
			<ResendEmail
				email={email}
				variant="verify-email"
				onResend={onResendEmail}
			/>
			<p className={styles.wrongEmail}>
				Wrong email?{" "}
				<button
					className={styles.registerAgain}
					type="button"
					onClick={onRegisterAgain}
				>
					Register again
				</button>
			</p>
		</>
	);
};
