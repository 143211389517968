import { useMemo, useState } from "react";
import {
	Bar,
	BarChart,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";

import { Dropdown } from "@app/components/controls";
import {
	ClientTradingStats,
	useClientTradingStats,
} from "../use-client-trading-stats";
import styles from "./index.module.css";
import { toDayjs } from "@app/lib/date";

type KeyOfGetClientTradingStats = keyof ClientTradingStats;

const BAR_WIDTH = 14;
const DATE_FORMAT = "DD-MM-YYYY";

const formatValue = (value?: number) => {
	if (!value) return "-";
	return value.toLocaleString(undefined, {
		maximumFractionDigits: 2,
		minimumFractionDigits: 2,
	});
};

const formatLabel = (value?: number) => {
	const formatter = Intl.NumberFormat("en-ZA", {
		notation: "compact",
		maximumFractionDigits: 3,
	});
	if (!value) return "0";
	return formatter.format(value);
};

const LABEL_TO_VALUE_MAP: {
	[key: string]: KeyOfGetClientTradingStats;
} = {
	"ZAR Volume": "zar_volume",
	"Cumulative ZAR Volume": "cumulative_zar_volume",
	"Transaction Count": "transaction_count",
};
const selectOptions = Object.keys(LABEL_TO_VALUE_MAP);

const getPlaceHolderDates = () => {
	const currentDate = toDayjs();
	const dates = [];
	for (let i = 5; i >= 0; i--) {
		dates.push(currentDate.subtract(i, "month").format(DATE_FORMAT));
	}
	return dates;
};

const ChartTooltip = ({ active, payload, label, showZar }: any) => {
	if (active && payload && payload.length) {
		return (
			<div className={styles.tooltip}>
				<p className={styles.tooltipLabel}>
					{toDayjs(label, DATE_FORMAT).format("MMMM YYYY")}
				</p>
				<p className={styles.tooltipValue}>
					{showZar ? "R " : ""}
					{showZar ? formatValue(payload[0].value) : payload[0].value}
				</p>
			</div>
		);
	}
	return null;
};

export const DashboardStatBlock = ({ clientId }: { clientId?: number }) => {
	const { data, isLoading } = useClientTradingStats(clientId);
	const [selected, setSelected] = useState(selectOptions[0]);

	const formattedData = useMemo(() => {
		if (!data?.[LABEL_TO_VALUE_MAP[selected]]) {
			return getPlaceHolderDates().map((date) => ({
				name: date,
				value: 0,
			}));
		}
		return (
			Object.keys(data?.[LABEL_TO_VALUE_MAP[selected]] || {}).map((dataStr) => {
				return {
					name: dataStr,
					value: data?.[LABEL_TO_VALUE_MAP[selected]][dataStr],
				};
			}) ?? []
		);
	}, [data, selected]);

	const currentData = data?.[LABEL_TO_VALUE_MAP[selected]];

	const hasData =
		!!currentData &&
		Object.keys(currentData).length > 0 &&
		Object.values(currentData).some((value) => value > 0);
	const showZar = selected.toLowerCase().includes("zar");

	return (
		<div className={styles.container}>
			<div className={styles.header}>
				<h2 className={styles.title}>Statistics</h2>
				<Dropdown
					className={styles.dropdown}
					options={selectOptions}
					optionLabel="label"
					value={selected}
					placeholder={selected}
					onChange={setSelected}
				/>
			</div>

			<div className={styles.wrapper}>
				{!isLoading && !hasData && <p className={styles.noData}>No data</p>}
				<ResponsiveContainer
					className={styles.chartContainer}
					width="100%"
					height="100%"
				>
					<BarChart
						width={500}
						height={300}
						data={formattedData}
						margin={{
							top: 8,
							right: 8,
							left: 0,
							bottom: 4,
						}}
					>
						<XAxis
							dataKey="name"
							type="category"
							tick={{ fill: "#888", fontSize: "12px" }}
							tickSize={10}
							stroke="#4F4F4F"
							tickFormatter={(date) =>
								toDayjs(date, DATE_FORMAT).format("MMM YY")
							}
						/>
						<YAxis
							dataKey="value"
							allowDecimals={selected !== "Transaction Count"}
							tick={{ fill: "#888", fontSize: "12px" }}
							tickSize={10}
							stroke="#4F4F4F"
							tickFormatter={(tick) => {
								if (showZar) return `R${formatLabel(tick)}`;
								return formatLabel(tick);
							}}
						/>
						{hasData && (
							<Tooltip
								cursor={false}
								content={<ChartTooltip showZar={showZar} />}
							/>
						)}
						<Bar maxBarSize={BAR_WIDTH} dataKey="value" fill="#56A7A2" />
					</BarChart>
				</ResponsiveContainer>
			</div>
		</div>
	);
};
