import { Label } from "@app/components/label";
import { FieldError as RHFFieldError, useFormContext } from "react-hook-form";
import styles from "./index.module.css";

import { FieldError } from "@app/components/field-error";
import checkedSrc from "./checked.svg";

export const RadioGroupField = ({
	name,
	label,
	onChange,
	error,
}: {
	label: string;
	name: string;
	error?: RHFFieldError;
	onChange?: (event: any) => void;
}) => {
	const { register, watch } = useFormContext();
	const value = watch(name);
	return (
		<div>
			<Label htmlFor={name}>{label}</Label>
			<div className={styles.radioGroup}>
				<label className={styles.radioLabel} data-active={value === "yes"}>
					<input
						className={styles.radio}
						value="yes"
						type="radio"
						{...register(name, {
							onChange,
						})}
					/>
					{value === "yes" ? (
						<img src={checkedSrc} alt="" width={16} height={16} />
					) : (
						<div className={styles.unchecked} />
					)}
					Yes
				</label>
				<label className={styles.radioLabel} data-active={value === "no"}>
					<input
						className={styles.radio}
						value="no"
						type="radio"
						{...register(name, {
							onChange,
						})}
					/>
					{value === "no" ? (
						<img src={checkedSrc} alt="" width={16} height={16} />
					) : (
						<div className={styles.unchecked} />
					)}
					No
				</label>
			</div>
			{error && <FieldError>{error?.message}</FieldError>}
		</div>
	);
};
