import { useSelector } from "react-redux";

import type { RootState } from "@app/redux";

import { RemoveBankAccountModalView } from "./remove-bank-account-modal-view";

export const RemoveBankAccountModal = (props: {
	isOpen: boolean;
	onBack: () => void;
	onClose: () => void;
	onRemove: (bankAccountId: number) => void;
}) => {
	const { withdrawalBankAccount } = useSelector(
		(rootState: RootState) => rootState.withdrawals,
	);
	const viewProps = {
		...props,
		bankAccount: withdrawalBankAccount,
	};
	return <RemoveBankAccountModalView {...viewProps} />;
};
