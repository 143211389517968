import { FormErrors } from "@app/utils/get-form-errors";
import { ReactNode, forwardRef, useCallback, useImperativeHandle } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Card } from "../card";
import { RadioGroupField } from "../radio-group-field";
import { Row } from "../row";
import { EntityLayout } from "./entity-layout";
import { getDataForEntityVariant } from "./get-data-for-entity-variant";
import {
	LinkableRelatedEntities,
	RelatedEntityVariant,
} from "./use-related-entities";

import { Dropdown } from "@app/components/dropdown";
import { FieldError } from "@app/components/field-error";
import { Label } from "@app/components/label";
import styles from "./init-entity-form.module.css";

type InitEntityFormInputs = {
	is_linked_entity: string;
	linked_entity_id: number;
};

type InitEntityFromProps = {
	index: number;
	id: number;
	variant: RelatedEntityVariant;
	onRemove?: () => void;
	onYes: (entityId: number) => void;
	onNo: () => void;
	footer?: ReactNode;
	linkableEntities?: Array<LinkableRelatedEntities>;
};

export const InitEntityFrom = forwardRef<any, InitEntityFromProps>(
	(
		{ index, variant, id, footer, onRemove, linkableEntities, onYes, onNo },
		ref,
	) => {
		const { name, linkedEntityLabel, linkedDropdownLabel, placeholder } =
			getDataForEntityVariant(variant);

		const methods = useForm<InitEntityFormInputs>();
		const {
			handleSubmit,
			setError,
			register,
			watch,
			getValues,
			setValue,
			formState: { errors },
		} = methods;

		const onSubmit = useCallback(() => {
			const values = getValues();
			if (!values.is_linked_entity) {
				setError("is_linked_entity", {
					type: "manual",
					message: "This field is required",
				});
			}

			if (values.is_linked_entity === "yes" && !values.linked_entity_id) {
				setError("linked_entity_id", {
					type: "manual",
					message: "This field is required",
				});
			}

			return false;
		}, [setError, getValues]);

		useImperativeHandle(
			ref,
			() => {
				return {
					id,
					submit: onSubmit,
				};
			},
			[id, onSubmit],
		);

		const isLinkedEntity = watch("is_linked_entity") === "yes";

		return (
			<FormProvider {...methods}>
				<EntityLayout
					id={index}
					index={index}
					footer={footer}
					hasName={false}
					displayName={`${name} ${index + 1}`}
					onRemove={onRemove}
				>
					<form
						className={styles.form}
						ref={ref}
						onSubmit={handleSubmit(onSubmit)}
					>
						<Card disableAnimation className={styles.card}>
							<Row style={{ marginBottom: isLinkedEntity ? undefined : 0 }}>
								<RadioGroupField
									name="is_linked_entity"
									label={linkedEntityLabel ?? ""}
									onChange={(event) => {
										const { value } = event.target;
										setValue("is_linked_entity", value);
										if (value === "no") {
											onNo();
										}
									}}
									error={errors.is_linked_entity}
								/>
							</Row>
							{isLinkedEntity && linkableEntities && (
								<Row style={{ marginBottom: 0 }}>
									<div>
										<Label htmlFor="linked_entity_id">
											{linkedDropdownLabel}
										</Label>
										<Dropdown
											{...register("linked_entity_id", {
												required: "This field is required",
												onChange: (event) => {
													const { value } = event.target;
													if (!value) return;
													const matching = linkableEntities.find(
														(entity) => entity.name === value,
													);
													if (matching?.id) {
														setValue("linked_entity_id", value);
														onYes(matching.related_entity_id);
													}
												},
											})}
											loading={!linkableEntities}
											value={
												linkableEntities.find(
													(entity) =>
														entity.related_entity_id ===
														watch("linked_entity_id"),
												)?.name
											}
											invalid={!!errors.linked_entity_id}
											placeholder={placeholder}
											options={linkableEntities.map((current) => current.name)}
										/>
										{errors.linked_entity_id && (
											<FieldError>{errors.linked_entity_id.message}</FieldError>
										)}
									</div>
								</Row>
							)}
						</Card>
					</form>
				</EntityLayout>
			</FormProvider>
		);
	},
);
