import type { GetSettlementAccounts } from "@app/entities";
import { apiTransactions } from "@app/services";

import type { AppThunk } from "../store";
import {
	setSettlementAccounts,
	setSettlementAccountsError,
	setSettlementAccountsLoading,
} from "./transactions-slice";

export const getSettlementAccountsAction =
	(
		clientId?: number,
		callback?: (response?: GetSettlementAccounts | string[]) => void,
	): AppThunk =>
	async (dispatch, getState) => {
		let results: GetSettlementAccounts | string[] | undefined = undefined;

		try {
			if (!clientId) {
				dispatch(setSettlementAccounts(undefined));
			} else {
				dispatch(setSettlementAccountsLoading(true));
				dispatch(setSettlementAccountsError(null));

				const response = await apiTransactions.GetSettlementAccounts(clientId);

				if ("reasons" in response) {
					dispatch(setSettlementAccountsError(response.reasons));
					results = response.reasons;
				} else {
					dispatch(setSettlementAccounts(response));

					results = response;
				}
			}
		} catch (error: any) {
			dispatch(setSettlementAccountsError(error));
		} finally {
			if (callback) callback(results);
			dispatch(setSettlementAccountsLoading(false));
		}
	};
