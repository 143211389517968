import { Dialog } from "@app/components/dialog";
import { useAuth } from "@app/hooks/use-auth";

import { Button } from "@app/components/button";
import { useMediaQuery } from "@app/hooks/use-media-query";
import styles from "./index.module.css";

export const LogoutResumeLaterModal = ({
	onBack,
	onClose,
}: {
	onBack: () => void;
	onClose: () => void;
}) => {
	const { onLogout } = useAuth();
	const isMobile = useMediaQuery();
	return (
		<Dialog
			isOpen
			size="sm"
			title="Logout"
			description="Your changes have been automatically saved."
			classNameDescription={styles.description}
			onClose={onClose}
			actions={
				<>
					<Button block={isMobile} onClick={onBack} variant="secondary">
						Back
					</Button>
					<Button block={isMobile} onClick={onLogout}>
						Logout
					</Button>
				</>
			}
		>
			<p className={styles.description}>
				You can always pick up where you left off by logging back into your
				account.
			</p>
		</Dialog>
	);
};
