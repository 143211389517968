import {
	DependencyList,
	RefObject,
	useCallback,
	useEffect,
	useState,
} from "react";

type ScrollableElement = HTMLElement | null;

export const useOverflowDetect = <T extends ScrollableElement>(
	ref: RefObject<T>,
	deps: DependencyList,
) => {
	const [isOverflowing, setIsOverflowing] = useState<boolean>(false);

	const checkOverflow = useCallback((): void => {
		if (ref.current) {
			const { scrollHeight, clientHeight } = ref.current;
			setIsOverflowing(scrollHeight > clientHeight);
		}
	}, [ref]);

	useEffect(() => {
		if (deps) {
			checkOverflow();
			window.addEventListener("resize", checkOverflow);
			return (): void => window.removeEventListener("resize", checkOverflow);
		}
	}, [checkOverflow, deps]);

	return isOverflowing;
};
