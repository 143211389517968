import useSWR from "swr";

type LatestRecipientTransaction = {
	date: string;
	fx_amount: string;
	id: number;
	payment_type: string;
	status: string;
	zar_amount: string;
	fx_currency: string;
};

export const tempLatestRecipientTransactionMapping = (
	data: LatestRecipientTransaction,
): {
	id: number;
	date: string;
	status: string;
	zarAmount: string;
	fxAmount: string;
	fxCurrency: string;
	paymentType: string;
} => ({
	id: data.id,
	date: data.date,
	status: data.status,
	fxCurrency: data.fx_currency,
	fxAmount: data.fx_amount,
	paymentType: data.payment_type,
	zarAmount: data.zar_amount,
});

export const useLatestRecipientTransactions = (
	recipientId?: string,
	params?: {
		limit?: number;
		offset?: number;
	},
) => {
	return useSWR<{
		items: Array<LatestRecipientTransaction>;
		count: number;
	}>(
		recipientId
			? `recipients/${recipientId}/latest-transactions/?limit=${
					params?.limit ?? 100
				}&offset=${params?.offset ?? 0}`
			: null,
	);
};
