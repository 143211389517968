import { memo } from "react";
import { twMerge } from "tailwind-merge";
import { Tag } from "../tag";
import { Typography } from "../typography";
import type { ViewProperties } from "./properties";

const defaultGroupAtCount = 5;
const tagClassName = twMerge("px-2", "py-0.75", "min-h-8");

const IndividualTag = ({ value, index, ...props }: any) => {
	const tagName = `${props.groupName}-tag-${index}`;

	return (
		<Tag
			className={twMerge(tagClassName, props.tagClassName?.(value))}
			containerClassName={twMerge(props.tagContainerClassName?.(value))}
			tagStyle={props.tagStyle}
			icon={props.tagIcon?.(value)}
			iconPlacement={props.iconPlacement}
			key={tagName}
			showRemoveButton={!props.hideRemoveButtonIndividual}
			removeButtonClassName={props.removeButtonClassName}
			removeButtonPlacement={props.removeButtonPlacementIndividual ?? "right"}
			onRemove={() => props.onRemove?.(value)}
		>
			{props.tagTemplate ? (
				props.tagTemplate(value)
			) : (
				<Typography className="text-gray-1100" theme="textMd">
					{tagName}
				</Typography>
			)}
		</Tag>
	);
};

const GroupedTag = ({ ...props }) => (
	<Tag
		className={twMerge(tagClassName, props.groupTagClassName)}
		containerClassName={twMerge(props.groupTagContainerClassName)}
		removeButtonClassName={props.removeButtonClassName}
		removeButtonPlacement={props.removeButtonPlacement ?? "right"}
		showRemoveButton={!props.hideRemoveButton}
		tagStyle={props.tagStyle}
		onRemove={() => props.onRemoveGroup?.(props.data)}
	>
		{props.groupTemplate ? (
			props.groupTemplate
		) : (
			<>
				<Typography theme="textMd" className={"text-gray-1100"}>
					{props.groupName}
				</Typography>
				{!props.hideGroupCount && (
					<div
						className={twMerge(
							"ml-1.5 h-6 rounded-4px bg-gray-120 px-1.5 py-0.5",
							props.groupCountInnerClassName,
						)}
					>
						<Typography
							theme="textSm"
							className={twMerge(
								"text-gray-1100",
								props.groupCountInnerClassName,
							)}
						>
							{props.data?.length || 0}
						</Typography>
					</div>
				)}
			</>
		)}
	</Tag>
);

export const TagGroupView = memo(
	<T,>({ data, groupAtCount, ...props }: ViewProperties<T>) => (
		<div className={twMerge("flex flex-row", props.className)}>
			{(data?.length || 0) < (groupAtCount || defaultGroupAtCount) ? (
				data?.map((value, i) => (
					<IndividualTag key={i} value={value} index={i} {...props} />
				))
			) : (
				<GroupedTag data={data} {...props} />
			)}
		</div>
	),
);
