import BankIcon from "@app/assets/images/bank-gray.svg";
import CoinsIcon from "@app/assets/images/coins.svg";
import { Button, Typography } from "@app/components";
import { useMediaQuery } from "@app/hooks/use-media-query";
import { formatNumber } from "@app/utils";

import type { ViewProperties } from "./properties";

import { Dialog } from "@app/components/dialog";
import { FiBox, FiShoppingBag } from "react-icons/fi";
import "./confirm-withdrawal-modal.css";

export const ConfirmWithdrawalModalView = (props: ViewProperties) => {
	const isMobile = useMediaQuery();

	return (
		<Dialog
			isOpen={props.isOpen}
			size="xl"
			className="confirm-withdrawal-modal"
			title="Confirm withdrawal"
			fullscreen={isMobile}
			showTopbar={isMobile}
			onBack={props.onBack}
			onClose={props.onClose}
			actions={
				<>
					<Button variant="secondary" onClick={props.onBack}>
						Back
					</Button>
					<Button onClick={props.onConfirm}>Confirm</Button>
				</>
			}
		>
			<div className="confirm-withdrawal-modal-info-card-section">
				<div className="confirm-withdrawal-modal-info-card-amount-section">
					<Typography
						className="confirm-withdrawal-modal-info-card-amount-heading"
						theme="textLg"
					>
						Withdraw Amount
					</Typography>
					<Typography
						className="confirm-withdrawal-modal-info-card-amount"
						theme="displayXs"
					>
						{formatNumber(props.withdrawalDetails?.amount ?? 0)}
					</Typography>
				</div>
				<div className="confirm-withdrawal-modal-info-card-separator" />
				<div className="confirm-withdrawal-modal-info-card-details">
					<div className="confirm-withdrawal-modal-info-card-detail">
						<div className="confirm-withdrawal-modal-info-card-detail-label">
							<img src={BankIcon} alt="" width={24} height={24} />
							<Typography theme="textLg">Bank</Typography>
						</div>
						<Typography
							className="confirm-withdrawal-modal-info-card-detail-value"
							theme="textLg"
						>
							{props.withdrawalDetails?.bank}
						</Typography>
					</div>
					<div className="confirm-withdrawal-modal-info-card-detail">
						<div className="confirm-withdrawal-modal-info-card-detail-label">
							<img src={CoinsIcon} alt="" width={24} height={24} />
							<Typography theme="textLg">Account Number</Typography>
						</div>
						<Typography
							className="confirm-withdrawal-modal-info-card-detail-value"
							theme="textLg"
						>
							{props.withdrawalDetails?.accountNumber}
						</Typography>
					</div>
					<div className="confirm-withdrawal-modal-info-card-detail">
						<div className="confirm-withdrawal-modal-info-card-detail-label">
							<FiBox color="#888" size={24} />
							<Typography theme="textLg">Account Type</Typography>
						</div>
						<Typography
							className="confirm-withdrawal-modal-info-card-detail-value"
							theme="textLg"
						>
							{props.withdrawalDetails?.accountTypeName}
						</Typography>
					</div>
					<div className="confirm-withdrawal-modal-info-card-detail">
						<div className="confirm-withdrawal-modal-info-card-detail-label">
							<FiShoppingBag color="#888" size={24} />
							<Typography theme="textLg">Capitec Withdrawal Fee</Typography>
						</div>
						<div className="withdrawal-type-container">
							<Typography
								className="confirm-withdrawal-modal-info-card-detail-value"
								theme="textLg"
							>
								{props.withdrawalDetails?.withdrawalTypeFee}
							</Typography>
						</div>
					</div>
				</div>
			</div>
		</Dialog>
	);
};
