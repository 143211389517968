import { paths } from "@app/constants/paths";
import { useTransaction } from "@app/hooks/use-transaction";
import { useTransactionId } from "@app/hooks/use-transaction-id";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const useSubmittedTransactionRedirect = () => {
	const navigate = useNavigate();
	const transactionId = useTransactionId();
	const { transaction, paymentStatus } = useTransaction(transactionId);

	useEffect(() => {
		if (!paymentStatus || !transaction?.transaction_id) return;
		const isSubmitted =
			!paymentStatus?.awaiting_submission_details && !paymentStatus.can_edit;
		if (isSubmitted)
			navigate(paths().viewTransaction(transaction.transaction_id));
	}, [transaction, paymentStatus, navigate]);
};
