import { Button } from "@app/components/button";
import { Dialog } from "@app/components/dialog";

export const ResumeLaterModal = (props: {
	onBack?: () => void;
	onClose?: () => void;
	onConfirm?: () => void;
}) => {
	const onClose = () => props.onClose?.();

	return (
		<Dialog
			isOpen
			onClose={onClose}
			title="Resume later"
			description="You can always pick up where you left off."
			actions={
				<>
					<Button onClick={() => props.onBack?.()} variant="secondary">
						Back
					</Button>
					<Button onClick={() => props.onConfirm?.()}>Confirm</Button>
				</>
			}
		/>
	);
};
