import { joinClasses } from "@app/utils";
import { ReactNode } from "react";
import { FiX } from "react-icons/fi";
import { MdCheck, MdInfoOutline, MdWarningAmber } from "react-icons/md";

export type ToastType = "error" | "info" | "success" | "warn";

export interface ToastTheme {
	icon?: ReactNode;
	className?: string;
	content?: ReactNode;
}

const sharedToastStyle = joinClasses(
	"border",
	"font-medium",
	"rounded-lg",
	"text-gray-1100",
	"shadow-none",
	"min-h-13.5",
	"px-4",
	"py-3",
);

export const types: { [key in ToastType]: ToastTheme } = {
	error: {
		icon: <FiX size={20} color="#f04438" />,
		className: joinClasses(sharedToastStyle, "border-red-500", "bg-red-100"),
		content: "Error",
	},
	info: {
		icon: <MdInfoOutline size={20} color="#969696" />,
		className: joinClasses(sharedToastStyle, "border-gray-500", "bg-gray-100"),
		content: "Info",
	},
	success: {
		icon: <MdCheck size={20} color="#008e17" />,
		className: joinClasses(sharedToastStyle, "border-teal-550", "bg-teal-50"),
		content: "Success",
	},
	warn: {
		icon: <MdWarningAmber size={20} color="c9bb22" />,
		className: joinClasses(
			sharedToastStyle,
			"border-yellow-500",
			"bg-yellow-100",
		),
		content: "Warning",
	},
};
