import useSWR from "swr";

export type PaymentStatus = {
	status_tag: string;
	can_edit: boolean;
	in_progress_details: {
		description: string;
		stage: number;
	};
	awaiting_submission_details: {
		steps_completed: string[];
		current_page: string;
		note: string;
	};
};

export const tempPaymentStatusMapping = (data: PaymentStatus) => {
	return {
		statusTag: data.status_tag,
		canEdit: data.can_edit,
		inProgressDetails: data.in_progress_details
			? {
					description: data.in_progress_details.description,
					stage: data.in_progress_details.stage,
				}
			: undefined,
		awaitingSubmissionDetails: data.awaiting_submission_details
			? {
					stepsCompleted: data.awaiting_submission_details.steps_completed,
					currentPage: data.awaiting_submission_details.current_page,
					note: data.awaiting_submission_details.note,
				}
			: undefined,
	};
};

export const usePaymentStatus = (paymentId?: number) =>
	useSWR<PaymentStatus>(paymentId ? `/payments/${paymentId}/status/` : null);
