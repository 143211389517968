import useSWR from "swr";

export type Recipient = {
	nickname: string;
	first_name: string;
	last_name: string;
	entity_type: string;
	company_name: string;
	currencies: string;
	country_label: string;
	bank_details: {
		bank_name: string;
		country: string;
		account_number: string;
		iban: string;
		swift_code: string;
		routing_number: string;
		sort_code: string;
		branch: "string";
	};
	address: {
		address_line_1: string;
		address_line_2: string;
		city: string;
		province: string;
		country: string;
		postal_code: string;
		combined_str: string;
	};
	is_client: boolean;
};

export const tempMapRecipient = (value: Recipient) => {
	return {
		nickname: value.nickname,
		firstName: value.first_name,
		lastName: value.last_name,
		companyName: value.company_name,
		currencies: value.currencies,
		entityType: value.entity_type,
		countryLabel: value.country_label,
		address: value.address
			? {
					addressLine1: value.address.address_line_1,
					addressLine2: value.address.address_line_2,
					city: value.address.city,
					country: value.address.country,
					postalCode: value.address.postal_code,
					province: value.address.province,
					combinedStr: value.address.combined_str,
				}
			: {},
		bankDetails: value.bank_details
			? {
					accountNumber: value.bank_details.account_number,
					bankName: value.bank_details.bank_name,
					branch: value.bank_details.branch,
					country: value.bank_details.country,
					iban: value.bank_details.iban,
					routingNumber: value.bank_details.routing_number,
					sortCode: value.bank_details.sort_code,
					swiftCode: value.bank_details.swift_code,
				}
			: {},
		isClient: value.is_client,
	};
};

export const useRecipient = (recipientId?: number | string) =>
	useSWR<Recipient>(recipientId ? `/recipients/${recipientId}/` : null);
