import { useEffect, useState } from "react";

type UseMountTransitionProps = {
	isMounted: boolean;
	delay: number;
};

const useMountTransition = ({
	isMounted,
	delay,
}: UseMountTransitionProps): boolean => {
	const [hasTransitionedIn, setHasTransitionedIn] = useState(false);

	useEffect(() => {
		let timeoutId: ReturnType<typeof setTimeout>;

		if (isMounted && !hasTransitionedIn) {
			timeoutId = setTimeout(() => setHasTransitionedIn(true), delay);
		} else if (!isMounted && hasTransitionedIn) {
			timeoutId = setTimeout(() => setHasTransitionedIn(false), delay);
		}

		return () => {
			clearTimeout(timeoutId);
		};
	}, [delay, isMounted, hasTransitionedIn]);

	return hasTransitionedIn;
};

export default useMountTransition;
