import clsx from "clsx";
import { ComponentPropsWithoutRef, forwardRef } from "react";

import styles from "./index.module.css";

export const Input = forwardRef<any, ComponentPropsWithoutRef<"input">>(
	({ className, id, name, type = "text", ...rest }, ref) => {
		return (
			<input
				ref={ref}
				className={clsx(styles.input, className)}
				type={type}
				id={id ?? name}
				name={name}
				{...rest}
			/>
		);
	},
);
