import { type ReactNode } from "react";

import { Title } from "@app/components/title";
import { FiMail, FiPhone } from "react-icons/fi";
import styles from "./index.module.css";

export const TransactionErrorCard = ({
	title = "Transaction failed",
	message,
	contact_email,
	contact_number,
	children,
}: {
	title?: string;
	message: string;
	contact_email?: string;
	contact_number?: string;
	children: ReactNode;
}) => {
	const hasContactDetails = contact_email || contact_number;

	return (
		<div className={styles.container}>
			<div className={styles.content}>
				<Title>{title}</Title>
				<p
					className={styles.message}
					dangerouslySetInnerHTML={{
						__html: message,
					}}
				/>
				{hasContactDetails && (
					<div className={styles.links}>
						<a className={styles.link} href={`mailto:${contact_email}`}>
							<FiMail size={16} color="#888" />
							{contact_email}
						</a>
						<a className={styles.link} href={`tel:${contact_number}`}>
							<FiPhone size={16} color="#888" />
							{contact_number}
						</a>
					</div>
				)}
			</div>
			{children}
		</div>
	);
};
