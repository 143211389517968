export const provinces = [
	{
		name: "Eastern Cape",
		value: "eastern_cape",
	},
	{
		name: "Free State",
		value: "free_state",
	},
	{
		name: "Gauteng",
		value: "gauteng",
	},
	{
		name: "KwaZulu-Natal",
		value: "kzn",
	},
	{
		name: "Limpopo",
		value: "limpopo",
	},
	{
		name: "Mpumalanga",
		value: "mpumalanga",
	},
	{
		name: "North West",
		value: "north-west",
	},
	{
		name: "Northern Cape",
		value: "northern-cape",
	},
	{
		name: "Western Cape",
		value: "western_cape",
	},
];
