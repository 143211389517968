import { Button, Typography } from "@app/components";
import { useMediaQuery } from "@app/hooks/use-media-query";

import type { ViewProperties } from "./properties";

import { Dialog } from "@app/components/dialog";
import { useWithdrawalBankAccountOptions } from "../use-withdrawal-bank-account-options";
import "./remove-bank-account-modal.css";

export const RemoveBankAccountModalView = (props: ViewProperties) => {
	const isMobile = useMediaQuery();

	const withdrawalBankAccountOptionsResult = useWithdrawalBankAccountOptions();

	return (
		<Dialog
			isOpen={props.isOpen}
			onClose={props.onClose}
			onBack={isMobile ? undefined : props.onBack}
			fullscreen={isMobile}
			title="Remove account"
			className="remove-bank-account-modal"
			actions={
				<>
					<Button onClick={props.onBack} variant="secondary">
						Cancel
					</Button>
					<Button
						onClick={() => {
							props.onRemove(props.bankAccount?.id as number);
						}}
					>
						Remove
					</Button>
				</>
			}
		>
			<div className="remove-bank-account-modal-details-section">
				<div className="remove-bank-account-modal-bank-account">
					<Typography
						className="remove-bank-account-modal-account-detail-label"
						theme="textSm"
					>
						Bank:
					</Typography>
					<Typography
						className="remove-bank-account-modal-account-detail-value"
						theme="textLg"
					>
						{props.bankAccount?.bank}
					</Typography>
				</div>
				<div className="remove-bank-account-modal-account-number-type">
					<Typography
						className="remove-bank-account-modal-account-detail-label"
						theme="textSm"
					>
						Account Number:
					</Typography>
					<Typography
						className="remove-bank-account-modal-account-detail-value"
						theme="textLg"
					>
						{props.bankAccount?.accountNumber}
					</Typography>
				</div>
				<div className="remove-bank-account-modal-account-number-type">
					<Typography
						className="remove-bank-account-modal-account-detail-label"
						theme="textSm"
					>
						Account Type:
					</Typography>
					<Typography
						className="remove-bank-account-modal-account-detail-value"
						theme="textLg"
					>
						{withdrawalBankAccountOptionsResult.data?.account_types?.find(
							(current) => current.value === props.bankAccount?.accountType,
						)?.name ?? props.bankAccount?.accountType}
					</Typography>
				</div>
			</div>
		</Dialog>
	);
};
