import { type ReactNode, useEffect } from "react";

import { useSetNewPageLoad } from "@app/helpers/auth";
import { abortRequests } from "@app/services";

export const SessionWrapper = ({ children }: { children: ReactNode }) => {
	const [setNewPageLoad] = useSetNewPageLoad();

	const onPageLoad = () => {
		setTimeout(() => {
			setNewPageLoad(false);
		}, 5000);
	};

	useEffect(() => {
		window.addEventListener("load", onPageLoad);
		return function cleanup() {
			abortRequests();
			window.removeEventListener("load", onPageLoad);
		};
	}, []);

	return <>{children}</>;
};
