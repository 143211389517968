import * as Sentry from "@sentry/react";

export const captureWithContext = (
	error: Error,
	context: any,
	contextName = "context",
) => {
	Sentry.setContext(contextName, context);
	Sentry.captureException(error);
};
