import { Dropdown } from "@app/components/dropdown";
import { FieldError } from "@app/components/field-error";
import { Input } from "@app/components/input";
import { Label } from "@app/components/label";
import { useCountries } from "@app/hooks/use-countries";
import { api } from "@app/services";
import {
	formatNumberString,
	removeNumberFormatting,
} from "@app/utils/format-number-string";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Card } from "../card";
import { OnboardingLayout } from "../onboarding-layout";
import { Row } from "../row";
import { TextField } from "../text-field";

import { ApiErrors } from "@app/components/api-errors";
import { FieldDescription } from "@app/components/field-description";
import { links } from "@app/constants/links";
import { paths } from "@app/constants/paths";
import { useFormErrors } from "@app/hooks/use-form-errors";
import { useMediaQuery } from "@app/hooks/use-media-query";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IncompleteInformationModal } from "../incomplete-information-modal";
import { OnboardingFooter } from "../onboarding-footer";
import { RadioGroupField } from "../radio-group-field";
import styles from "./form.module.css";
import { MultiSelectNew } from "./multi-select-new";
import {
	CompanyDetails,
	CompanyDetailsUpdate,
	useCompanyDetails,
} from "./use-company-details";
import { useOnboardingIndustries } from "./use-onboarding-industries";
import { useOnboardingSourceOfFunds } from "./use-onboarding-source-of-funds";

type Inputs = {
	trading_name: string;
	industry_id: number;
	industry_name: string;
	md_ceo_name: string;
	incorporation_country: string;
	average_turnover_per_month: string;
	cash_percent_turnover: string;
	source_of_funds: Array<{
		id: number;
		name: string;
	}>;
	source_of_funds_description: string;
	countries_transacted: Array<string>;
	has_ccn: string;
	ccn: string;
};

const mapFormToDTO = (data: Inputs): CompanyDetailsUpdate => {
	const averageTurnoverNumberValue = data.average_turnover_per_month
		? removeNumberFormatting(data.average_turnover_per_month)
		: "";

	return {
		...data,
		average_turnover_per_month:
			typeof averageTurnoverNumberValue === "string"
				? Number.parseFloat(averageTurnoverNumberValue)
				: undefined,
		cash_percent_turnover:
			typeof data.cash_percent_turnover === "string"
				? Number.parseFloat(data.cash_percent_turnover)
				: undefined,
		source_of_funds: data.source_of_funds?.map((current) => current.id),
		source_of_funds_description: data.source_of_funds_description,
		has_ccn: ["yes", "no"].includes(data.has_ccn)
			? data.has_ccn === "yes"
			: undefined,
		ccn: data.has_ccn === "yes" ? data.ccn : undefined,
	};
};

export const Form = ({ activeClientId }: { activeClientId: number }) => {
	const navigate = useNavigate();
	const isMobile = useMediaQuery();

	const [showGeneralError, setShowGeneralError] = useState(false);
	const [targetPath, setTargetPath] = useState<string | null>(null);
	const [isSavingChanges, setIsSavingChanges] = useState(false);
	const [isSaved, setIsSaved] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const { update, submit } = useCompanyDetails();
	const { data: countries } = useCountries();
	const { data: industries } = useOnboardingIndustries();
	const { data: sourceOfFunds } = useOnboardingSourceOfFunds();

	const methods = useForm<Inputs>({
		shouldFocusError: false,
		defaultValues: async () => {
			try {
				const { data } = await api.get<CompanyDetails>(
					`/onboarding/${activeClientId}/company-details/`,
				);
				const { data: sourceOfFunds } = await api.get<
					Array<{ id: number; name: string }>
				>("/onboarding/source-of-funds/");
				return {
					...data,
					average_turnover_per_month:
						data.average_turnover_per_month?.toString(),
					cash_percent_turnover: data.cash_percent_turnover?.toString(),
					countries_transacted: data.countries_transacted,
					source_of_funds_description: data.source_of_funds_description,
					source_of_funds: data.source_of_funds
						?.map((current) => {
							const match = sourceOfFunds?.find(
								(source) => source.name === current,
							);
							return match;
						})
						.filter(Boolean) as Array<{ id: number; name: string }>,
				} as Inputs;
			} catch (error) {
				setShowGeneralError(true);
				return {} as Inputs;
			}
		},
	});
	const { register, handleSubmit, watch, setValue, getValues } = methods;
	const {
		errors,
		apiErrors,
		onErrors,
		clearApiFieldErrors,
		clearErrors,
		setError,
		onInvalid,
	} = useFormErrors(methods);

	const onSubmit: SubmitHandler<Inputs> = async (data) => {
		clearApiFieldErrors();
		setIsSubmitting(true);
		setIsSavingChanges(true);

		const errors = await submit(mapFormToDTO(data));

		if (errors) {
			onErrors(errors, true);
		} else {
			setIsSaved(true);
			navigate(paths().onboarding.business.directors);
		}
		setIsSavingChanges(false);
		setIsSubmitting(false);
	};

	const handlePartialSave = async () => {
		const data = mapFormToDTO(getValues());
		if (Object.keys(data).length === 0) return;
		setIsSavingChanges(true);

		const errors = await update(data);
		clearApiFieldErrors(data);
		if (errors) {
			onErrors(errors);
		} else {
			setIsSaved(true);
		}
		setIsSavingChanges(false);
	};

	const handleNavigate = async (path: string) => {
		if (!path) return;
		const data = getValues();
		const errors = await submit(mapFormToDTO(data));
		if (errors) {
			setTargetPath(path);
			onErrors(errors, true);
		} else {
			navigate(path);
		}
	};

	const isOtherSourceOfFundsSelected = watch("source_of_funds")?.some(
		(current) => current.name === "Other",
	);

	const countriesTranactedWithField = (
		<div>
			<Label
				htmlFor="countries_transacted"
				tooltip="Add the primary countries the company transacts with."
				tooltipWidth={230}
			>
				Countries transacted with*
			</Label>
			<MultiSelectNew
				mobileHeading="Countries transacted with"
				error={!!errors.countries_transacted}
				placeholder="Select a country"
				options={
					countries?.map((country) => ({ id: country, name: country })) ?? []
				}
				onChange={(value) => {
					const values = value.map((country) => country.id.toString());
					setValue("countries_transacted", values);
					if (values.length > 0) {
						clearErrors("countries_transacted");
					}
					handlePartialSave();
				}}
				value={
					watch("countries_transacted")?.map((country) => ({
						id: country,
						name: country,
					})) ?? []
				}
			/>
			{errors.countries_transacted && (
				<FieldError>{errors.countries_transacted.message}</FieldError>
			)}
			<FieldDescription>
				View our supported currencies and countries{" "}
				<a href={links.supportedCurrencies} target="_blank" rel="noreferrer">
					here
				</a>
			</FieldDescription>
		</div>
	);

	register("source_of_funds", {
		required: "This field is required",
	});
	register("countries_transacted", {
		required: "This field is required",
	});
	register("has_ccn", {
		required: "This field is required",
	});

	return (
		<OnboardingLayout
			step={1}
			variant="legal_entity"
			onStepNavigate={handleNavigate}
			showError={showGeneralError}
		>
			<FormProvider {...methods}>
				<form
					className={styles.form}
					id="business-details"
					onSubmit={handleSubmit(onSubmit, onInvalid)}
				>
					<Card>
						<Row>
							<TextField
								label="Trading name"
								placeholder="Enter a trading name (optional)"
								error={errors.trading_name}
								{...register("trading_name", {
									onBlur: handlePartialSave,
								})}
							/>
							<div>
								<Label htmlFor="industry_name">
									Nature of business / industry*
								</Label>
								<Dropdown
									{...register("industry_name", {
										required: "This field is required",
										onChange: (event) => {
											setValue("industry_name", event.value);
											const match = industries?.find(
												(current) => current.name === event.value,
											);
											if (match) {
												setValue("industry_id", match.id);
											}
											if (event.value) clearErrors("industry_name");
											handlePartialSave();
										},
									})}
									loading={!industries}
									value={watch("industry_name")}
									invalid={!!errors.industry_id || !!errors.industry_name}
									placeholder="Select an industry"
									options={industries?.map((current) => current.name) ?? []}
								/>
								{(errors.industry_id || errors.industry_name) && (
									<FieldError>
										{errors.industry_id?.message ??
											errors.industry_name?.message}
									</FieldError>
								)}
							</div>
						</Row>

						<Row>
							<TextField
								label="Who is the MD / CEO of the company?*"
								placeholder="Enter a full name"
								error={errors.md_ceo_name}
								{...register("md_ceo_name", {
									required: "This field is required",
									onBlur: handlePartialSave,
								})}
							/>
							<div>
								<Label htmlFor="incorporation_country">
									Country of incorporation*
								</Label>
								<Dropdown
									{...register("incorporation_country", {
										required: "This field is required",
										onChange: (event) => {
											setValue("incorporation_country", event.value);
											if (event.value) clearErrors("incorporation_country");
											handlePartialSave();
										},
									})}
									loading={!countries}
									value={watch("incorporation_country")}
									invalid={!!errors.incorporation_country}
									placeholder="Select an country"
									options={countries ?? []}
								/>
								{errors.incorporation_country && (
									<FieldError>
										{errors.incorporation_country.message}
									</FieldError>
								)}
							</div>
						</Row>
					</Card>

					<Card title="Transactional Information">
						<Row>
							<div>
								<Label htmlFor="average_turnover_per_month">
									Average turnover per month*
								</Label>
								<div className={styles.amount}>
									<div className={styles.zar}>ZAR</div>
									<Input
										className={styles.amountInput}
										placeholder="Enter an amount in Rands"
										aria-invalid={!!errors.average_turnover_per_month}
										{...register("average_turnover_per_month", {
											required: "This field is required",
											validate: (value) => {
												if (value) {
													const numberValue = removeNumberFormatting(value);
													const floatValue = Number.parseFloat(numberValue);
													if (floatValue < 0) {
														return "Amount must be greater than 0";
													}
													return true;
												}
												return true;
											},
											onChange: (event) => {
												const value = event.target.value;
												const numberValue = removeNumberFormatting(value);
												setValue("average_turnover_per_month", numberValue);
											},
											onBlur: () => {
												handlePartialSave();
											},
										})}
										value={formatNumberString(
											watch("average_turnover_per_month"),
											" ",
										)}
									/>
								</div>
								{errors.average_turnover_per_month && (
									<FieldError>
										{errors.average_turnover_per_month.message}
									</FieldError>
								)}
							</div>

							<TextField
								label="Cash % of turnover*"
								placeholder="Enter a turnover percentage"
								error={errors.cash_percent_turnover}
								{...register("cash_percent_turnover", {
									required: "This field is required",
									onBlur: () => {
										const value = watch("cash_percent_turnover");
										const parsed = value ? Number.parseFloat(`${value}`) : null;
										if (parsed && (parsed < 0 || parsed > 100)) {
											setError("cash_percent_turnover", {
												type: "manual",
												message: "Percentage must be between 0 and 100",
											});
											return;
										}

										if (
											errors.cash_percent_turnover &&
											errors.cash_percent_turnover.type === "manual"
										) {
											clearErrors("cash_percent_turnover");
										}
										handlePartialSave();
									},
									onChange: (event) => {
										const value = event.target.value;
										const numberValue = value.replace(/[^0-9.]/g, "");
										setValue("cash_percent_turnover", numberValue);
									},
								})}
								tooltip="Cash refers to physical cash payments received (i.e. notes & coins)."
								tooltipWidth={292}
							/>
						</Row>
						<Row>
							<div>
								<Label
									htmlFor="source_of_funds"
									tooltip="Specify the source of funds to be transacted with."
									tooltipWidth={230}
								>
									Source of funds*
								</Label>

								<MultiSelectNew
									options={sourceOfFunds ?? []}
									onChange={(value) => {
										const values = value.map((current) => ({
											id: current.id as number,
											name: current.name,
										}));
										setValue("source_of_funds", values);
										if (values.length > 0) {
											clearErrors("source_of_funds");
										}
										handlePartialSave();
									}}
									error={!!errors.source_of_funds}
									placeholder="Select an option"
									value={watch("source_of_funds") ?? []}
									mobileHeading="Source of funds"
								/>

								{errors.source_of_funds && (
									<FieldError>{errors.source_of_funds.message}</FieldError>
								)}
							</div>

							{isOtherSourceOfFundsSelected ? (
								<TextField
									label="Source of Funds - Other*"
									placeholder={
										isMobile
											? "Please elaborate"
											: 'Elaborate on the "Other" source of funds'
									}
									error={errors.source_of_funds_description}
									{...register("source_of_funds_description", {
										required: "This field is required",
										onBlur: handlePartialSave,
									})}
								/>
							) : (
								countriesTranactedWithField
							)}
						</Row>
						{isOtherSourceOfFundsSelected && (
							<Row>
								{countriesTranactedWithField}
								<div />
							</Row>
						)}
						<Row>
							<RadioGroupField
								error={errors.has_ccn}
								name="has_ccn"
								label="Does the company have a CCN / ​Importer Code?*"
								onChange={() => {
									handlePartialSave();
								}}
							/>

							{watch("has_ccn") === "yes" && (
								<TextField
									label="CCN/​Importer Code*"
									placeholder="Enter the CNN/Importer code"
									error={errors.ccn}
									{...register("ccn", {
										onBlur: handlePartialSave,
									})}
								/>
							)}
						</Row>
						<ApiErrors errors={apiErrors} />
					</Card>
				</form>
			</FormProvider>
			<OnboardingFooter
				isSubmitting={isSubmitting}
				formId="business-details"
				isSaving={isSavingChanges}
				hasSaved={isSaved}
				onBack={() => {
					navigate(paths().onboarding.business.companyBasics);
				}}
			/>

			{!!targetPath && (
				<IncompleteInformationModal
					path={targetPath}
					onCancel={() => setTargetPath("")}
				/>
			)}
		</OnboardingLayout>
	);
};
