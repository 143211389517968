import type {
	GetExchangeDetails,
	GetForexQuote,
	GetSettlementAccounts,
} from "@app/entities";
import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

export type State = {
	confirmQuoteLoading?: boolean;
	confirmQuoteError?: string[] | null;

	exchangeDetailsLoading?: boolean;
	exchangeDetailsError?: string[] | null;
	exchangeDetails?: GetExchangeDetails;

	settlementAccountsLoading?: boolean;
	settlementAccountsError?: string[] | null;
	settlementAccounts?: GetSettlementAccounts;
};

export const defaultState: State = {};

const slice = createSlice({
	name: "transactions",
	initialState: defaultState,
	reducers: {
		setConfirmQuoteLoading(state: State, action: PayloadAction<boolean>) {
			state.confirmQuoteLoading = action.payload;
		},
		setConfirmQuoteError(state: State, action: PayloadAction<string[] | null>) {
			state.confirmQuoteError = action.payload;
		},

		setSettlementAccounts(
			state: State,
			action: PayloadAction<GetSettlementAccounts | undefined>,
		) {
			state.settlementAccounts = action.payload;
		},
		setSettlementAccountsLoading(state: State, action: PayloadAction<boolean>) {
			state.settlementAccountsLoading = action.payload;
		},
		setSettlementAccountsError(
			state: State,
			action: PayloadAction<string[] | null>,
		) {
			state.settlementAccountsError = action.payload;
		},

		setExchangeDetails(
			state: State,
			action: PayloadAction<GetExchangeDetails | undefined>,
		) {
			state.exchangeDetails = action.payload;
		},
		setExchangeDetailsLoading(state: State, action: PayloadAction<boolean>) {
			state.exchangeDetailsLoading = action.payload;
		},
		setExchangeDetailsError(
			state: State,
			action: PayloadAction<string[] | null>,
		) {
			state.exchangeDetailsError = action.payload;
		},
	},
});

export const {
	setExchangeDetails,
	setExchangeDetailsLoading,
	setExchangeDetailsError,

	setConfirmQuoteError,
	setConfirmQuoteLoading,

	setSettlementAccounts,
	setSettlementAccountsError,
	setSettlementAccountsLoading,
} = slice.actions;

export default slice.reducer;
