import { Button } from "@app/components/button";
import { CustomLoader } from "@app/components/custom-loader";
import { SignedInLayout } from "@app/components/signed-in-layout";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Alert } from "./alert";
import { BalanceCard } from "./balance-card";

import { InProgressTransactionCarousel } from "@app/components/in-progress-transaction-carousel";
import { Notifications } from "@app/components/notifications";
import { paths } from "@app/constants/paths";
import type {
	CreateWithdrawal,
	WithdrawalDetails,
	WithdrawalTypes,
} from "@app/entities";
import {
	useCreateWithdrawalRequest,
	useDeleteWithdrawalBankAccount,
	useGetWithdrawalBankAccount,
	useGetWithdrawalBankAccounts,
	useShowToast,
} from "@app/helpers";
import { useMediaQuery } from "@app/hooks/use-media-query";
import type { RootState } from "@app/redux";
import { useNavigate } from "react-router-dom";
import { useClients } from "../../hooks/use-clients";
import {
	tempCompletedTransactionMapping,
	useCompletedTransactions,
} from "../../hooks/use-completed-transactions";
import {
	tempInProgressTransactionMapping,
	useInProgressTransactions,
} from "../../hooks/use-in-progress-transactions";
import { useSystemStatus } from "../../hooks/use-system-status";
import { AddEditBankAccountModal } from "./add-edit-bank-account-modal";
import { ConfirmWithdrawalModal } from "./confirm-withdrawal-modal";
import { DashboardCompletedTransactions } from "./dashboard-completed-transactions";
import { DashboardStatBlock } from "./dashboard-stat-block";
import styles from "./index.module.css";
import { ManageAccountsModal } from "./manage-accounts-modal";
import { RemoveBankAccountModal } from "./remove-bank-account-modal";
import { useWithdrawalBankAccountOptions } from "./use-withdrawal-bank-account-options";
import { WithdrawModal } from "./withdraw-modal";
import { WithdrawalSubmittedModal } from "./withdrawal-submitted-modal";

const Dashboard = () => {
	const systemStatusResult = useSystemStatus();
	const clientsResult = useClients();
	const withdrawalBankAccountOptionsResult = useWithdrawalBankAccountOptions();
	const navigate = useNavigate();
	const isMobile = useMediaQuery();
	const [createWithdrawalRequest] = useCreateWithdrawalRequest();
	const [getWithdrawalBankAccount] = useGetWithdrawalBankAccount();
	const [getWithdrawalBankAccounts] = useGetWithdrawalBankAccounts();
	const [deleteWithdrawalBankAccount] = useDeleteWithdrawalBankAccount();
	const [showToast] = useShowToast();

	const [bankAccountsModalStep, setBankAccountsModalStep] = useState(0);
	const [withdrawModalStep, setWithdrawModalStep] = useState(0);
	const [isEditBankAccount, setIsEditBankAccount] = useState(false);
	const [showBankAccountsModal, setShowBankAccountsModal] = useState(false);
	const [showWithdrawModal, setShowWithdrawModal] = useState(false);
	const [withdrawalDetails, setWithdrawalDetails] =
		useState<WithdrawalDetails>();

	const inProgressTransactionsResult = useInProgressTransactions(
		clientsResult.activeClientId,
	);
	const completedTransactionsResult = useCompletedTransactions(
		clientsResult.activeClientId,
		{
			limit: 3,
			offset: 0,
			ordering: "-transaction_date",
		},
	);

	const { createWithdrawalRequestLoading } = useSelector(
		(rootState: RootState) => rootState.withdrawals,
	);

	const onModalStepChange = (withdrawStep: number, bankAccountStep: number) => {
		setBankAccountsModalStep(bankAccountStep);
		setWithdrawModalStep(withdrawStep);
		setIsEditBankAccount(false);
		setShowBankAccountsModal(bankAccountStep !== 0);
		setShowWithdrawModal(withdrawStep !== 0);
	};

	const onSubmitWithdrawal = (withdrawalDetails: WithdrawalDetails) => {
		const withdrawalOption =
			withdrawalBankAccountOptionsResult.data?.account_types?.find(
				(x) => x.value === withdrawalDetails.accountType,
			);

		if (withdrawalOption) {
			setWithdrawModalStep(2);
			setWithdrawalDetails({
				...withdrawalDetails,
				accountTypeName: withdrawalOption.name,
			});
		}
	};

	const onConfirmWithdrawal = () => {
		if (withdrawalDetails) {
			const detailsAsCreateWithdrawal: CreateWithdrawal = {
				amount: withdrawalDetails.amount,
				bankId: withdrawalDetails.id,
				withdrawalType:
					withdrawalDetails.withdrawalTypeValue as WithdrawalTypes,
			};

			createWithdrawalRequest(detailsAsCreateWithdrawal, (response) => {
				if (response === 200) {
					setWithdrawModalStep(3);
				}
			});
		}
	};

	const onEditBankAccount = (bankAccountId: number) => {
		getWithdrawalBankAccount(bankAccountId, (response) => {
			if (!Array.isArray(response)) {
				setBankAccountsModalStep(2);
				setIsEditBankAccount(true);
			}
		});
	};

	const onClickRemoveBankAccount = (bankAccountId: number) => {
		getWithdrawalBankAccount(bankAccountId, (response) => {
			if (!Array.isArray(response)) {
				setBankAccountsModalStep(3);
			}
		});
	};

	const onRemoveBankAccount = (bankAccountId: number) => {
		deleteWithdrawalBankAccount(bankAccountId, (response) => {
			if (response === 200) {
				getWithdrawalBankAccounts();
				setBankAccountsModalStep(1);
				showToast("Account removed", "success");
			}
		});
	};

	const isLoading =
		(showWithdrawModal && withdrawalBankAccountOptionsResult.isLoading) ||
		createWithdrawalRequestLoading;

	const showConirmWithdrawModal = showWithdrawModal && withdrawModalStep === 2;
	const showManageAccountsModal =
		showBankAccountsModal && bankAccountsModalStep === 1;

	return (
		<SignedInLayout title="Home" mobileRightSection={<Notifications />}>
			{isLoading && <CustomLoader />}
			<div className={styles.container}>
				{systemStatusResult.data && <Alert status={systemStatusResult.data} />}
				<h2 className={styles.title}>Home</h2>
				<div className={styles.cards}>
					<BalanceCard
						isLoading={clientsResult.isLoading}
						backAccountId={clientsResult.activeBankAccountId}
						onWithdraw={() => onModalStepChange(1, 0)}
					/>
					{isMobile ? (
						<Button
							onClick={() => {
								navigate(paths().sendReceiveFunds());
							}}
							block
						>
							Send/Receive funds
						</Button>
					) : (
						<DashboardStatBlock clientId={clientsResult.activeClientId} />
					)}
				</div>
				{isMobile && (
					<>
						<div className={styles.divider} />
						<h2 className={styles.transactionsTitle}>Transactions</h2>
					</>
				)}
				<div className={styles.inProgressContainer}>
					<InProgressTransactionCarousel
						isLoading={
							inProgressTransactionsResult.isLoading || clientsResult.isLoading
						}
						data={inProgressTransactionsResult.data?.map(
							tempInProgressTransactionMapping,
						)}
					/>
				</div>
				{!isMobile && <div className={styles.divider} />}
				<DashboardCompletedTransactions
					isLoading={
						completedTransactionsResult.isLoading || clientsResult.isLoading
					}
					count={completedTransactionsResult.data?.count ?? 0}
					data={completedTransactionsResult.data?.items.map(
						tempCompletedTransactionMapping,
					)}
				/>
			</div>
			{isMobile && (
				<div className={styles.mobileStatCard}>
					<div className={styles.divider} />
					<DashboardStatBlock clientId={clientsResult.activeClientId} />
				</div>
			)}

			<WithdrawModal
				isOpen={
					showWithdrawModal && !showBankAccountsModal && withdrawModalStep === 1
				}
				onAddBankAccount={() => onModalStepChange(1, 2)}
				onClose={() => onModalStepChange(0, 0)}
				onManageAccounts={() => onModalStepChange(0, 1)}
				onSubmit={onSubmitWithdrawal}
				resetValues={withdrawModalStep === 0 || withdrawModalStep === 3}
			/>
			<ConfirmWithdrawalModal
				isOpen={showConirmWithdrawModal}
				onClose={() => onModalStepChange(0, 0)}
				onBack={() => onModalStepChange(1, 0)}
				onConfirm={onConfirmWithdrawal}
				withdrawalDetails={withdrawalDetails}
			/>
			<WithdrawalSubmittedModal
				withdrawalType={withdrawalDetails?.withdrawalTypeName}
				isOpen={showWithdrawModal && withdrawModalStep === 3}
				onClose={() => {
					onModalStepChange(0, 0);
				}}
			/>
			<ManageAccountsModal
				isOpen={showManageAccountsModal}
				onAddBankAccount={() => onModalStepChange(0, 2)}
				onBack={() => onModalStepChange(1, 0)}
				onClose={() => {
					onModalStepChange(0, 0);
				}}
				onDelete={onClickRemoveBankAccount}
				onEdit={onEditBankAccount}
			/>
			<AddEditBankAccountModal
				isEdit={isEditBankAccount}
				isOpen={showBankAccountsModal && bankAccountsModalStep === 2}
				onBack={() => {
					onModalStepChange(
						withdrawModalStep === 1 ? 1 : 0,
						withdrawModalStep === 1 ? 0 : 1,
					);
				}}
				onClose={() => {
					onModalStepChange(0, 0);
				}}
				onSave={() => {
					onModalStepChange(
						withdrawModalStep === 1 ? 1 : 0,
						withdrawModalStep === 1 ? 0 : 1,
					);
				}}
			/>
			<RemoveBankAccountModal
				isOpen={showBankAccountsModal && bankAccountsModalStep === 3}
				onBack={() => {
					onModalStepChange(0, 1);
				}}
				onClose={() => {
					onModalStepChange(0, 0);
				}}
				onRemove={onRemoveBankAccount}
			/>
		</SignedInLayout>
	);
};

export default Dashboard;
