import { useEffect } from "react";

import { api } from "@app/services";
import { captureException } from "@sentry/react";

export const useSetCurrentPage = (
	paymentId: number | null | undefined,
	page: "payment_details" | "bop" | "supporting_documents" | "review",
) => {
	useEffect(() => {
		const handleSet = async () => {
			if (!paymentId) return;
			try {
				await api.put(`payments/${paymentId}/current-page/`, {
					current_page: page,
					payment_id: paymentId,
				});
			} catch (error) {
				captureException(error);
			}
		};
		if (paymentId && page) handleSet();
	}, [paymentId, page]);
};
