import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { paths } from "@app/constants/paths";

import { Button } from "@app/components/button";
import styles from "./index.module.css";
import { ResumeLaterModal } from "./resume-later-modal";

import { FiHome } from "react-icons/fi";

export const TransactionDetailsFooter = ({
	isFailed = false,
	showResumeLater,
	onResumeLaterChange,
	isResumeLaterSecondaryAction = false,
	resumeLaterPath,
	...props
}: {
	onBack: () => void;
	isFailed?: boolean;
	onNext?: () => void;
	onResumeLater?: () => Promise<void>;
	customNextLabel?: string;
	isLoading?: boolean;
	showResumeLater?: boolean;
	resumeLaterPath?: string;
	onResumeLaterChange?: (show: boolean) => void;
	isResumeLaterSecondaryAction?: boolean;
}) => {
	const [isLoading, setLoading] = useState(false);
	const navigate = useNavigate();

	const [showResumeLaterModal, setShowResumeLaterModal] = useState(false);

	const onResumeLater = () => setShowResumeLaterModal(true);

	const onResumeLaterClosed = () => {
		setShowResumeLaterModal(false);
		if (onResumeLaterChange) {
			onResumeLaterChange(false);
		}
	};

	const onResumeLaterConfirm = async () => {
		const path =
			resumeLaterPath ||
			window.sessionStorage.getItem("resume-later-path") ||
			paths().dashboard;
		if (props.onResumeLater) {
			setLoading(true);
			await props.onResumeLater();
			if (onResumeLaterChange) {
				onResumeLaterChange(false);
			}
			navigate(path);
			setLoading(false);
		} else {
			navigate(path);
		}
	};

	useEffect(() => {
		if (typeof showResumeLater === "boolean") {
			setShowResumeLaterModal(showResumeLater);
		}
	}, [showResumeLater]);

	return (
		<>
			<div
				className={styles.footer}
				style={{
					justifyContent: isFailed ? "center" : "space-between",
				}}
			>
				{isFailed ? (
					<Button onClick={() => navigate(paths().dashboard)}>
						<FiHome size={20} />
						Home
					</Button>
				) : (
					<>
						{!isResumeLaterSecondaryAction ? (
							<Button
								className={styles.button}
								disabled={props.isLoading || isLoading}
								variant="secondary"
								onClick={props.onBack}
							>
								Back
							</Button>
						) : (
							<Button
								className={styles.button}
								variant="secondary"
								disabled={props.isLoading || isLoading}
								onClick={onResumeLater}
							>
								Resume later
							</Button>
						)}
						<Button
							className={styles.button}
							type={props.onNext ? "button" : "submit"}
							disabled={props.isLoading || isLoading}
							onClick={() => props.onNext?.()}
						>
							{props.customNextLabel ? props.customNextLabel : "Next"}
						</Button>
					</>
				)}
			</div>
			{showResumeLaterModal && (
				<ResumeLaterModal
					onBack={onResumeLaterClosed}
					onClose={onResumeLaterClosed}
					onConfirm={onResumeLaterConfirm}
				/>
			)}
		</>
	);
};
