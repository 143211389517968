import { FieldError } from "@app/components/field-error";
import { Label } from "@app/components/label";
import { clsx } from "clsx";
import { useRef } from "react";
import { provinces } from "../individual-address-information/provinces";

import { FieldError as RHFFieldError, useFormContext } from "react-hook-form";

import { Dropdown } from "@app/components/dropdown";
import { Input } from "@app/components/input";
import styles from "./index.module.css";

export const ProvinceField = ({
	error,
	provinceFieldName,
	otherFieldName,
	onChange,
	disabled,
}: {
	provinceFieldName: string;
	otherFieldName: string;
	countryFieldName: string;
	error?: RHFFieldError;
	disabled?: boolean;
	onChange?: (value: string) => void;
}) => {
	const { register, setValue, watch, clearErrors } = useFormContext();
	const otherDropdownRef = useRef<any>(null);
	const fieldValue = watch(provinceFieldName);
	const isOtherSelected = watch(provinceFieldName) === "other";

	const value =
		fieldValue === "other"
			? "Other"
			: provinces.find((current) => current.value === fieldValue)?.name;

	const registration = register(provinceFieldName, {
		required: "This field is required",
		onChange: (event) => {
			event.preventDefault();
			const isNoChange = !event.value || value === event.value;
			if (isNoChange) return;
			const matchingProvince =
				event.value === "Other"
					? "other"
					: provinces.find((current) => current.name === event.value)?.value;
			if (!matchingProvince) return;
			setValue(provinceFieldName, matchingProvince);
			if (error?.type === "required") clearErrors(provinceFieldName);
			if (onChange) onChange(matchingProvince);
		},
	});

	const options = [...provinces.map((current) => current.name), "Other"];

	return (
		<div>
			<Label htmlFor={provinceFieldName}>
				{isOtherSelected ? "State / Province*" : "Province*"}
			</Label>
			<div className={styles.otherContainer}>
				<Dropdown
					disabled={disabled}
					className={clsx(isOtherSelected && styles.otherDropdown)}
					name={registration.name}
					onChange={registration.onChange}
					ref={(ref) => {
						otherDropdownRef.current = ref;
						registration.ref(ref);
					}}
					value={value}
					invalid={!!error}
					placeholder={
						isOtherSelected
							? "Enter other state / province"
							: "Select a province"
					}
					options={options}
					valueTemplate={
						isOtherSelected
							? () => <span style={{ color: "#262626" }}>Other</span>
							: undefined
					}
				/>
				{isOtherSelected && (
					<Input
						disabled={disabled}
						aria-invalid={!!error}
						placeholder="Enter other state / province"
						{...register(otherFieldName, {
							required: "This field is required",
						})}
					/>
				)}
			</div>
			{error && <FieldError>{error.message}</FieldError>}
		</div>
	);
};
