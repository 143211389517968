import { api } from "@app/services";
import { getApiError } from "@app/utils/get-api-error";
import { getFilenameFromHeader } from "@app/utils/get-filename-from-header";
import { saveAs } from "file-saver";

export const useDownloadStatement = () => {
	return {
		downloadStatement: async (params: {
			bankAccountId?: number;
			fromDate?: string;
			toDate?: string;
		}) => {
			try {
				const { data, headers } = await api.get(
					`bank-accounts/${params.bankAccountId}/statement/`,
					{
						responseType: "blob",
						params: {
							from_date: params.fromDate,
							to_date: params.toDate,
						},
					},
				);
				const fileName = getFilenameFromHeader(
					headers,
					`${params.fromDate}-${params.toDate}.pdf`,
				);
				saveAs(data, fileName);
			} catch (error: any) {
				return getApiError(error);
			}
		},
	};
};
