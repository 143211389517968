import { useClients } from "@app/hooks/use-clients";
import { api } from "@app/services";
import { getFormErrors } from "@app/utils/get-form-errors";
import useSWR from "swr";

export type PersonalInformation = {
	first_name: string;
	middle_names: string;
	last_name: string;
	email_address: string;
	contact_number: string;
	id_number: string;
	tax_number: string;
	id_document: string;
	id_selfie: string;
};

export type PersonalInformationUpdate = {
	first_name: string;
	middle_names: string;
	last_name: string;
	email_address: string;
	contact_number: string;
	id_number: string;
	tax_number: string;
};

export const usePersonalInformation = () => {
	const { activeClientId } = useClients();
	const { data, error } = useSWR<PersonalInformation>(
		activeClientId
			? `/onboarding/${activeClientId}/personal-information/`
			: null,
	);

	return {
		data,
		error,
		update: async (newData: Partial<PersonalInformationUpdate>) => {
			try {
				await api.patch(
					`/onboarding/${activeClientId}/personal-information/`,
					newData,
				);
			} catch (error: any) {
				return getFormErrors(error);
			}
		},
		submit: async (newData: PersonalInformationUpdate) => {
			try {
				await api.put(
					`/onboarding/${activeClientId}/personal-information/`,
					newData,
				);
			} catch (error: any) {
				return getFormErrors(error);
			}
		},
	};
};
