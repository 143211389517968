import React, { ReactNode } from "react";
import { useEffect, useState } from "react";

import type { SelectButtonState } from "./models/select-button-state";
import { type Properties, ViewProperties } from "./properties";
import { SelectButtonView } from "./select-button-view";

export const SelectButton = <T,>(props: Properties<T>) => {
	const defaultState: SelectButtonState<T> = {
		selected: props.value,
	};

	const [state, setState] = useState<SelectButtonState<T>>(defaultState);

	const disabled = (value: unknown) =>
		props.disabled ? props.disabled(value as T) : false;

	const optionTemplate = (value: unknown, index: number) => {
		return props.optionTemplate
			? props.optionTemplate(value as T, index)
			: undefined;
	};

	const optionClassName = (value: unknown, index: number) => {
		return props.optionClassName
			? props.optionClassName(value as T, index)
			: "";
	};

	const onChanged = (value?: unknown) => {
		const valueAsT = value as T;
		if (valueAsT !== undefined) {
			let newSelected = [...(state.selected || [])];

			const foundIndex = newSelected?.findIndex((x) => x === valueAsT);

			if (foundIndex === -1) {
				if (!props.maxSelectable || props.maxSelectable === 1) {
					newSelected = [valueAsT];
				} else if (newSelected.length < (props.maxSelectable || 1)) {
					newSelected?.push(valueAsT);
				} else {
					if (!props.preventSelectionAtMax) {
						switch (props.removeOrder) {
							case "LIFO": {
								newSelected.pop();
								break;
							}
							case "FIFO": {
								newSelected.splice(0, 1);
								break;
							}
							default: {
								newSelected.splice(0, 1);
								break;
							}
						}

						newSelected?.push(valueAsT);
					}
				}
			} else if (
				props.deselectable &&
				foundIndex !== undefined &&
				foundIndex > -1
			) {
				newSelected?.splice(foundIndex, 1);
			}

			setState({ ...state, selected: newSelected });
			props.onChange?.(newSelected);
		} else {
			setState({ ...state, selected: undefined });
			props.onChange?.(undefined);
		}
	};

	useEffect(() => {
		if (props.value && props.value !== state.selected) {
			setState({ ...state, selected: props.value });
		}
	}, [props.value]);

	const optionsWrappingTemplate = (optionsContent: unknown) =>
		props.optionsWrappingTemplate?.(optionsContent as ReactNode[]);

	return (
		<SelectButtonView
			{...props}
			selectedIndices={state.selected}
			disabled={disabled}
			optionTemplate={optionTemplate}
			optionClassName={optionClassName}
			onChange={undefined}
			onChanged={onChanged}
			optionsWrappingTemplate={
				props.optionsWrappingTemplate ? optionsWrappingTemplate : undefined
			}
		/>
	);
};
