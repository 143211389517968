import { type ReactNode } from "react";

import { joinClasses } from "@app/utils";
import styles from "./index.module.css";

export const Note = ({
	children,
	variant = "default",
	className,
	showBreak = false,
}: {
	children: ReactNode;
	variant?: "default" | "full" | "fit";
	className?: string;
	showBreak?: boolean;
}) => {
	return (
		<p className={joinClasses(styles.note, className)} data-variant={variant}>
			<strong>Note:</strong>
			{showBreak && <br />} {children}
		</p>
	);
};
