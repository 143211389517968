import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useWebSocket, { ReadyState } from "react-use-websocket";

import { wsUrl } from "@app/config/env";
import { paths } from "@app/constants/paths";
import type {
	ForexQuote,
	ForexQuoteConfirmPage,
	GetForexQuote,
	GetForexQuoteForm,
} from "@app/entities";
import {
	type FieldInvalidationDetailResponse,
	type MappedReasons,
} from "@app/services";

interface ForexQuoteConfirmPageResponse {
	fx_amount: string;
	zar_amount: string;
}

interface ForexQuoteResponse {
	quote_id: number;
	spread: string;
	amount: string;
	rate: string;
	quote_page_display: string;
	confirm_page_display: ForexQuoteConfirmPageResponse;
	value_dates_disabled: {
		[valueDate: string]: boolean;
	};
}

interface WebsocketErrorResponse {
	errors?: FieldInvalidationDetailResponse[];
}

function getForexQuoteApi(clientId: number): string {
	if (wsUrl) {
		return `${wsUrl}api/clients/${clientId}/forex-quote/`;
	}
	const host = document.location.host;
	return `wss://${host}/api/clients/${clientId}/forex-quote/`;
}

const mapToGetForexQuoteConfirmPage = (
	value: ForexQuoteConfirmPageResponse,
): ForexQuoteConfirmPage => {
	return {
		fxAmount: value?.fx_amount,
		zarAmount: value?.zar_amount,
	};
};

const mapToGetForexQuote = (value: ForexQuoteResponse): ForexQuote => {
	return {
		quoteId: value.quote_id,
		amount: value.amount,
		spread: value.spread,
		rate: value.rate,
		quotePageDisplay: value.quote_page_display,
		confirmPageDisplay: mapToGetForexQuoteConfirmPage(
			value.confirm_page_display,
		),
		valueDatesDisabled: Object.keys(value.value_dates_disabled ?? []).filter(
			(current) => value.value_dates_disabled[current],
		),
	};
};

const mapErrors = (exception: WebsocketErrorResponse) => {
	const fieldReasons: FieldInvalidationDetailResponse[] | undefined =
		Array.isArray(exception.errors)
			? (exception as WebsocketErrorResponse).errors
			: undefined;

	const mappedReasons: MappedReasons | undefined = fieldReasons
		? {
				quoteAmount:
					fieldReasons
						?.filter((x) => x.loc.includes("amount"))
						?.map((x) => x.msg || "") || [],
			}
		: undefined;

	return mappedReasons;
};

export const useGetForexQuoteWebsocket = (
	clientId: number,
	params?: GetForexQuoteForm | GetForexQuote,
	isEnabled = true,
) => {
	const [value, setValue] = useState<ForexQuote | undefined>(undefined);
	const [errors, setErrors] = useState<MappedReasons | undefined>(undefined);
	const [showOutOfHours, setShowOutOfHours] = useState(false);
	const [wsUrl, setWsUrl] = useState<string | null>(null);
	const navigate = useNavigate();

	function clear() {
		setWsUrl(null);
		setValue(undefined);
		setErrors(undefined);
		setShowOutOfHours(false);
	}

	const { lastJsonMessage, readyState } = useWebSocket(
		isEnabled ? wsUrl : null,
		{
			onClose(event) {
				console.log("Websocket closed", event);
			},
			onOpen(event) {
				console.log("Websocket opened", event);
			},
			shouldReconnect: () => !showOutOfHours,
			onError: (event) => {
				console.log("Websocket error", event);
			},
			retryOnError: false,
			reconnectInterval: 5000,
			reconnectAttempts: 3,
		},
	);

	// Run when the connection state (readyState) changes
	useEffect(() => {
		if (readyState === ReadyState.OPEN && lastJsonMessage) {
			if (lastJsonMessage.error) {
				if (lastJsonMessage.error_code === 4402) {
					setShowOutOfHours(true);
					return;
				}

				if (lastJsonMessage.error_code === 4422) {
					const errorsObject: WebsocketErrorResponse = JSON.parse(
						lastJsonMessage.error,
					);
					setErrors(mapErrors(errorsObject));
					return;
				}
				navigate(paths().error.generalError(lastJsonMessage));
				return;
			}
			setErrors(undefined);
			setShowOutOfHours(false);
			setValue(mapToGetForexQuote(lastJsonMessage));
		}
	}, [lastJsonMessage, readyState, navigate]);

	useEffect(() => {
		if (
			params?.transactionType &&
			params?.fxCurrency &&
			params?.settlementAccounts
		) {
			const value =
				params.fxCurrency === params.quoteCurrency && params.fxAmount
					? params.fxAmount
					: params.quoteAmount;
			const quoteAmountString = value
				? value.replace(/,/g, "").toString()
				: "0";

			const forexQuote = {
				fxCurrency: params.fxCurrency || "",
				quoteAmount: Number.parseFloat(quoteAmountString || "").toFixed(2),
				quoteCurrency: params.quoteCurrency,
				transactionType: params.transactionType,
			};

			const listOfVariables = [
				`transaction_type=${forexQuote?.transactionType}`,
				`fx_currency=${forexQuote?.fxCurrency}`,
				`quote_currency=${forexQuote?.quoteCurrency}`,
				`quote_amount=${forexQuote?.quoteAmount}`,
				`settlement_account_id=${params.settlementAccounts.bankAccountId}`,
			];

			const wsUrl = `${getForexQuoteApi(clientId)}?${listOfVariables.join(
				"&",
			)}`;
			setWsUrl(wsUrl);
		}
	}, [
		params?.fxCurrency,
		params?.quoteAmount,
		params?.fxAmount,
		params?.transactionType,
		params?.settlementAccounts,
	]);

	return {
		value,
		mappedReasons: errors,
		showOutOfHours,
		clear,
	};
};
