export function concatenateArrays(
	...args: (string[] | undefined | null)[]
): string[] {
	let results: string[] = [];

	for (const arg of args) {
		results = results.concat(arg || []);
	}

	return results.filter((x) => x);
}
