import { type SetStateAction, useEffect, useRef, useState } from "react";

import type { StateCallback } from "@app/entities";

export function useStateWithCallback<T>(
	value: T,
): [T, (value: T | SetStateAction<T>, callback?: StateCallback<T>) => void] {
	const [state, setState] = useState<T>(value);

	const onChangeCallback = useRef<StateCallback<T> | undefined>(undefined);

	useEffect(() => {
		onChangeCallback.current?.(state);
	}, [state]);

	return [
		state,
		(value: T | SetStateAction<T>, callback?: StateCallback<T>) => {
			setState(value);
			onChangeCallback.current = callback;
		},
	];
}
