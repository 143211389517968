import React, { ReactNode } from "react";

import { Button } from "@app/components/button";
import { Dialog } from "@app/components/dialog";

export const ConfirmationModal = ({
	description,
	heading,
	onClose,
	buttonText,
	children,
}: {
	heading?: string;
	buttonText?: string;
	onClose: () => void;
	children?: ReactNode;
	description?: ReactNode;
}) => {
	return (
		<Dialog
			title={heading}
			isOpen
			onClose={onClose}
			actions={
				<Button centered onClick={onClose}>
					{buttonText}
				</Button>
			}
			isSmallHeader
			description={description}
		>
			{children}
		</Dialog>
	);
};
