import { ReactNode } from "react";

import { Typography } from "@app/components";

import type { ViewProperties } from "../properties";

import { FiChevronDown } from "react-icons/fi";
import "./components.css";
import { Fragment } from "react";

export const MultiSelectDropDown = <T,>(
	props: ViewProperties<T> & {
		onClick: () => void;
	},
) => {
	const getValue = () => {
		if (props.value && Array.isArray(props.value) && props.value.length > 0) {
			return props.value;
		}
		return [];
	};

	const getContentWrapper = (content: ReactNode) => {
		const outlinedContent = (
			<div
				onClick={props.onClick}
				className="drop-down-wrapper flex flex-row"
				style={{ flexDirection: "row" }}
			>
				{content}
			</div>
		);

		return props.hideOutlineContent ? (
			getValue().length === 0 ? (
				<>{outlinedContent}</>
			) : (
				<>{content}</>
			)
		) : (
			<>{outlinedContent}</>
		);
	};

	return (
		<>
			{getContentWrapper(
				<>
					{/* looks like its within the dropdown */}
					{getValue().length > 0 && !props.hideOutlineContent && (
						<div className="inner-wrapper">
							{getValue().map((option, index) => (
								<Fragment key={index}>
									{props.valueTemplate?.(option, index)}
								</Fragment>
							))}
						</div>
					)}
					{getValue().length > 2 && !props.hideOutlineContent && (
						<div
							className="flex h-100% max-h-12 flex-row items-center"
							onClick={props.onClick}
						>
							<Typography
								theme="textSm"
								className="whitespace-nowrap text-nowrap"
							>
								{`+${getValue().length - 2}`} more
							</Typography>
						</div>
					)}

					{/* Looks like its just floating on its own */}
					{getValue().length === 1 && props.hideOutlineContent && (
						<>{props.valueTemplate?.(getValue()[0], 0, props.onClick)}</>
					)}
					{getValue().length > 1 && props.hideOutlineContent && (
						<div
							className="item-wrapper flex h-100% max-h-12 w-fit max-w-fit flex-row gap-4"
							onClick={props.onClick}
						>
							<Typography
								theme="textSm"
								className="whitespace-nowrap text-nowrap"
							>
								{props.placeholder === "Currency" ? "Currencies" : "Recipients"}
							</Typography>
							<div className="rounded-1 bg-gray-110 px-3 py-1">
								{getValue().length}
							</div>
						</div>
					)}

					{getValue().length === 0 && (
						<Typography theme="textSm" className={"placeholder-text"}>
							{props.placeholder}
						</Typography>
					)}
					{props.showDropDownIcon &&
						(!props.hideOutlineContent || getValue().length === 0) && (
							<FiChevronDown
								className="chevron-down-icon mr-2"
								size={20}
								color="#888"
							/>
						)}
				</>,
			)}
		</>
	);
};
