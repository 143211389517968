import { Button, IconButton } from "@app/components";

import type { ViewProperties } from "./properties";

import { Dialog } from "@app/components/dialog";
import { useMediaQuery } from "@app/hooks/use-media-query";
import { FiPlus, FiTrash2 } from "react-icons/fi";
import styles from "./index.module.css";

export const ManageAccountsModalView = (props: ViewProperties) => {
	const isMobile = useMediaQuery();
	const hasAccounts =
		props.withdrawalBankAccounts?.items &&
		props.withdrawalBankAccounts.items.length > 0;
	return (
		<Dialog
			title="Manage accounts"
			isOpen={props.isOpen}
			onClose={props.onClose}
			onBack={isMobile ? undefined : props.onBack}
			size="xl"
			fullscreen={isMobile}
			className="manage-accounts-modal"
			actions={
				<>
					<Button
						disabled={props.loading}
						variant="secondary"
						onClick={props.onBack}
					>
						Back
					</Button>
					<Button
						className={styles.addAccountButton}
						disabled={props.loading}
						onClick={props.onAddBankAccount}
					>
						<FiPlus size={20} />
						Add bank account
					</Button>
				</>
			}
		>
			{hasAccounts ? (
				<ul className={styles.list}>
					{props.withdrawalBankAccounts?.items?.map((account, index) => {
						return (
							<li className={styles.item} key={index}>
								<div className={styles.detail}>
									<p className={styles.bankName}>{account.bank}</p>
									<p>{account.accountNumber}</p>
								</div>
								<div className={styles.actions}>
									<Button
										disabled={props.loading}
										size="sm"
										variant="secondary"
										inline
										onClick={() => props.onEdit(account.id as number)}
										type="button"
									>
										Edit
									</Button>
									<IconButton
										size="sm"
										disabled={props.loading}
										onClick={() => props.onDelete(account.id as number)}
									>
										<FiTrash2 size={24} />
									</IconButton>
								</div>
							</li>
						);
					})}
				</ul>
			) : (
				<div className={styles.noAccounts}>No accounts added yet</div>
			)}
		</Dialog>
	);
};
