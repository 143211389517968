export const HomeIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="25"
			height="24"
			viewBox="0 0 25 24"
			fill="none"
			role="presentation"
		>
			<path
				d="M9.5 20.9988V13.5988C9.5 13.0387 9.5 12.7587 9.60899 12.5448C9.70487 12.3566 9.85785 12.2036 10.046 12.1078C10.2599 11.9988 10.5399 11.9988 11.1 11.9988H13.9C14.4601 11.9988 14.7401 11.9988 14.954 12.1078C15.1422 12.2036 15.2951 12.3566 15.391 12.5448C15.5 12.7587 15.5 13.0387 15.5 13.5988V20.9988M11.5177 2.76278L4.73539 8.0379C4.28202 8.39052 4.05534 8.56683 3.89203 8.78764C3.74737 8.98322 3.6396 9.20356 3.57403 9.43783C3.5 9.7023 3.5 9.98948 3.5 10.5638V17.7988C3.5 18.9189 3.5 19.4789 3.71799 19.9067C3.90973 20.2831 4.21569 20.589 4.59202 20.7808C5.01984 20.9988 5.57989 20.9988 6.7 20.9988H18.3C19.4201 20.9988 19.9802 20.9988 20.408 20.7808C20.7843 20.589 21.0903 20.2831 21.282 19.9067C21.5 19.4789 21.5 18.9189 21.5 17.7988V10.5638C21.5 9.98948 21.5 9.7023 21.426 9.43783C21.3604 9.20356 21.2526 8.98322 21.108 8.78764C20.9447 8.56683 20.718 8.39052 20.2646 8.03791L13.4823 2.76278C13.131 2.48953 12.9553 2.3529 12.7613 2.30038C12.5902 2.25404 12.4098 2.25404 12.2387 2.30038C12.0447 2.3529 11.869 2.48953 11.5177 2.76278Z"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
