import CheckIcon from "@app/assets/images/check.svg";
import DownloadIcon from "@app/assets/images/download-teal.svg";
import EditIcon from "@app/assets/images/edit-pencil.svg";
import FileIcon from "@app/assets/images/file.svg";
import { IconButton, Typography } from "@app/components";

import type { ListTransactionInProgress } from "@app/entities";

import { BopDocument } from "@app/hooks/use-bop-documents";
import { SupportingDocument } from "@app/hooks/use-supporting-documents";
import styles from "./index.module.css";

export const DocumentsDetailsBlockView = (props: {
	transaction?: ListTransactionInProgress;
	onEdit?: () => void;
	loading: boolean;
	documents?: SupportingDocument[];
	requiredDocuments?: BopDocument[];
	onDownloadDoc: (document: SupportingDocument) => void;
}) => {
	return (
		<div className={styles.container}>
			{props.loading ? null : (
				<>
					<div className={styles.headingRow}>
						<div className={styles.leftCol}>
							<img alt="" src={FileIcon} width={24} height={24} />
							<Typography theme="displayXs" className="font-medium">
								Documents
							</Typography>
						</div>

						{props.onEdit && (
							<IconButton
								aria-label="Edit document"
								className={styles.editButton}
								onClick={() => {
									if (props.onEdit) props.onEdit();
								}}
							>
								<img src={EditIcon} width={24} height={24} alt="" />
							</IconButton>
						)}
					</div>
					<div className={styles.bodyRow}>
						{props.documents?.length === 0 ? (
							<div>
								<Typography
									theme="textMd"
									className={styles.noDocumentsPlaceholder}
								>
									No documents added
								</Typography>
							</div>
						) : (
							props.requiredDocuments?.map((doc) => {
								let match = props.documents?.find((current) => {
									return current.document_type_id === doc.document_type_id;
								});

								if (
									!match &&
									props.documents?.length === 1 &&
									props.requiredDocuments?.length === 1
								) {
									match = props.documents[0];
								}

								if (!match) return null;

								return (
									<div key={doc.document_type_id} className={styles.rowItem}>
										<div className={styles.leftCol}>
											<img src={CheckIcon} width={16} height={16} alt="" />
											<Typography theme="textMd">
												{doc.document_name}
											</Typography>
										</div>

										<div className="right-col justify-end">
											<IconButton
												size="sm"
												aria-label="Download document"
												className={styles.downloadButton}
												onClick={() => {
													props.onDownloadDoc(match);
												}}
											>
												<img src={DownloadIcon} width={16} height={16} alt="" />
											</IconButton>
										</div>
									</div>
								);
							})
						)}
					</div>
				</>
			)}
		</div>
	);
};
