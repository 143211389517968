import useSWR from "swr";

export type BopDocument = {
	document_type_id: number;
	document_name: string;
	required: boolean;
};

export const useBopDocuments = (paymentId?: number) => {
	return useSWR<BopDocument[]>(
		paymentId ? `/payments/${paymentId}/bop-documents/` : null,
	);
};
