import { useClients } from "@app/hooks/use-clients";
import { useMemo, useState } from "react";
import useSWR from "swr";

export interface Notification {
	id: number;
	title: string;
	description: string;
	visible: boolean;
	priority: number;
}

const STORAGE_KEY = "notifications";

const getReadNotificationsForClient = (clientId: number): number[] => {
	try {
		const storage = window.localStorage.getItem(STORAGE_KEY);
		const parsed = storage ? JSON.parse(storage) : {};
		return parsed[clientId] || [];
	} catch {
		window.localStorage.removeItem(STORAGE_KEY);
		return [];
	}
};

const setReadNotificationsForClient = (
	clientId: number,
	notifications: number[],
) => {
	const storage = window.localStorage.getItem(STORAGE_KEY);
	const parsed = storage ? JSON.parse(storage) : {};
	parsed[clientId] = Array.from(new Set(notifications));
	window.localStorage.setItem(STORAGE_KEY, JSON.stringify(parsed));
};

export const useNotifications = () => {
	const { activeClientId } = useClients();
	const { data, error, isLoading } = useSWR<Notification[]>(
		"/utilities/notifications/",
	);

	const [readNotifications, setReadNotifications] = useState(
		getReadNotificationsForClient(activeClientId || 0),
	);

	const notifications = useMemo(() => {
		if (!data) return [];
		const sorted = [...data].sort((a: Notification, b: Notification) => {
			if (
				readNotifications.includes(a.id) &&
				!readNotifications.includes(b.id)
			) {
				return 1;
			}

			if (
				!readNotifications.includes(a.id) &&
				readNotifications.includes(b.id)
			) {
				return -1;
			}

			if (a.priority > b.priority) return -1;
			if (a.priority < b.priority) return 1;
			return 0;
		});

		return sorted;
	}, [data, readNotifications]);

	const markAsRead = (notificationId: number) => {
		if (activeClientId) {
			setReadNotifications([...readNotifications, notificationId]);
			setReadNotificationsForClient(
				activeClientId,
				readNotifications.concat(notificationId),
			);
		}
	};

	return {
		data,
		hasNotifications: !!data?.length,
		notifications,
		readNotifications,
		unreadCount: notifications.filter(
			(notification) => !readNotifications.includes(notification.id),
		).length,
		hasUnreadNotifications:
			!isLoading &&
			notifications.some(
				(notification) => !readNotifications.includes(notification.id),
			),
		markAsRead,
		isLoading,
		error,
	};
};
