import useSWR from "swr";

export type SupportingDocument = {
	document_type_id: number;
	document_type: string;
	documents: Array<{
		id: number;
		document_name: string;
		document_extension: string;
		document_size: string;
	}>;
};

export const useSupportingDocuments = (paymentId?: number) => {
	return useSWR<Array<SupportingDocument>>(
		paymentId ? `/payments/${paymentId}/supporting-documents/` : null,
	);
};
