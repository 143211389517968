import { Button } from "@app/components/button";
import styles from "./index.module.css";

export const Footer = (props: {
	onClickDelete: () => void;
	onSendFunds: () => void;
}) => {
	return (
		<>
			<div className={styles.footer}>
				<Button variant="secondary" onClick={() => props.onClickDelete()}>
					Remove
				</Button>
				<Button onClick={() => props.onSendFunds()}>Send funds</Button>
			</div>
		</>
	);
};
