import { useCurrencies } from "@app/hooks/use-currencies";
import { Fragment } from "react";

import { CountryIcon } from "@app/components/country-icon";
import { MoreInfoTooltip } from "@app/components/more-info-tooltip";

import styles from "./index.module.css";

export const ReceivableCurrenciesTooltip = () => {
	const { data: currencies } = useCurrencies();

	const mappedCurrencies = currencies?.currencies.map(
		(currency) =>
			currencies.currency_mapping.find(
				(current) => current.currency_code === currency,
			)!,
	);
	const mappedCurrenciesDealingRoomOnly = currencies?.dealing_room_only?.map(
		(currency) =>
			currencies.currency_mapping.find(
				(current) => current.currency_code === currency,
			)!,
	);

	const sections = [
		{
			title: "Available online:",
			items: mappedCurrencies,
		},
		{
			title:
				"Dealing room only currencies. <br> Please call us (021 518 0559) to exchange:",
			items: mappedCurrenciesDealingRoomOnly,
		},
	];

	return (
		<MoreInfoTooltip hasIcon name="What currencies can I receive?">
			<div className={styles.container}>
				{sections.map((section) => (
					<Fragment key={section.title}>
						<p className={styles.title}>
							<div
								dangerouslySetInnerHTML={{
									__html: section.title,
								}}
							/>
						</p>
						<ul className={styles.list}>
							{section.items?.map((item) => (
								<li key={item.currency_code} className={styles.item}>
									<CountryIcon
										width={16}
										height={16}
										currencyCode={item.currency_code}
									/>
									<p>{item.currency_code}</p>
								</li>
							))}
						</ul>
					</Fragment>
				))}
			</div>
		</MoreInfoTooltip>
	);
};
