import { ReactNode, SetStateAction } from "react";
import { FieldValues, UseFormSetError } from "react-hook-form";
import { FormErrors } from "./get-form-errors";

export const setFormErrors = <T extends FieldValues>(
	errors: FormErrors,
	setError: UseFormSetError<T>,
	setApiErrors: (value: SetStateAction<ReactNode[]>) => void,
	shouldFocusError = false,
) => {
	for (const current of errors.fieldErrors) {
		setError(current.name as any, {
			type: "api",
			message: current.message,
		});
	}
	setApiErrors(errors.apiErrors);
	if (shouldFocusError) {
		setTimeout(() => {
			const errorElems = document.querySelectorAll(".field-error");
			if (errorElems.length > 0) {
				errorElems[0].scrollIntoView({
					behavior: "smooth",
					block: "center",
				});
			}
		}, 200);
	}
};
