import useSWR from "swr";

export type AccountManager = {
	name: string;
	title: string;
	email: string;
	contact_number: string;
	profile_photo: string;
};

export const tempAccountManagerMapping = (data?: AccountManager) => {
	if (!data) return undefined;
	return {
		name: data.name,
		title: data.title,
		email: data.email,
		contactNumber: data.contact_number,
		profilePhoto: data.profile_photo,
	};
};

export const useAccountManager = (clientId?: number) =>
	useSWR<AccountManager>(
		clientId ? `/clients/${clientId}/account_manager/` : null,
	);
