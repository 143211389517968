import { useState } from "react";
import { Controller } from "react-hook-form";

import { ErrorLabel, Typography } from "@app/components";
import { DatePicker } from "@app/components/controls";

import { FiAlertCircle } from "react-icons/fi";
import styles from "../form-builder.module.css";
import { showMappedReasons } from "../helpers";
import type { DatePickerPartialProps } from "../types";

export const DatePickerPartial = (props: DatePickerPartialProps) => {
	return (
		<Controller
			name={props.name}
			control={props.formControl}
			rules={{
				required: props.required ? "This field is required" : false,
				validate: (x) =>
					(props.onCustomValidationRule && props.onCustomValidationRule(x)) ||
					props.customErrorMessage,
			}}
			render={({ field, fieldState: { error } }) => {
				const [focused, setFocused] = useState(false);

				return (
					<div
						className={`${props.fieldRowClassName || ""} ${styles.inputRow}`}
					>
						{props.showLabel ? (
							<Typography
								theme="textSm"
								className={`${styles.formLabel} ${
									focused ? "text-teal-550" : ""
								}`}
							>
								{props.title +
									(props.required && !props.hideAsterisk ? "*" : "")}
							</Typography>
						) : (
							<></>
						)}
						<DatePicker
							appendToParent={props.appendToParent}
							className={props.className}
							disabled={props.disabled}
							{...field}
							panelClassName={props.panelClassName}
							placeholder={props.placeholder}
							dateTemplate={props.dateTemplate}
							defaultValue={props.defaultValue}
							disabledDates={props.disabledDates}
							disabledDays={props.disabledDays}
							enabledDates={props.enabledDates}
							inline={props.inline}
							inputClassName={props.inputClassName}
							maxDate={props.maxDate}
							maxDateCount={props.maxDateCount}
							minDate={props.minDate}
							numberOfMonths={props.numberOfMonths}
							readOnlyInput={props.readOnlyInput}
							required={props.required}
							selectionMode={props.selectionMode}
							onChange={(value) => {
								props.onChange && props.onChange(value);
								field.onChange(value);
							}}
							onHide={() => setFocused(false)}
							onShow={() => setFocused(true)}
						/>
						{error && (
							<ErrorLabel>
								<FiAlertCircle size={16} color="#f04438" />
								<span>{error.message}</span>
							</ErrorLabel>
						)}
						{!error &&
							showMappedReasons(
								props.mappedName ?? field.name,
								"#f04438",
								props.mappedReasons,
							)}
					</div>
				);
			}}
		/>
	);
};
