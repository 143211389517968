import React from "react";

import type { CurrencyOption } from "@app/entities";

import { MultiSelect } from "../multi-select";
import type { ViewProperties } from "./properties";

import { CountryIcon } from "@app/components/country-icon";

import { MappedCurrency } from "@app/hooks/use-currencies";
import styles from "./index.module.css";

export const MultiSelectCurrencyViewResponsive = (props: ViewProperties) => {
	const handleRemove = (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		currency: CurrencyOption,
	) => {
		event.preventDefault();
		event.stopPropagation();
		props.onRemoveSelectedItem?.(currency);
	};

	const renderCurrencyOption = (
		currency: MappedCurrency,
		index: number,
		onClick?: () => void,
		inline?: boolean,
	) => (
		<div
			data-inline={inline}
			className={styles.selectedTag}
			onClick={onClick}
			key={index}
		>
			<CountryIcon
				width={20}
				height={20}
				currencyCode={currency.currencyCode}
			/>
			<p>{currency.currencyCode}</p>
			<button
				type="button"
				aria-label="Remove"
				onClick={(e) => handleRemove(e, currency)}
			>
				<svg
					role="presentation"
					xmlns="http://www.w3.org/2000/svg"
					width="14"
					height="14"
					viewBox="0 0 14 14"
					fill="none"
				>
					<path
						d="M10.5 3.5L3.5 10.5M3.5 3.5L10.5 10.5"
						stroke="#B0B0B0"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			</button>
		</div>
	);

	return (
		<MultiSelect
			{...props}
			hideOutlineContent={props.hideOutlineContent}
			filterPlaceholder="Search currencies"
			valueTemplate={(
				currency: MappedCurrency,
				index: number,
				onClick?: () => void,
			) => (
				<>
					{index <= 1 && renderCurrencyOption(currency, index, onClick, true)}
					{index >= 2 &&
						props.hideOutlineContent &&
						renderCurrencyOption(currency, index, onClick)}
				</>
			)}
			options={props.options ?? props.defaultOptions ?? []}
			itemTemplate={(option) => (
				<div className={styles.item} key={option.currencyCode}>
					<CountryIcon
						width={24}
						height={24}
						currencyCode={option.currencyCode}
					/>
					<p>
						{option?.currencyCode} - {option?.verboseName}
					</p>
				</div>
			)}
			headingText={props.headingText ?? "Filter by currency"}
		/>
	);
};
