import { twMerge } from "tailwind-merge";

import { Button, Paginator, TagGroup, Typography } from "@app/components";
import { SignedInLayout } from "@app/components/signed-in-layout";
import type { Control } from "react-hook-form";
import styles from "./index.module.css";

import type { FilterRecipientsByForm } from "@app/entities";

import type { RecipientTableModel } from "./models/models";
import type { RecipientsState } from "./models/recipients-state";

import { CountryIcon } from "@app/components/country-icon";
import { Popover } from "@app/components/popover";
import { Search } from "@app/components/search";
import { MappedCurrency } from "@app/hooks/use-currencies";
import { useEffect, useState } from "react";
import "./recipients.css";
import { SortableTable } from "@app/components/sortable-table";
import { RemoveRecipientModal } from "./remove-recipient-modal";
import { FilterSection } from "./filter-section";

export const RecipientsView = (props: {
	appliedFilters: RecipientsState;
	currentFilters: RecipientsState;
	control: Control<FilterRecipientsByForm, any>;
	currentPage?: number;
	filterGroupSize: number;
	recipients?: RecipientTableModel[];
	loading?: boolean;
	openDeleteModal: boolean;
	openFilterModal: boolean;
	state: {
		currencies?: string[];
		countries?: string[];
		sortOrder: number;
		sortField: string;
		recipient_name?: string;
		limit?: string;
		offset?: string;
		search?: string;
	};
	total?: number;
	onAdd: () => void;
	onApplyFilters: () => void;
	onNavigatePage: (
		id: number,
		destination: "sendFunds" | "edit" | "history",
	) => void;
	onRemoveRecipient: (id: number) => void;
	onChangeCountryFilters: (value: string[]) => void;
	onChangeCurrencyFilters: (value: MappedCurrency[]) => void;
	onClearFilters: () => void;
	onDelete: () => void;
	onFilterClosed: () => void;
	onFilterByName: (value: string) => void;
	onPageChange: (page: number, rowsPerPage: number) => void;
	onSortByName: (sortOrder: number, sortField: string) => void;
	onRemoveCountryFilterTag: (value?: string, runFilter?: boolean) => void;
	onRemoveCountryFiltersTag: (value?: string[]) => void;
	onRemoveCurrencyFilterTag: (
		value?: MappedCurrency,
		runFilter?: boolean,
	) => void;
	onRemoveCurrencyFiltersTag: (values?: MappedCurrency[]) => void;
	setOpenFilterModal: (value: boolean) => void;
	setOpenDeleteModal: (value: boolean) => void;
}) => {
	return (
		<SignedInLayout
			title="Recipients"
			footer={
				<div className="recipients-footer">
					<Paginator
						onNavigatePage={props.onPageChange}
						totalRecords={props.total || 0}
						currentPage={props.currentPage}
					/>
				</div>
			}
		>
			<div className="min-h-[calc(100vh-82px-8rem)]">
				<>
					<div className={styles.header}>
						<h2 className={styles.title}>Recipients</h2>
						<div className={styles.actions}>
							<Button
								onClick={() => {
									props.onAdd();
								}}
							>
								Add new recipient
							</Button>

							<div className={styles.divider} />

							<Search
								placeholder="Search recipients"
								className={styles.search}
								value={props.state.recipient_name || ""}
								onChange={(event) => props.onFilterByName(event.target.value)}
								onClear={() => {
									props.onFilterByName("");
								}}
							/>

							<Button
								className={styles.filterButton}
								variant="secondary"
								style={{ marginLeft: "0.5rem" }}
								onClick={() =>
									props.openFilterModal
										? props.setOpenFilterModal(false)
										: props.setOpenFilterModal(true)
								}
							>
								{props.openFilterModal ? "Hide" : "Show"} filters
								<svg
									data-open={props.openFilterModal}
									className={styles.filterButtonIcon}
									width="20"
									height="20"
									viewBox="0 0 20 20"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
									role="presentation"
								>
									<path
										d="M5 7.5L10 12.5L15 7.5"
										stroke="currentColor"
										strokeWidth="2"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</Button>
						</div>
					</div>
					<div className={styles.appliedFiltersRow}>
						<p className={styles.count}>
							{props.total === undefined
								? "Recipients"
								: `${props.total ?? 0} recipient${(props.total ?? 0) === 1 ? "" : "s"}`}
						</p>
						{!props.openFilterModal && (
							<div className="flex flex-row justify-end gap-x-2">
								{(props.appliedFilters.currencies?.length || 0) > 0 && (
									<Popover
										toggleContent={
											<TagGroup
												className="gap-x-2"
												data={props.appliedFilters.currencies}
												groupAtCount={3}
												groupName={"Currencies"}
												groupTagContainerClassName="px-0"
												tagContainerClassName={() => "px-0"}
												tagStyle="filter"
												tagTemplate={(option) => (
													<span
														className={twMerge("flex", "flex-row", "gap-2")}
													>
														<CountryIcon
															width={20}
															height={20}
															currencyCode={option.currencyCode}
														/>

														<Typography theme="textMd">
															{option.currencyCode}
														</Typography>
													</span>
												)}
												onRemove={(value) =>
													props.onRemoveCurrencyFilterTag(value, true)
												}
												onRemoveGroup={props.onRemoveCurrencyFiltersTag}
											/>
										}
										popperContent={
											(props.appliedFilters.currencies?.length || 0) >=
											props.filterGroupSize ? (
												<div className="flex w-max flex-col items-start rounded-2xl bg-gray-880 px-3.5 py-2.5 text-white">
													{props.appliedFilters.currencies?.map((option) => (
														<span
															key={option.currencyCode}
															className={twMerge(
																"flex",
																"flex-row",
																"gap-2",
																"gap-x-1",
															)}
														>
															<CountryIcon
																width={20}
																height={20}
																currencyCode={option.currencyCode}
															/>

															<Typography theme="textMd">
																{option.currencyCode}
															</Typography>
														</span>
													))}
												</div>
											) : undefined
										}
										showOnHover
										placement={"bottom"}
										offset={[0, 7]}
									/>
								)}
								{(props.appliedFilters.countries?.length || 0) > 0 && (
									<Popover
										toggleContent={
											<TagGroup
												className="gap-x-2"
												data={props.appliedFilters.countries}
												groupAtCount={props.filterGroupSize}
												groupName="Countries"
												groupTagContainerClassName={"px-0 gap-x-2"}
												tagTemplate={(option) => (
													<Typography theme="textMd">{option}</Typography>
												)}
												tagContainerClassName={() => "px-0"}
												tagStyle="filter"
												onRemove={(value) =>
													props.onRemoveCountryFilterTag(value, true)
												}
												onRemoveGroup={props.onRemoveCountryFiltersTag}
											/>
										}
										popperContent={
											(props.appliedFilters.countries?.length || 0) >=
											props.filterGroupSize ? (
												<div className="flex w-max flex-col items-start rounded-2xl bg-gray-880 px-3.5 py-2.5 text-white">
													{props.appliedFilters.countries?.map((x) => (
														<Typography key={x} theme="textSm">
															{x}
														</Typography>
													))}
												</div>
											) : undefined
										}
										showOnHover
										placement={"bottom"}
										offset={[0, 7]}
									/>
								)}
							</div>
						)}
					</div>

					<FilterSection
						selectedCountries={props.currentFilters.countries}
						selectedCurrencies={props.currentFilters.currencies}
						isOpen={props.openFilterModal}
						onApplyFilters={props.onApplyFilters}
						onChangeCountryFilters={props.onChangeCountryFilters}
						onChangeCurrencyFilters={props.onChangeCurrencyFilters}
						onClose={props.onFilterClosed}
						onClearFilters={props.onClearFilters}
						onRemoveCountryFilterTag={props.onRemoveCountryFilterTag}
						onRemoveCurrencyFilterTag={props.onRemoveCurrencyFilterTag}
					/>

					<SortableTable
						isLoading={props.loading}
						data={props.recipients ?? []}
						columns={[
							{
								field: "name",
								header: "Name",
								sortable: true,
								sortField: "recipient_name",
							},
							{
								field: "currencyDisplay",
								header: "Currencies",
							},
							{
								field: "country",
								header: "Country",
								sortable: true,
							},
							{
								field: "lastTransactionDisplay",
								header: "Last transaction",
								width: 280,
							},
							{
								field: "view",
								width: 82,
							},
							{
								field: "menu",
								width: 72,
							},
						]}
						onSort={props.onSortByName}
						sortOrder={props.state.sortOrder}
						sortField={props.state.sortField}
					/>

					<RemoveRecipientModal
						onClose={() => {
							props.setOpenDeleteModal(false);
						}}
						isOpen={props.openDeleteModal}
						onRemove={props.onDelete}
					/>
				</>
			</div>
		</SignedInLayout>
	);
};
