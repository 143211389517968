import { CountryIcon } from "@app/components/country-icon";
import { SendReceiveIcon } from "@app/components/send-receive-icon";
import { paths } from "@app/constants/paths";
import { useNavigate } from "react-router-dom";
import styles from "./index.module.css";

const FormattedValue = ({
	value,
	bold = false,
}: {
	value: string;
	bold?: boolean;
}) => {
	const rands = value.split(".")[0];
	const cents = value.includes(".") ? value.split(".")[1] : "00";
	return (
		<p className={styles.value} data-bold={bold}>
			<span>{rands}</span>
			<span className={styles.cents}>.{cents}</span>
		</p>
	);
};

export const TransactionList = ({
	data,
}: {
	data: {
		id: number;
		paymentType: string;
		zarAmount: string;
		recipient: string;
		fxAmount: string;
		fxCurrency: string;
	}[];
}) => {
	const navigate = useNavigate();
	return (
		<ul className={styles.list}>
			{data.map((current) => (
				<li className={styles.item} key={current.id}>
					<button
						type="button"
						className={styles.button}
						onClick={() => {
							navigate(paths().viewTransaction(current.id));
						}}
					>
						<SendReceiveIcon
							size={42}
							type={current.paymentType as "send" | "receive"}
						/>
						{current.paymentType === "receive" ? (
							<p className={styles.type}>Received</p>
						) : (
							<div className={styles.type}>
								<p>Sent to</p>
								<p className={styles.recipient}>{current.recipient}</p>
							</div>
						)}
						<div className={styles.values}>
							<div className={styles.row}>
								<FormattedValue bold value={current.zarAmount} />
								<CountryIcon currencyCode="ZAR" height={16} width={16} />
							</div>
							<div className={styles.row}>
								<FormattedValue value={current.fxAmount} />
								<CountryIcon
									currencyCode={current.fxCurrency}
									height={16}
									width={16}
								/>
							</div>
						</div>
					</button>
				</li>
			))}
		</ul>
	);
};
