import { fetcher } from "@app/fetcher";
import { EntityType } from "@app/types";
import useSWR, { preload } from "swr";

interface UserProfile {
	email: string;
	pending_email_change: string;
	register_entity_type: EntityType;
}

export const preloadUserProfile = () =>
	preload<UserProfile>("/users/get-user-profile/", fetcher);

export const useUserProfile = () =>
	useSWR<UserProfile>("/users/get-user-profile/");
