import { Typography } from "@app/components";
import { CountryIcon } from "@app/components/country-icon";
import { paths } from "@app/constants/paths";
import { useGoToTransaction } from "@app/hooks/use-go-to-transaction";
import { FiArrowDown } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import type { ViewRecipientTableModel } from "./models/view-recipient-table-model";
import type { Properties } from "./properties";

export const TransactionsSection = (props: Properties) => {
	const navigate = useNavigate();
	const location = useLocation();

	const inProgress = props.latestTransactions?.filter((transaction) =>
		transaction.status.toLowerCase().includes("in progress"),
	);
	const completed = props.latestTransactions?.filter((transaction) =>
		transaction.status.toLowerCase().includes("complete"),
	);

	const getCountryIcon = (value: string) => {
		const updatedValue = value.split(" ")[0];
		return <CountryIcon currencyCode={updatedValue} width={16} height={16} />;
	};

	const TransactionList = ({
		transactions = [],
	}: {
		transactions?: ViewRecipientTableModel[];
	}) => {
		const { onGoToTransaction, isLoading } = useGoToTransaction();
		return (
			<div className="wrapper mb-5 w-full min-w-full">
				{transactions?.map((transaction) => (
					<button
						type="button"
						key={transaction.id}
						disabled={isLoading}
						onClick={() => {
							if (transaction.status === "Complete") {
								navigate(paths().viewTransaction(transaction.id));
								return;
							}

							window.sessionStorage.setItem(
								"resume-later-path",
								location.pathname,
							);
							onGoToTransaction(transaction.id);
						}}
						className="latest-transaction-detail"
					>
						<div className="download-array-container">
							<FiArrowDown size={24} color="#000" />
						</div>
						<div className="flex flex-col">
							<Typography theme="textXs" className={"capitalize"}>
								{transaction.paymentType}
							</Typography>
							<Typography theme="textXs" className="font-semibold">
								{transaction.date}
							</Typography>
						</div>
						<div className="flex flex-col">
							<div className="flex flex-row items-center justify-end">
								<Typography theme="textXs" className="font-semibold">
									{transaction.zarAmount}
								</Typography>
								<div className="country-icon">
									{getCountryIcon(transaction.zarAmount)}
								</div>
							</div>
							<div className="flex flex-row items-center justify-end">
								<Typography theme="textXs">{transaction.fxAmount}</Typography>
								<div>{getCountryIcon(transaction.fxAmount)}</div>
							</div>
						</div>
					</button>
				))}
			</div>
		);
	};

	return (
		<>
			{(inProgress ?? []).length > 0 && (
				<Typography
					theme="textXs"
					className={"mb-[0.555rem] w-full font-semibold"}
				>
					In progress
				</Typography>
			)}
			<TransactionList transactions={inProgress} />

			{(completed ?? []).length > 0 && (
				<Typography
					theme="textXs"
					className={"mb-[0.555rem] w-full font-semibold"}
				>
					Completed
				</Typography>
			)}
			<TransactionList transactions={completed} />
		</>
	);
};
