import { CircleFlag } from "react-circle-flags";
import styles from "./index.module.css";

export type CountryOption = {
	name: string;
	code: string;
	countryCode: string;
	variant?: "value" | "option";
	value?: string;
};

export const CountryOption = ({
	value,
	name,
	code,
	countryCode,
	variant = "option",
}: CountryOption) => {
	const isActive = value && value === code;
	return (
		<div
			className={styles.countryOption}
			data-variant={variant}
			data-active={isActive}
		>
			<div className={styles.inner} style={{ gap: code.length >= 4 ? 8 : 12 }}>
				<CircleFlag
					cdnUrl="/flags/"
					width={20}
					height={20}
					countryCode={countryCode}
				/>
				{variant === "option" ? (
					<>
						<span className={styles.code}>{code}</span>
						{name}
					</>
				) : (
					code
				)}
			</div>

			{isActive && (
				<svg
					role="presentation"
					xmlns="http://www.w3.org/2000/svg"
					width="20"
					height="20"
					viewBox="0 0 20 20"
					fill="none"
				>
					<path
						d="M16.6668 5L7.50016 14.1667L3.3335 10"
						stroke="#888888"
						strokeWidth="1.66667"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			)}
		</div>
	);
};
