import type {
	GetBopDetails,
	GetPaymentBopDocs,
	ListSuggestedPaymentBop,
	Payment,
	PaymentBopOptions,
	PaymentFeeTypes,
} from "@app/entities";
import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

export type State = {
	createApnLoading?: boolean;
	createApnError?: string[] | null;

	submitPaymentLoading?: boolean;
	submitPaymentError?: string[] | null;

	createSupportingDocumentLoading?: boolean;
	createSupportingDocumentError?: string[] | null;

	deleteSupportingDocumentLoading?: boolean;
	deleteSupportingDocumentError?: string[] | null;

	listPaymentBopsLoading?: boolean;
	listPaymentBopsError?: string[] | null;
	listPaymentBops?: GetBopDetails[];
	listPaymentBopsCount?: number;

	listSuggestedPaymentBopsLoading?: boolean;
	listSuggestedPaymentBopsError?: string[] | null;
	listSuggestedPaymentBops?: ListSuggestedPaymentBop;

	paymentBopOptionsLoading?: boolean;
	paymentBopOptionsError?: string[] | null;
	paymentBopOptions?: PaymentBopOptions;

	paymentBopDetailsLoading?: boolean;
	paymentBopDetailsError?: string[] | null;
	paymentBopDetails?: GetBopDetails | null;

	paymentFeeTypeOptionsLoading?: boolean;
	paymentFeeTypeOptionsError?: string[] | null;
	paymentFeeTypeOptions?: PaymentFeeTypes;
	paymentFeeTypeOptionsCount?: number;

	paymentBopDocsLoading?: boolean;
	paymentBopDocsError?: string[] | null;
	paymentBopDocs?: GetPaymentBopDocs[] | null;

	downloadSupportingDocLoading?: boolean;
	downloadSupportingDocError?: string[] | null;

	updateBopLoading?: boolean;
	updateBopError?: string[] | null;

	paymentLoading?: boolean;
	paymentError?: string[] | null;
	payment?: Payment | null;

	updatePaymentLoading?: boolean;
	updatePaymentError?: string[] | null;

	updatePaymentFieldsLoading?: boolean;
	updatePaymentFieldsError?: string[] | null;
};

export const defaultState: State = {};

const slice = createSlice({
	name: "payments",
	initialState: defaultState,
	reducers: {
		setCreateApnLoading(state: State, action: PayloadAction<boolean>) {
			state.createApnLoading = action.payload;
		},
		setCreateApnError(state: State, action: PayloadAction<string[] | null>) {
			state.createApnError = action.payload;
		},

		setCreateSupportingDocumentLoading(
			state: State,
			action: PayloadAction<boolean>,
		) {
			state.createSupportingDocumentLoading = action.payload;
		},
		setCreateSupportingDocumentError(
			state: State,
			action: PayloadAction<string[] | null>,
		) {
			state.createSupportingDocumentError = action.payload;
		},

		setDeleteSupportingDocumentLoading(
			state: State,
			action: PayloadAction<boolean>,
		) {
			state.deleteSupportingDocumentLoading = action.payload;
		},
		setDeleteSupportingDocumentError(
			state: State,
			action: PayloadAction<string[] | null>,
		) {
			state.deleteSupportingDocumentError = action.payload;
		},
		setSubmitPaymentLoading(state: State, action: PayloadAction<boolean>) {
			state.submitPaymentLoading = action.payload;
		},
		setSubmitPaymentError(
			state: State,
			action: PayloadAction<string[] | null>,
		) {
			state.submitPaymentError = action.payload;
		},
		setListPaymentBopsLoading(state: State, action: PayloadAction<boolean>) {
			state.listPaymentBopsLoading = action.payload;
		},
		setListPaymentBopsError(
			state: State,
			action: PayloadAction<string[] | null>,
		) {
			state.listPaymentBopsError = action.payload;
		},
		setListPaymentBops(state: State, action: PayloadAction<GetBopDetails[]>) {
			state.listPaymentBops = action.payload;
		},
		setListPaymentBopsCount(state: State, action: PayloadAction<number>) {
			state.listPaymentBopsCount = action.payload;
		},

		setListSuggestedPaymentBopsLoading(
			state: State,
			action: PayloadAction<boolean>,
		) {
			state.listSuggestedPaymentBopsLoading = action.payload;
		},
		setListSuggestedPaymentBopsError(
			state: State,
			action: PayloadAction<string[] | null>,
		) {
			state.listSuggestedPaymentBopsError = action.payload;
		},
		setListSuggestedPaymentBops(
			state: State,
			action: PayloadAction<ListSuggestedPaymentBop>,
		) {
			state.listSuggestedPaymentBops = action.payload;
		},

		setPaymentBopOptionsLoading(state: State, action: PayloadAction<boolean>) {
			state.paymentBopOptionsLoading = action.payload;
		},
		setPaymentBopOptionsError(
			state: State,
			action: PayloadAction<string[] | null>,
		) {
			state.paymentBopOptionsError = action.payload;
		},
		setPaymentBopOptions(
			state: State,
			action: PayloadAction<PaymentBopOptions>,
		) {
			state.paymentBopOptions = action.payload;
		},

		setPayment(state: State, action: PayloadAction<Payment | null>) {
			state.payment = action.payload;
		},
		setPaymentLoading(state: State, action: PayloadAction<boolean>) {
			state.paymentLoading = action.payload;
		},
		setPaymentError(state: State, action: PayloadAction<string[] | null>) {
			state.paymentError = action.payload;
		},

		setPaymentBopDetails(
			state: State,
			action: PayloadAction<GetBopDetails | null>,
		) {
			state.paymentBopDetails = action.payload;
		},
		setPaymentBopDetailsLoading(state: State, action: PayloadAction<boolean>) {
			state.paymentBopDetailsLoading = action.payload;
		},
		setPaymentBopDetailsError(
			state: State,
			action: PayloadAction<string[] | null>,
		) {
			state.paymentBopDetailsError = action.payload;
		},

		setPaymentFeeTypeOptions(
			state: State,
			action: PayloadAction<PaymentFeeTypes>,
		) {
			state.paymentFeeTypeOptions = action.payload;
		},
		setPaymentFeeTypeOptionsCount(state: State, action: PayloadAction<number>) {
			state.paymentFeeTypeOptionsCount = action.payload;
		},
		setPaymentFeeTypeOptionsLoading(
			state: State,
			action: PayloadAction<boolean>,
		) {
			state.paymentFeeTypeOptionsLoading = action.payload;
		},
		setPaymentFeeTypeOptionsError(
			state: State,
			action: PayloadAction<string[] | null>,
		) {
			state.paymentFeeTypeOptionsError = action.payload;
		},

		setPaymentBopDocs(
			state: State,
			action: PayloadAction<GetPaymentBopDocs[] | null>,
		) {
			state.paymentBopDocs = action.payload;
		},
		setPaymentBopDocsLoading(state: State, action: PayloadAction<boolean>) {
			state.paymentBopDocsLoading = action.payload;
		},
		setPaymentBopDocsError(
			state: State,
			action: PayloadAction<string[] | null>,
		) {
			state.paymentBopDocsError = action.payload;
		},

		setDownloadPaymentSupportingDocsLoading(
			state: State,
			action: PayloadAction<boolean>,
		) {
			state.downloadSupportingDocLoading = action.payload;
		},
		setDownloadPaymentSupportingDocsError(
			state: State,
			action: PayloadAction<string[] | null>,
		) {
			state.downloadSupportingDocError = action.payload;
		},

		setUpdateBopLoading(state: State, action: PayloadAction<boolean>) {
			state.updateBopLoading = action.payload;
		},
		setUpdateBopError(state: State, action: PayloadAction<string[] | null>) {
			state.updateBopError = action.payload;
		},

		setUpdatePaymentLoading(state: State, action: PayloadAction<boolean>) {
			state.updatePaymentLoading = action.payload;
		},
		setUpdatePaymentError(
			state: State,
			action: PayloadAction<string[] | null>,
		) {
			state.updatePaymentError = action.payload;
		},

		setUpdatePaymentFieldsLoading(
			state: State,
			action: PayloadAction<boolean>,
		) {
			state.updatePaymentFieldsLoading = action.payload;
		},
		setUpdatePaymentFieldsError(
			state: State,
			action: PayloadAction<string[] | null>,
		) {
			state.updatePaymentFieldsError = action.payload;
		},
	},
});

export const {
	setCreateApnLoading,
	setCreateApnError,

	setSubmitPaymentError,
	setSubmitPaymentLoading,

	setCreateSupportingDocumentLoading,
	setCreateSupportingDocumentError,

	setDeleteSupportingDocumentLoading,
	setDeleteSupportingDocumentError,

	setListPaymentBops,
	setListPaymentBopsCount,
	setListPaymentBopsLoading,
	setListPaymentBopsError,

	setListSuggestedPaymentBops,
	setListSuggestedPaymentBopsLoading,
	setListSuggestedPaymentBopsError,

	setPaymentBopOptions,
	setPaymentBopOptionsLoading,
	setPaymentBopOptionsError,

	setPayment,
	setPaymentLoading,
	setPaymentError,

	setPaymentBopDetails,
	setPaymentBopDetailsLoading,
	setPaymentBopDetailsError,

	setPaymentBopDocs,
	setPaymentBopDocsLoading,
	setPaymentBopDocsError,

	setDownloadPaymentSupportingDocsLoading,
	setDownloadPaymentSupportingDocsError,
	setPaymentFeeTypeOptions,
	setPaymentFeeTypeOptionsCount,
	setPaymentFeeTypeOptionsLoading,
	setPaymentFeeTypeOptionsError,

	setUpdateBopLoading,
	setUpdateBopError,

	setUpdatePaymentLoading,
	setUpdatePaymentError,

	setUpdatePaymentFieldsLoading,
	setUpdatePaymentFieldsError,
} = slice.actions;

export default slice.reducer;
