import { useState } from "react";

import { Button } from "@app/components/button";
import { useMediaQuery } from "@app/hooks/use-media-query";
import { formatStatusMessage } from "@app/utils/format-status-message";
import { SystemStatus } from "../../../hooks/use-system-status";
import styles from "./index.module.css";

const getFirstTwoSentences = (message: string) => {
	const sentences = message.split(".");
	if (sentences.length <= 2) return message;
	return `${sentences.slice(0, 2).join(".")}.`;
};

export const Alert = ({ status }: { status?: SystemStatus }) => {
	const [showDetails, setShowDetails] = useState(false);
	const isMobile = useMediaQuery();
	if (!status) return null;
	if (status.transactions_enabled) return null;

	const content =
		isMobile && !showDetails
			? getFirstTwoSentences(status.message_body)
			: status.message_body;
	return (
		<div role="alert" className={styles.container}>
			<header className={styles.header}>
				<svg
					role="presentation"
					xmlns="http://www.w3.org/2000/svg"
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
				>
					<path
						d="M12 8V12M12 16H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
						stroke="#FDB022"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
				<h2>{status.message_title}</h2>
			</header>
			<p
				className={styles.content}
				dangerouslySetInnerHTML={{
					__html: formatStatusMessage(content!),
				}}
			/>
			{isMobile && (
				<Button
					className={styles.button}
					onClick={() => setShowDetails(!showDetails)}
					inline
					size="sm"
					variant="tertiary"
					noPadding
				>
					{showDetails ? "See less" : "See more"}
				</Button>
			)}
		</div>
	);
};
