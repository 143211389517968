import { Button } from "@app/components/button";
import { Dialog } from "@app/components/dialog";

import styles from "./index.module.css";

export const VerificationLinkSentModal = (props: {
	isOpen: boolean;
	onClose: () => void;
	email?: string;
}) => {
	return (
		<Dialog
			isOpen={props.isOpen}
			onClose={props.onClose}
			isSmallHeader
			title="Verification email sent"
			actions={
				<Button centered onClick={props.onClose}>
					Close
				</Button>
			}
		>
			<div className={styles.content}>
				<p>
					We have sent an email to{" "}
					<strong>
						{props.email ?? "the email address associated with your account"}
					</strong>{" "}
					to verify your new email address.
				</p>
				<p>Click the link in the email to update your email address.</p>
			</div>
		</Dialog>
	);
};
