import { Button } from "@app/components";
import { Title } from "@app/components/title";
import { useMediaQuery } from "@app/hooks/use-media-query";
import useMountTransition from "@app/hooks/use-mount-transition";
import { ReactNode, useEffect, useRef, useState } from "react";

import arrowLeftSrc from "./arrow-left.svg";
import arrowTopSrc from "./arrow-top.svg";

import styles from "./index.module.css";

export const TutorialStep = ({
	title,
	children,
	target,
	onContinue,
	arrowPosition = "start",
}: {
	title: string;
	target: string;
	children: ReactNode;
	onContinue: () => void;
	arrowPosition?: "start" | "end";
}) => {
	const tooltipRef = useRef<HTMLDivElement | null>(null);
	const isMobile = useMediaQuery();
	const [targetElement, setTargetElement] = useState<Element | null>(
		document.querySelector(target),
	);

	useEffect(() => {
		setTargetElement(document.querySelector(target));
	}, [target]);

	const handleBackdropClick = () => {
		const translateDirection = isMobile ? "translateY" : "translateX";
		const translateValue = isMobile ? "16px" : "32px";
		tooltipRef.current?.animate(
			[
				{ transform: `${translateDirection}(0)` },
				{ transform: `${translateDirection}(${translateValue})`, offset: 0.1 },
				{ transform: `${translateDirection}(${translateValue})`, offset: 0.9 },
				{ transform: `${translateDirection}(0)` },
			],
			{
				duration: 700,
				easing: "ease-in-out",
			},
		);
	};

	let layoutStyles = null;
	const targetRect = targetElement?.getBoundingClientRect();

	if (targetRect) {
		if (isMobile) {
			layoutStyles = {
				top: targetRect.bottom + 32,
				left: 24,
			};
		} else {
			layoutStyles = {
				top: targetRect.top - 20,
				left: targetRect.right + 48,
			};
		}
	}

	const show = !!layoutStyles;

	const hasTransitionedIn = useMountTransition({
		isMounted: show,
		delay: 300,
	});

	if (!show) return null;

	return (
		<>
			{show && (
				<>
					<div className={styles.backdrop} data-visible={hasTransitionedIn} />
					<button
						type="button"
						aria-label="backdrop"
						className={styles.backdropButton}
						onClick={handleBackdropClick}
					/>
					<div
						className={styles.tooltip}
						data-visible={hasTransitionedIn}
						ref={tooltipRef}
						style={layoutStyles ? layoutStyles : undefined}
					>
						{isMobile ? (
							<img
								src={arrowTopSrc}
								data-position={arrowPosition}
								alt=""
								className={styles.topArrow}
							/>
						) : (
							<img
								src={arrowLeftSrc}
								data-position={arrowPosition}
								alt=""
								className={styles.leftArrow}
							/>
						)}
						<Title variant="inline">{title}</Title>
						{typeof children === "string" ? (
							<p className={styles.description}>{children}</p>
						) : (
							children
						)}
						<div className={styles.actions}>
							<Button onClick={onContinue} block>
								Continue
							</Button>
						</div>
					</div>
				</>
			)}
		</>
	);
};
