import { Controller } from "react-hook-form";

import type { HiddenFieldPartialProps } from "../types";

export const HiddenFieldPartial = (props: HiddenFieldPartialProps) => {
	return (
		<Controller
			name={props.name}
			control={props.formControl}
			defaultValue={props.value}
			render={({ field }) => (
				<input type={props.type} {...field} value={props.value} />
			)}
		/>
	);
};
