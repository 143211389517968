import { joinClasses } from "@app/utils";

import styles from "./index.module.css";

export const TabButtons = ({
	className,
	active,
	options,
	fullWidth = false,
}: {
	className?: string;
	active?: number;
	options: {
		value: number;
		label: string;
		onClick: () => void;
	}[];
	fullWidth?: boolean;
}) => {
	return (
		<div
			className={joinClasses(styles.container, className)}
			data-full-width={fullWidth}
		>
			{options.map((current) => (
				<button
					type="button"
					className={styles.button}
					key={current.value}
					data-active={active === current.value}
					onClick={current.onClick}
				>
					{current.label}
				</button>
			))}
		</div>
	);
};
