import { ComponentPropsWithoutRef } from "react";

import { joinClasses } from "@app/utils";
import styles from "./index.module.css";

export const Checkbox = ({
	value,
	onChange,
	error = false,
	className,
	...rest
}: {
	className?: string;
	value: boolean;
	onChange?: (value: boolean) => void;
	error?: boolean;
} & Omit<ComponentPropsWithoutRef<"input">, "value" | "onChange">) => {
	return (
		<div
			className={joinClasses(styles.container, className)}
			data-error={error}
			data-checked={value}
		>
			<input
				{...rest}
				className={styles.checkbox}
				type="checkbox"
				checked={value}
				onChange={(event) => onChange?.(event.target.checked)}
			/>
			{value && (
				<svg
					role="presentation"
					xmlns="http://www.w3.org/2000/svg"
					width="12"
					height="12"
					viewBox="0 0 12 12"
					fill="none"
				>
					<path
						d="M10 3L4.5 8.5L2 6"
						stroke="white"
						strokeWidth="1.6666"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			)}
		</div>
	);
};
