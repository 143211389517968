import { FormErrors } from "@app/utils/get-form-errors";
import { ReactNode, useCallback, useState } from "react";
import { FieldErrors, FieldValues, UseFormReturn } from "react-hook-form";

const getAllKeys = (obj: Record<string, any>, prefix = ""): Array<string> => {
	let keys: string[] = [];
	for (const key in obj) {
		if (obj[key]) {
			const newKey = prefix ? `${prefix}.${key}` : key;
			if (
				typeof obj[key] === "object" &&
				obj[key] !== null &&
				!Array.isArray(obj[key])
			) {
				keys = keys.concat(getAllKeys(obj[key], newKey));
			} else {
				keys.push(newKey);
			}
		}
	}
	return keys;
};

export const handleScrollToFirstError = () => {
	setTimeout(() => {
		const errorElems = document.querySelectorAll(".field-error");
		if (errorElems.length > 0) {
			errorElems[0].scrollIntoView({
				behavior: "smooth",
				block: "center",
			});
		}
	}, 200);
};

export const useFormErrors = <T extends FieldValues>({
	formState: { errors },
	setError,
	clearErrors,
}: UseFormReturn<T, any, undefined>) => {
	const [apiErrors, setApiErrors] = useState<Array<ReactNode>>([]);

	const handleErrors = useCallback(
		(errors: FormErrors, shouldFocusError = false) => {
			for (const current of errors.fieldErrors) {
				setError(
					current.name as any,
					{
						type: "api",
						message: current.message,
					},
					{ shouldFocus: false },
				);
			}
			setApiErrors(errors.apiErrors);
			if (shouldFocusError) {
				handleScrollToFirstError();
			}
		},
		[setError],
	);
	return {
		apiErrors,
		errors: errors,
		clearErrors,
		setError,
		clearApiFieldErrors: (partial?: Record<string, any>) => {
			const handleClearErrors = (prefix: string, errors: FieldErrors<T>) => {
				const keys = partial ? getAllKeys(partial) : null;
				for (const key of Object.keys(errors)) {
					const current = errors[key as keyof T];
					if (!current) return;
					const newPrefix = `${prefix}${key}` as any;
					if (!current.type && typeof current === "object") {
						// @ts-ignore
						handleClearErrors(`${newPrefix}.`, current);
					} else if (errors[key as keyof T]?.type === "api") {
						if (keys) {
							if (keys.includes(newPrefix)) {
								clearErrors(newPrefix);
							}
						} else {
							clearErrors(newPrefix);
						}
					}
				}
			};
			handleClearErrors("", errors);
			setApiErrors([]);
		},
		onErrors: handleErrors,
		onInvalid: () => {
			handleScrollToFirstError();
		},
	};
};
