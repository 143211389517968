import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Button, ExchangeDetailsBlock, Typography } from "@app/components";
import { SignedInLayout } from "@app/components/signed-in-layout";
import { useMediaQuery } from "@app/hooks/use-media-query";

import { FiHome } from "react-icons/fi";

import { paths } from "@app/constants/paths";

import { Title } from "@app/components/title";
import { tempExchangeDetailsMapping } from "@app/hooks/use-exchange-details";
import { useSystemStatus } from "@app/hooks/use-system-status";
import { useTransaction } from "@app/hooks/use-transaction";
import { useTransactionId } from "@app/hooks/use-transaction-id";
import { formatStatusMessage } from "@app/utils/format-status-message";
import styles from "./index.module.css";

export const FundConfirmBankClosedError = () => {
	const isMobile = useMediaQuery();
	const navigate = useNavigate();
	const { data: systemStatus } = useSystemStatus();

	const transactionId = useTransactionId();
	const { exchangeDetails, isExchangeDetailsLoading } =
		useTransaction(transactionId);

	const onHome = () => navigate(paths().dashboard);

	useEffect(() => {
		if (!exchangeDetails && !isExchangeDetailsLoading) {
			navigate(paths().dashboard);
		}
	}, [exchangeDetails, isExchangeDetailsLoading, navigate]);

	return (
		<SignedInLayout
			title="Transaction Failed"
			childHeaderClassName="mt-6"
			footer={
				<div className={styles.footer}>
					<Button onClick={onHome}>
						<FiHome size={20} color="#fff" />
						<Typography theme="textMd" className={"font-semibold"}>
							Home
						</Typography>
					</Button>
				</div>
			}
		>
			<div className={styles.container}>
				<div className={styles.wrapper}>
					{!isMobile && <Title>Transaction Failed</Title>}
					{systemStatus && (
						<div
							className={styles.content}
							dangerouslySetInnerHTML={{
								__html: formatStatusMessage(systemStatus.message_body),
							}}
						/>
					)}
				</div>
				<ExchangeDetailsBlock
					className="mt-2"
					exchangeDetails={tempExchangeDetailsMapping(exchangeDetails)}
					overrideHeading={
						<div className="mb-1 flex flex-col">
							<Typography theme="displayXSm" className="font-display">
								Transaction Details
							</Typography>
							<Typography className={"text-gray-650"} theme="textMd">
								Please take note of these details should you wish to start a new
								transaction
							</Typography>
						</div>
					}
					overrideShowPaymentDetails
				/>
			</div>
		</SignedInLayout>
	);
};
