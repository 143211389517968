export const removeEmptyFields = <T extends Record<string, any>>(
	obj: T,
	allowEmptyStringFields: Array<string> = [],
): T => {
	const newObj = {} as T;
	for (const key in obj) {
		const isValidEmptyField =
			allowEmptyStringFields.includes(key) && obj[key] === "";

		const isValidField = !!obj[key] || typeof obj[key] === "boolean";

		if (isValidEmptyField || isValidField) {
			if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
				const result = removeEmptyFields(obj[key], allowEmptyStringFields);
				if (Object.keys(result).length === 0) {
					continue;
				}
				newObj[key] = result;
			} else {
				newObj[key] = obj[key];
			}
		}
	}

	return newObj;
};
