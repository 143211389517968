export const getErrorMessage = (error: any) => {
	console.error(error);
	if (!error.response) {
		return error.message ?? "An error occurred. Please try again later.";
	}

	const data = error.response.data as {
		message?: string | any[];
		detail?: string | any[];
	};
	return `${data.message}. ${data.detail}`;
};
