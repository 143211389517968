import { useDispatch } from "react-redux";

import type {
	WithdrawOptions,
	WithdrawalBankAccount,
	WithdrawalBankAccounts,
} from "@app/entities";
import type { AppDispatch } from "@app/redux";
import {
	createWithdrawalBankAccountAction,
	createWithdrawalRequestAction,
	deleteWithdrawalBankAccountAction,
	getWithdrawOptionsAction,
	getWithdrawalBankAccountAction,
	getWithdrawalBankAccountsAction,
	updateWithdrawalBankAccountAction,
} from "@app/redux/withdrawals/withdrawals-actions";
import type { MappedReasons } from "@app/services";

import type {
	CreateWithdrawal,
	CreateWithdrawalBankAccount,
	UpdateWithdrawalBankAccount,
} from "../entities/withdrawals";

export const useGetWithdrawalBankAccounts = () => {
	const dispatch: AppDispatch = useDispatch();

	const getWithdrawalBankAccounts = (
		callback?: (
			response: WithdrawalBankAccounts | string[] | undefined,
		) => void,
	) => {
		dispatch(getWithdrawalBankAccountsAction(callback));
	};

	return [getWithdrawalBankAccounts];
};

export const useGetWithdrawalBankAccount = () => {
	const dispatch: AppDispatch = useDispatch();

	const getWithdrawalBankAccount = (
		bankAccountId: number,
		callback?: (response: WithdrawalBankAccount | string[] | undefined) => void,
	) => {
		dispatch(getWithdrawalBankAccountAction(bankAccountId, callback));
	};

	return [getWithdrawalBankAccount];
};

export const useGetWithdrawOptions = () => {
	const dispatch: AppDispatch = useDispatch();

	const getWithdrawOptions = (
		callback?: (response: WithdrawOptions | string[] | undefined) => void,
	) => {
		dispatch(getWithdrawOptionsAction(callback));
	};

	return [getWithdrawOptions];
};

export const useCreateWithdrawalBankAccount = () => {
	const dispatch: AppDispatch = useDispatch();

	const createWithdrawalBankAccount = (
		withdrawalBankAccount: CreateWithdrawalBankAccount,
		callback?: (errors?: string[], mappedReasons?: MappedReasons) => void,
	) => {
		dispatch(
			createWithdrawalBankAccountAction(withdrawalBankAccount, callback),
		);
	};

	return [createWithdrawalBankAccount];
};

export const useCreateWithdrawalRequest = () => {
	const dispatch: AppDispatch = useDispatch();

	const createWithdrawalRequest = (
		withdrawal: CreateWithdrawal,
		callback?: (response: number | string[] | undefined) => void,
	) => {
		dispatch(createWithdrawalRequestAction(withdrawal, callback));
	};

	return [createWithdrawalRequest];
};

export const useDeleteWithdrawalBankAccount = () => {
	const dispatch: AppDispatch = useDispatch();

	const deleteWithdrawalBankAccount = (
		bankAccountId: number,
		callback?: (response: number | string[] | undefined) => void,
	) => {
		dispatch(deleteWithdrawalBankAccountAction(bankAccountId, callback));
	};

	return [deleteWithdrawalBankAccount];
};

export const useUpdateWithdrawalBankAccount = () => {
	const dispatch: AppDispatch = useDispatch();

	const updateWithdrawalBankAccount = (
		withdrawalBankAccount: UpdateWithdrawalBankAccount,
		callback?: (errors?: string[], mappedReasons?: MappedReasons) => void,
	) => {
		dispatch(
			updateWithdrawalBankAccountAction(withdrawalBankAccount, callback),
		);
	};

	return [updateWithdrawalBankAccount];
};
