import { Button } from "@app/components";
import { Dialog } from "@app/components/dialog";
import { isProofOfPaymentsDownloadEnabled } from "@app/constants/feature-flags";
import { useMediaQuery } from "@app/hooks/use-media-query";
import { useTransaction } from "@app/hooks/use-transaction";
import { useTransactionId } from "@app/hooks/use-transaction-id";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { SVGProps, useState } from "react";

import styles from "./index.module.css";

import downloadIconSrc from "./download.svg";
import { MoreInfoTooltip } from "@app/components/more-info-tooltip";

const OptionDownloadIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="1em"
		height="1em"
		fill="none"
		role="presentation"
		{...props}
	>
		<path
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			d="M17.5 12.5v1c0 1.4 0 2.1-.273 2.635a2.5 2.5 0 0 1-1.092 1.092c-.535.273-1.235.273-2.635.273h-7c-1.4 0-2.1 0-2.635-.273a2.5 2.5 0 0 1-1.093-1.092C2.5 15.6 2.5 14.9 2.5 13.5v-1m11.667-4.167L10 12.5m0 0L5.833 8.333M10 12.5v-10"
		/>
	</svg>
);

const ChevronIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		role="presentation"
		width="20"
		height="21"
		viewBox="0 0 20 21"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M15 12.5625L10 7.5625L5 12.5625"
			stroke="currentColor"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
);

export const DownloadDropdown = () => {
	const isMobile = useMediaQuery();
	const transactionId = useTransactionId();
	const [isOpen, setIsOpen] = useState(false);
	const { onDownloadTransaction, onDownloadProofOfPayment, payment } =
		useTransaction(transactionId);

	if (!isProofOfPaymentsDownloadEnabled) {
		if (isMobile) {
			return (
				<button
					type="button"
					className={styles.downloadIconButton}
					onClick={onDownloadTransaction}
				>
					<img
						src={downloadIconSrc}
						alt="Download transaction"
						width={24}
						height={24}
					/>
				</button>
			);
		}

		return (
			<Button variant="secondary" onClick={onDownloadTransaction}>
				Download transaction
			</Button>
		);
	}

	if (isMobile) {
		return (
			<>
				<button
					type="button"
					onClick={() => setIsOpen(true)}
					aria-label="Download"
					className={styles.downloadIconButton}
				>
					<img
						src={downloadIconSrc}
						alt="Download transaction"
						width={24}
						height={24}
					/>
				</button>
				<Dialog
					isOpen={isOpen}
					title="Download"
					onClose={() => setIsOpen(false)}
					bottomsheet
				>
					<ul>
						<li>
							<button
								className={styles.downloadOption}
								type="button"
								onClick={() => {
									onDownloadTransaction();
									setIsOpen(false);
								}}
							>
								<OptionDownloadIcon width={20} height={20} />
								Transaction confirmation
							</button>
						</li>
						<li>
							{payment?.proof_of_payment_ready ? (
								<button
									className={styles.downloadOption}
									type="button"
									onClick={() => {
										onDownloadProofOfPayment();
										setIsOpen(false);
									}}
								>
									<OptionDownloadIcon width={20} height={20} />
									Proof of payment
								</button>
							) : (
								<MoreInfoTooltip
									maxWidth={280}
									name="Proof of payment"
									className={styles.downloadOption}
									disabled
									indicator={
										<>
											<OptionDownloadIcon width={20} height={20} />
											Proof of payment
										</>
									}
								>
									This is not yet available. As soon as it is, we will send it
									via email and you will then be able to download it here at any
									point.
								</MoreInfoTooltip>
							)}
						</li>
					</ul>
				</Dialog>
			</>
		);
	}

	return (
		<DropdownMenu.Root open={isOpen} onOpenChange={setIsOpen}>
			<DropdownMenu.Trigger>
				<Button className={styles.downloadButton} variant="secondary">
					Download
					<ChevronIcon data-open={isOpen} className={styles.toggleIcon} />
				</Button>
			</DropdownMenu.Trigger>
			<DropdownMenu.Portal>
				<DropdownMenu.Content
					className={styles.downloadMenu}
					sideOffset={4}
					align="end"
				>
					<DropdownMenu.Item>
						<button
							className={styles.downloadOption}
							onClick={onDownloadTransaction}
							type="button"
						>
							<OptionDownloadIcon width={20} height={20} />
							Transaction confirmation
						</button>
					</DropdownMenu.Item>
					<DropdownMenu.Item>
						{payment?.proof_of_payment_ready ? (
							<button
								className={styles.downloadOption}
								type="button"
								onClick={onDownloadProofOfPayment}
							>
								<OptionDownloadIcon width={20} height={20} />
								Proof of payment
							</button>
						) : (
							<MoreInfoTooltip
								maxWidth={280}
								name="Proof of payment"
								className={styles.downloadOption}
								disabled
								indicator={
									<>
										<OptionDownloadIcon width={20} height={20} />
										Proof of payment
									</>
								}
							>
								This is not yet available. As soon as it is, we will send it via
								email and you will then be able to download it here at any
								point.
							</MoreInfoTooltip>
						)}
					</DropdownMenu.Item>
				</DropdownMenu.Content>
			</DropdownMenu.Portal>
		</DropdownMenu.Root>
	);
};
