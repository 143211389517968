import type { SetProfilePictureParams } from "@app/entities";
import { getMappedReasons } from "@app/utils";

import { getReasons } from "@app/utils/get-reasons";
import { api } from "./api";
import type { GenericFailureResponse, GenericResponse } from "./models";

export async function setProfilePicture(params: SetProfilePictureParams) {
	try {
		const { status } = await api.post(
			`clients/${params.clientId}/profile-picture/`,
			params.profilePicture
				? mapToSetProfilePicture(params.profilePicture)
				: "",
			{
				onUploadProgress: params.onUploadProgress,
			},
		);
		return status;
	} catch (exception: any) {
		const error = exception.data as GenericFailureResponse;
		const reasons = getReasons(exception.data);
		const mappedReasons = getMappedReasons(error);

		return {
			reasons,
			mappedReasons,
		};
	}
}

function mapToSetProfilePicture(value: File): FormData {
	const formData = new FormData();

	formData.append("image", value);

	return formData;
}
