import { useClients } from "@app/hooks/use-clients";
import { api } from "@app/services";
import { getFormErrors } from "@app/utils/get-form-errors";

export type ShareholderStructureType = "simple" | "complex";

export const getShareholderStructure = async (activeClientId: number) =>
	api.get<{
		shareholder_structure: ShareholderStructureType;
	}>(`onboarding/${activeClientId}/shareholder-structure/`);

export const useShareholderStructure = () => {
	const { activeClientId } = useClients();

	return {
		submit: async (newData: {
			shareholder_structure: ShareholderStructureType;
		}) => {
			try {
				await api.put(
					`/onboarding/${activeClientId}/shareholder-structure/`,
					newData,
				);
			} catch (error) {
				return getFormErrors(error);
			}
		},
	};
};
