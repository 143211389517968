import { twMerge } from "tailwind-merge";

import { Button, FormBuilder, Typography } from "@app/components";

import type { ViewProperties } from "./properties";

import { Dialog } from "@app/components/dialog";
import { useMediaQuery } from "@app/hooks/use-media-query";
import "./withdraw-modal.css";

import { MoreInfoTooltip } from "@app/components/more-info-tooltip";
import { Note } from "@app/components/note";
import styles from "./index.module.css";

export const DEFAULT_WITHDRAW_TYPE = "standard";
const DEFAULT_FEE = "ZAR 20.00";

export const WithdrawModalView = (props: ViewProperties) => {
	const isMobile = useMediaQuery();
	return (
		<Dialog
			isOpen={props.isOpen}
			onClose={props.onClose}
			size="xl"
			className="withdraw-modal"
			title="Withdraw ZAR"
			description="Withdraw ZAR via a local EFT to your South African bank account."
			fullscreen={isMobile}
			showTopbar={isMobile}
			actions={
				<>
					<Button variant="secondary" onClick={props.onClose}>
						Cancel
					</Button>
					<Button form="withdraw-form" type="submit">
						Submit
					</Button>
				</>
			}
		>
			<form
				id="withdraw-form"
				onSubmit={props.handleSubmit(props.onHandleSubmitValid)}
				noValidate
			>
				<div className="content-container">
					<div className="withdraw-modal-bank-account-section">
						<div className="withdraw-modal-bank-account-title-section">
							<Typography className="withdraw-modal-title" theme="textLg">
								Bank Account
							</Typography>
							<Button
								variant="tertiary"
								className={styles.manageAccountsButton}
								onClick={props.onManageAccounts}
							>
								Manage accounts
							</Button>
						</div>
						<FormBuilder
							formTitle="withdrawForm"
							formControl={props.formControl}
							errors={props.errors}
							formInputs={props.bankAccountInput}
						/>
					</div>
					<div className="withdraw-modal-withdrawal-type-title-section">
						<Typography className="withdraw-modal-title" theme="textLg">
							Withdrawal Details
						</Typography>
					</div>
					<div className="withdraw-modal-account-number-section">
						<Typography
							className={twMerge(
								"withdraw-modal-account-number-label",
								props.isFocused ? "text-teal-550" : "text-gray-1100",
							)}
							theme="textSm"
						>
							Amount
						</Typography>
						<div className="withdraw-modal-account-number-input-section">
							<div className="withdraw-modal-account-number-input-prefix">
								ZAR
							</div>
							<FormBuilder
								formTitle="withdrawForm"
								formControl={props.formControl}
								errors={props.errors}
								formInputs={props.accountNumberInput}
							/>
						</div>
					</div>
					<div className={styles.capitecFeeContainer}>
						<p className={styles.capitecFeeLabel}>
							Capitec Withdrawal Fee
							<MoreInfoTooltip
								name="Capitec Withdrawal Fee"
								hasIcon
								maxWidth={280}
							>
								This processing fee is charged by Capitec. We do not charge any
								fees/commission on top of this.
							</MoreInfoTooltip>
						</p>
						{props.withdrawOptions?.withdrawalTypes?.find(
							(current) => current.value === DEFAULT_WITHDRAW_TYPE,
						)?.fee ?? DEFAULT_FEE}
					</div>
					<Note variant="full">
						Capitec currently does not facilitate RTC/fast EFTs. Withdrawals
						typically take 3 business days to reflect.
					</Note>
					{/* <FormBuilder
                        formTitle="withdrawForm"
                        formControl={props.formControl}
                        errors={props.errors}
                        formInputs={props.withdrawalTypeInput}
                    /> */}
				</div>
			</form>
		</Dialog>
	);
};
