import { atom, useAtom } from "jotai";

export interface ForexQuoteOptions {
	quoteCurrency?: string;
	quoteAmount?: string;
	fxCurrency?: string;
	fxAmount?: string;
	transactionType?: string;
	valueDate?: string;
	settlementAccounts?: {
		bankAccountId: number;
		accountType: string;
		accountNumber: string;
	};
}

const quoteOptionsAtom = atom<undefined | ForexQuoteOptions>(undefined);

export const useQuoteOptions = () => useAtom(quoteOptionsAtom);
