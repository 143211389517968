import { useDispatch } from "react-redux";

import type { ToastType } from "@app/components";
import type { AppDispatch } from "@app/redux";
import {
	hideToastAction,
	showToastAction,
} from "@app/redux/toast/toast-actions";

export const useHideToast = () => {
	const dispatch: AppDispatch = useDispatch();

	const hideToast = () => {
		dispatch(hideToastAction());
	};

	return [hideToast];
};

export const useShowToast = () => {
	const dispatch: AppDispatch = useDispatch();

	const showToast = (message: string, type: ToastType) => {
		dispatch(showToastAction(message, type));
	};

	return [showToast];
};
