import useSWR from "swr";

export type PaymentBop = {
	id: number;
	bop_code: string;
	category: string;
	description: string;
};

export const usePaymentBop = (paymentId?: number) => {
	return useSWR<PaymentBop>(paymentId ? `/payments/${paymentId}/bop/` : null);
};
