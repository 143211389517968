import type {
	CreateApnParams,
	CreateSupportingDocument,
	CreateSupportingDocumentParams,
	GetBopDetails,
	GetPaymentBopDocs,
	ListPaymentBop,
	ListSuggestedPaymentBop,
	PaymentBopOptions,
	PaymentFeeTypes,
	UpdatePaymentBopParams,
	UpdatePaymentFieldsParams,
	UpdatePaymentParams,
} from "@app/entities";
import {
	type MappedReasons,
	type PaymentBopQueryParams,
	type SupportingDocResponse,
	apiPayments,
} from "@app/services";

import { getActiveClientFromSession } from "@app/hooks/use-clients";
import type { AppThunk } from "../store";
import {
	setCreateApnError,
	setCreateApnLoading,
	setCreateSupportingDocumentError,
	setCreateSupportingDocumentLoading,
	setDeleteSupportingDocumentError,
	setDeleteSupportingDocumentLoading,
	setDownloadPaymentSupportingDocsError,
	setDownloadPaymentSupportingDocsLoading,
	setListPaymentBops,
	setListPaymentBopsCount,
	setListPaymentBopsError,
	setListPaymentBopsLoading,
	setListSuggestedPaymentBops,
	setListSuggestedPaymentBopsError,
	setListSuggestedPaymentBopsLoading,
	setPayment,
	setPaymentBopDetails,
	setPaymentBopDetailsError,
	setPaymentBopDetailsLoading,
	setPaymentBopDocs,
	setPaymentBopDocsError,
	setPaymentBopDocsLoading,
	setPaymentBopOptions,
	setPaymentBopOptionsError,
	setPaymentBopOptionsLoading,
	setPaymentFeeTypeOptions,
	setPaymentFeeTypeOptionsCount,
	setPaymentFeeTypeOptionsError,
	setPaymentFeeTypeOptionsLoading,
	setSubmitPaymentError,
	setSubmitPaymentLoading,
	setUpdateBopError,
	setUpdateBopLoading,
	setUpdatePaymentError,
	setUpdatePaymentFieldsError,
	setUpdatePaymentFieldsLoading,
	setUpdatePaymentLoading,
} from "./payments-slice";

export const getPaymentBopAction =
	(
		payment_id: number,
		callback?: (response?: GetBopDetails | string[]) => void,
	): AppThunk =>
	async (dispatch) => {
		let results: GetBopDetails | string[] | undefined = undefined;

		try {
			dispatch(setPaymentBopDetailsLoading(true));
			dispatch(setPaymentBopDetailsError(null));

			const activeClientId = getActiveClientFromSession();

			if (activeClientId) {
				const response = await apiPayments.getPaymentBop(payment_id);

				if ("reasons" in response) {
					dispatch(setPaymentBopDetailsError(response.reasons));
					results = response.reasons;
				} else {
					dispatch(setPaymentBopDetails(response));

					results = response;
				}
			}
		} catch (error: any) {
			dispatch(setPaymentBopDetailsError(error));
		} finally {
			if (callback) callback(results);
			dispatch(setPaymentBopDetailsLoading(false));
		}
	};

export const getPaymentBopDocsAction =
	(
		payment_id: number,
		callback?: (response?: GetPaymentBopDocs[] | string[]) => void,
	): AppThunk =>
	async (dispatch) => {
		let results: GetPaymentBopDocs[] | string[] | undefined = undefined;

		try {
			dispatch(setPaymentBopDocsLoading(true));
			dispatch(setPaymentBopDocsError(null));

			const activeClientId = getActiveClientFromSession();

			if (activeClientId) {
				const response = await apiPayments.getPaymentBopDocs(payment_id);

				if ("reasons" in response) {
					dispatch(setPaymentBopDocsError(response.reasons));
					results = response.reasons;
				} else {
					dispatch(setPaymentBopDocs(response));

					results = response;
				}
			}
		} catch (error: any) {
			dispatch(setPaymentBopDocsError(error));
		} finally {
			callback?.(results);
			dispatch(setPaymentBopDocsLoading(false));
		}
	};

export const downloadPaymentSupportingDocsAction =
	(
		document_id: number,
		callback?: (response?: SupportingDocResponse | string[]) => void,
	): AppThunk =>
	async (dispatch) => {
		let results: SupportingDocResponse | string[] | undefined = undefined;

		try {
			dispatch(setDownloadPaymentSupportingDocsLoading(true));
			dispatch(setDownloadPaymentSupportingDocsError(null));

			const activeClientId = getActiveClientFromSession();

			if (activeClientId) {
				const response =
					await apiPayments.downloadPaymentSupportingDocs(document_id);

				if ("reasons" in response) {
					dispatch(setDownloadPaymentSupportingDocsError(response.reasons));
					results = response.reasons;
				} else results = response;
			}
		} catch (error: any) {
			dispatch(setDownloadPaymentSupportingDocsError(error));
		} finally {
			callback?.(results);
			dispatch(setDownloadPaymentSupportingDocsLoading(false));
		}
	};

export const clearAllPaymentInfoAction = (): AppThunk => async (dispatch) => {
	try {
		dispatch(setPayment(null));
		dispatch(setPaymentBopDetails(null));
	} catch (error: any) {
	} finally {
	}
};

export const getListPaymentBopsAction =
	(
		queryParams: PaymentBopQueryParams,
		callback?: (response?: ListPaymentBop | string[]) => void,
	): AppThunk =>
	async (dispatch) => {
		let results: ListPaymentBop | string[] | undefined = undefined;

		try {
			dispatch(setListPaymentBopsLoading(true));
			dispatch(setListPaymentBopsError(null));

			const response = await apiPayments.getPaymentBops(queryParams);

			if ("reasons" in response) {
				dispatch(setListPaymentBopsError(response.reasons));
				results = response.reasons;
			} else {
				dispatch(setListPaymentBops(response.items));

				dispatch(setListPaymentBopsCount(response.count));

				results = response;
			}
		} catch (error: any) {
			dispatch(setListPaymentBopsError(error));
		} finally {
			callback?.(results);
			dispatch(setListPaymentBopsLoading(false));
		}
	};

export const getListSuggestedPaymentBopsAction =
	(
		paymentId: number,
		callback?: (response?: ListSuggestedPaymentBop | string[]) => void,
	): AppThunk =>
	async (dispatch) => {
		let results: ListSuggestedPaymentBop | string[] | undefined = undefined;

		try {
			dispatch(setListSuggestedPaymentBopsLoading(true));
			dispatch(setListSuggestedPaymentBopsError(null));

			const response = await apiPayments.ListSuggestedPaymentBops(paymentId);

			if ("reasons" in response) {
				dispatch(setListSuggestedPaymentBopsError(response.reasons));
				results = response.reasons;
			} else {
				dispatch(setListSuggestedPaymentBops(response));

				results = response;
			}
		} catch (error: any) {
			dispatch(setListSuggestedPaymentBopsError(error));
		} finally {
			callback?.(results);
			dispatch(setListSuggestedPaymentBopsLoading(false));
		}
	};

export const getPaymentBopsOptionsAction =
	(
		paymentId: number,
		callback?: (response?: PaymentBopOptions | string[]) => void,
	): AppThunk =>
	async (dispatch) => {
		let results: PaymentBopOptions | string[] | undefined = undefined;

		try {
			dispatch(setPaymentBopOptionsLoading(true));
			dispatch(setPaymentBopOptionsError(null));

			const response = await apiPayments.GetPaymentBopOptions(paymentId);

			if ("reasons" in response) {
				dispatch(setPaymentBopOptionsError(response.reasons));
				results = response.reasons;
			} else {
				dispatch(setPaymentBopOptions(response));

				results = response;
			}
		} catch (error: any) {
			dispatch(setPaymentBopOptionsError(error));
		} finally {
			callback?.(results);
			dispatch(setPaymentBopOptionsLoading(false));
		}
	};

export const getPaymentFeeTypeOptionsAction =
	(
		paymentId: number,
		callback?: (response?: PaymentFeeTypes | string[]) => void,
	): AppThunk =>
	async (dispatch) => {
		let results: PaymentFeeTypes | string[] | undefined = undefined;
		try {
			dispatch(setPaymentFeeTypeOptionsLoading(true));
			dispatch(setPaymentFeeTypeOptionsError(null));

			const response = await apiPayments.getPaymentFeeTypeOptions(paymentId);

			if ("reasons" in response) {
				dispatch(setPaymentFeeTypeOptionsError(response.reasons));
				results = response.reasons;
			} else {
				dispatch(setPaymentFeeTypeOptions(response));

				dispatch(
					setPaymentFeeTypeOptionsCount(response.allowedFeeTypes.length),
				);

				results = response;
			}
		} catch (error: any) {
			dispatch(setPaymentFeeTypeOptionsError(error));
		} finally {
			callback?.(results);
			dispatch(setPaymentFeeTypeOptionsLoading(false));
		}
	};

export const createApnAction =
	(
		params: CreateApnParams,
		callback?: (errors?: string[], mappedReasons?: MappedReasons) => void,
	): AppThunk =>
	async (dispatch) => {
		let reasons: string[] | undefined = undefined;
		let mappedReasons: MappedReasons | undefined;

		try {
			dispatch(setCreateApnLoading(true));
			dispatch(setCreateApnError(null));

			const response = await apiPayments.createApn(params);

			if (typeof response === "object" && response?.reasons) {
				dispatch(setCreateApnError(response.reasons));
				reasons = response.reasons;
				if (response.mappedReasons) {
					mappedReasons = response.mappedReasons;
				}
			}
		} catch (error: any) {
			dispatch(setCreateApnError(error));
		} finally {
			dispatch(setCreateApnLoading(false));
			callback?.(reasons, mappedReasons);
		}
	};

export const submitPaymentAction =
	(
		paymentId: number,
		callback?: (errors?: string[], mappedReasons?: MappedReasons) => void,
	): AppThunk =>
	async (dispatch) => {
		let reasons: string[] | undefined = undefined;
		let mappedReasons: MappedReasons | undefined;

		try {
			dispatch(setSubmitPaymentLoading(true));
			dispatch(setSubmitPaymentError(null));

			const response = await apiPayments.submitPayment(paymentId);

			if (typeof response === "object" && response?.reasons) {
				dispatch(setSubmitPaymentError(response.reasons));
				reasons = response.reasons;
				if (response.mappedReasons) {
					mappedReasons = response.mappedReasons;
				}
			}
		} catch (error: any) {
			dispatch(setSubmitPaymentError(error));
		} finally {
			dispatch(setSubmitPaymentLoading(false));
			callback?.(reasons, mappedReasons);
		}
	};

export const createSupportingDocumentAction =
	(
		params: CreateSupportingDocumentParams,
		callback?: (
			results?: CreateSupportingDocument,
			response?: string[],
			mappedReasons?: MappedReasons,
		) => void,
	): AppThunk =>
	async (dispatch) => {
		let results: CreateSupportingDocument | string[] | undefined = undefined;
		let reasons: string[] | undefined = undefined;
		let mappedReasons: MappedReasons | undefined;

		try {
			dispatch(setCreateSupportingDocumentLoading(true));
			dispatch(setCreateSupportingDocumentError(null));

			const response = await apiPayments.createSupportingDocument(params);

			if ("reasons" in response) {
				if (typeof response === "object" && response?.reasons) {
					dispatch(setCreateSupportingDocumentError(response.reasons));
					reasons = response.reasons;
					if (response.mappedReasons) {
						mappedReasons = response.mappedReasons;
					}
				}
			} else {
				results = response;
			}
		} catch (error: any) {
			dispatch(setCreateSupportingDocumentError(error));
		} finally {
			dispatch(setCreateSupportingDocumentLoading(false));
			if (callback) callback(results, reasons, mappedReasons);
		}
	};

export const deleteSupportingDocumentAction =
	(
		documentId: number,
		callback?: (errors?: string[], mappedReasons?: MappedReasons) => void,
	): AppThunk =>
	async (dispatch) => {
		let reasons: string[] | undefined = undefined;
		let mappedReasons: MappedReasons | undefined;

		try {
			dispatch(setDeleteSupportingDocumentLoading(true));
			dispatch(setDeleteSupportingDocumentError(null));

			const response = await apiPayments.deleteSupportingDocument(documentId);

			if (typeof response === "object" && response?.reasons) {
				dispatch(setDeleteSupportingDocumentError(response.reasons));
				reasons = response.reasons;
				if (response.mappedReasons) {
					mappedReasons = response.mappedReasons;
				}
			}
		} catch (error: any) {
			dispatch(setDeleteSupportingDocumentError(error));
		} finally {
			dispatch(setDeleteSupportingDocumentLoading(false));
			callback?.(reasons, mappedReasons);
		}
	};

export const updateBopAction =
	(
		bopParams: UpdatePaymentBopParams,
		callback?: (errors?: string[], mappedReasons?: MappedReasons) => void,
	): AppThunk =>
	async (dispatch) => {
		let reasons: string[] | undefined = undefined;
		let mappedReasons: MappedReasons | undefined;

		try {
			dispatch(setUpdateBopLoading(true));
			dispatch(setUpdateBopError(null));

			const response = await apiPayments.updateBop(bopParams);

			if (typeof response === "object" && response?.reasons) {
				dispatch(setUpdateBopError(response.reasons));
				reasons = response.reasons;
			}
		} catch (error: any) {
			dispatch(setUpdateBopError(error));
		} finally {
			dispatch(setUpdateBopLoading(false));
			callback?.(reasons, mappedReasons);
		}
	};

export const updatePaymentAction =
	(
		paymentParams: UpdatePaymentParams,
		callback?: (errors?: string[], mappedReasons?: MappedReasons) => void,
	): AppThunk =>
	async (dispatch) => {
		let reasons: string[] | undefined = undefined;
		let mappedReasons: MappedReasons | undefined;

		try {
			dispatch(setUpdatePaymentLoading(true));
			dispatch(setUpdatePaymentError(null));

			const response = await apiPayments.updatePayment(paymentParams);

			if (typeof response === "object" && response?.reasons) {
				dispatch(setUpdatePaymentError(response.reasons));
				reasons = response.reasons;
				if (response.mappedReasons) {
					mappedReasons = response.mappedReasons;
				}
			}
		} catch (error: any) {
			dispatch(setUpdatePaymentError(error));
		} finally {
			dispatch(setUpdatePaymentLoading(false));
			callback?.(reasons, mappedReasons);
		}
	};

export const updatePaymentFieldsAction =
	(
		params: UpdatePaymentFieldsParams,
		callback?: (errors?: string[], mappedReasons?: MappedReasons) => void,
	): AppThunk =>
	async (dispatch) => {
		let reasons: string[] | undefined = undefined;
		let mappedReasons: MappedReasons | undefined;

		try {
			dispatch(setUpdatePaymentFieldsLoading(true));
			dispatch(setUpdatePaymentFieldsError(null));

			const response = await apiPayments.updatePaymentFields(params);

			if (typeof response === "object" && response?.reasons) {
				dispatch(setUpdatePaymentFieldsError(response.reasons));
				reasons = response.reasons;
				if (response.mappedReasons) {
					mappedReasons = response.mappedReasons;
				}
			}
		} catch (error: any) {
			dispatch(setUpdatePaymentFieldsError(error));
		} finally {
			dispatch(setUpdatePaymentFieldsLoading(false));
			callback?.(reasons, mappedReasons);
		}
	};
