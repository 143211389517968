import type { CountryOption } from ".";

export const COUNTRY_CODE_OPTIONS: Array<CountryOption> = [
	{
		name: "South Africa",
		code: "+27",
		countryCode: "za",
	},
	{
		name: "Afghanistan",
		code: "+93",
		countryCode: "af",
	},
	{
		name: "Albania",
		code: "+355",
		countryCode: "al",
	},
	{
		name: "Algeria",
		code: "+213",
		countryCode: "dz",
	},
	{
		name: "Andorra",
		code: "+376",
		countryCode: "ad",
	},
	{
		name: "Angola",
		code: "+244",
		countryCode: "ao",
	},
	{
		name: "Argentina",
		code: "+54",
		countryCode: "ar",
	},
	{
		name: "Australia",
		code: "+61",
		countryCode: "au",
	},
	{
		name: "Austria",
		code: "+43",
		countryCode: "at",
	},
	{
		name: "Bangladesh",
		code: "+880",
		countryCode: "bd",
	},
	{
		name: "Belarus",
		code: "+375",
		countryCode: "by",
	},
	{
		name: "Belgium",
		code: "+32",
		countryCode: "be",
	},
	{
		name: "Benin",
		code: "+229",
		countryCode: "bj",
	},
	{
		name: "Bosnia and Herzegovina",
		code: "+387",
		countryCode: "ba",
	},
	{
		name: "Botswana",
		code: "+267",
		countryCode: "bw",
	},
	{
		name: "Brazil",
		code: "+55",
		countryCode: "br",
	},
	{
		name: "Bulgaria",
		code: "+359",
		countryCode: "bg",
	},
	{
		name: "Burkina Faso",
		code: "+226",
		countryCode: "bf",
	},
	{
		name: "Burundi",
		code: "+257",
		countryCode: "bi",
	},
	{
		name: "Cameroon",
		code: "+237",
		countryCode: "cm",
	},
	{
		name: "Cape Verde",
		code: "+238",
		countryCode: "cv",
	},
	{
		name: "Chad",
		code: "+235",
		countryCode: "td",
	},
	{
		name: "Chile",
		code: "+56",
		countryCode: "cl",
	},
	{
		name: "China",
		code: "+86",
		countryCode: "cn",
	},
	{
		name: "Hong Kong",
		code: "+852",
		countryCode: "hk",
	},
	{
		name: "Colombia",
		code: "+57",
		countryCode: "co",
	},
	{
		name: "Comoros",
		code: "+269",
		countryCode: "km",
	},
	{
		name: "Congo",
		code: "+242",
		countryCode: "cg",
	},
	{
		name: "Costa Rica",
		code: "+506",
		countryCode: "cr",
	},
	{
		name: "Croatia",
		code: "+385",
		countryCode: "hr",
	},
	{
		name: "Cyprus",
		code: "+357",
		countryCode: "cy",
	},
	{
		name: "Czech Republic",
		code: "+420",
		countryCode: "cz",
	},
	{
		name: "Denmark",
		code: "+45",
		countryCode: "dk",
	},
	{
		name: "Djibouti",
		code: "+253",
		countryCode: "dj",
	},
	{
		name: "Ecuador",
		code: "+593",
		countryCode: "ec",
	},
	{
		name: "Egypt",
		code: "+20",
		countryCode: "eg",
	},
	{
		name: "El Salvador",
		code: "+503",
		countryCode: "sv",
	},
	{
		name: "Equatorial Guinea",
		code: "+240",
		countryCode: "gq",
	},
	{
		name: "Eritrea",
		code: "+291",
		countryCode: "er",
	},
	{
		name: "Estonia",
		code: "+372",
		countryCode: "ee",
	},
	{
		name: "Eswatini",
		code: "+268",
		countryCode: "sz",
	},
	{
		name: "Ethiopia",
		code: "+251",
		countryCode: "et",
	},
	{
		name: "Finland",
		code: "+358",
		countryCode: "fi",
	},
	{
		name: "France",
		code: "+33",
		countryCode: "fr",
	},
	{
		name: "Gabon",
		code: "+241",
		countryCode: "ga",
	},
	{
		name: "Gambia",
		code: "+220",
		countryCode: "gm",
	},
	{
		name: "Germany",
		code: "+49",
		countryCode: "de",
	},
	{
		name: "Ghana",
		code: "+233",
		countryCode: "gh",
	},
	{
		name: "Greece",
		code: "+30",
		countryCode: "gr",
	},
	{
		name: "Guinea",
		code: "+224",
		countryCode: "gn",
	},
	{
		name: "Guinea-Bissau",
		code: "+245",
		countryCode: "gw",
	},
	{
		name: "Hungary",
		code: "+36",
		countryCode: "hu",
	},
	{
		name: "Iceland",
		code: "+354",
		countryCode: "is",
	},
	{
		name: "India",
		code: "+91",
		countryCode: "in",
	},
	{
		name: "Indonesia",
		code: "+62",
		countryCode: "id",
	},
	{
		name: "Iran",
		code: "+98",
		countryCode: "ir",
	},
	{
		name: "Iraq",
		code: "+964",
		countryCode: "iq",
	},
	{
		name: "Ireland",
		code: "+353",
		countryCode: "ie",
	},
	{
		name: "Israel",
		code: "+972",
		countryCode: "il",
	},
	{
		name: "Italy",
		code: "+39",
		countryCode: "it",
	},
	{
		name: "Ivory Coast",
		code: "+225",
		countryCode: "ci",
	},
	{
		name: "Japan",
		code: "+81",
		countryCode: "jp",
	},
	{
		name: "Kazakhstan",
		code: "+7",
		countryCode: "kz",
	},
	{
		name: "Kenya",
		code: "+254",
		countryCode: "ke",
	},
	{
		name: "Kosovo",
		code: "+383",
		countryCode: "xk",
	},
	{
		name: "Kuwait",
		code: "+965",
		countryCode: "kw",
	},
	{
		name: "Latvia",
		code: "+371",
		countryCode: "lv",
	},
	{
		name: "Lebanon",
		code: "+961",
		countryCode: "lb",
	},
	{
		name: "Lesotho",
		code: "+266",
		countryCode: "ls",
	},
	{
		name: "Liberia",
		code: "+231",
		countryCode: "lr",
	},
	{
		name: "Lithuania",
		code: "+370",
		countryCode: "lt",
	},
	{
		name: "Luxembourg",
		code: "+352",
		countryCode: "lu",
	},
	{
		name: "Madagascar",
		code: "+261",
		countryCode: "mg",
	},
	{
		name: "Malawi",
		code: "+265",
		countryCode: "mw",
	},
	{
		name: "Malaysia",
		code: "+60",
		countryCode: "my",
	},
	{
		name: "Malta",
		code: "+356",
		countryCode: "mt",
	},
	{
		name: "Mauritania",
		code: "+222",
		countryCode: "mr",
	},
	{
		name: "Mauritius",
		code: "+230",
		countryCode: "mu",
	},
	{
		name: "Mexico",
		code: "+52",
		countryCode: "mx",
	},
	{
		name: "Moldova",
		code: "+373",
		countryCode: "md",
	},
	{
		name: "Monaco",
		code: "+377",
		countryCode: "mc",
	},
	{
		name: "Montenegro",
		code: "+382",
		countryCode: "me",
	},
	{
		name: "Morocco",
		code: "+212",
		countryCode: "ma",
	},
	{
		name: "Mozambique",
		code: "+258",
		countryCode: "mz",
	},
	{
		name: "Myanmar",
		code: "+95",
		countryCode: "mm",
	},
	{
		name: "Namibia",
		code: "+264",
		countryCode: "na",
	},
	{
		name: "Nepal",
		code: "+977",
		countryCode: "np",
	},
	{
		name: "Netherlands",
		code: "+31",
		countryCode: "nl",
	},
	{
		name: "New Zealand",
		code: "+64",
		countryCode: "nz",
	},
	{
		name: "Nicaragua",
		code: "+505",
		countryCode: "ni",
	},
	{
		name: "Niger",
		code: "+227",
		countryCode: "ne",
	},
	{
		name: "Nigeria",
		code: "+234",
		countryCode: "ng",
	},
	{
		name: "North Korea",
		code: "+850",
		countryCode: "kp",
	},
	{
		name: "Norway",
		code: "+47",
		countryCode: "no",
	},
	{
		name: "Pakistan",
		code: "+92",
		countryCode: "pk",
	},
	{
		name: "Palestine",
		code: "+970",
		countryCode: "ps",
	},
	{
		name: "Panama",
		code: "+507",
		countryCode: "pa",
	},
	{
		name: "Paraguay",
		code: "+595",
		countryCode: "py",
	},
	{
		name: "Peru",
		code: "+51",
		countryCode: "pe",
	},
	{
		name: "Philippines",
		code: "+63",
		countryCode: "ph",
	},
	{
		name: "Poland",
		code: "+48",
		countryCode: "pl",
	},
	{
		name: "Portugal",
		code: "+351",
		countryCode: "pt",
	},
	{
		name: "Qatar",
		code: "+974",
		countryCode: "qa",
	},
	{
		name: "Romania",
		code: "+40",
		countryCode: "ro",
	},
	{
		name: "Russia",
		code: "+7",
		countryCode: "ru",
	},
	{
		name: "Rwanda",
		code: "+250",
		countryCode: "rw",
	},
	{
		name: "San Marino",
		code: "+378",
		countryCode: "sm",
	},
	{
		name: "Saudi Arabia",
		code: "+966",
		countryCode: "sa",
	},
	{
		name: "Senegal",
		code: "+221",
		countryCode: "sn",
	},
	{
		name: "Serbia",
		code: "+381",
		countryCode: "rs",
	},
	{
		name: "Seychelles",
		code: "+248",
		countryCode: "sc",
	},
	{
		name: "Sierra Leone",
		code: "+232",
		countryCode: "sl",
	},
	{
		name: "Singapore",
		code: "+65",
		countryCode: "sg",
	},
	{
		name: "Slovakia",
		code: "+421",
		countryCode: "sk",
	},
	{
		name: "Slovenia",
		code: "+386",
		countryCode: "si",
	},
	{
		name: "Somalia",
		code: "+252",
		countryCode: "so",
	},
	{
		name: "South Korea",
		code: "+82",
		countryCode: "kr",
	},
	{
		name: "South Sudan",
		code: "+211",
		countryCode: "ss",
	},
	{
		name: "Spain",
		code: "+34",
		countryCode: "es",
	},
	{
		name: "Sri Lanka",
		code: "+94",
		countryCode: "lk",
	},
	{
		name: "Sudan",
		code: "+249",
		countryCode: "sd",
	},
	{
		name: "Sweden",
		code: "+46",
		countryCode: "se",
	},
	{
		name: "Switzerland",
		code: "+41",
		countryCode: "ch",
	},
	{
		name: "Syria",
		code: "+963",
		countryCode: "sy",
	},
	{
		name: "Taiwan",
		code: "+886",
		countryCode: "tw",
	},
	{
		name: "Tajikistan",
		code: "+992",
		countryCode: "tj",
	},
	{
		name: "Tanzania",
		code: "+255",
		countryCode: "tz",
	},
	{
		name: "Thailand",
		code: "+66",
		countryCode: "th",
	},
	{
		name: "Togo",
		code: "+228",
		countryCode: "tg",
	},
	{
		name: "Tunisia",
		code: "+216",
		countryCode: "tn",
	},
	{
		name: "Turkey",
		code: "+90",
		countryCode: "tr",
	},
	{
		name: "Turkmenistan",
		code: "+993",
		countryCode: "tm",
	},
	{
		name: "Uganda",
		code: "+256",
		countryCode: "ug",
	},
	{
		name: "Ukraine",
		code: "+380",
		countryCode: "ua",
	},
	{
		name: "United Arab Emirates",
		code: "+971",
		countryCode: "ae",
	},
	{
		name: "United Kingdom",
		code: "+44",
		countryCode: "gb",
	},
	{
		name: "United States / Canada",
		code: "+1",
		countryCode: "us",
	},
	// {
	// 	name: "Canada",
	// 	code: "+1",
	// 	countryCode: "ca",
	// },
	{
		name: "Uruguay",
		code: "+598",
		countryCode: "uy",
	},
	{
		name: "Uzbekistan",
		code: "+998",
		countryCode: "uz",
	},
	{
		name: "Venezuela",
		code: "+58",
		countryCode: "ve",
	},
	{
		name: "Vietnam",
		code: "+84",
		countryCode: "vn",
	},
	{
		name: "Yemen",
		code: "+967",
		countryCode: "ye",
	},
	{
		name: "Zambia",
		code: "+260",
		countryCode: "zm",
	},
	{
		name: "Zimbabwe",
		code: "+263",
		countryCode: "zw",
	},
];
