import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

export type State = {
	createRecipientLoading?: boolean;
	createRecipientError?: string[] | null;

	setRecipientNicknameLoading?: boolean;
	setRecipientNicknameError?: string[] | null;

	deleteRecipientLoading?: boolean;
	deleteRecipientError?: string[] | null;

	updateRecipientLoading?: boolean;
	updateRecipientError?: string[] | null;

	recipientId?: number;
	recipientIdLoading?: boolean;
};

export const defaultState: State = {};

const slice = createSlice({
	name: "recipients",
	initialState: defaultState,
	reducers: {
		setCreateRecipientLoading(state: State, action: PayloadAction<boolean>) {
			state.createRecipientLoading = action.payload;
		},
		setCreateRecipientError(
			state: State,
			action: PayloadAction<string[] | null>,
		) {
			state.createRecipientError = action.payload;
		},
		setSetRecipientNicknameLoading(
			state: State,
			action: PayloadAction<boolean>,
		) {
			state.setRecipientNicknameLoading = action.payload;
		},
		setSetRecipientNicknameError(
			state: State,
			action: PayloadAction<string[] | null>,
		) {
			state.setRecipientNicknameError = action.payload;
		},

		setDeleteRecipientLoading(state: State, action: PayloadAction<boolean>) {
			state.deleteRecipientLoading = action.payload;
		},
		setDeleteRecipientError(
			state: State,
			action: PayloadAction<string[] | null>,
		) {
			state.deleteRecipientError = action.payload;
		},

		setUpdateRecipientLoading(state: State, action: PayloadAction<boolean>) {
			state.updateRecipientLoading = action.payload;
		},
		setUpdateRecipientError(
			state: State,
			action: PayloadAction<string[] | null>,
		) {
			state.updateRecipientError = action.payload;
		},

		setRecipientId(state: State, action: PayloadAction<number | undefined>) {
			state.recipientId = action.payload;
		},
		setRecipientIdLoading(state: State, action: PayloadAction<boolean>) {
			state.recipientIdLoading = action.payload;
		},
	},
});

export const {
	setCreateRecipientLoading,
	setCreateRecipientError,

	setSetRecipientNicknameLoading,
	setSetRecipientNicknameError,

	setDeleteRecipientLoading,
	setDeleteRecipientError,

	setUpdateRecipientLoading,
	setUpdateRecipientError,

	setRecipientId,
	setRecipientIdLoading,
} = slice.actions;

export default slice.reducer;
