import styles from "./index.module.css";

const LoadingIcon = () => (
	<svg
		role="presentation"
		xmlns="http://www.w3.org/2000/svg"
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
	>
		<g clipPath="url(#clip0_338_25739)">
			<path
				d="M9.99935 1.66675V5.00008M9.99935 15.0001V18.3334M4.99935 10.0001H1.66602M18.3327 10.0001H14.9993M15.898 15.8988L13.541 13.5417M15.898 4.1667L13.541 6.52372M4.10066 15.8988L6.45768 13.5417M4.10066 4.1667L6.45768 6.52372"
				stroke="#56A7A2"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_338_25739">
				<rect width="20" height="20" fill="white" />
			</clipPath>
		</defs>
	</svg>
);

const SavedIcon = () => (
	<svg
		role="presentation"
		xmlns="http://www.w3.org/2000/svg"
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
	>
		<g clipPath="url(#clip0_338_25742)">
			<path
				d="M6.24935 10.0001L8.74935 12.5001L13.7493 7.50008M18.3327 10.0001C18.3327 14.6025 14.6017 18.3334 9.99935 18.3334C5.39698 18.3334 1.66602 14.6025 1.66602 10.0001C1.66602 5.39771 5.39698 1.66675 9.99935 1.66675C14.6017 1.66675 18.3327 5.39771 18.3327 10.0001Z"
				stroke="#56A7A2"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_338_25742">
				<rect width="20" height="20" fill="white" />
			</clipPath>
		</defs>
	</svg>
);

export const ChangeSaved = ({
	status,
}: {
	status: "saving" | "saved" | "unsaved";
}) => {
	return (
		<div role="alert" className={styles.container}>
			{status === "saving" && (
				<>
					<LoadingIcon />
					Saving changes...
				</>
			)}
			{status === "saved" && (
				<>
					<SavedIcon />
					Changes saved
				</>
			)}
			{status === "unsaved" && "Your changes will be automatically saved"}
		</div>
	);
};
