import { api } from "@app/services";
import { useCallback } from "react";

export const useUserSettings = () => {
	const handleChangeEmail = useCallback(async (token: string) => {
		return api.post("users/change-email/", {
			token,
		});
	}, []);
	return {
		changePassword: async (currentPassword: string, newPassword: string) => {
			return api.post("users/change-password/", {
				current_password: currentPassword,
				new_password: newPassword,
			});
		},
		sendChangeEmailLink: async (email: string) => {
			return await api.post("users/send-change-email-link/", {
				new_email: email,
			});
		},
		changeEmail: handleChangeEmail,
	};
};
