import type { Properties, ViewProperties } from "./properties";
import { TagGroupView } from "./tag-group-view";

export const TagGroup = <T,>(props: Properties<T>) => {
	const tagIcon = (value: unknown): string =>
		props.tagIcon ? props.tagIcon(value as T) : "";
	const tagClassName = (value: unknown) =>
		props.tagClassName ? props.tagClassName(value as T) : "";

	const tagContainerClassName = (value: unknown) => {
		return props.tagContainerClassName
			? props.tagContainerClassName(value as T)
			: "";
	};

	const tagTemplate = (value: unknown) => props.tagTemplate?.(value as T);

	const onRemove = (value: unknown) => props.onRemove?.(value as T);

	const onRemoveGroup = (value: unknown) => props.onRemoveGroup?.(value as T[]);

	const viewProps: ViewProperties<T> = {
		...props,
	};

	return (
		<TagGroupView
			{...viewProps}
			onRemove={onRemove}
			onRemoveGroup={onRemoveGroup}
			tagIcon={props.tagIcon ? tagIcon : undefined}
			tagClassName={props.tagClassName ? tagClassName : undefined}
			tagContainerClassName={tagContainerClassName}
			tagTemplate={props.tagTemplate ? tagTemplate : undefined}
		/>
	);
};
