import clsx from "clsx";
import { ComponentPropsWithoutRef, ElementRef, useRef } from "react";
import { FiX } from "react-icons/fi";
import styles from "./index.module.css";

export const Search = ({
	onClear,
	className,
	placeholder,
	...rest
}: { onClear?: () => void } & ComponentPropsWithoutRef<"input">) => {
	const inputRef = useRef<ElementRef<"input">>(null);
	return (
		<div className={clsx(styles.container, className)}>
			<button
				className={styles.button}
				type="button"
				aria-label="focus search"
				onClick={() => {
					inputRef.current?.focus();
				}}
			>
				<svg
					role="presentation"
					xmlns="http://www.w3.org/2000/svg"
					width="20"
					height="20"
					viewBox="0 0 20 20"
					fill="none"
				>
					<path
						d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
						stroke="currentColor"
						strokeWidth="1.66667"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			</button>
			<input
				ref={inputRef}
				placeholder={placeholder}
				aria-label="search"
				className={styles.input}
				{...rest}
			/>
			{onClear && rest.value && (
				<button
					type="button"
					aria-label="clear search"
					className={styles.clearButton}
					onClick={onClear}
				>
					<FiX size={20} color="#888" />
				</button>
			)}
		</div>
	);
};
