import { ComponentPropsWithoutRef, ReactNode } from "react";

import clsx from "clsx";
import { MoreInfoTooltip } from "../more-info-tooltip";
import styles from "./index.module.css";

export const Label = ({
	children,
	className,
	tooltip,
	tooltipWidth,
	actions,
	...rest
}: ComponentPropsWithoutRef<"label"> & {
	htmlFor: string;
	tooltip?: ReactNode;
	tooltipWidth?: number;
	actions?: ReactNode;
}) => {
	const tooltipTitle =
		typeof children === "string" ? children.replace("*", "") : undefined;
	return (
		<div className={styles.container}>
			<label className={clsx(styles.label, className)} {...rest}>
				{children}
				{tooltip && (
					<MoreInfoTooltip hasIcon maxWidth={tooltipWidth} name={tooltipTitle}>
						{tooltip}
					</MoreInfoTooltip>
				)}
			</label>
			{actions}
		</div>
	);
};
