export const SendReceiveIcon = () => {
	return (
		<svg
			role="presentation"
			xmlns="http://www.w3.org/2000/svg"
			width="29"
			height="28"
			viewBox="0 0 29 28"
			fill="none"
		>
			<path
				d="M5.16695 8.16537H23.8336M23.8336 8.16537L19.167 3.4987M23.8336 8.16537L19.167 12.832"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M23.8335 19.8346H5.16687M5.16687 19.8346L9.83354 24.5013M5.16687 19.8346L9.83354 15.168"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
