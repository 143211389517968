import styles from "./index.module.css";

export const StatusTag = ({ status }: { status?: string }) => {
	if (!status) return null;
	return (
		<div data-status={status} className={styles.tag}>
			{status === "complete" && "Complete"}
			{status === "awaiting_submission" && "Awaiting submission"}
			{status === "in_progress" && "In progress"}
		</div>
	);
};
