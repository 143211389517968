type Seperator = "," | " ";

const addSeparatorToNumber = (val: string, separator: Seperator = ",") => {
	try {
		return val.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, separator);
	} catch {
		return val;
	}
};

export const removeNumberFormatting = (value: string) => {
	const formatted = value.replace(/[^0-9.]/g, "");
	if (
		formatted.startsWith("0") &&
		!formatted.includes(".") &&
		formatted.length > 1
	) {
		return formatted.slice(1);
	}
	return formatted;
};

export const formatNumberString = (
	val: string,
	useSeparator: Seperator = ",",
) => {
	if (!val) return val;
	const isTempValue =
		val.endsWith(".") ||
		val.endsWith(useSeparator) ||
		val.endsWith(".0") ||
		val.endsWith(`${useSeparator}0`);
	if (isTempValue) return addSeparatorToNumber(val, useSeparator);
	const hasDecimal = val.includes(".") && val.split(".")[1].length > 2;
	if (hasDecimal)
		return addSeparatorToNumber(
			val.slice(0, val.lastIndexOf(".") + 3),
			useSeparator,
		);
	return addSeparatorToNumber(val, useSeparator);
};
