import { type ReactNode } from "react";

import { joinClasses } from "@app/utils";

import styles from "./index.module.css";

export const ErrorLabel = ({
	className,
	children,
}: {
	children?: ReactNode;
	className?: string;
}) => {
	return (
		<div className={joinClasses("field-error", styles.container, className)}>
			{children}
		</div>
	);
};
