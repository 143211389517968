import type {
	WithdrawOptions,
	WithdrawalBankAccount,
	WithdrawalBankAccounts,
} from "@app/entities";
import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

export type State = {
	withdrawalBankAccountsLoading?: boolean;
	withdrawalBankAccountsError?: string[] | null;
	withdrawalBankAccounts?: WithdrawalBankAccounts;

	withdrawalBankAccountLoading?: boolean;
	withdrawalBankAccountError?: string[] | null;
	withdrawalBankAccount?: WithdrawalBankAccount;

	withdrawOptionsLoading?: boolean;
	withdrawOptionsError?: string[] | null;
	withdrawOptions?: WithdrawOptions;

	createWithdrawalBankAccountLoading?: boolean;
	createWithdrawalBankAccountError?: string[] | null;

	createWithdrawalRequestLoading?: boolean;
	createWithdrawalRequestError?: string[] | null;

	deleteWithdrawalBankAccountLoading?: boolean;
	deleteWithdrawalBankAccountError?: string[] | null;

	updateWithdrawalBankAccountLoading?: boolean;
	updateWithdrawalBankAccountError?: string[] | null;
};

export const defaultState: State = {};

const slice = createSlice({
	name: "withdrawals",
	initialState: defaultState,
	reducers: {
		setWithdrawalBankAccountsLoading(
			state: State,
			action: PayloadAction<boolean>,
		) {
			state.withdrawalBankAccountsLoading = action.payload;
		},
		setWithdrawalBankAccountsError(
			state: State,
			action: PayloadAction<string[] | null>,
		) {
			state.withdrawalBankAccountsError = action.payload;
		},
		setWithdrawalBankAccounts(
			state: State,
			action: PayloadAction<WithdrawalBankAccounts>,
		) {
			state.withdrawalBankAccounts = action.payload;
		},

		setWithdrawalBankAccountLoading(
			state: State,
			action: PayloadAction<boolean>,
		) {
			state.withdrawalBankAccountLoading = action.payload;
		},
		setWithdrawalBankAccountError(
			state: State,
			action: PayloadAction<string[] | null>,
		) {
			state.withdrawalBankAccountError = action.payload;
		},
		setWithdrawalBankAccount(
			state: State,
			action: PayloadAction<WithdrawalBankAccount>,
		) {
			state.withdrawalBankAccount = action.payload;
		},

		setWithdrawOptionsLoading(state: State, action: PayloadAction<boolean>) {
			state.withdrawOptionsLoading = action.payload;
		},
		setWithdrawOptionsError(
			state: State,
			action: PayloadAction<string[] | null>,
		) {
			state.withdrawOptionsError = action.payload;
		},
		setWithdrawOptions(state: State, action: PayloadAction<WithdrawOptions>) {
			state.withdrawOptions = action.payload;
		},

		setCreateWithdrawalBankAccountLoading(
			state: State,
			action: PayloadAction<boolean>,
		) {
			state.createWithdrawalBankAccountLoading = action.payload;
		},
		setCreateWithdrawalBankAccountError(
			state: State,
			action: PayloadAction<string[] | null>,
		) {
			state.createWithdrawalBankAccountError = action.payload;
		},

		setCreateWithdrawalRequestLoading(
			state: State,
			action: PayloadAction<boolean>,
		) {
			state.createWithdrawalRequestLoading = action.payload;
		},
		setCreateWithdrawalRequestError(
			state: State,
			action: PayloadAction<string[] | null>,
		) {
			state.createWithdrawalRequestError = action.payload;
		},

		setDeleteWithdrawalBankAccountLoading(
			state: State,
			action: PayloadAction<boolean>,
		) {
			state.deleteWithdrawalBankAccountLoading = action.payload;
		},
		setDeleteWithdrawalBankAccountError(
			state: State,
			action: PayloadAction<string[] | null>,
		) {
			state.deleteWithdrawalBankAccountError = action.payload;
		},

		setUpdateWithdrawalBankAccountLoading(
			state: State,
			action: PayloadAction<boolean>,
		) {
			state.updateWithdrawalBankAccountLoading = action.payload;
		},
		setUpdateWithdrawalBankAccountError(
			state: State,
			action: PayloadAction<string[] | null>,
		) {
			state.updateWithdrawalBankAccountError = action.payload;
		},
	},
});

export const {
	setWithdrawalBankAccountsLoading,
	setWithdrawalBankAccountsError,
	setWithdrawalBankAccounts,

	setWithdrawalBankAccountLoading,
	setWithdrawalBankAccountError,
	setWithdrawalBankAccount,

	setWithdrawOptionsLoading,
	setWithdrawOptionsError,
	setWithdrawOptions,

	setCreateWithdrawalBankAccountLoading,
	setCreateWithdrawalBankAccountError,

	setCreateWithdrawalRequestLoading,
	setCreateWithdrawalRequestError,

	setDeleteWithdrawalBankAccountLoading,
	setDeleteWithdrawalBankAccountError,

	setUpdateWithdrawalBankAccountLoading,
	setUpdateWithdrawalBankAccountError,
} = slice.actions;

export default slice.reducer;
