import { ReactNode, useRef, useState } from "react";
import { OnboardingFooter } from "../onboarding-footer";
import { OnboardingLayout } from "../onboarding-layout";

import styles from "./index.module.css";

import { ApiErrors } from "@app/components/api-errors";
import { ProcessingModal } from "@app/components/processing-modal";
import { paths } from "@app/constants/paths";
import { useClients } from "@app/hooks/use-clients";
import { sleep } from "@app/utils/sleep";
import { useRelatedEntities } from "../related-entities/use-related-entities";
import { PrimaryShareholderForm } from "./primary-shareholder-form";

export const PrimaryBusinessShareholders = () => {
	const { activeClientId } = useClients();
	const [showSuccess, setShowSuccess] = useState(false);
	const [isFinalSubmission, setIsFinalSubmission] = useState(false);
	const [apiErrors, setApiErrors] = useState<ReactNode[]>([]);
	const [isSaved, setIsSaved] = useState(false);
	const [isSubmitting, setSubmitting] = useState(false);
	const [isSavingChanges, setIsSavingChanges] = useState(false);
	const refs = useRef<Record<string, any>>({});

	const { entitiesForVariant, submit, mutate } =
		useRelatedEntities("shareholder");

	const handleSubmit = async () => {
		setIsSavingChanges(true);
		setSubmitting(true);

		const allRefs = Object.values(refs.current).filter(Boolean);
		const results = await Promise.all(allRefs.map((ref) => ref?.submit()));
		const isSuccessful = results.every((isSuccess) => isSuccess);
		await mutate();

		if (isSuccessful) {
			const startTime = Date.now();
			setIsFinalSubmission(true);
			const errors = await submit();
			if (errors?.apiErrors) {
				setApiErrors(errors?.apiErrors);
			} else {
				const pastTime = Date.now() - startTime;
				if (pastTime < 2000) {
					await sleep(2000 - pastTime);
				}
				setShowSuccess(true);
				setTimeout(() => {
					setShowSuccess(false);
					window.location.href = paths().dashboard;
				}, 4000);
			}
			setIsFinalSubmission(false);
		}

		setIsSavingChanges(false);
		setIsSaved(true);
		setSubmitting(false);
	};

	const handleNavigate = async (path: string) => {
		if (!path) return;
		const elem = document.querySelector(`#entity-${path}`);
		if (elem) {
			elem.scrollIntoView({ behavior: "smooth" });
		}
	};

	return (
		<OnboardingLayout
			step={0}
			variant="legal_entity"
			onStepNavigate={handleNavigate}
		>
			{entitiesForVariant.map((entity, index) => {
				if (!activeClientId) return null;
				return (
					<PrimaryShareholderForm
						onPartialSave={() => mutate()}
						ref={(ref) => {
							refs.current[entity.id] = ref;
						}}
						activeClientId={activeClientId}
						key={entity.id}
						id={entity.related_entity_id}
						linkId={entity.id}
						index={index}
						onSavingChanges={setIsSavingChanges}
						onSaved={setIsSaved}
						isDisabled={!entity.is_editable}
					/>
				);
			})}
			<ApiErrors className={styles.apiErrors} errors={apiErrors} />

			<OnboardingFooter
				isSubmitting={isSubmitting}
				formId="primary-shareholders"
				onSubmit={handleSubmit}
				primaryText="Submit"
				isSaving={isSavingChanges}
				hasSaved={isSaved}
			/>
			<ProcessingModal
				successText="Form submitted"
				processingText="Submitting form"
				isOpen={isFinalSubmission || showSuccess}
				showSuccess={showSuccess}
			/>
		</OnboardingLayout>
	);
};
