import { useClients } from "@app/hooks/use-clients";
import { RelatedEntities } from "../related-entities";

export const BusinessCompanySignatories = () => {
	const { activeClientId } = useClients();
	if (!activeClientId) return null;
	return (
		<RelatedEntities
			variant="authorised_signatory"
			activeClientId={activeClientId}
		/>
	);
};
