import { useDispatch } from "react-redux";

import type { CreateRecipient, UpdateRecipient } from "@app/entities";
import type { AppDispatch } from "@app/redux";
import {
	createRecipientAction,
	deleteRecipientAction,
	setRecipientIdAction,
	setRecipientNicknameAction,
	updateRecipientAction,
} from "@app/redux/recipients/recipients-actions";
import type { MappedReasons } from "@app/services";

export const useDeleteRecipient = () => {
	const dispatch: AppDispatch = useDispatch();

	const deleteRecipient = (
		recipientId: number,
		callback?: (response: number | string[] | undefined) => void,
	) => {
		dispatch(deleteRecipientAction(recipientId, callback));
	};

	return [deleteRecipient];
};

export const useCreateRecipient = () => {
	const dispatch: AppDispatch = useDispatch();

	const createRecipient = (
		recipient: CreateRecipient,
		callback?: (errors?: string[], mappedReasons?: MappedReasons) => void,
	) => {
		dispatch(createRecipientAction(recipient, callback));
	};

	return [createRecipient];
};

export const useUpdateRecipient = () => {
	const dispatch: AppDispatch = useDispatch();

	const updateRecipient = (
		recipient: UpdateRecipient,
		callback?: (errors?: string[], mappedReasons?: MappedReasons) => void,
	) => {
		dispatch(updateRecipientAction(recipient, callback));
	};

	return [updateRecipient];
};

export const useSetRecipientNickname = () => {
	const dispatch: AppDispatch = useDispatch();

	const setRecipientNickname = (
		recipientId: number,
		nickname: string,
		callback?: (errors?: string[], mappedReasons?: MappedReasons) => void,
	) => {
		dispatch(setRecipientNicknameAction(recipientId, nickname, callback));
	};

	return [setRecipientNickname];
};

export const useSetRecipientId = () => {
	const dispatch: AppDispatch = useDispatch();

	const setRecipientId = (recipientId?: number) => {
		dispatch(setRecipientIdAction(recipientId));
	};

	return [setRecipientId];
};
