import { ApiErrors } from "@app/components/api-errors";
import { useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Card } from "../card";
import { OnboardingFooter } from "../onboarding-footer";
import { OnboardingLayout } from "../onboarding-layout";

import { Label } from "@app/components/label";
import { paths } from "@app/constants/paths";
import { useFormErrors } from "@app/hooks/use-form-errors";
import { useUserProfile } from "@app/screens/user-settings/use-user-profile";
import { api } from "@app/services";
import { useNavigate } from "react-router-dom";
import { ContactNumberField } from "../contact-number-field";
import { DocumentField } from "../document-field";
import { Row } from "../row";
import { TextField } from "../text-field";
import styles from "./form.module.css";
import {
	PersonalInformation,
	PersonalInformationUpdate,
	usePersonalInformation,
} from "./use-personal-information";

type Inputs = {
	first_name: string;
	middle_names: string;
	last_name: string;
	contact_number: string;
	email_address: string;
	id_number: string;
	tax_number: string;
	id_document: string;
	id_selfie: string;
};

export const Form = ({ activeClientId }: { activeClientId: number }) => {
	const navigate = useNavigate();
	const [showGeneralErrors, setShowGeneralErrors] = useState(false);
	const [isSavingChanges, setIsSavingChanges] = useState(false);
	const [isSaved, setIsSaved] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const { update, submit } = usePersonalInformation();
	const { data: userProfile } = useUserProfile();

	const mapFormToDTO = (data: Inputs): PersonalInformationUpdate => {
		return {
			...data,
			email_address: data.email_address
				? data.email_address
				: userProfile?.email ?? "",
		};
	};

	const methods = useForm<Inputs>({
		shouldFocusError: false,
		defaultValues: async () => {
			try {
				const { data } = await api.get<PersonalInformation>(
					`/onboarding/${activeClientId}/personal-information/`,
				);
				return data;
			} catch {
				setShowGeneralErrors(true);
				return {} as PersonalInformation;
			}
		},
	});
	const { register, handleSubmit, watch, setValue, getValues, trigger } =
		methods;
	const {
		errors,
		clearApiFieldErrors,
		onErrors,
		setError,
		clearErrors,
		apiErrors,
		onInvalid,
	} = useFormErrors(methods);

	const onSubmit: SubmitHandler<Inputs> = async (data) => {
		clearApiFieldErrors();
		setIsSubmitting(true);
		setIsSavingChanges(true);

		const errors = await submit(mapFormToDTO(data));

		if (errors) {
			onErrors(errors, true);
		} else {
			setIsSaved(true);
			navigate(paths().onboarding.individual.addressInformation);
		}
		setIsSavingChanges(false);
		setIsSubmitting(false);
	};

	const handlePartialSave = async () => {
		const data = mapFormToDTO(getValues());
		if (Object.keys(data).length === 0) return;
		setIsSavingChanges(true);

		const errors = await update(data);
		clearApiFieldErrors();
		if (errors) {
			onErrors(errors);
		} else {
			setIsSaved(true);
		}
		setIsSavingChanges(false);
	};

	const handleNavigate = async (path: string) => {
		if (!path) return;
		const data = getValues();
		const errors = await submit(mapFormToDTO(data));
		if (errors) {
			onErrors(errors, true);
			return;
		}
		navigate(path);
	};

	register("id_document", {
		required: "This field is required",
	});

	register("id_selfie", {
		required: "This field is required",
	});

	return (
		<OnboardingLayout
			step={0}
			onStepNavigate={handleNavigate}
			showError={showGeneralErrors}
		>
			<FormProvider {...methods}>
				<Card>
					<form
						id="personal"
						onSubmit={(event) => {
							event.preventDefault();
							const isValid = true;

							const data = getValues();

							if (!isValid) {
								trigger();
								onInvalid();
								return;
							}

							handleSubmit(onSubmit, onInvalid)(event);
						}}
					>
						<Label htmlFor="first_name">Full name*</Label>
						<Row className={styles.nameRow}>
							<TextField
								error={errors.first_name}
								autoComplete="given-name"
								placeholder="Enter your first name"
								{...register("first_name", {
									onBlur: handlePartialSave,
								})}
							/>

							<TextField
								error={errors.middle_names}
								placeholder="Enter middle name (optional)"
								{...register("middle_names", {
									onBlur: handlePartialSave,
								})}
							/>

							<TextField
								error={errors.last_name}
								autoComplete="family-name"
								placeholder="Enter your last name"
								{...register("last_name", {
									required: "This field is required",
									onBlur: handlePartialSave,
								})}
							/>
						</Row>
						<Row>
							<TextField
								label="Email*"
								error={errors.email_address}
								name="email"
								type="email"
								placeholder="Enter your email address"
								disabled
								value={watch("email_address") ?? userProfile?.email}
							/>

							<ContactNumberField
								error={errors.contact_number}
								onBlur={() => handlePartialSave()}
							/>
						</Row>
						<Row>
							<TextField
								error={errors.id_number}
								label="ID number*"
								placeholder="Enter your ID number"
								{...register("id_number", {
									required: "This field is required",
									validate: (value) => {
										const idNumber = value.replace(/\s/g, "");
										if (idNumber.length !== 13) {
											return "ID number must be 13 characters long";
										}
										if (!/^\d+$/.test(idNumber)) {
											return "ID number must only contain numbers";
										}
										return true;
									},
									onBlur: handlePartialSave,
								})}
							/>

							<TextField
								error={errors.tax_number}
								label="Tax number*"
								placeholder="Enter your tax number"
								{...register("tax_number", {
									required: "This field is required",
									validate: (value) => {
										const taxNumber = value.replace(/\s/g, "");
										if (taxNumber.length !== 10) {
											return "Tax number must be 10 characters long";
										}
										if (!/^\d+$/.test(taxNumber)) {
											return "Tax number must only contain numbers";
										}
										return true;
									},
									onChange: (event) => {
										const value = event.target.value.replace(/\D/g, "");
										setValue("tax_number", value);
									},
									onBlur: handlePartialSave,
								})}
							/>
						</Row>
						<DocumentField
							type="id_document"
							tooltip="Upload a copy of your ID Book or both sides of your ID Card."
							value={watch("id_document")}
							onChange={async () => {
								if (!activeClientId) return;
								const result = await api.get<PersonalInformation>(
									`/onboarding/${activeClientId}/personal-information/`,
								);
								setValue("id_document", result.data.id_document);
								clearErrors("id_document");
							}}
							label="ID Document"
							error={errors.id_document}
						/>

						<DocumentField
							type="id_selfie"
							tooltip={
								<>
									Upload a picture of yourself holding the uploaded ID
									<br />
									(must be legible and clear).
								</>
							}
							tooltipWidth={224}
							value={watch("id_selfie")}
							onChange={async () => {
								if (!activeClientId) return;
								const result = await api.get<PersonalInformation>(
									`/onboarding/${activeClientId}/personal-information/`,
								);
								setValue("id_selfie", result.data.id_selfie);
								clearErrors("id_selfie");
							}}
							label="Selfie with ID"
							error={errors.id_selfie}
						/>
						<ApiErrors className={styles.apiErrors} errors={apiErrors} />
					</form>
				</Card>
			</FormProvider>
			<OnboardingFooter
				isSubmitting={isSubmitting}
				formId="personal"
				isSaving={isSavingChanges}
				hasSaved={isSaved}
			/>
		</OnboardingLayout>
	);
};
