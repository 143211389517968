import { useNavigate } from "react-router-dom";

import { paths } from "@app/constants/paths";

import { Button } from "../button";
import styles from "./index.module.css";

export const NoTransactionsBlock = ({
	showButton = false,
}: {
	showButton?: boolean;
}) => {
	const navigate = useNavigate();

	return (
		<div className={styles.container}>
			<p>No transactions to display</p>

			{showButton && (
				<Button
					inline
					size="sm"
					variant="secondary"
					onClick={() => navigate(paths().sendReceiveFunds(undefined))}
				>
					Send/Receive funds
				</Button>
			)}
		</div>
	);
};
