import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

export type State = {
	userLoading?: boolean;
	userError?: string[] | null;
	unverifiedEmail?: string | null;

	resendConfirmationEmailLoading?: boolean;
	resendConfirmationEmailError?: string[] | null;

	sendResetPasswordLinkLoading?: boolean;
	sendResetPasswordLinkError?: string[] | null;

	resetPasswordLoading?: boolean;
	resetPasswordError?: string[] | null;

	newPageLoad: boolean;
};

export const defaultState: State = {
	newPageLoad: true,
};

const slice = createSlice({
	name: "auth",
	initialState: defaultState,
	reducers: {
		setUserLoading(state: State, action: PayloadAction<boolean>) {
			state.userLoading = action.payload;
		},
		setUnverifiedEmail(state: State, action: PayloadAction<string | null>) {
			state.unverifiedEmail = action.payload;
		},
		setUserError(state: State, action: PayloadAction<string[] | null>) {
			state.userError = action.payload;
		},
		setResendConfirmationEmailLoading(
			state: State,
			action: PayloadAction<boolean>,
		) {
			state.resendConfirmationEmailLoading = action.payload;
		},
		setResendConfirmationEmailError(
			state: State,
			action: PayloadAction<string[] | null>,
		) {
			state.resendConfirmationEmailError = action.payload;
		},
		setSendPasswordResetLinkLoading(
			state: State,
			action: PayloadAction<boolean>,
		) {
			state.sendResetPasswordLinkLoading = action.payload;
		},
		setSendPasswordResetLinkError(
			state: State,
			action: PayloadAction<string[] | null>,
		) {
			state.sendResetPasswordLinkError = action.payload;
		},
		setResetPasswordError(
			state: State,
			action: PayloadAction<string[] | null>,
		) {
			state.resetPasswordError = action.payload;
		},
		setResetPasswordLoading(state: State, action: PayloadAction<boolean>) {
			state.resetPasswordLoading = action.payload;
		},

		setNewPageLoad(state: State, action: PayloadAction<boolean>) {
			state.newPageLoad = action.payload;
		},
	},
});

export const {
	setUserLoading,
	setUserError,
	setUnverifiedEmail,

	setResendConfirmationEmailLoading,
	setResendConfirmationEmailError,

	setSendPasswordResetLinkLoading,
	setSendPasswordResetLinkError,

	setResetPasswordLoading,
	setResetPasswordError,

	setNewPageLoad,
} = slice.actions;

export default slice.reducer;
