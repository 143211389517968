import placeholderSrc from "@app/assets/images/user-profile-blank-round.svg";
import { CSSProperties } from "react";
import styles from "./index.module.css";

export const ProfilePicture = ({
	src,
	size = 30,
}: {
	src?: string;
	size?: number;
}) => {
	return (
		<img
			className={styles.profilePicture}
			src={src || placeholderSrc}
			width={size}
			height={size}
			style={{ "--size": `${size}px` } as CSSProperties}
			alt=""
		/>
	);
};
