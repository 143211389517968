import { useState } from "react";

import { Typography } from "@app/components";

import type { ViewProperties } from "../properties";

import { ApiErrors } from "@app/components/api-errors";
import { MoreInfoTooltip } from "@app/components/more-info-tooltip";
import {
	FiBriefcase,
	FiCheck,
	FiChevronDown,
	FiInfo,
	FiUser,
	FiUserPlus,
} from "react-icons/fi";
import "./recipients-type-selection.css";

import { Dialog } from "@app/components/dialog";
import { useMediaQuery } from "@app/hooks/use-media-query";
import styles from "./recipients-type-selection.module.css";

const getIcon = (iconName: string, size = 32) => {
	if (iconName === "FiUser")
		return <FiUser size={size} color="#56A7A2" className="stroke-2 pb-1" />;
	if (iconName === "FiUserPlus")
		return <FiUserPlus size={size} color="#56A7A2" className="stroke-2 pb-1" />;
	if (iconName === "FiBriefcase")
		return (
			<FiBriefcase size={size} color="#56A7A2" className="stroke-2 pb-1" />
		);
	return null;
};

export const RecipientTypeSelection = (
	props: ViewProperties & { editMode?: boolean; error?: string },
) => {
	const [showRecipientTypeSelect, setShowRecipientTypeSelect] = useState(false);

	const getRecipientTypeButtonContent = (
		index: number,
		recipientType: string,
		iconName: string,
		message: string,
	) => {
		return (
			<button
				key={index}
				data-current={props.recipientType === recipientType}
				data-error={!!props.error}
				type="button"
				className={styles.recipientButton}
				onClick={() => props.onSelectRecipientType(recipientType)}
			>
				{iconName.startsWith("/static") ? (
					<img width={32} height={32} alt="" src={iconName} />
				) : (
					getIcon(iconName)
				)}
				<p>{message}</p>
			</button>
		);
	};

	const getRecipientFromRecipientType = () => {
		const selectedRecipient = props.recipientTypeOptions.filter(
			(x) => x.type === props.recipientType,
		)[0];
		if (selectedRecipient) {
			return selectedRecipient;
		}
	};

	const getModalItems = (items: typeof props.recipientTypeOptions) => {
		return items.map((current, index) => {
			return (
				<button
					type="button"
					key={index}
					className={`flex h-16 w-full flex-row items-center justify-between px-3 rounded ${
						props.recipientType === current.type ? "bg-gray-50" : ""
					}`}
					onClick={() => props.onSelectRecipientType(current.type)}
				>
					<span className="flex h-full items-center">
						{current.icon.startsWith("/static") ? (
							<img width={20} height={20} alt="" src={current.icon} />
						) : (
							getIcon(current.icon, 20)
						)}
						<Typography
							theme="textMd"
							className="font-primary-medium ml-4 leading-5 text-gray-900"
						>
							{current.label}
						</Typography>
					</span>
					{props.recipientType === current.type && (
						<FiCheck size={24} color="#888" />
					)}
				</button>
			);
		});
	};

	const recipient = getRecipientFromRecipientType();

	return (
		<div className="recipient-type-selection-wrapper">
			<Typography theme="textSm" className="mb-6">
				Please ensure that accurate details are provided to avoid misplaced
				funds.
			</Typography>
			<Typography
				theme="textSm"
				className={"recipient-type-text flex flex-row"}
			>
				What type of recipient is this?
				<span className="ml-2 flex items-center">
					{props.isEdit && (
						<MoreInfoTooltip
							indicator={
								<div className="flex h-full items-center gap-2 px-0">
									<FiInfo size={24} color="#56a7a2" />
									{!props.isEdit && (
										<Typography
											theme="textMd"
											className={"font-medium text-teal-550"}
										>
											Not sure?
										</Typography>
									)}
								</div>
							}
							maxWidth={360}
							hasIcon
							name="Not sure?"
						>
							To add a recipient that\'s a Trust, NPO, or any other recipient
							type not listed here, please select "Another company"
						</MoreInfoTooltip>
					)}
				</span>
			</Typography>
			{!props.isEdit && (
				<div className="mb-6 flex h-full w-full flex-col justify-between gap-2">
					{props.recipientTypeOptions.map((current, index) => {
						return getRecipientTypeButtonContent(
							index,
							current.type,
							current.icon,
							current.label,
						);
					})}
				</div>
			)}
			{props.error && (
				<div className="mb-6">
					<ApiErrors autoFocus errors={[props.error]} />
				</div>
			)}
			{props.isEdit && (
				<button
					type="button"
					className={"recipient-drop-down-selector"}
					onClick={() => setShowRecipientTypeSelect(true)}
				>
					<div className="flex h-full flex-row items-center">
						{recipient?.icon && getIcon(recipient.icon, 40)}
						<Typography
							theme="textMd"
							className="font-primary-medium ml-4 leading-5 text-gray-900"
						>
							{recipient?.label}
						</Typography>
					</div>
					<FiChevronDown size={24} color="#56a7a2" />
				</button>
			)}
			{!props.isEdit && (
				<div className="flex w-full flex-row justify-center">
					<MoreInfoTooltip
						name="Not sure?"
						indicator={
							<div className="flex h-full items-center gap-2 px-0">
								<FiInfo size={24} color="#56a7a2" />
								{!props.isEdit && (
									<Typography
										theme="textMd"
										className={"font-medium text-teal-550"}
									>
										Not sure?
									</Typography>
								)}
							</div>
						}
						maxWidth={360}
						hasIcon
					>
						To add a recipient that\'s a Trust, NPO, or any other recipient type
						not listed here, please select "Another company"
					</MoreInfoTooltip>
				</div>
			)}

			<Dialog
				bottomsheet
				isOpen={showRecipientTypeSelect}
				onClose={() => setShowRecipientTypeSelect(false)}
			>
				<div>{getModalItems(props.recipientTypeOptions)}</div>
			</Dialog>
		</div>
	);
};
