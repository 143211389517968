import type { SetProfilePictureParams } from "@app/entities";
import { type MappedReasons, apiClients } from "@app/services";

import { getActiveClientFromSession } from "@app/hooks/use-clients";
import type { AppThunk } from "../store";
import {
	resetProfilePicture,
	setSetProfilePictureError,
	setSetProfilePictureLoading,
} from "./clients-slice";

export const setProfilePictureAction = (
	params: SetProfilePictureParams,
	callback?: (errors?: string[], mappedReasons?: MappedReasons) => void,
): AppThunk => {
	let reasons: string[] | undefined = undefined;
	let mappedReasons: MappedReasons | undefined;

	return async (dispatch, getState) => {
		try {
			dispatch(setSetProfilePictureLoading(true));
			dispatch(setSetProfilePictureError(null));

			const activeClientId = getActiveClientFromSession();

			if (activeClientId) {
				params.clientId = activeClientId;

				const response = await apiClients.setProfilePicture(params);

				if (typeof response === "object" && response?.reasons) {
					dispatch(setSetProfilePictureError(response.reasons));
					reasons = response.reasons;
					if (response.mappedReasons) {
						mappedReasons = response.mappedReasons;
					}
				} else dispatch(resetProfilePicture());
			}
		} catch (error: any) {
			dispatch(setSetProfilePictureError(error));
		} finally {
			if (callback) callback(reasons, mappedReasons);
			dispatch(setSetProfilePictureLoading(false));
		}
	};
};
