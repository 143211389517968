import { ReactNode, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";

import { paths } from "@app/constants/paths";

import { apiUrl } from "../../config/env";

import { Menu, type MenuPassThroughOptions } from "primereact/menu";
import type { MenuItem } from "primereact/menuitem";

import logo from "@app/assets/images/logo-bright.svg";
import WalletIcon from "@app/assets/images/wallet.svg";
import { Button } from "@app/components/button";

import { FiHome, FiMail, FiPhone, FiUser } from "react-icons/fi";

import { AccountManager } from "@app/hooks/use-account-manager";
import styles from "./index.module.css";
import "./sidebar.css";
import { ProfilePicture } from "../profile-picture";

export const Sidebar = ({
	accountManager,
	content,
	disableLogoLink = false,
}: {
	accountManager?: AccountManager;
	content?: ReactNode;
	disableLogoLink?: boolean;
}) => {
	const navigate = useNavigate();

	const sidebarRoutes = [
		{
			path: paths().dashboard,
			icon: <FiHome size={16} color="#D1D1D1" />,
			displayName: "Home",
		},
		{
			path: paths().transactions(),
			icon: <img alt="" src={WalletIcon} width={16} />,
			displayName: "Transactions",
		},
		{
			path: paths().recipients,
			icon: <FiUser color="#D1D1D1" size={16} />,
			displayName: "Recipients",
			childPaths: [
				paths().addRecipient,
				paths().viewRecipient(),
				paths().editRecipient(),
			],
		},
	];

	const onSendOrReceiveFunds = () => {
		navigate(paths().sendReceiveFunds());
	};

	const hasAccountManager = !!accountManager?.name;

	const menuItems: MenuItem[] = useMemo(() => {
		return sidebarRoutes.map((route) => ({
			url: route.path,
			label: route.displayName || "",
			icon: route.icon,
			command: (event) => {
				if (event.item.url) {
					event.originalEvent.preventDefault();
					event.originalEvent.stopPropagation();
					navigate(event.item.url, {
						preventScrollReset: false,
					});
				}
			},
			className:
				route.path === location.pathname ||
				route.childPaths?.some((childPath) =>
					location.pathname.startsWith(childPath),
				)
					? "p-menuitem-active"
					: undefined,
		}));
	}, [navigate]);

	const accountManagerPhotoUrl =
		accountManager?.profile_photo && apiUrl
			? apiUrl + accountManager.profile_photo.replace(/^\//, "")
			: "";

	return (
		<div className="side-menu-root">
			{disableLogoLink ? (
				<img className={styles.logo} src={logo} alt="Future Forex" />
			) : (
				<Link className={styles.logo} to={paths().dashboard}>
					<img src={logo} alt="Future Forex" />
				</Link>
			)}
			{content ?? (
				<Menu
					pt={
						{
							root: "sidebar-root",
							label: "sidebar-label",
							menuitem: "sidebar-menuitem ",
						} as MenuPassThroughOptions
					}
					model={menuItems}
					id="popup_menu_left"
				/>
			)}

			<div className="margined-section">
				<div>
					{!content && (
						<Button
							onClick={() => {
								onSendOrReceiveFunds();
							}}
							block
						>
							Send/Receive funds
						</Button>
					)}
				</div>

				<div>
					<div
						className="naming-section"
						data-no-account-manager={!hasAccountManager}
					>
						{!hasAccountManager ? null : (
							<ProfilePicture size={40} src={accountManagerPhotoUrl} />
						)}
						<div>
							<p className={styles.name}>
								{hasAccountManager ? accountManager?.name : "Contact us"}
							</p>
							{hasAccountManager && accountManager?.title && (
								<p className={styles.title}>{accountManager?.title}</p>
							)}
						</div>
					</div>
					<div className={styles.contactSection}>
						<a
							className={styles.contactLink}
							href={`mailto:${accountManager?.email ?? "info@futureforex.com"}`}
						>
							<FiMail className={"icon-filled"} size={16} color="#D1D1D1" />
							{accountManager?.email ?? "info@futureforex.com"}
						</a>

						<a
							className={styles.contactLink}
							href={`tel:${accountManager?.contact_number ?? "021 518 0558"}`}
						>
							<FiPhone className={"icon-filled"} size={16} color="#D1D1D1" />
							{accountManager?.contact_number ?? "021 518 0558"}
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};
