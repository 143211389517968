import styles from "./form-builder.module.css";
import {
	CustomPartial,
	DatePickerPartial,
	DropDownOptionPartial,
	DropDownPartial,
	HeadingPartial,
	HiddenFieldPartial,
	InputFieldPartial,
	MultiSelectCurrencyPartial,
	MultiSelectPartial,
	SpacerPartial,
	SubheadingPartial,
} from "./partials";
import { DropDownOptionGroupedPartial } from "./partials/drop-down-option-grouped-partial";
import type { FormInputProps, ViewProperties } from "./types";

export const FormBuilder = (props: ViewProperties) => {
	const buildField = (fieldProps: FormInputProps) => {
		switch (fieldProps.type) {
			case "custom":
				return <CustomPartial {...fieldProps} />;
			case "date-picker":
				return (
					<DatePickerPartial
						{...fieldProps}
						errors={props.errors}
						formControl={props.formControl}
						mappedReasons={props.mappedReasons}
					/>
				);
			case "dropdown":
				return (
					<DropDownPartial
						{...fieldProps}
						errors={props.errors}
						formControl={props.formControl}
						mappedReasons={props.mappedReasons}
					/>
				);
			case "dropdown-option-grouped":
				return (
					<DropDownOptionGroupedPartial
						{...fieldProps}
						errors={props.errors}
						formControl={props.formControl}
						mappedReasons={props.mappedReasons}
					/>
				);
			case "dropdown-option":
				return (
					<DropDownOptionPartial
						{...fieldProps}
						errors={props.errors}
						formControl={props.formControl}
						mappedReasons={props.mappedReasons}
						optionLabel="label"
					/>
				);
			case "multiselect":
				return (
					<MultiSelectPartial
						{...fieldProps}
						errors={props.errors}
						formControl={props.formControl}
						mappedReasons={props.mappedReasons}
					/>
				);
			case "multiselect-currency":
				return (
					<MultiSelectCurrencyPartial
						{...fieldProps}
						errors={props.errors}
						formControl={props.formControl}
						mappedReasons={props.mappedReasons}
					/>
				);

			case "heading":
				return <HeadingPartial {...fieldProps} />;

			case "spacer":
				return <SpacerPartial />;

			case "subheading":
				return <SubheadingPartial {...fieldProps} />;

			case "hidden":
				return (
					<HiddenFieldPartial
						{...fieldProps}
						errors={props.errors}
						formControl={props.formControl}
						mappedReasons={props.mappedReasons}
					/>
				);

			default:
				return (
					<InputFieldPartial
						{...fieldProps}
						errors={props.errors}
						formControl={props.formControl}
						mappedReasons={props.mappedReasons}
					/>
				);
		}
	};

	return (
		<div className={styles.root}>
			{props.ignoreRows &&
				props.formInputs.map((inputRow, parentIndex) =>
					inputRow.map((inputField, index) => (
						<div className={styles.formField} key={`${parentIndex}-${index}`}>
							{buildField(inputField)}
						</div>
					)),
				)}
			{!props.ignoreRows &&
				props.formInputs.map((inputRow, parentIndex) => (
					<div key={`inputrow-${parentIndex}`} className={styles.formRow}>
						{inputRow.map((inputField, index) => (
							<div className={styles.formField} key={`${parentIndex}-${index}`}>
								{buildField(inputField)}
							</div>
						))}
					</div>
				))}
		</div>
	);
};
