import type { MultiSelectChangeEvent } from "primereact/multiselect";
import { useEffect, useState } from "react";

import { useMediaQuery } from "@app/hooks/use-media-query";
import type { MultiSelectModelState } from "./models/multi-select-state";
import { MultiSelectView } from "./multi-select-view";
import { MultiSelectViewResponsive } from "./multi-select-view-responsive";
import type { Properties, ViewProperties } from "./properties";

export const MultiSelect = <T,>(props: Properties<T>) => {
	const isMobile = useMediaQuery();
	const [state, setState] = useState<MultiSelectModelState<T>>({
		selected: null as unknown as T[],
		errors: [],
	});
	const [logicState, setLogicState] = useState({
		overflowing: false,
		showingPanel: false,
	});
	const [overflowing, setOverflowing] = useState(false);

	const onChangeSelected = (event: MultiSelectChangeEvent) => {
		const errors = [];

		props.onChange?.(event.value);
		const selectedArray = event.value as T[];
		if (props.required && selectedArray.length === 0) {
			errors.push("minlength");
		}

		setState({ ...state, errors, selected: event.value });
	};

	const onHidePanel = () => {
		setLogicState({ ...logicState, showingPanel: false });
		props.onHide?.();
	};

	const onShowPanel = () => {
		setLogicState({ ...logicState, showingPanel: true });
		props.onShow?.();
	};

	const onOverflow = (overflow: boolean) => {
		setOverflowing(overflow);
		props.onOverflow?.(overflow);
	};

	const itemTemplate = (option: unknown) => {
		return props.itemTemplate?.(option as T);
	};

	const selectedItemTemplate = (option: unknown) => {
		return props.selectedItemTemplate?.(option as T);
	};

	useEffect(() => {
		if (props.value !== state.selected) {
			if (props.value === undefined) {
				setState({ ...state, selected: [] });
			} else {
				const valuesAsArray = Array.isArray(props.value)
					? props.value
					: [props.value];

				setState({ ...state, selected: valuesAsArray });
			}
		}
	}, [props.value]);

	const viewProps: ViewProperties<T> = {
		...props,
		overflowing,
		showingPanel: logicState.showingPanel,
		onHidePanel,
		onShowPanel,
		onOverflow,
		error: props.error,
		value: state.selected,
		onChangeSelected,
	};

	if (isMobile && !props.ignoreMobile) {
		return (
			<MultiSelectViewResponsive
				{...viewProps}
				onChange={undefined}
				itemTemplate={props.itemTemplate ? itemTemplate : undefined}
				selectedItemTemplate={
					props.selectedItemTemplate ? selectedItemTemplate : undefined
				}
				hideOutlineContent={props.hideOutlineContent}
			/>
		);
	}

	return (
		<MultiSelectView
			{...viewProps}
			onChange={undefined}
			itemTemplate={props.itemTemplate ? itemTemplate : undefined}
			selectedItemTemplate={
				props.selectedItemTemplate ? selectedItemTemplate : undefined
			}
		/>
	);
};
