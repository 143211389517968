import { useMemo } from "react";
import useSWR from "swr";

export type Currencies = {
	currencies: Array<string>;
	dealing_room_only: Array<string>;
	currency_mapping: Array<{
		currency_code: string;
		country_code: string;
		verbose_name: string;
	}>;
};

export type MappedCurrency = {
	currencyCode: string;
	countryCode: string;
	verboseName: string;
};

export const tempCurrenciesMapping = (
	data: Currencies["currency_mapping"][number],
) => {
	return {
		currencyCode: data.currency_code,
		countryCode: data.country_code,
		verboseName: data.verbose_name,
	};
};

export const useCurrencies = () => {
	const { data, error, isLoading } = useSWR<Currencies>(
		"/utilities/currencies/",
	);

	const orderedMappedCurrencies = useMemo(() => {
		if (!data) return [];
		return [...data.currencies, ...data.dealing_room_only].map(
			(current) =>
				data.currency_mapping.find((item) => item.currency_code === current)!,
		);
	}, [data]);

	return {
		data,
		error,
		isLoading,
		orderedMappedCurrencies,
	};
};
