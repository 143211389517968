import { FiEye, FiEyeOff } from "react-icons/fi";

export const PasswordToggleButton = ({
	value,
	onChange,
}: {
	value: boolean;
	onChange: (value: boolean) => void;
}) => {
	return (
		<button
			aria-label="Toggle show password"
			type="button"
			tabIndex={-1}
			onClick={() => onChange(!value)}
		>
			{value ? <FiEyeOff size={20} /> : <FiEye size={20} />}
		</button>
	);
};
