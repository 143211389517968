import notificationsActiveIcon from "@app/assets/images/notifications-active.svg";
import notificationsInactiveIcon from "@app/assets/images/notifications-inactive.svg";
import { useNotifications } from "../notifications/use-notifications";

export const NotificationIndicator = () => {
	const { hasUnreadNotifications } = useNotifications();
	return (
		<>
			{hasUnreadNotifications ? (
				<img src={notificationsActiveIcon} alt="" />
			) : (
				<img src={notificationsInactiveIcon} alt="" />
			)}
		</>
	);
};
