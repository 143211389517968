import useSWR from "swr";
import { useClients } from "./use-clients";

export type ValueDates = {
	fec_enabled: boolean;
	notes: Array<string>;
	tooltips: {
		skipped_days: string | null;
		value_dates_tooltip: string | null;
	};
	value_dates: Array<{
		date: string;
		description: string;
		is_enabled: boolean;
		iso_date: string;
		tooltips: {
			prefunding_required: string | null;
			unavailable: string | null;
		};
	}>;
};

export const useValueDates = (currency?: string) => {
	const { activeClientId } = useClients();
	return useSWR<ValueDates>(
		activeClientId && currency
			? `/clients/${activeClientId}/value_dates/?currency=${currency}`
			: null,
	);
};
