import { SuggestedPaymentBop } from "@app/entities";
import { ElementRef, useEffect, useRef } from "react";
import { FiClock, FiLayers, FiStar } from "react-icons/fi";
import styles from "./suggested-bop-card.module.css";

export const SuggestedBopCard = ({
	value,
	isPopular = false,
	isRecent = false,
	isSelected = false,
	shouldScroll,
	onScrollComplete,
	onChange,
}: {
	shouldScroll: boolean;
	onScrollComplete: () => void;
	value: SuggestedPaymentBop;
	isPopular?: boolean;
	isRecent?: boolean;
	isSelected?: boolean;
	onChange: (value: SuggestedPaymentBop) => void;
}) => {
	const buttonRef = useRef<ElementRef<"button">>(null);
	const showTag = isPopular || isRecent;

	useEffect(() => {
		if (isSelected && shouldScroll) {
			buttonRef.current?.scrollIntoView({
				behavior: "smooth",
				block: "nearest",
				inline: "start",
			});
			onScrollComplete();
		}
	}, [isSelected, shouldScroll, onScrollComplete]);

	return (
		<button
			ref={buttonRef}
			type="button"
			onClick={() => onChange(value)}
			data-selected={isSelected}
			className={styles.container}
		>
			<FiLayers color="#888" size={24} />
			<p className={styles.code}>{value.bopCode}</p>
			<p className={styles.description}>{value.category}</p>
			{showTag && (
				<div
					className={styles.tag}
					data-type={isPopular ? "popular" : "recent"}
				>
					{isPopular ? (
						<FiStar color="#262626" size={14} />
					) : (
						<FiClock color="#262626" size={14} />
					)}

					{isPopular ? "Popular" : "Recent"}
				</div>
			)}
		</button>
	);
};
