import { api } from "@app/services";
import { getFormErrors } from "@app/utils/get-form-errors";
import useSWR from "swr";

export type AdditionalInformation = {
	registration_referrer_id: number;
	referrer_name?: string;
};

export const useAdditionalInformation = (clientId?: number) => {
	const { data } = useSWR<AdditionalInformation>(
		clientId ? `/onboarding/${clientId}/company-additional-information/` : null,
	);

	return {
		data,
		update: async (newData: Partial<AdditionalInformation>) => {
			try {
				await api.patch(
					`/onboarding/${clientId}/company-additional-information/`,
					newData,
				);
			} catch (error) {
				return getFormErrors(error);
			}
		},
		submit: async (newData: AdditionalInformation) => {
			try {
				await api.put(
					`/onboarding/${clientId}/company-additional-information/`,
					newData,
				);
			} catch (error) {
				return getFormErrors(error);
			}
		},
	};
};
