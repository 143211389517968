import { Button } from "@app/components/button";
import { Dialog } from "@app/components/dialog";
import { useMediaQuery } from "@app/hooks/use-media-query";

export const RemoveProfilePictureModal = ({
	onClose,
	onRemove,
	isOpen = false,
}: {
	onClose: () => void;
	onRemove: () => void;
	isOpen?: boolean;
}) => {
	const isMobile = useMediaQuery();
	return (
		<Dialog
			fullscreen={isMobile}
			isOpen={isOpen}
			onClose={onClose}
			title="Remove profile picture"
			description="Are you sure you want to remove your profile picture?"
			actions={
				<>
					<Button variant="secondary" onClick={onClose}>
						Cancel
					</Button>
					<Button onClick={onRemove}>Remove</Button>
				</>
			}
		/>
	);
};
