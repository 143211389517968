import { Button } from "@app/components/button";
import { type ElementRef, useEffect, useRef } from "react";
import { FiAlertCircle } from "react-icons/fi";

import { Checkbox } from "@app/components/checkbox";
import { paths } from "@app/constants/paths";
import { useTransaction } from "@app/hooks/use-transaction";
import styles from "./index.module.css";

export const DuplicateTransactionBlock = ({
	transactionId,
	checked,
	onChange,
	showError = false,
}: {
	transactionId?: number;
	checked: boolean;
	showError?: boolean;
	onChange: (checked: boolean) => void;
}) => {
	const ref = useRef<ElementRef<"div">>(null);
	const { onGoToTransaction, isLoading } = useTransaction(transactionId);

	useEffect(() => {
		if (ref.current && showError) {
			ref.current.scrollIntoView({ behavior: "smooth" });
		}
	}, [showError]);

	const handleViewTransaction = () => {
		window.sessionStorage.setItem("resume-later-path", paths().transactions());
		onGoToTransaction();
	};

	return (
		<div role="alert" className={styles.container} ref={ref}>
			<div className={styles.header}>
				<FiAlertCircle size={24} color="#F04438" />
				<p>Please note</p>
			</div>
			<p>
				This transaction appears to be a duplicate of one booked in the past 24
				hours:
			</p>
			<div>
				<label className={styles.label}>
					<Checkbox
						className={styles.checkbox}
						error={showError}
						onChange={(value: boolean) => onChange(value)}
						value={checked}
					/>
					<span>
						{" "}
						I confirm that this <strong>is not a duplicate transaction</strong>{" "}
						and would like to continue.
					</span>
				</label>
				{showError && (
					<p className={styles.error}>This is required in order to continue</p>
				)}
			</div>
			<Button
				disabled={isLoading}
				onClick={handleViewTransaction}
				variant="secondary"
			>
				View transaction
			</Button>
		</div>
	);
};
