import CheckCircle from "@app/assets/images/check-circle.svg";
import { Button } from "@app/components/button";

import { Dialog } from "@app/components/dialog";
import "./withdrawal-submitted-modal.css";

import styles from "./index.module.css";

export const WithdrawalSubmittedModal = (props: {
	isOpen: boolean;
	withdrawalType?: string;
	onClose: () => void;
}) => {
	return (
		<Dialog
			size="xl"
			className="withdrawal-submitted-modal"
			isOpen={props.isOpen}
			actions={
				<Button centered onClick={props.onClose}>
					Close
				</Button>
			}
		>
			<div className={styles.container}>
				<img
					alt=""
					className={styles.icon}
					src={CheckCircle}
					width={80}
					height={80}
				/>
				<h2 className={styles.title}>Withdrawal submitted</h2>
				<p>
					This will be processed in the next{" "}
					{props.withdrawalType?.toLowerCase() === "standard" ? "3" : "1-2"}{" "}
					business days.
				</p>
			</div>
		</Dialog>
	);
};
