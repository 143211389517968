import { pathParams } from "@app/constants/paths";
import { useParams } from "react-router-dom";

export const useTransactionId = () => {
	const params = useParams();
	const paramId = params[pathParams.id]
		? Number.parseInt(params[pathParams.id] ?? "")
		: undefined;
	return paramId;
};
