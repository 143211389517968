export type FormErrors = {
	apiErrors: Array<string>;
	fieldErrors: Array<{
		name: string;
		message: string;
	}>;
};

export const getFormErrors = (
	error: any,
	fieldNameMapping?: (current: string, parts: string[]) => string,
): FormErrors => {
	console.error(error);
	if (!error?.data?.detail) {
		if (error?.message) {
			return {
				apiErrors: [error.message],
				fieldErrors: [],
			};
		}
		return {
			apiErrors: ["An unexpected error occurred. Please try again."],
			fieldErrors: [],
		};
	}

	const apiErrors: string[] =
		typeof error.data?.detail === "string" ? [error.data.detail] : [];
	const fieldErrors: FormErrors["fieldErrors"] = [];
	if (Array.isArray(error.data.detail)) {
		for (const current of error.data.detail) {
			const key = current.loc[current.loc.length - 1];
			const name = fieldNameMapping ? fieldNameMapping(key, current.loc) : key;
			fieldErrors.push({
				name,
				message: current.msg,
			});
		}
	}

	return {
		apiErrors,
		fieldErrors,
	};
};
