import { useState } from "react";
import { Controller } from "react-hook-form";

import { ErrorLabel, Typography } from "@app/components";
import { MultiSelectCurrency } from "@app/components/controls";

import { FiAlertCircle } from "react-icons/fi";
import styles from "../form-builder.module.css";
import { showMappedReasons } from "../helpers";
import type { MultiSelectCurrencyPartialProps } from "../types";

export const MultiSelectCurrencyPartial = (
	props: MultiSelectCurrencyPartialProps,
) => {
	return (
		<Controller
			name={props.name}
			control={props.formControl}
			rules={{
				required: props.required ? "This field is required" : false,
				validate: (x) =>
					props.onCustomValidationRule?.(x) || props.customErrorMessage,
			}}
			render={({ field, fieldState: { error } }) => {
				const [focused, setFocused] = useState(false);

				return (
					<div
						className={`${props.fieldRowClassName || ""} ${styles.inputRow}`}
					>
						{props.showLabel ? (
							<div className={styles.multiSelectCurrencyLabel}>
								<Typography
									theme="textSm"
									className={`${styles.formLabel} ${
										focused ? "text-teal-550" : "text-gray-1100"
									}`}
								>
									{props.title + (props.required ? "*" : "")}
								</Typography>
								{props.popover && props.popover}
							</div>
						) : (
							<></>
						)}
						<MultiSelectCurrency
							appendToParent={props.appendToParent}
							disabled={props.disabled}
							actionLabel="Save"
							error={
								!!error ||
								showMappedReasons(
									props.mappedName ?? field.name,
									"#f04438",
									props.mappedReasons,
								) !== false
							}
							options={props.options || undefined}
							placeholder={props.placeholder}
							{...field}
							headingText="Select currencies"
							onChange={(value) => {
								if (props.onChange) props.onChange(value);
								field.onChange(value);
							}}
							optionLabel={props.optionLabel || ""}
							selectedItemsScrollable
							required={props.required}
							onHide={() => setFocused(false)}
							onShow={() => setFocused(true)}
						/>
						{error && (
							<ErrorLabel>
								<FiAlertCircle size={16} />
								<span>{error.message}</span>
							</ErrorLabel>
						)}
						{!error &&
							showMappedReasons(
								props.mappedName ?? field.name,
								"#f04438",
								props.mappedReasons,
							)}
					</div>
				);
			}}
		/>
	);
};
