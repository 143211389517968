import { Button } from "@app/components/button";
import { CountryIcon } from "@app/components/country-icon";
import { NoTransactionsBlock } from "@app/components/no-transactions-block";
import { Fragment, useState } from "react";
import { FiArrowDown, FiArrowUp } from "react-icons/fi";
import type { TransactionTableModel } from "../models/models";
import type { ViewProperties } from "../properties";

import { CountTag } from "@app/components/count-tag";
import { paths } from "@app/constants/paths";
import { useNavigate } from "react-router-dom";
import styles from "./completed-transactions.module.css";
import { SortTransactionsModal } from "./sort-transactions-modal";

export const CompletedTransactions = (props: ViewProperties) => {
	const navigate = useNavigate();
	const [showSortModal, setShowSortModal] = useState(false);
	const { listCompletedTransactions } = props;

	const showDate = (tx: TransactionTableModel, index: number) => {
		if (index === 0) return true;
		if (tx.date === listCompletedTransactions![index - 1].date) return false;
		return true;
	};

	const hasTransactions =
		listCompletedTransactions && listCompletedTransactions.length > 0;

	return (
		<div className="completed-transactions-wrapper">
			<div className={styles.header}>
				<div className={styles.headerInner}>
					<h3 className={styles.title}>Completed</h3>
					<CountTag>{listCompletedTransactions?.length ?? 0}</CountTag>
				</div>

				{hasTransactions && (
					<Button
						size="sm"
						variant="secondary"
						inline
						onClick={() => setShowSortModal(true)}
					>
						Sort
					</Button>
				)}
			</div>

			<div>
				{hasTransactions ? (
					listCompletedTransactions?.map((transaction, index) => {
						return (
							<Fragment key={transaction.id}>
								{showDate(transaction, index) && (
									<p className={styles.date}>{transaction.date}</p>
								)}
								<button
									className={styles.button}
									type="button"
									onClick={() => {
										navigate(paths().viewTransaction(transaction.id));
									}}
									key={index}
								>
									<div
										data-type={transaction.paymentType}
										className={styles.type}
									>
										{transaction.paymentType === "send" ? (
											<FiArrowDown size={24} />
										) : (
											<FiArrowUp size={24} />
										)}
									</div>

									{transaction.paymentType === "send" ? (
										<div>
											<p>Sent to</p>
											<p className={styles.recipient}>
												{transaction.recipientDisplay}
											</p>
										</div>
									) : (
										<p>Received</p>
									)}

									<div className={styles.currencies}>
										<div className={styles.row}>
											<p className={styles.zar}>{transaction.zarAmount}</p>
											<CountryIcon
												width={16}
												height={16}
												currencyCode={transaction.zarAmount.split(" ")[0]}
											/>
										</div>
										<div className={styles.row}>
											<p>{transaction.fxAmount}</p>
											<CountryIcon
												width={16}
												height={16}
												currencyCode={transaction.fxAmount.split(" ")[0]}
											/>
										</div>
									</div>
								</button>
							</Fragment>
						);
					})
				) : (
					<div className={styles.empty}>
						<NoTransactionsBlock />
					</div>
				)}
			</div>

			{showSortModal && (
				<SortTransactionsModal
					onClose={() => setShowSortModal(false)}
					sortField={props.state.sortField}
					sortOrder={props.state.sortOrder}
					onSort={props.onSortByName}
				/>
			)}
		</div>
	);
};
