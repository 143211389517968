import { Button } from "@app/components/button";
import { ElementRef, useEffect, useRef } from "react";
import { FiCheck } from "react-icons/fi";

import styles from "./index.module.css";

export const SelectedCell = ({
	isSelected,
	id,
	shouldScrollToChange = true,
	onChangeSelected,
}: {
	isSelected: boolean;
	id: number;
	shouldScrollToChange?: boolean;
	onChangeSelected: (id: number | undefined) => void;
}) => {
	const containerRef = useRef<ElementRef<"div">>(null);

	useEffect(() => {
		if (isSelected && shouldScrollToChange && containerRef.current) {
			const timeout = setTimeout(() => {
				containerRef.current?.scrollIntoView({
					behavior: "smooth",
					block: "center",
					inline: "center",
				});
			}, 200);
			return () => {
				clearTimeout(timeout);
			};
		}
	}, [isSelected, shouldScrollToChange]);

	return (
		<div className={styles.container}>
			<div className={styles.inner} ref={containerRef}>
				{isSelected ? (
					<Button size="sm" onClick={() => onChangeSelected(undefined)}>
						Selected
						<FiCheck color="#fff" size={20} />
					</Button>
				) : (
					<Button
						variant="secondary"
						size="sm"
						onClick={() => onChangeSelected(id)}
					>
						Select
					</Button>
				)}
			</div>
		</div>
	);
};
