import { RelatedEntityVariant } from "./use-related-entities";

export const getDataForEntityVariant = (variant: RelatedEntityVariant) => {
	if (variant === "director") {
		return {
			name: "Director",
			linkedEntityLabel: "Is the director also a Signatory / Shareholder?",
			placeholder: "Select a signatory / shareholder previously added",
			linkedDropdownLabel: "Signatory / Shareholder*",
		};
	}

	if (variant === "authorised_signatory") {
		return {
			name: "Signatory",
			linkedEntityLabel: "Is the signatory also a Director / Shareholder?",
			placeholder: "Select a director / shareholder previously added",
			linkedDropdownLabel: "Director / Shareholder*",
		};
	}

	if (variant === "shareholder") {
		return {
			name: "Shareholder",
			linkedEntityLabel: "Is the shareholder also a Director / Signatory?",
			placeholder: "Select a director / signatory previously added",
			linkedDropdownLabel: "Director / Signatory*",
		};
	}

	return {};
};
