export const downloadFile = (
	contentType: string,
	data: string,
	fileName: string,
) => {
	const element = document.createElement("a");
	element.setAttribute(
		"href",
		data.indexOf(",") > -1 ? `${data}` : `data:${contentType};base64,${data}`,
	);
	element.setAttribute("download", fileName);
	document.body.appendChild(element);
	element.click();
	document.body.removeChild(element);
};
