import ViewTransaction from "@app/screens/view-transaction";
import { ReactNode, useState } from "react";

import { TransactionDetailsFooter } from "../transaction-details-footer";

import { tempPaymentStatusMapping } from "@app/hooks/use-payment-status";
import { TransactionLayout, TransactionStep } from "../transaction-layout";
import styles from "./index.module.css";
import { TransactionSubmittedModal } from "./transaction-submitted-modal";

export const ReviewTransactionView = (props: {
	paymentStatus?: ReturnType<typeof tempPaymentStatusMapping> | null;
	showCompleteModal: boolean;
	setShowCompleteModal: (value: boolean) => void;
	onNavigateTransaction: (navigationPath: string) => void;
	onBack: () => void;
	onNext: () => void;
	apiErrors: Array<ReactNode>;
	onComplete: () => void;
	isSubmitting: boolean;
	onEditPayment: () => void;
	onEditDocument: () => void;
	onEditBop: () => void;
}) => {
	const [showResumeLater, setShowResumeLater] = useState(false);
	return (
		<TransactionLayout
			step={TransactionStep.ReviewTransaction}
			onStepNavigate={props.onNavigateTransaction}
			onResumeLater={() => setShowResumeLater(true)}
			footer={
				<TransactionDetailsFooter
					showResumeLater={showResumeLater}
					onResumeLaterChange={setShowResumeLater}
					onBack={props.onBack}
					onNext={props.onNext}
					customNextLabel="Submit"
					isLoading={props.isSubmitting}
				/>
			}
		>
			<h2 className={styles.title}>Review transaction</h2>
			<ViewTransaction enableEditButtons {...props} />
			<TransactionSubmittedModal
				isOpen={props.showCompleteModal}
				onClose={props.onComplete}
			/>
		</TransactionLayout>
	);
};
