import React, { type ReactNode } from "react";

import { joinClasses } from "@app/utils";

import styles from "./index.module.css";

export const Title = ({
	children,
	className,
	variant = "default",
}: {
	children: ReactNode;
	variant?: "default" | "inline" | "small";
	className?: string;
}) => {
	return (
		<>
			<h1 data-variant={variant} className={styles.title}>
				{children}
			</h1>
			<div
				data-variant={variant}
				className={joinClasses(styles.container, className)}
			>
				<div className={styles.line} />
			</div>
		</>
	);
};
