import { SendReceiveIcon } from "@app/components/send-receive-icon";
import { joinClasses } from "@app/utils";

export enum SendReceiveButtonGroupValue {
	SEND = "send",
	RECEIVE = "receive",
}

interface SendReceiveButtonGroupProps {
	value?: string;
	onChange: (value: string) => void;
	className?: string;
}

export const SendReceiveButtonGroup = ({
	value,
	onChange,
	className,
}: SendReceiveButtonGroupProps) => {
	const buttonStyles =
		"flex items-center justify-center text-gray-900 text-xl flex-1 h-20 max-w-50 px-6 border border-gray-110 rounded-lg shadow-generic gap-4 transition-all disabled:cursor-not-allowed hover:border-teal-550 focus:border-teal-550 hover:bg-gray-75 focus:bg-gray-75 mobile:text-base mobile:max-w-full mobile:h-14";
	const activeStyles = "border-teal-550 bg-gray-75";
	return (
		<div
			className={joinClasses(
				"flex w-full items-center gap-2 mobile:justify-center",
				className,
			)}
		>
			<button
				type="button"
				className={joinClasses(
					buttonStyles,
					value === SendReceiveButtonGroupValue.SEND && activeStyles,
				)}
				onClick={() => onChange(SendReceiveButtonGroupValue.SEND)}
			>
				<SendReceiveIcon type={SendReceiveButtonGroupValue.SEND} />
				Send
			</button>
			<button
				type="button"
				className={joinClasses(
					buttonStyles,
					value === SendReceiveButtonGroupValue.RECEIVE && activeStyles,
				)}
				onClick={() => onChange(SendReceiveButtonGroupValue.RECEIVE)}
			>
				<SendReceiveIcon type={SendReceiveButtonGroupValue.RECEIVE} />
				Receive
			</button>
		</div>
	);
};
