import { CountryOption } from "@app/components/country-option";
import { Dropdown } from "@app/components/dropdown";
import { Label } from "@app/components/label";
import { useMediaQuery } from "@app/hooks/use-media-query";
import { parsePhoneNumber } from "libphonenumber-js";
import { useMemo, useState } from "react";

import checkedSrc from "./checked.svg";
import chevronDownSrc from "./chevron-down.svg";

import { FieldError as RHFFieldError, useFormContext } from "react-hook-form";

import { Button } from "@app/components";
import { COUNTRY_CODE_OPTIONS } from "@app/components/country-option/country-code-options";
import { Dialog } from "@app/components/dialog";
import { FieldError } from "@app/components/field-error";
import { Input } from "@app/components/input";
import { Search } from "@app/components/search";
import { CircleFlag } from "react-circle-flags";
import styles from "./index.module.css";

export const ContactNumberField = ({
	error,
	placeholder,
	onBlur,
	disabled,
}: {
	error?: RHFFieldError;
	placeholder?: string;
	onBlur?: () => void;
	disabled?: boolean;
}) => {
	const [searchText, setSearchText] = useState<string>("");
	const [showDialog, setShowDialog] = useState(false);
	const isMobile = useMediaQuery();
	const { watch, setValue } = useFormContext();

	const value = watch("contact_number");

	const defaultPlaceholder = isMobile
		? "Enter your number"
		: "Enter your contact number";

	const { code, number } = useMemo(() => {
		if (!value) return { code: COUNTRY_CODE_OPTIONS[0], number: "" };
		try {
			const parsedNumber = parsePhoneNumber(value);
			const code = parsedNumber?.countryCallingCode
				? `+${parsedNumber?.countryCallingCode}`
				: COUNTRY_CODE_OPTIONS[0].code;

			return {
				code:
					COUNTRY_CODE_OPTIONS.find((current) => current.code === code) ??
					COUNTRY_CODE_OPTIONS[0],
				number: parsedNumber?.nationalNumber ?? "",
			};
		} catch {
			const matchingCode = COUNTRY_CODE_OPTIONS.find((current) =>
				value.startsWith(current.code),
			);

			return {
				code: matchingCode ?? COUNTRY_CODE_OPTIONS[0],
				number: value.replace(matchingCode?.code ?? "", ""),
			};
		}
	}, [value]);

	return (
		<>
			<div>
				<Label htmlFor="contact_number">Contact number*</Label>
				<div className={styles.telRow}>
					{isMobile ? (
						<button
							disabled={disabled}
							data-error={!!error}
							className={styles.trigger}
							type="button"
							onClick={() => setShowDialog(true)}
						>
							{code.countryCode && (
								<CircleFlag
									cdnUrl="/flags/"
									width={20}
									height={20}
									countryCode={code.countryCode}
								/>
							)}
							{code.code ?? ""}
							<img src={chevronDownSrc} alt="" width={20} height={20} />
						</button>
					) : (
						<Dropdown
							disabled={disabled}
							invalid={!!error}
							value={code}
							onChange={(event) => {
								setValue("contact_number", `${event.value.code}${number}`);
								onBlur?.();
							}}
							panelClassName={styles.dropdownPanel}
							className={styles.dropdown}
							aria-label="country code"
							optionLabel="name"
							options={COUNTRY_CODE_OPTIONS}
							valueTemplate={(option: CountryOption) => (
								<CountryOption variant="value" {...option} />
							)}
							itemTemplate={(option: CountryOption) => (
								<CountryOption {...option} value={code.code} />
							)}
						/>
					)}
					<Input
						disabled={disabled}
						className={styles.telInput}
						id="contact_number"
						type="tel"
						aria-invalid={!!error}
						autoComplete="tel"
						placeholder={placeholder ?? defaultPlaceholder}
						name="contact_number"
						onChange={(event) => {
							if (event.target.value.length >= 12) return;
							const value = event.target.value.replace(/\D/g, "");
							setValue("contact_number", `${code.code}${value}`);
						}}
						onBlur={onBlur}
						value={number}
					/>
				</div>
				{error && <FieldError>{error.message}</FieldError>}
			</div>

			<Dialog
				fullscreen
				wrapperClassName={styles.wrapper}
				isOpen={showDialog}
				onClose={() => {
					setShowDialog(false);
				}}
				actions={
					<Button
						block
						onClick={() => {
							setShowDialog(false);
						}}
					>
						Close
					</Button>
				}
				title="Country code"
			>
				<Search
					placeholder="Search"
					value={searchText}
					onChange={(event) => setSearchText(event.target.value)}
					onClear={() => setSearchText("")}
				/>

				<ul className={styles.list}>
					{COUNTRY_CODE_OPTIONS.filter((option) => {
						if (!searchText) return true;
						return option.name.toLowerCase().includes(searchText.toLowerCase());
					}).map((option) => {
						const isSelected = option.code === code.code;
						return (
							<li key={option.countryCode}>
								<button
									aria-current={isSelected}
									type="button"
									data-selected={isSelected}
									className={styles.optionButton}
									onClick={() => {
										setValue("contact_number", `${option.code}${number}`);
										onBlur?.();
									}}
								>
									<CircleFlag
										cdnUrl="/flags/"
										width={20}
										height={20}
										countryCode={option.countryCode}
									/>
									<span className={styles.optionCode}>{option.code}</span>
									<span className={styles.optionName}>{option.name}</span>

									{isSelected ? (
										<img src={checkedSrc} alt="" width={16} height={16} />
									) : (
										<div className={styles.unchecked} />
									)}
								</button>
							</li>
						);
					})}
				</ul>
			</Dialog>
		</>
	);
};
