import { useSelector } from "react-redux";

import type { RootState } from "@app/redux";

import { ManageAccountsModalView } from "./manage-accounts-modal-view";
import type { Properties, ViewProperties } from "./properties";

export const ManageAccountsModal = (props: Properties) => {
	const { withdrawalBankAccounts, withdrawalBankAccountLoading } = useSelector(
		(rootState: RootState) => rootState.withdrawals,
	);

	const viewProps: ViewProperties = {
		...props,
		loading: withdrawalBankAccountLoading ?? false,
		withdrawalBankAccounts,
	};
	return <ManageAccountsModalView {...viewProps} />;
};
