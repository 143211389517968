export type FormatNumberOptions = {
	currencyCode?: string;
	decimalPlaces?: number;
	formatUnit?: boolean;
	groupSeparator?: string;
	isPercentage?: boolean;
	padSeparator?: string;
	padStart?: number;
	unitSuffixes?: string[];
};

export const formatNumber = (
	value: string | number,
	options?: FormatNumberOptions,
) => {
	const safeOptions = {
		currencyCode: options?.currencyCode || "ZAR",
		decimalPlaces: options?.decimalPlaces || 2,
		formatUnit: options?.formatUnit || false,
		groupSeparator: options?.groupSeparator || ",",
		isPercentage: options?.isPercentage || false,
		padSeparator: options?.padSeparator || "0",
		padStart: options?.padStart || 0,
		unitSuffixes: options?.unitSuffixes || ["", "k", "m", "b", "t"],
	};

	let valueAsNumber =
		typeof value === "string"
			? Number.parseFloat(
					value
						.replace(/[^0-9.-]/g, "")
						.replace(/^(-)|-+/g, "$1")
						.replace(/^([^.]*\.)|\.+/g, "$1"),
				)
			: value;

	let unitSuffix = "";
	if (safeOptions.formatUnit) {
		const unit = Math.min(
			Math.trunc(Math.log10(Math.abs(valueAsNumber)) / 3),
			safeOptions.unitSuffixes.length - 1,
		);

		unitSuffix = safeOptions.unitSuffixes[unit];

		valueAsNumber *= 1 / Math.pow(10, unit * 3);
	}

	if (Number.isNaN(valueAsNumber)) {
		return "";
	}

	const absValue = Math.abs(valueAsNumber);
	const absValueDecimal = absValue.toFixed(safeOptions.decimalPlaces);

	let preOutput = absValueDecimal.replace(
		/(\d)(?=(\d{3})+\b)/g,
		`$1${safeOptions.groupSeparator}`,
	);

	if (safeOptions.padStart) {
		const padLength =
			safeOptions.decimalPlaces > 0
				? safeOptions.decimalPlaces + 1 + safeOptions.padStart
				: safeOptions.padStart;

		preOutput = preOutput.padStart(padLength, safeOptions.padSeparator);
	}

	return `${valueAsNumber < 0 ? "-" : ""}${safeOptions.currencyCode} ${preOutput}${unitSuffix}${safeOptions.isPercentage ? "%" : ""}`;
};
