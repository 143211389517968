import { type ReactNode, useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";

import { Button, Paginator, Tag, TagGroup, Typography } from "@app/components";
import {
	DatePicker,
	MultiSelect,
	MultiSelectCurrency,
	SelectButton,
} from "@app/components/controls";
import { CustomLoader } from "@app/components/custom-loader";
import { NoTransactionsBlock } from "@app/components/no-transactions-block";
import { SignedInLayout } from "@app/components/signed-in-layout";
import { useMediaQuery } from "@app/hooks/use-media-query";
import type { Control } from "react-hook-form";
import {
	LiaGreaterThanEqualSolid,
	LiaLessThanEqualSolid,
} from "react-icons/lia";

import type {
	CurrencyOption,
	FilterRecipientsByForm,
	ListTransactionInProgress,
	TransactionDirection,
} from "@app/entities";
import type { TransactionsQueryParams } from "@app/services";

import { MappedCurrency } from "@app/hooks/use-currencies";
import { RecipientNames } from "@app/hooks/use-recipient-names";
import type {
	TransactionTableModel,
	TransactionsFilterState,
} from "./models/models";

import { ApiErrors } from "@app/components/api-errors";
import { CountTag } from "@app/components/count-tag";
import { CountryIcon } from "@app/components/country-icon";
import { InProgressTransactionCarousel } from "@app/components/in-progress-transaction-carousel";
import { Notifications } from "@app/components/notifications";
import { Popover } from "@app/components/popover";
import { Search } from "@app/components/search";
import { dateFormats } from "@app/constants/date-formats";
import {
	FiArrowDown,
	FiArrowUp,
	FiChevronDown,
	FiChevronUp,
} from "react-icons/fi";
import { CompletedTransactions } from "./components-responsive/completed-transactions";
import { FilterSection } from "./components-responsive/filter-section";
import styles from "./index.module.css";
import "./transactions.css";
import { SortableTable } from "@app/components/sortable-table";
import { toDayjs } from "@app/lib/date";

const TypeOption = ({
	option,
	variant = "filter",
}: {
	option: TransactionDirection;
	variant?: "filter" | "tag";
}) => {
	const iconSize = variant === "filter" ? 24 : 18;
	return (
		<div className={styles.typeOption} data-variant={variant}>
			{option === "send" && (
				<div className={styles.typeIcon} data-type={option}>
					<FiArrowDown size={iconSize} color="262626" />
				</div>
			)}
			{option === "receive" && (
				<div className={styles.typeIcon} data-type={option}>
					<FiArrowUp size={iconSize} color="262626" />
				</div>
			)}
			{option === "both" ? "Both" : option === "send" ? "Send" : "Receive"}
		</div>
	);
};

export const TransactionsView = (props: {
	control: Control<FilterRecipientsByForm, any>;
	currencies?: CurrencyOption[];
	currenciesOverflowing: boolean;
	currentPage?: number;
	errors?: string[];
	filterGroupSize: number;
	filterState: TransactionsFilterState;
	listCompletedTransactions?: TransactionTableModel[];
	listInProgressTransactions?: ListTransactionInProgress[];
	inProgressTotal?: number;
	loading?: boolean;
	loadingTableData?: boolean;
	recipientsOverflowing: boolean;
	showFilters: boolean;
	state: TransactionsQueryParams;
	total?: number;
	totalFilters: number;
	onApplyFilters: () => void;
	onChangeCurrencyFilters: (value: MappedCurrency[]) => void;
	onChangeDirectionFilter: (value?: TransactionDirection[]) => void;
	onChangeEndDateFilter: (value: Date) => void;
	onChangeRecipientFilters: (value: RecipientNames[]) => void;
	onChangeStartDateFilter: (value: Date) => void;
	onClearFilters: () => void;
	onCurrenciesOverflow: (overflow: boolean) => void;
	onFilterByName: (value: string) => void;
	onPageChange: (page: number, rowsPerPage: number) => void;
	onRecipientsOverflow: (overflow: boolean) => void;
	onRemoveCurrencyFilter: (value: MappedCurrency) => void;
	onRemoveCurrencyFilterTag: (value: MappedCurrency) => void;
	onRemoveCurrencyFiltersTag: () => void;
	onRemoveDateFiltersTag: () => void;
	onRemoveDirectionFilterTag: (value: TransactionDirection) => void;
	onRemoveDirectionFiltersTag: (value?: TransactionDirection[]) => void;
	onRemoveRecipientFilter: (value: RecipientNames) => void;
	onRemoveRecipientFilterTag: (value: RecipientNames) => void;
	onRemoveRecipientFiltersTag: () => void;
	onSortByName: (sortOrder: number, columnKey: string) => void;
	onToggleShowFilters: () => void;
}) => {
	const isMobile = useMediaQuery();
	const [searchText, setSearchText] = useState(props.state.search ?? "");

	const getDateFiltersContent = () => {
		const filterStartDate = props.filterState.appliedStartDate !== undefined;
		const filterEndDate = props.filterState.appliedEndDate !== undefined;

		if (!filterStartDate && !filterEndDate) {
			return <></>;
		}

		const startDateFormatted = filterStartDate
			? toDayjs(props.filterState.appliedStartDate, dateFormats.iso8601).format(
					dateFormats.paddedDayShortMonthYear,
				)
			: "";
		const endDateFormatted = filterEndDate
			? toDayjs(props.filterState.appliedEndDate, dateFormats.iso8601).format(
					dateFormats.paddedDayShortMonthYear,
				)
			: "";

		let dateString = "";
		let equalityIconName: ReactNode | undefined;

		if (filterStartDate && filterEndDate) {
			dateString = `${startDateFormatted} to ${endDateFormatted}`;
		} else if (filterStartDate) {
			dateString = startDateFormatted;
			equalityIconName = <LiaGreaterThanEqualSolid color="#383838" size={20} />;
		} else {
			dateString = endDateFormatted;
			equalityIconName = <LiaLessThanEqualSolid color="#383838" size={20} />;
		}

		return (
			<TagGroup
				className="gap-x-2"
				data={[dateString]}
				groupAtCount={props.filterGroupSize}
				groupName={"Dates"}
				groupTagContainerClassName={"px-0 gap-x-2"}
				tagTemplate={(option) => (
					<>
						{equalityIconName}
						<Typography theme="textMd">{option}</Typography>
					</>
				)}
				tagContainerClassName={() => "px-0"}
				tagStyle="filter"
				onRemove={() => props.onRemoveDateFiltersTag()}
				onRemoveGroup={() => props.onRemoveDateFiltersTag()}
			/>
		);
	};

	useEffect(() => {
		if (props.state.search !== undefined) {
			setSearchText(props.state.search);
		}
	}, [props.state.search]);

	return (
		<SignedInLayout
			title="Transactions"
			footer={
				!isMobile && (
					<div className={styles.footer}>
						<Paginator
							onNavigatePage={props.onPageChange}
							totalRecords={props.total || 0}
							currentPage={props.currentPage}
						/>
					</div>
				)
			}
			mobileRightSection={<Notifications />}
		>
			<div className="transactions-section min-h-[calc(100vh-82px-8rem)]">
				<>
					<div className={"header-row"}>
						<div className="title-section">
							<Typography
								theme="displayMd"
								className="transactions-heading-style"
							>
								Transactions
							</Typography>
						</div>

						{isMobile ? (
							<FilterSection {...props} />
						) : (
							<>
								<div className="filter-section mb-6">
									<div className="search-input">
										<Search
											placeholder="Search by recipient and currency"
											value={searchText}
											onChange={(event) => {
												const { value } = event.target;
												setSearchText(value);
											}}
											onKeyDown={(event) => {
												if (event.key === "Enter") {
													props.onFilterByName(searchText);
												}
											}}
										/>
									</div>

									<div className="filter-button">
										<Button
											style={{ marginLeft: "0.5rem" }}
											variant="secondary"
											onClick={props.onToggleShowFilters}
										>
											{props.showFilters ? "Hide filters" : "Show filters"}
											{props.showFilters ? (
												<FiChevronUp size={16} color="inherit" />
											) : (
												<FiChevronDown size={16} color="inherit" />
											)}
										</Button>
									</div>
								</div>
							</>
						)}
					</div>
				</>

				{!isMobile && (
					<>
						{!props.showFilters && props.totalFilters > 0 && (
							<div className="mb-6 flex flex-row justify-end gap-x-2">
								{(props.filterState.appliedRecipientNamesSelected?.length ||
									0) > 0 && (
									<Popover
										toggleContent={
											<TagGroup
												className="gap-x-2"
												data={props.filterState.appliedRecipientNamesSelected}
												groupAtCount={props.filterGroupSize}
												groupName={"Recipients"}
												groupTagContainerClassName={"px-0 gap-x-2"}
												tagTemplate={(option) => (
													<Typography theme="textMd">{option.name}</Typography>
												)}
												tagContainerClassName={() => "px-0"}
												tagStyle="filter"
												onRemove={props.onRemoveRecipientFilterTag}
												onRemoveGroup={() =>
													props.onRemoveRecipientFiltersTag()
												}
											/>
										}
										popperContent={
											(props.filterState.currentRecipientNamesSelected
												?.length || 0) >= props.filterGroupSize ? (
												<div className="flex w-max flex-col items-start rounded-2xl bg-gray-880 px-3.5 py-2.5 text-white">
													{props.filterState.currentRecipientNamesSelected?.map(
														(x) => (
															<Typography key={x.name} theme="textSm">
																{x.name}
															</Typography>
														),
													)}
												</div>
											) : undefined
										}
										showOnHover
										placement={"bottom"}
										offset={[0, 7]}
									/>
								)}
								{(props.filterState.appliedCurrenciesSelected?.length || 0) >
									0 && (
									<Popover
										toggleContent={
											<TagGroup
												className="gap-x-2"
												data={props.filterState.appliedCurrenciesSelected}
												groupAtCount={3}
												groupName={"Currencies"}
												groupTagContainerClassName="px-0"
												tagContainerClassName={() => "px-0"}
												tagStyle="filter"
												tagTemplate={(option) => (
													<span
														className={twMerge("flex", "flex-row", "gap-2")}
													>
														<CountryIcon
															width={20}
															height={20}
															currencyCode={option.currencyCode}
														/>
														<Typography theme="textMd">
															{option.currencyCode}
														</Typography>
													</span>
												)}
												onRemove={props.onRemoveCurrencyFilterTag}
												onRemoveGroup={() => props.onRemoveCurrencyFiltersTag()}
											/>
										}
										popperContent={
											(props.filterState.currentCurrenciesSelected?.length ||
												0) >= props.filterGroupSize ? (
												<div className="flex w-max flex-col items-start rounded-2xl bg-gray-880 px-3.5 py-2.5 text-white">
													{props.filterState.currentCurrenciesSelected?.map(
														(x) => (
															<span
																key={x.currencyCode}
																className={twMerge("flex", "flex-row", "gap-2")}
															>
																<CountryIcon
																	width={16}
																	height={16}
																	currencyCode={x.currencyCode}
																/>
																<Typography theme="textMd">
																	{x.currencyCode}
																</Typography>
															</span>
														),
													)}
												</div>
											) : undefined
										}
										showOnHover
										placement={"bottom"}
										offset={[0, 7]}
									/>
								)}
								{(props.filterState.appliedDirectionsSelected?.length || 0) >
									0 && (
									<TagGroup
										className="gap-x-2"
										data={props.filterState.appliedDirectionsSelected}
										tagContainerClassName={() => "px-0"}
										tagStyle="filter"
										groupAtCount={3}
										groupName="Directions"
										groupTagContainerClassName="px-0"
										tagTemplate={(option) => (
											<TypeOption variant="tag" option={option} />
										)}
										onRemove={props.onRemoveDirectionFilterTag}
										onRemoveGroup={props.onRemoveDirectionFiltersTag}
									/>
								)}
								{getDateFiltersContent()}
							</div>
						)}
					</>
				)}
				<>
					{props.showFilters && (
						<div className="mb-6 rounded-4px border border-solid border-gray-110 bg-gray-75 p-6">
							<div className="flex flex-row justify-between">
								<div>
									<Typography theme="textLg" className="font-semibold">
										Filter by
									</Typography>
								</div>
								<div>
									<Typography theme="textMd" className="font-semibold">
										{props.totalFilters}{" "}
										{props.totalFilters === 1
											? "filter applied"
											: "filters applied"}
									</Typography>
								</div>
							</div>
							<div className="mt-4">
								<div className="mb-6 flex flex-row justify-between gap-6">
									<div className="w-full min-w-0 max-w-50%">
										<div className="flex flex-row justify-between">
											<Typography
												theme="textSm"
												className="mb-1.5 font-semibold text-gray-1100"
											>
												Recipient
											</Typography>
											{props.recipientsOverflowing && (
												<Typography
													theme="textXs"
													className="mb-1.5 font-semibold text-gray-650"
												>
													{
														props.filterState.currentRecipientNamesSelected
															?.length
													}{" "}
													Selected
												</Typography>
											)}
										</div>
										<MultiSelect
											className="w-full rounded-4px py-0"
											display="chip"
											filter
											labelClassName="flex items-center py-0"
											optionLabel="name"
											options={props.filterState.recipientNameOptions || []}
											placeholder="Select a recipient"
											selectedItemsScrollable
											selectedItemTemplate={(option) =>
												option ? (
													<span className="inline-flex h-full items-center gap-2 align-middle">
														<Tag
															containerClassName="px-0 pr-2 min-w-max"
															tagStyle="filter"
															showRemoveButton
															removeButtonPlacement="right"
															onRemove={(event) => {
																event.stopPropagation();
																props.onRemoveRecipientFilter(option);
															}}
														>
															<Typography theme="textMd">
																{option?.name || ""}
															</Typography>
														</Tag>
													</span>
												) : undefined
											}
											value={props.filterState.currentRecipientNamesSelected}
											virtualized
											onChange={props.onChangeRecipientFilters}
											onOverflow={props.onRecipientsOverflow}
										/>
									</div>
									<div className="w-full">
										<Typography
											theme="textSm"
											className="mb-1.5 font-semibold text-gray-1100"
										>
											Type
										</Typography>
										<SelectButton
											className="w-full"
											optionClassName={() => "w-30% shadow-none max-h-12"}
											options={props.filterState.directionOptions}
											optionTemplate={(option) => (
												<TypeOption option={option} />
											)}
											value={props.filterState.currentDirectionsSelected}
											onChange={props.onChangeDirectionFilter}
										/>
									</div>
								</div>
								<div className="flex w-full flex-row justify-between gap-6">
									<div className="w-full min-w-0 max-w-50%">
										<div className="flex flex-row justify-between">
											<Typography
												theme="textSm"
												className="mb-1.5 font-semibold text-gray-1100"
											>
												Currency
											</Typography>
											{props.currenciesOverflowing && (
												<Typography
													theme="textXs"
													className="mb-1.5 font-semibold text-gray-650"
												>
													{props.filterState.currentCurrenciesSelected?.length}{" "}
													Selected
												</Typography>
											)}
										</div>
										<MultiSelectCurrency
											className="w-full rounded-4px"
											placeholder="Select a currency"
											selectedItemsScrollable
											labelClassName="flex items-center py-0"
											value={props.filterState.currentCurrenciesSelected}
											onRemoveSelectedItem={props.onRemoveCurrencyFilter}
											onChange={props.onChangeCurrencyFilters}
											onOverflow={props.onCurrenciesOverflow}
										/>
									</div>
									<div className="w-full max-w-50%">
										<div className="flex w-full flex-row items-center justify-between gap-2">
											<div className="w-full">
												<Typography
													theme="textSm"
													className="mb-1.5 font-semibold text-gray-1100"
												>
													Start Date
												</Typography>
												<DatePicker
													className="w-full"
													placeholder="Select a date"
													inputClassName={"date-input min-h-12"}
													maxDate={props.filterState.currentEndDate}
													value={
														props.filterState.currentStartDate
															? [props.filterState.currentStartDate]
															: []
													}
													onChange={(value) =>
														props.onChangeStartDateFilter(value[0])
													}
												/>
											</div>
											<div className="pt-6">{"-"}</div>
											<div className="w-full">
												<Typography
													theme="textSm"
													className="mb-1.5 font-semibold text-gray-1100"
												>
													End Date
												</Typography>
												<DatePicker
													placeholder="Select a date"
													className="w-full"
													inputClassName={"date-input min-h-12"}
													minDate={props.filterState.currentStartDate}
													value={
														props.filterState.currentEndDate
															? [props.filterState.currentEndDate]
															: []
													}
													onChange={(value) =>
														props.onChangeEndDateFilter(value[0])
													}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="mt-4 flex w-full flex-row justify-end gap-4">
								<div className="flex w-25% flex-row justify-end gap-4">
									<Button
										variant="secondary"
										onClick={props.onClearFilters}
										style={{ minWidth: "108px" }}
									>
										Clear all
									</Button>
									<Button
										onClick={props.onApplyFilters}
										style={{ minWidth: "108px" }}
									>
										Apply
									</Button>
								</div>
							</div>
						</div>
					)}
				</>
				<InProgressTransactionCarousel
					isLoading={props.loadingTableData}
					data={props.listInProgressTransactions}
				/>
				{isMobile ? (
					<>
						<CompletedTransactions {...props} />
						<Paginator
							onNavigatePage={props.onPageChange}
							totalRecords={props.total || 0}
							currentPage={props.currentPage}
						/>
					</>
				) : (
					<>
						<div className="divider" />
						<div className={"sub-header-row"}>
							<Typography theme="displayXs" className="completed-heading-style">
								Completed
							</Typography>
							<CountTag>{props.total ?? ""}</CountTag>
						</div>
						{props.errors && props.errors.length > 0 && (
							<ApiErrors errors={props.errors} />
						)}
						{!props.loadingTableData &&
						props.listCompletedTransactions?.length === 0 ? (
							<NoTransactionsBlock />
						) : (
							<SortableTable
								isLoading={props.loading}
								data={props.listCompletedTransactions ?? []}
								columns={[
									{
										field: "date",
										sortField: "transaction_date",
										header: "Date",
										sortable: true,
									},
									{
										field: "recipientDisplay",
										sortField: "recipient_name",
										header: "Recipient",
										sortable: true,
									},
									{
										field: "zarAmountDisplay",
										header: "ZAR amount",
										sortField: "zar_amount",
										sortable: true,
									},
									{
										field: "tablePaymentType",
										header: "Type",
										sortField: "direction",
										sortable: true,
									},
									{
										field: "fxAmountDisplay",
										sortField: "fx_amount",
										header: "FX Amount",
										sortable: true,
									},
									{
										field: "view",
										width: 94,
									},
								]}
								onSort={props.onSortByName}
								sortOrder={props.state.sortOrder}
								sortField={props.state.sortField}
							/>
						)}
					</>
				)}

				{props.loading && <CustomLoader />}
			</div>
		</SignedInLayout>
	);
};
