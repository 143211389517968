import { links } from "@app/constants/links";

import styles from "./index.module.css";

export const NeedAssistance = () => {
	return (
		<footer className={styles.footer}>
			<div className={styles.inner}>
				<p>
					Need assistance?{" "}
					<a
						className={styles.link}
						href={links.contact}
						target="_blank"
						rel="noreferrer"
					>
						Get in touch
					</a>
				</p>
			</div>
		</footer>
	);
};
