import { api } from "@app/services";
import { getFormErrors } from "@app/utils/get-form-errors";
import useSWR from "swr";

export type AdditionalInformation = {
	approximate_transaction_amount: number;
	registration_referrer_id: number;
	referrer_name?: string;
};

export const useAdditionalInformation = (clientId?: number) => {
	const { data } = useSWR<AdditionalInformation>(
		clientId ? `/onboarding/${clientId}/additional-information/` : null,
	);

	return {
		data,
		update: async (newData: Partial<AdditionalInformation>) => {
			try {
				await api.patch(
					`/onboarding/${clientId}/additional-information/`,
					newData,
				);
			} catch (error) {
				return getFormErrors(error);
			}
		},
		submit: async (
			newData: {
				approximate_transaction_amount: number;
				registration_referrer_id?: number;
				referrer_name?: string;
			},
			isFinalSubmission = true,
		) => {
			try {
				await api.put(
					`/onboarding/${clientId}/additional-information/`,
					newData,
				);
				if (isFinalSubmission) {
					await api.post(`/onboarding/${clientId}/submit/`);
				}
			} catch (error) {
				return getFormErrors(error);
			}
		},
	};
};
