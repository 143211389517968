import React, { type ReactNode } from "react";

import styles from "./index.module.css";

export const ButtonGroup = ({
	data,
}: {
	data: {
		icon?: ReactNode;
		title: string;
		onClick: () => void;
	}[];
}) => {
	return (
		<div className={styles.buttonGroup}>
			{data.map((current) => {
				return (
					<button
						key={current.title}
						className={styles.button}
						type="button"
						onClick={current.onClick}
					>
						{typeof current.icon === "string" ? (
							<img src={current.icon} alt="" width={32} height={32} />
						) : (
							current.icon
						)}
						{current.title}
					</button>
				);
			})}
		</div>
	);
};
