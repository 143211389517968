import { paths } from "@app/constants/paths";
import { fetcher } from "@app/fetcher";
import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { preload } from "swr";
import useSWRImmutable from "swr/immutable";

export type Clients = {
	items: Array<{
		client_id: number;
		name: string;
		bank_account_id: number;
	}>;
	count: number;
};

export const CLIENT_STORAGE_KEY = "active-client-id";
const MAX_CLIENTS = 1000;

export const getActiveClientFromSession = () => {
	const stored = window.sessionStorage.getItem(CLIENT_STORAGE_KEY);
	return stored && Number.parseInt(stored, 10)
		? Number.parseInt(stored, 10)
		: "";
};

export const preloadClients = () =>
	preload<Clients>(`/clients/?limit=${MAX_CLIENTS}`, fetcher);

export const useClients = (isEnabled = true) => {
	const result = useSWRImmutable<Clients>(
		isEnabled ? `/clients/?limit=${MAX_CLIENTS}` : null,
	);
	const params = useParams<{ clientId?: string }>();

	const paramClientId =
		params.clientId && !Number.isNaN(Number.parseInt(params.clientId, 10))
			? Number.parseInt(params.clientId, 10)
			: undefined;

	const activeClientId = useMemo(() => {
		if (result.isLoading) return;
		if (
			paramClientId &&
			result.data?.items.find((current) => current.client_id === paramClientId)
		) {
			return paramClientId;
		}
		return result.data?.items[0]?.client_id;
	}, [result, paramClientId]);

	useEffect(() => {
		if (
			paramClientId &&
			result.data &&
			!result.data.items.find((current) => current.client_id === paramClientId)
		) {
			// Using a normal redirect here to cancel any pending navigations e.g. Redirect to onboarding
			window.location.href = paths().error.general;
		}
	}, [paramClientId, result.data]);

	useEffect(() => {
		if (activeClientId) {
			window.sessionStorage.setItem(
				CLIENT_STORAGE_KEY,
				activeClientId.toString(),
			);
		}
	}, [activeClientId]);

	return {
		data: result.data,
		error: result.error,
		isLoading: result.isLoading,
		activeClientId,
		activeBankAccountId: result.data?.items.find(
			(current) => current.client_id === activeClientId,
		)?.bank_account_id,
	};
};
