import { api } from "@app/services";
import { convertBlobToBase64 } from "@app/utils";
import useSWR from "swr";
import { useClients } from "./use-clients";

export const useProfilePicture = () => {
	const { activeClientId } = useClients();
	return useSWR<
		| {
				data: Blob;
				base64: string;
				contentType: string;
		  }
		| undefined
	>(
		activeClientId ? `/clients/${activeClientId}/profile-picture/` : null,
		async (path: string) => {
			const { data, headers } = await api.get(path, {
				responseType: "blob",
			});
			return {
				data,
				base64: await convertBlobToBase64(data),
				contentType: headers["content-type"],
			};
		},
	);
};
