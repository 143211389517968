import useSWR from "swr";

export interface Payment {
	payment_reference: string;
	purpose_of_payment: string;
	proof_of_payment_ready?: boolean;
	fee_type: string;
	swift_amount: string;
	swift_fee: string;
	swift_fee_tooltip: string;
	recipient_id?: number; // Only on send payments
	amount_due?: string; // Only on send payments
}
export const usePayment = (paymentId?: number) => {
	return useSWR<Payment>(paymentId ? `/payments/${paymentId}` : null);
};
