import { SignedInLayout } from "@app/components/signed-in-layout";
import { ReactNode, useMemo, useState } from "react";

import { MobileStepsNavigation } from "@app/components/mobile-steps-navigation";
import {
	NavigationStep,
	NavigationSteps,
} from "@app/components/navigation-steps";
import { paths } from "@app/constants/paths";
import { useAuth } from "@app/hooks/use-auth";
import { useTransaction } from "@app/hooks/use-transaction";
import { useTransactionId } from "@app/hooks/use-transaction-id";

import { TransactionDirection } from "@app/entities";
import styles from "./index.module.css";
import { TransactionSummary } from "./transaction-summary";
import { toDayjs } from "@app/lib/date";

export enum TransactionStep {
	PaymentDetails = 0,
	BalanceOfPayment = 1,
	Documents = 2,
	ReviewTransaction = 3,
}

export const TransactionLayout = ({
	children,
	step = 0,
	onStepNavigate,
	footer = null,
	onResumeLater,
}: {
	children: ReactNode;
	step?: number;
	onStepNavigate?: (path: string, targetId?: string | undefined) => void;
	footer?: ReactNode;
	onResumeLater?: () => void;
}) => {
	const [showMenu, setShowMenu] = useState(false);
	const { onLogout } = useAuth();
	const transactionId = useTransactionId();
	const { transaction, paymentStatus, exchangeDetails } =
		useTransaction(transactionId);

	const formattedDate = transaction?.transaction_date
		? toDayjs(transaction.transaction_date, "DD-MM-YYYY").format("D MMM YYYY")
		: "";

	const formattedDirection = transaction?.direction
		? transaction.direction === "send"
			? "Send"
			: "Receive"
		: "";
	const title = `${formattedDirection}: ${formattedDate}`;

	const completedSteps = useMemo(
		() => paymentStatus?.awaiting_submission_details?.steps_completed ?? [],
		[paymentStatus?.awaiting_submission_details?.steps_completed],
	);
	const steps: Array<NavigationStep> = useMemo(() => {
		if (!transactionId) return [];
		return [
			{
				title: "Payment details",
				completed: completedSteps.includes("payment_details"),
				active: step === TransactionStep.PaymentDetails,
				path: paths().paymentDetails(transactionId),
			},
			{
				title: "BoP details",
				completed: completedSteps.includes("bop"),
				disabled: !completedSteps.includes("payment_details"),
				active: step === TransactionStep.BalanceOfPayment,
				path: paths().balanceOfPayment(transactionId),
			},
			{
				title: "Documents",
				completed: completedSteps.includes("supporting_documents"),
				disabled: !completedSteps.includes("bop"),
				active: step === TransactionStep.Documents,
				path: paths().documents(transactionId),
			},
			{
				title: "Review transaction",
				disabled: !completedSteps.includes("supporting_documents"),
				completed: completedSteps.includes("review_transaction"),
				active: step === TransactionStep.ReviewTransaction,
				path: paths().reviewTransaction(transactionId),
			},
		];
	}, [step, transactionId, completedSteps]);

	const handleResumeLater = () => {
		setShowMenu(false);
		onResumeLater?.();
	};

	return (
		<SignedInLayout
			hideBottomNav
			footer={footer}
			mobileHeader={
				<MobileStepsNavigation
					step={step}
					steps={steps}
					onLogout={onLogout}
					showMenu={showMenu}
					onShowMenuChange={setShowMenu}
					title={title}
					subtitle={steps[step].title}
				>
					<div>
						{exchangeDetails && (
							<TransactionSummary
								zarAmount={exchangeDetails.zar_amount}
								direction={exchangeDetails.direction as TransactionDirection}
								fxAmount={exchangeDetails.fx_amount}
							/>
						)}
						<NavigationSteps
							className={styles.steps}
							steps={steps}
							onStepNavigate={onStepNavigate}
							action={
								onResumeLater && (
									<button
										className={styles.button}
										type="button"
										onClick={handleResumeLater}
									>
										Resume later
									</button>
								)
							}
						/>
					</div>
				</MobileStepsNavigation>
			}
			content={
				<>
					<NavigationSteps
						action={
							onResumeLater && (
								<button
									className={styles.button}
									type="button"
									onClick={handleResumeLater}
								>
									Resume later
								</button>
							)
						}
						steps={steps}
						onStepNavigate={onStepNavigate}
					>
						<p>{formattedDirection}</p>
						<p className={styles.date}>{formattedDate}</p>
						{exchangeDetails && (
							<TransactionSummary
								zarAmount={exchangeDetails.zar_amount}
								direction={exchangeDetails.direction as TransactionDirection}
								fxAmount={exchangeDetails.fx_amount}
							/>
						)}
					</NavigationSteps>
				</>
			}
		>
			{children}
		</SignedInLayout>
	);
};
