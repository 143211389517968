import { paths } from "@app/constants/paths";
import { PaymentStatus } from "@app/hooks/use-payment-status";

export const getNavigationPathFromStatus = (
	transactionId: number,
	paymentStatus: PaymentStatus,
) => {
	// Submission completed
	if (!paymentStatus.awaiting_submission_details) {
		return paths().viewTransaction(transactionId);
	}

	const current = paymentStatus.awaiting_submission_details.current_page;

	if (current === "bop") {
		return paths().balanceOfPayment(transactionId);
	}

	if (current === "supporting_documents") {
		return paths().documents(transactionId);
	}

	if (current === "review") {
		return paths().reviewTransaction(transactionId);
	}

	return paths().paymentDetails(transactionId);
};
