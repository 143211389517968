import useSWR from "swr";
import { useClients } from "./use-clients";

export type RecipientsQuery = {
	currencies: string;
	countries: string;
	ordering: string;
	recipient_name: string;
	limit: number;
	offset: number;
};

export type Recipient = {
	id: number;
	name: string;
	currencies: string;
	country: string;
	last_transaction_date: string;
	last_transaction_amount: string;
	last_transaction: string;
};

export const tempRecipientMap = (recipient: Recipient) => ({
	country: recipient.country,
	currencies: recipient.currencies,
	id: recipient.id,
	lastTransactionAmount: recipient.last_transaction_amount,
	lastTransactionDate: recipient.last_transaction_date,
	name: recipient.name,
});

export const useRecipients = (query?: Partial<RecipientsQuery>) => {
	const { activeClientId } = useClients();
	return useSWR<{
		items: Recipient[];
		count: number;
	}>(
		activeClientId
			? [`/clients/${activeClientId}/recipients/`, { params: query }]
			: null,
	);
};
