export const entityTypes = [
	{
		name: "Individual",
		value: "individual",
	},
	{
		name: "Private Company (Pty) Ltd",
		value: "pty_ltd",
	},
	{
		name: "Close Corporation (CC)",
		value: "cc",
	},
	{
		name: "Trust",
		value: "trust",
	},
	{
		name: "Partnership",
		value: "partnership",
	},
	{
		name: "Listed Company",
		value: "listed_company",
	},
	{
		name: "Non-Profit Organisation (NPO)",
		value: "npo",
	},
	{
		name: "Other",
		value: "other",
	},
];
