import { useClients } from "@app/hooks/use-clients";
import { api } from "@app/services";
import { getFormErrors } from "@app/utils/get-form-errors";
import useSWR from "swr";

export type CompanyDetails = {
	trading_name: string;
	industry_id: number;
	industry_name: string;
	md_ceo_name: string;
	incorporation_country: string;
	average_turnover_per_month: number;
	cash_percent_turnover: number;
	source_of_funds: Array<string> | null;
	source_of_funds_description: string | null;
	countries_transacted: Array<string> | null;
	has_ccn: string;
	ccn: string;
};

export type CompanyDetailsUpdate = {
	trading_name: string;
	industry_id: number;
	industry_name: string;
	md_ceo_name: string;
	incorporation_country: string;
	average_turnover_per_month: number | undefined;
	cash_percent_turnover: number | undefined;
	source_of_funds: Array<number>;
	source_of_funds_description: string | null;
	countries_transacted: Array<string>;
	has_ccn?: boolean;
	ccn: string | undefined;
};

export const useCompanyDetails = () => {
	const { activeClientId } = useClients();
	const { data } = useSWR<CompanyDetails>(
		activeClientId ? `/onboarding/${activeClientId}/company-details/` : null,
	);

	return {
		data,
		update: async (newData: Partial<CompanyDetailsUpdate>) => {
			try {
				await api.patch(
					`/onboarding/${activeClientId}/company-details/`,
					newData,
				);
			} catch (error) {
				return getFormErrors(error);
			}
		},

		submit: async (newData: CompanyDetailsUpdate) => {
			try {
				await api.put(
					`/onboarding/${activeClientId}/company-details/`,
					newData,
				);
			} catch (error) {
				return getFormErrors(error);
			}
		},
	};
};
