import { useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import { Button, Typography } from "@app/components";
import { SignedInLayout } from "@app/components/signed-in-layout";
import { links } from "@app/constants/links";
import { useMediaQuery } from "@app/hooks/use-media-query";

import { Title } from "@app/components/title";
import { paths } from "@app/constants/paths";
import { FiHome, FiMail, FiPhone } from "react-icons/fi";

import styles from "./index.module.css";

export const SignedInGeneralError = () => {
	const isMobile = useMediaQuery();
	const navigate = useNavigate();

	const onHome = () => navigate(paths().dashboard);

	return (
		<SignedInLayout
			title="Error"
			childChildContainerClassName="m-0 py-12 px-50 mobile:p-6"
			footer={
				isMobile && (
					<div className={styles.footer}>
						<a
							className={styles.link}
							href={links.contact}
							target="_blank"
							rel="noreferrer"
						>
							Contact us
						</a>
						<Button className="min-w-32" onClick={onHome}>
							<FiHome size={20} color="#fff" />
							Home
						</Button>
					</div>
				)
			}
			childHeaderClassName="mt-6"
		>
			<div className="min-h-[calc(100vh-82px-8rem)] justify-center">
				<div className="flex flex-row justify-center">
					<div className={styles.card}>
						<Title>Error encountered</Title>
						<p className="text-center">An unexpected error has occurred.</p>
						<p className="text-center">
							Our team has been notified and will resolve this as soon as
							possible.
						</p>
						<p className="mt-6 text-center">
							Please contact us if you need assistance.
						</p>
						<div className="mt-6">
							<a
								className={twMerge("flex flex-row", "items-center", "gap-x-3")}
								href={links.getInTouch.emailAddress}
							>
								<FiMail className="w-10" size={16} color="#888" />
								<Typography className="font-medium" theme="textMd">
									info@futureforex.co.za
								</Typography>
							</a>
							<a
								className={twMerge(
									"flex flex-row",
									"mt-2",
									"items-center",
									"gap-x-3",
								)}
								href={links.getInTouch.phoneNumber}
							>
								<FiPhone className="w-10" size={16} color="#888" />
								<Typography className="font-medium" theme="textMd">
									021 518 0558
								</Typography>
							</a>
						</div>
						{!isMobile && (
							<div className={styles.actions}>
								<a
									className={styles.link}
									href={links.contact}
									target="_blank"
									rel="noreferrer"
								>
									Contact us
								</a>
								<Button className="min-w-32" onClick={onHome}>
									<FiHome size={20} color="#fff" />
									Home
								</Button>
							</div>
						)}
					</div>
				</div>
			</div>
		</SignedInLayout>
	);
};
