import { ErrorBoundary } from "@sentry/react";
import { Providers } from "./providers";
import { Router } from "./router";
import { GeneralError } from "./screens/errors/general-error";

const Main = () => (
	<Providers>
		<ErrorBoundary fallback={<GeneralError />}>
			<Router />
		</ErrorBoundary>
	</Providers>
);

export default Main;
