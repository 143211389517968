import { useClients } from "@app/hooks/use-clients";
import { api } from "@app/services";
import { getFormErrors } from "@app/utils/get-form-errors";
import useSWR from "swr";

export type AddressInformation = {
	same_addresses: boolean;
	residential_address: {
		address_1: string;
		address_2: string;
		city: string;
		province: string | null;
		country: string;
		postal_code: string;
		other_province: string;
	};
	proof_of_address: string;
	postal_address: {
		address_1: string;
		address_2: string;
		city: string;
		province: string | null;
		country: string;
		postal_code: string;
		other_province: string;
	};
};

export type AddressInformationUpdate = {
	same_addresses: boolean;
	residential_address: {
		address_1: string;
		address_2: string;
		city: string;
		province: string | null;
		other_province: string;
		country: string;
		postal_code: string;
	};
	proof_of_address: string;
	postal_address: {
		address_1: string;
		address_2: string;
		city: string;
		other_province: string;
		province: string | null;
		country: string;
		postal_code: string;
	} | null;
};

const mapFieldNames = (key: string, parts: Array<string>) => {
	if (
		parts.length > 2 &&
		["postal_address", "residential_address"].includes(parts[parts.length - 2])
	) {
		return `${parts[parts.length - 2]}_${key}`;
	}

	return key;
};

export const useAddressInformation = () => {
	const { activeClientId } = useClients();
	const { data } = useSWR<AddressInformationUpdate>(
		activeClientId
			? `/onboarding/${activeClientId}/address-information/`
			: null,
	);

	return {
		data,
		update: async (newData: Partial<AddressInformationUpdate>) => {
			try {
				await api.patch(
					`/onboarding/${activeClientId}/address-information/`,
					newData,
				);
			} catch (error: any) {
				return getFormErrors(error, mapFieldNames);
			}
		},
		submit: async (newData: AddressInformationUpdate) => {
			try {
				await api.put(
					`/onboarding/${activeClientId}/address-information/`,
					newData,
				);
			} catch (error: any) {
				return getFormErrors(error, mapFieldNames);
			}
		},
	};
};
