import useSWR from "swr";
import { useClients } from "./use-clients";

export type RecipientNames = {
	id: number;
	name: string;
};

export const useRecipientNames = (clientId?: number) => {
	const { activeClientId } = useClients();
	return useSWR<RecipientNames[]>(
		activeClientId ? `/clients/${activeClientId}/recipient-names/` : null,
	);
};
