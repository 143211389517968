import React, {
	type MouseEvent,
	type ReactNode,
	useEffect,
	useState,
} from "react";

import {
	AccordionTab,
	Accordion as PrimeAccordion,
} from "primereact/accordion";
import { twMerge } from "tailwind-merge";
import { Subheading } from "../subheading";

import "./accordion.css";

export const Accordion = (props: {
	children: ReactNode;
	className?: string;
	header: ReactNode;
	isDisabled?: boolean;
	itemIndex?: number;
	stayOpen?: boolean;
	onOpen?: (itemIndex: number, event: MouseEvent<HTMLElement>) => void;
	onClose?: (event: MouseEvent<HTMLElement>) => void;
}) => {
	const [activeIndex, setActiveIndex] = useState(props.itemIndex);

	const onTabChange = (itemIndex: number, event: MouseEvent<HTMLElement>) => {
		if (activeIndex === itemIndex) {
			setActiveIndex(undefined);
			if (props.onClose) {
				props.onClose(event);
			}
		} else {
			setActiveIndex(itemIndex);
		}

		if (props.onOpen) {
			props.onOpen(itemIndex, event);
		}
	};

	useEffect(() => {
		if (props.itemIndex !== undefined) {
			setActiveIndex(props.itemIndex);
		}
	}, [props.itemIndex]);

	return (
		<PrimeAccordion
			className={twMerge("accordion", props.className)}
			activeIndex={activeIndex}
			onTabChange={(e) =>
				!props.stayOpen && onTabChange(e.index as number, e.originalEvent)
			}
		>
			<AccordionTab
				header={<Subheading>{props.header}</Subheading>}
				disabled={props.isDisabled}
			>
				{props.children}
			</AccordionTab>
		</PrimeAccordion>
	);
};
