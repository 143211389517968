import { type ReactNode, useEffect, useMemo, useRef, useState } from "react";

import { twMerge } from "tailwind-merge";
import styles from "./index.module.css";

import { clsx } from "clsx";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import { CountTag } from "../count-tag";

type CarouselDirections = "next" | "previous";

const SLIDE_PREFIX = "#slide-";

const INITIAL_DISPLAY_AMOUNT = 6;

export const Carousel = (props: {
	className?: string;
	itemIndex?: number;
	itemsOuterClassName?: string;
	values?: any[];
	heading?: string;
	displayAmount?: boolean;
	template: (item: any, index?: number) => ReactNode;
}) => {
	const [shouldShowAll, setShouldShowAll] = useState(false);
	const [activeIndex, setActiveIndex] = useState(props.itemIndex || 0);
	const carouselWrapperRef = useRef<HTMLDivElement>(null);
	const scrollingTimeout = useRef<NodeJS.Timeout | null>();

	const handleToggleSlide = (direction: CarouselDirections) => {
		let index = activeIndex;
		const [min, max] = [0, (props.values?.length || 0) - 1];

		if (direction === "next") {
			index++;

			if (carouselWrapperRef.current?.scrollWidth) {
				if (
					(carouselWrapperRef.current.scrollLeft || 0) +
						(carouselWrapperRef.current.clientWidth || 0) >=
					(carouselWrapperRef.current.scrollWidth || 0)
				)
					index = 0;
			}
		} else if (direction === "previous") {
			index--;

			if (carouselWrapperRef.current?.scrollWidth) {
				let willMove = false;

				do {
					const objectToScroll = document.querySelector<HTMLDivElement>(
						`${SLIDE_PREFIX}${index}`,
					);

					if (index <= 0) {
						break;
					}

					if (objectToScroll) {
						if (
							carouselWrapperRef.current.scrollWidth -
								carouselWrapperRef.current.clientWidth >
							objectToScroll.offsetLeft
						)
							willMove = true;
						else index--;
					}
				} while (!willMove);
			}
		}

		if (index > max) {
			// at max, start from top
			index = 0;
		}

		if (index < min) {
			// at min, start from max
			index = max;
		}

		setActiveIndex(index);
		const objectToScroll = document.querySelector(
			`${SLIDE_PREFIX}${index > (props.values?.length || 2) - 1 ? 1 : index}`,
		);
		objectToScroll?.scrollIntoView({ block: "nearest", inline: "start" });
	};

	useEffect(() => {
		if (props.itemIndex !== undefined) {
			setActiveIndex(props.itemIndex);
		}
	}, [props.itemIndex]);

	useEffect(() => {
		const onScrolling = () => {
			if (scrollingTimeout.current) {
				clearTimeout(scrollingTimeout.current);
				scrollingTimeout.current = null;
			}
			scrollingTimeout.current = setTimeout(() => {
				if (!carouselWrapperRef.current) return;

				for (
					let index = 0;
					index <= Array.from(carouselWrapperRef.current.children).length;
					index++
				) {
					const element = carouselWrapperRef.current.children[
						index
					] as HTMLDivElement;
					if (!element) continue;
					if (carouselWrapperRef.current.scrollLeft <= element.offsetLeft) {
						setActiveIndex(index);
						break;
					}
				}
			}, 100);
		};

		if (carouselWrapperRef.current) {
			carouselWrapperRef.current.removeEventListener("scroll", onScrolling);
			carouselWrapperRef.current.addEventListener("scroll", onScrolling);
		}

		return () => {
			if (carouselWrapperRef.current) {
				carouselWrapperRef.current.removeEventListener("scroll", onScrolling);
			}
		};
	}, [carouselWrapperRef.current]);

	useEffect(() => {
		const timeout = setTimeout(() => {
			setShouldShowAll(true);
		}, 200);
		return () => clearTimeout(timeout);
	}, []);

	const items = useMemo(() => {
		if (!props.values) return [];
		return shouldShowAll
			? props.values
			: props.values.slice(0, INITIAL_DISPLAY_AMOUNT);
	}, [props.values, shouldShowAll]);

	return (
		<div className={clsx(styles.carousel, props.className)}>
			<div className={styles.header}>
				<div className={twMerge(styles.headerInner, props.itemsOuterClassName)}>
					{props.heading && <h3 className={styles.title}>{props.heading}</h3>}
					{props.displayAmount && <CountTag>{props.values?.length}</CountTag>}
				</div>
				<div className={styles.arrows}>
					<button
						type="button"
						aria-label="Previous"
						className={styles.button}
						onClick={() => {
							handleToggleSlide("previous");
						}}
					>
						<FiArrowLeft size={24} color="#fff" />
					</button>
					<button
						className={styles.button}
						type="button"
						aria-label="Next"
						onClick={() => {
							handleToggleSlide("next");
						}}
					>
						<FiArrowRight size={24} color="#fff" />
					</button>
				</div>
			</div>

			<div
				id="carousel"
				className={twMerge(styles.slider, props.itemsOuterClassName)}
				ref={carouselWrapperRef}
			>
				{items.map((x, index) => {
					return (
						<div
							className={styles.slide}
							key={`slide-${index}`}
							id={`slide-${index}`}
						>
							{props.template(x, index)}
						</div>
					);
				})}
			</div>
		</div>
	);
};
