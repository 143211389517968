import { ReactNode } from "react";

import { useMediaQuery } from "@app/hooks/use-media-query";
import styles from "./index.module.css";

export const Title = ({
	children,
}: {
	children: ReactNode;
}) => {
	const isMobile = useMediaQuery();
	return (
		<>
			<h1 className={styles.title}>{children}</h1>
			{!isMobile && <div className={styles.divider} />}
		</>
	);
};
