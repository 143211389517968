import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

dayjs.tz.setDefault("Africa/Johannesburg");

export const toDayjs = (date?: Date | string, formatter?: string) => {
	const result = dayjs.utc(date, formatter).tz("Africa/Johannesburg");
	return result;
};
