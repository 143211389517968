import { paths } from "@app/constants/paths";
import { fetcher } from "@app/fetcher";
import { getNavigationPathFromStatus } from "@app/utils/get-navigation-path-from-status";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { preload } from "swr";
import { ExchangeDetails } from "./use-exchange-details";
import { PaymentStatus } from "./use-payment-status";

export const useGoToTransaction = () => {
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();
	const handleGoToTransaction = useCallback(
		async (transactionId?: number) => {
			try {
				setIsLoading(true);
				if (!transactionId) {
					throw new Error("No transaction ID found");
				}
				const exchangeDetails = await preload<ExchangeDetails>(
					`/transactions/${transactionId}/exchange-details/`,
					fetcher,
				);

				if (!exchangeDetails.payment_ids.length) {
					throw new Error("No payment IDs found");
				}
				const paymentId = exchangeDetails.payment_ids[0];
				const paymentStatus = await preload<PaymentStatus>(
					`/payments/${paymentId}/status/`,
					fetcher,
				);

				const path = getNavigationPathFromStatus(transactionId, paymentStatus);
				setIsLoading(false);
				navigate(path);
			} catch {
				setIsLoading(false);
				navigate(paths().error.generalError());
			}
		},
		[navigate],
	);
	return {
		isLoading,
		onGoToTransaction: handleGoToTransaction,
	};
};
