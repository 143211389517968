import type { MouseEvent, ReactNode } from "react";
import { twMerge } from "tailwind-merge";

import type { TagStyle } from "@app/entities";

import {
	FiArrowLeft,
	FiArrowRight,
	FiCheck,
	FiLoader,
	FiStar,
} from "react-icons/fi";
import { MdClose } from "react-icons/md";
import "./tag.css";

const defaultStyle: TagStyle = "default";

const TagIcon = ({
	tagStyle,
	icon,
}: { tagStyle?: TagStyle; icon?: string }) => {
	if (icon === "FiArrowRight") {
		return <FiArrowRight size={16} color="#262626" />;
	}

	if (icon === "FiArrowLeft") {
		return <FiArrowLeft size={16} color="#262626" />;
	}

	if (tagStyle) {
		if (tagStyle === "complete") return <FiCheck size={16} color="#262626" />;
		if (tagStyle === "inProgress")
			return <FiLoader size={16} color="#262626" />;
		if (tagStyle === "popular") return <FiStar size={16} color="#262626" />;
	}

	return null;
};

const RemoveButton = ({
	onRemove,
	removeButtonPlacement,
	removeButtonClassName,
}: {
	onRemove?: (event: MouseEvent<HTMLDivElement>) => void;
	removeButtonPlacement?: "left" | "right";
	removeButtonClassName?: string;
}) => {
	const removeButtonMarginClass =
		removeButtonPlacement === "left"
			? "mr-1"
			: removeButtonPlacement === "right"
				? "ml-1"
				: "";

	return (
		<div onClick={onRemove}>
			<MdClose
				className={twMerge(
					"cursor-pointer",
					removeButtonMarginClass,
					removeButtonClassName,
				)}
				color="#b0b0b0"
				size={14}
			/>
		</div>
	);
};

export const Tag = ({
	children,
	className,
	containerClassName,
	icon,
	iconPlacement,
	removeButtonClassName,
	removeButtonPlacement,
	showRemoveButton,
	tagStyle,
	text,
	onRemove,
}: {
	children?: ReactNode;
	className?: string;
	containerClassName?: string;
	icon?: string;
	iconPlacement?: "left" | "right";
	removeButtonClassName?: string;
	removeButtonPlacement?: "left" | "right";
	showRemoveButton?: boolean;
	tagStyle?: TagStyle;
	text?: string;
	onRemove?: (event: MouseEvent<HTMLDivElement>) => void;
}) => (
	<div className={twMerge("tag-container", containerClassName)}>
		<div
			className={twMerge(
				"tag",
				`tag-${tagStyle ?? defaultStyle}`,
				className ?? "",
			)}
		>
			{showRemoveButton && removeButtonPlacement === "left" && (
				<RemoveButton
					onRemove={onRemove}
					removeButtonPlacement={removeButtonPlacement}
					removeButtonClassName={removeButtonClassName}
				/>
			)}
			{iconPlacement === "left" && <TagIcon tagStyle={tagStyle} icon={icon} />}
			{children ?? ""}
			{text}
			{iconPlacement === "right" && <TagIcon tagStyle={tagStyle} icon={icon} />}
			{showRemoveButton && removeButtonPlacement === "right" && (
				<RemoveButton
					onRemove={onRemove}
					removeButtonPlacement={removeButtonPlacement}
					removeButtonClassName={removeButtonClassName}
				/>
			)}
		</div>
	</div>
);
