import { MutableRefObject, useEffect, useRef, useState } from "react";

/**
 * A utility hook to check if an element is visible in the viewport. Once visible it will disconnect the observer.
 * @param ref MutibleRefObject of the element to observe
 * @param isEnabled Boolean to enable/disable the observer
 * @returns Boolean to indicate if the element is visible
 */
export const useIsVisibleOnce = (
	ref: MutableRefObject<HTMLElement | null>,
	isEnabled = true,
) => {
	const observerRef = useRef<IntersectionObserver | null>(null);
	const [isIntersecting, setIntersecting] = useState(false);

	useEffect(() => {
		if (!ref.current || !isEnabled) return;
		observerRef.current = new IntersectionObserver(([entry]) =>
			setIntersecting(entry.isIntersecting),
		);
		observerRef.current.observe(ref.current);
		return () => {
			if (observerRef.current) {
				observerRef.current.disconnect();
			}
		};
	}, [ref, isEnabled]);

	useEffect(() => {
		if (isIntersecting && observerRef.current) {
			observerRef.current.disconnect();
		}
	}, [isIntersecting]);
	return isIntersecting;
};
