import { fetcher } from "@app/fetcher";
import { EntityType } from "@app/types";
import useSWR, { preload } from "swr";
import { useClients } from "./use-clients";

export type ClientProfile = {
	display_name: string;
	entity_type: EntityType;
	profile_picture_url: string;
	lead_type: string;
	first_name: string;
	last_name: string;
};

export const preloadClientProfile = (activeClientId: number | string) =>
	preload<ClientProfile>(`clients/${activeClientId}/profile/`, fetcher);

export const useClientProfile = () => {
	const { activeClientId } = useClients();
	return useSWR<ClientProfile>(
		activeClientId ? `clients/${activeClientId}/profile/` : null,
	);
};
