import { EntityFormInputs } from "./entity-form";

const defaultEntity = {
	entity_type: "individual",
	email_address: "",
	contact_number: "",
	first_name: "",
	middle_names: "",
	last_name: "",
	nationality: "",
	country_of_birth: "",
	residential_address: {
		address_1: "",
		address_2: "",
		city: "",
		province: "",
		other_province: "",
		postal_code: "",
		country: "South Africa",
	},
	id_document: null,
	id_selfie: null,
};

export const isEntityEmpty = (entity: EntityFormInputs) => {
	return Object.keys(defaultEntity).every((key) => {
		if (key === "residential_address") {
			return Object.keys(defaultEntity.residential_address).every(
				(addressKey) => {
					return (
						entity.residential_address[
							addressKey as keyof typeof entity.residential_address
						] ===
						defaultEntity.residential_address[
							addressKey as keyof typeof defaultEntity.residential_address
						]
					);
				},
			);
		}
		return (
			entity[key as keyof typeof defaultEntity] ===
			defaultEntity[key as keyof typeof defaultEntity]
		);
	});
};
