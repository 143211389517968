import styles from "./index.module.css";

export const PaymentTypeCell = ({ type }: { type?: string }) => {
	if (!type) return null;
	return (
		<div data-type={type} className={styles.cell}>
			{type === "receive" && (
				<svg
					role="presentation"
					xmlns="http://www.w3.org/2000/svg"
					width="14"
					height="15"
					viewBox="0 0 14 15"
					fill="none"
				>
					<path
						d="M11.0827 7.10286H2.91602M2.91602 7.10286L6.99935 11.1862M2.91602 7.10286L6.99935 3.01953"
						stroke="#262626"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			)}
			{type === "send" ? "Send" : "Receive"}
			{type === "send" && (
				<svg
					role="presentation"
					xmlns="http://www.w3.org/2000/svg"
					width="14"
					height="15"
					viewBox="0 0 14 15"
					fill="none"
				>
					<path
						d="M2.91732 7.10026H11.084M11.084 7.10026L7.00065 3.01693M11.084 7.10026L7.00065 11.1836"
						stroke="#262626"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			)}
		</div>
	);
};
