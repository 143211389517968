import unsortedSrc from "@app/assets/images/default-sort.svg";
import sortDownSrc from "@app/assets/images/sort-down.svg";
import sortUpSrc from "@app/assets/images/sort-up.svg";

import styles from "./index.module.css";

export interface SortableTableColumn<T> {
	field: string;
	sortField?: string;
	header?: string;
	sortable?: boolean;
	width?: number | string;
}

export const SortableTable = <T,>({
	data,
	columns,
	sortField,
	sortOrder = -1,
	isLoading = false,
	onSort,
}: {
	columns: Array<SortableTableColumn<keyof T>>;
	data: Array<{ [key: string]: any }>;
	sortField?: string;
	sortOrder?: number;
	onSort?: (sortOrder: number, sortField: string) => void;
	isLoading?: boolean;
}) => {
	if (isLoading) {
		return <div className={styles.loading} />;
	}

	return (
		<div className={styles.wrapper}>
			<table className={styles.table}>
				<thead>
					<tr className={styles.headerRow}>
						{columns.map((column) => {
							const sortKey = column.sortField ?? column.field;
							return (
								<th
									key={column.field}
									className={styles.headerCell}
									style={{
										width: column.width,
									}}
								>
									<button
										disabled={!column.sortable}
										className={styles.headerCellContent}
										type="button"
										onClick={() => {
											if (!onSort) return;
											if (sortKey === sortField) {
												onSort(sortOrder === -1 ? 1 : -1, sortKey);
											} else {
												onSort(-1, sortKey);
											}
										}}
									>
										{column.header}

										{column.sortable && (
											<img
												src={
													sortKey === sortField && sortOrder === 1
														? sortUpSrc
														: sortKey === sortField && sortOrder === -1
															? sortDownSrc
															: unsortedSrc
												}
												alt=""
											/>
										)}
									</button>
								</th>
							);
						})}
					</tr>
				</thead>
				<tbody>
					{data.map((row, index) => {
						return (
							<tr className={styles.row} key={index}>
								{columns.map((column) => {
									return (
										<td className={styles.cell} key={column.field}>
											{row[column.field as keyof typeof row]}
										</td>
									);
								})}
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
};
