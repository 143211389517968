import { Button, IconButton } from "@app/components";
import { DocumentUploadModal } from "@app/components/document-upload-modal";
import { MoreInfoTooltip } from "@app/components/more-info-tooltip";
import { useMediaQuery } from "@app/hooks/use-media-query";
import { formatFileSize, joinClasses } from "@app/utils";
import { ComponentPropsWithoutRef, ReactNode, useMemo, useState } from "react";
import styles from "./index.module.css";
import {
	OnboardingDocumentType,
	useOnboardingDocument,
} from "./use-onboarding-document";

import { FieldError } from "@app/components/field-error";
import { abortRequests } from "@app/services";
import { getFormErrors } from "@app/utils/get-form-errors";
import { AxiosProgressEvent } from "axios";
import clsx from "clsx";
import { FieldError as RHFFieldError } from "react-hook-form";
import { FiPlus, FiTrash2 } from "react-icons/fi";
import checkIconSrc from "./check.svg";
import documentIconSrc from "./document.svg";

const getErrorMessage = (str?: string) => {
	if (str === "field required") return "This document is required";
	return str;
};

export const DocumentField = ({
	type,
	label,
	value,
	onChange,
	tooltip,
	error,
	tooltipWidth = 260,
	relatedEntityId,
	disabled,
	className,
	...rest
}: {
	type: OnboardingDocumentType;
	label: string;
	value?: string | null;
	onChange?: () => Promise<void>;
	tooltip?: ReactNode;
	error?: RHFFieldError;
	tooltipWidth?: number;
	disabled?: boolean;
	className?: string;
	relatedEntityId?: number;
} & Omit<ComponentPropsWithoutRef<"div">, "onChange">) => {
	const [isRemoving, setIsRemoving] = useState(false);
	const isMobile = useMediaQuery();
	const [uploadedFile, setUploadedFile] = useState<File | null>(null);
	const { uploadDocument, downloadDocument, mutate, data, deleteDocument } =
		useOnboardingDocument(type, relatedEntityId);
	const [showUpload, setShowUpload] = useState(false);

	const handleRemove = async () => {
		setIsRemoving(true);
		await deleteDocument();
		setUploadedFile(null);
		await onChange?.();
		setIsRemoving(false);
	};

	const info = useMemo(() => {
		if (!data && !uploadedFile) return null;
		if (uploadedFile && !data)
			return {
				name: uploadedFile.name,
				size: uploadedFile.size,
				extension: uploadedFile.name.split(".").pop(),
			};
		return data;
	}, [uploadedFile, data]);

	return (
		<>
			<div {...rest} className={clsx(styles.wrapper, className)}>
				<div
					className={styles.container}
					data-error={!!error}
					data-active={!!data}
				>
					{!info ? (
						<>
							<div className={styles.addRow}>
								<img
									className={styles.icon}
									src={documentIconSrc}
									width={24}
									height={24}
									alt=""
								/>
								<span>{label}*</span>
								{tooltip && (
									<MoreInfoTooltip
										name={label}
										className={styles.trigger}
										hasIcon
										maxWidth={tooltipWidth}
									>
										{tooltip}
									</MoreInfoTooltip>
								)}
							</div>
							{isMobile ? (
								<IconButton
									disabled={disabled}
									onClick={() => setShowUpload(true)}
								>
									<FiPlus size={24} color="#56A7A2" />
								</IconButton>
							) : (
								<Button disabled={disabled} onClick={() => setShowUpload(true)}>
									Add file
								</Button>
							)}
						</>
					) : (
						<div className={styles.editRow}>
							<div className={styles.inner}>
								{!isMobile && (
									<img
										className={styles.icon}
										src={checkIconSrc}
										width={24}
										height={24}
										alt=""
									/>
								)}
								<div className={styles.content}>
									<p className={styles.label}>
										{isMobile && (
											<img
												className={styles.icon}
												src={checkIconSrc}
												width={24}
												height={24}
												alt=""
											/>
										)}
										{label}
										{tooltip && (
											<MoreInfoTooltip
												name={label}
												hasIcon
												maxWidth={tooltipWidth}
											>
												{tooltip}
											</MoreInfoTooltip>
										)}
									</p>
									<div className={styles.innerContent}>
										<div className={styles.content}>
											<ul
												className={joinClasses(
													styles.options,
													styles.fileDetails,
												)}
											>
												<li className={styles.name}>{info.name}</li>
												<li className={styles.divider} />
												<li>{formatFileSize(info.size)}</li>
											</ul>

											<ul className={styles.options}>
												<li>
													<button
														className={styles.button}
														type="button"
														onClick={downloadDocument}
													>
														Download
													</button>
												</li>

												<li className={styles.divider} />
												{isMobile ? (
													<li>
														<button
															disabled={disabled}
															className={styles.button}
															type="button"
															onClick={() => setShowUpload(true)}
														>
															Edit
														</button>
													</li>
												) : (
													<li>
														<button
															disabled={isRemoving || disabled}
															className={styles.button}
															type="button"
															onClick={() => {
																handleRemove();
															}}
														>
															{isRemoving ? "Removing" : "Remove"}
														</button>
													</li>
												)}
											</ul>
										</div>
										{isMobile && (
											<IconButton onClick={handleRemove} disabled={disabled}>
												<FiTrash2 size={24} color="#56A7A2" />
											</IconButton>
										)}
									</div>
								</div>
							</div>
							{!isMobile && (
								<Button
									disabled={disabled}
									variant="secondary"
									onClick={() => setShowUpload(true)}
								>
									Change file
								</Button>
							)}
						</div>
					)}
				</div>
				{error && <FieldError>{getErrorMessage(error.message)}</FieldError>}
			</div>
			{showUpload && (
				<DocumentUploadModal
					multiple={false}
					maxFiles={1}
					showSingleButton
					onClose={() => setShowUpload(false)}
					onConfirmUpload={async () => {
						await onChange?.();
						setShowUpload(false);
					}}
					onCancelUpload={() => {
						abortRequests();
					}}
					onDeleteItem={() => {
						if (data) {
							handleRemove();
						}
					}}
					onUpload={async (
						index: number,
						file: File | undefined,
						onSetUploadComplete: (index: number, id?: number | string) => void,
						onSetUploadProgress: (progressEvent: AxiosProgressEvent) => void,
						onUploadError: (error: string, index: number) => void,
					) => {
						if (!file) return;
						try {
							await uploadDocument(file, onSetUploadProgress);
							setUploadedFile(file);
							onSetUploadComplete(index);
							mutate();
						} catch (error) {
							if (error) {
								const { fieldErrors, apiErrors } = getFormErrors(error);
								if (apiErrors.length > 0) {
									onUploadError(apiErrors[0], index);
									return;
								}

								if (fieldErrors.length > 0) {
									onUploadError(fieldErrors[0].message, index);
									return;
								}

								onUploadError("Upload failed", index);
							}
						}
					}}
				/>
			)}
		</>
	);
};
