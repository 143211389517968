import useSWR from "swr";

export interface FeeTypeOption {
	label: string;
	fee_type: string;
	amount: string;
	description: string;
}

export interface PaymentOptions {
	allowed_fee_types?: FeeTypeOption[];
	display_apn_details: boolean;
	note?: string;
}

export const usePaymentOptions = (paymentId?: number) => {
	return useSWR<PaymentOptions>(
		paymentId ? `/payments/${paymentId}/options/` : null,
	);
};
