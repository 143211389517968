import useSWR from "swr";

const THIRTY_MINUTES = 1000 * 60 * 30;

export const useVerifySession = (isEnabled = true) =>
	useSWR<{ ok: string }>(isEnabled ? "/auth/verify-session/" : null, {
		revalidateOnFocus: true,
		revalidateOnReconnect: true,
		refreshInterval: THIRTY_MINUTES,
	});
