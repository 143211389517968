import { useAuth } from "@app/hooks/use-auth";
import { useClientProfile } from "@app/hooks/use-client-profile";
import { useClients } from "@app/hooks/use-clients";
import { useExchangeDetails } from "@app/hooks/use-exchange-details";
import { useStatus } from "@app/hooks/use-status";
import { useTransactionId } from "@app/hooks/use-transaction-id";
import { AccountOpenedModal } from "@app/screens/onboarding/account-opened-modal";
import { InProgressModal } from "@app/screens/onboarding/in-progress-modal";
import { InboundCapturedModal } from "@app/screens/onboarding/inbound-captured-modal";
import { InboundModal } from "@app/screens/onboarding/inbound-modal";
import { useUserProfile } from "@app/screens/user-settings/use-user-profile";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "../button";
import { StatusCheckModal } from "../status-check-modal";
import { OutboundModal } from "./outbound-modal";
import { useClientOnboardingStatus } from "./use-client-onboarding-status";
import { paths } from "@app/constants/paths";

export const Status = () => {
	const { data: clientProfile } = useClientProfile();
	const transactionId = useTransactionId();

	const [showInboundModal, setShowInboundModal] = useState(false);
	const [showOutboundModal, setShowOutboundModal] = useState(false);
	const [showInProgressModal, setShowInProgressModal] = useState(false);
	const [showCompleteModal, setShowCompleteModal] = useState(false);

	const { data: userProfile } = useUserProfile();
	const { onLogout } = useAuth();
	const location = useLocation();

	const { data: clients, error: clientsError } = useClients();
	const isTransactionRoute = location.pathname.includes("transaction");
	const isOnboardingRoute = location.pathname.includes("onboarding");
	const isErrorRoute = location.pathname.includes("error");
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const { error: exchangeDetailsError } = useExchangeDetails(
		transactionId,
		isTransactionRoute,
	);
	const { error: clientProfileError } = useClientProfile();
	const { error, onError } = useStatus();

	const { data: onboardingStatus, mutate } = useClientOnboardingStatus();

	useEffect(() => {
		if (!clients || !userProfile) return;
		const hasNoClients = clients.items.length === 0;
		if (!isOnboardingRoute && !isErrorRoute && hasNoClients) {
			setShowInboundModal(true);
		}
	}, [isOnboardingRoute, isErrorRoute, clients, userProfile]);

	useEffect(() => {
		if (!onboardingStatus) return;
		if (
			onboardingStatus.status === "awaiting_account_opening_confirmation" &&
			!isOnboardingRoute &&
			!isErrorRoute
		) {
			setShowCompleteModal(true);
		}
	}, [onboardingStatus, isOnboardingRoute, isErrorRoute]);

	useEffect(() => {
		if (!clientProfile || !onboardingStatus) return;
		if (
			clientProfile.lead_type === "outbound" &&
			["onboarding_individual", "onboarding_business"].includes(
				onboardingStatus?.status,
			)
		) {
			setShowOutboundModal(true);
		}
	}, [clientProfile, onboardingStatus]);

	useEffect(() => {
		if (!onboardingStatus) return;
		if (
			[
				"processing_submission",
				"processing_shareholder_information",
				"awaiting_shareholder_information",
				"awaiting_e_signature",
				"sending_documents_to_bank",
				"awaiting_bank_account_opening",
			].includes(onboardingStatus.status) &&
			!isOnboardingRoute &&
			!isErrorRoute
		) {
			setShowInProgressModal(true);
		}
	}, [onboardingStatus, isOnboardingRoute, isErrorRoute]);

	useEffect(() => {
		if (clientProfileError && clientProfileError.status === 403) {
			onError(clientProfileError);
		}
	}, [clientProfileError, onError]);

	useEffect(() => {
		if (exchangeDetailsError && exchangeDetailsError.status === 403) {
			onError(exchangeDetailsError);
		}
	}, [exchangeDetailsError, onError]);

	if (isOnboardingRoute || isErrorRoute) return null;

	return (
		<>
			{(error ?? clientsError) && (
				<StatusCheckModal
					isOpen
					error={error ?? clientsError}
					actions={
						<Button centered onClick={onLogout}>
							Log out
						</Button>
					}
				/>
			)}

			{showInboundModal && (
				<InboundModal
					variant={
						clientProfile?.entity_type ?? userProfile?.register_entity_type
					}
					onComplete={() => {
						setShowInboundModal(false);
						navigate({
							pathname: paths().root,
							search: new URLSearchParams({
								captured: "true",
							}).toString(),
						});
					}}
				/>
			)}

			{searchParams.get("captured") && <InboundCapturedModal />}
			{showOutboundModal && <OutboundModal />}
			{showInProgressModal && <InProgressModal />}
			{showCompleteModal && (
				<AccountOpenedModal
					onComplete={() => {
						setShowCompleteModal(false);
						mutate();
					}}
				/>
			)}
		</>
	);
};
