import { ProgressBar } from "primereact/progressbar";
import { twMerge } from "tailwind-merge";

import { Typography } from "@app/components";
import { formatFileSize } from "@app/utils";
import { PiFile, PiFileJpg, PiFilePdf, PiFilePng } from "react-icons/pi";

import { useState } from "react";
import { FiX } from "react-icons/fi";
import styles from "./document-upload-item.module.css";

const FileIcon = ({
	extension,
	type,
}: {
	extension?: string;
	type?: string;
}) => {
	const iconProps = { color: "#262626", size: 42 };
	if (
		extension === "jpg" ||
		(type && ["image/jpg", "image/jpeg"].includes(type))
	)
		return <PiFileJpg {...iconProps} />;
	if (extension === "png" || "image/png" === type)
		return <PiFilePng {...iconProps} />;
	if (extension === "pdf" || "application/pdf" === type)
		return <PiFilePdf {...iconProps} />;
	return <PiFile {...iconProps} />;
};

export const DocumentUploadItem = ({
	load = 0,
	className,
	error,
	extension,
	...props
}: {
	className?: string;
	error?: string;
	existing?: boolean;
	file?: File;
	fileName?: string;
	fileSize?: number;
	hideBar?: boolean;
	extension?: string;
	overrideErrorMessage?: string;
	load?: number;
	showFileImage?: boolean;
	onDelete: () => void;
}) => {
	const [showActive, setShowActive] = useState(false);
	return (
		<div
			className={styles.container}
			data-error={!!error}
			data-active={showActive}
		>
			<FileIcon extension={extension} type={props.file?.type} />
			<div className={styles.inner}>
				<div
					className={twMerge("flex flex-row", "items-start", "justify-between")}
				>
					<div>
						<p className={styles.fileName}>
							{props.file ? props.file.name : props.fileName ?? ""}
						</p>
						<p className={styles.description} data-error={!!error}>
							{error
								? props.overrideErrorMessage ?? error
								: formatFileSize(
										props.fileSize ?? (props.file ? props.file.size : 0),
									)}
						</p>
					</div>
					<button
						onMouseEnter={() => setShowActive(true)}
						onMouseLeave={() => setShowActive(false)}
						type="button"
						aria-label="Delete"
						onClick={props.onDelete}
					>
						<FiX size={24} color="#262626" />
					</button>
				</div>
				{!error && (
					<div className={"flex flex-row items-center gap-x-2"}>
						{!props.hideBar && (
							<>
								<ProgressBar
									color="#56a7a2"
									className={twMerge(
										"w-full",
										"document-upload-file-progress h-2",
									)}
									value={load}
									showValue={false}
								/>
								<Typography
									theme="textSm"
									className={"min-w-10 text-right leading-4 text-gray-650"}
								>
									{load}%
								</Typography>
							</>
						)}
					</div>
				)}
			</div>
		</div>
	);
};
