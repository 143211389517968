import styles from "./progress-circle.module.css";

export const ProgressCirlce = ({ value = 0 }: { value: number }) => {
	return (
		<svg
			className={styles.circularProgress}
			style={{ "--progress": value }}
			role="presentation"
			width="50"
			height="50"
			viewBox="0 0 50 50"
		>
			<circle className={styles.bg} />
			<circle className={styles.fg} />
		</svg>
	);
};
