import type { GroupedArray } from "@app/entities";

export const arrayGroupBy = <T, K>(values: T[] | undefined, key: keyof K) => {
	return values?.reduce(
		(result, item) => ({
			...result,
			[item[key.toString() as keyof unknown]]: [
				...(result[item[key.toString() as keyof unknown]] || []),
				item,
			],
		}),
		{} as GroupedArray<T>,
	);
};
