import { type ReactNode, memo, useEffect } from "react";

export const ScrollRestore = memo(({ children }: { children: ReactNode }) => {
	useEffect(() => {
		try {
			window.scrollTo({
				top: 0,
				behavior: "instant" as ScrollBehavior,
			});
		} catch {
			window.scrollTo(0, 0);
		}
	}, []);

	return <>{children}</>;
});
