import { joinClasses } from "@app/utils";
import React, { type ComponentPropsWithoutRef, type ReactNode } from "react";

import styles from "./index.module.css";

export const Subheading = ({
	children,
	className,
	...rest
}: { children: ReactNode } & ComponentPropsWithoutRef<"div">) => {
	return (
		<div className={joinClasses(styles.container, className)}>
			<div className={styles.line} />
			<h3 className={styles.heading} {...rest}>
				{children}
			</h3>
		</div>
	);
};
