import { Button } from "@app/components/button";
import { Dialog } from "@app/components/dialog";
import { useState } from "react";

import { ApiErrors } from "../api-errors";
import styles from "./index.module.css";
import { NotificationList } from "./notifcation-list";
import { NotificationsEmpty } from "./notifications-empty";
import { useNotifications } from "./use-notifications";

export const Notifications = () => {
	const [showDialog, setShowDialog] = useState(false);
	const {
		notifications,
		hasUnreadNotifications,
		isLoading,
		error,
		hasNotifications,
	} = useNotifications();

	return (
		<>
			<button
				type="button"
				className={styles.button}
				disabled={isLoading}
				aria-label="Notifications"
				onClick={() => setShowDialog(true)}
			>
				{hasUnreadNotifications && <div className={styles.indicator} />}
				<svg
					role="presentation"
					xmlns="http://www.w3.org/2000/svg"
					width="24"
					height="25"
					viewBox="0 0 24 25"
					fill="none"
				>
					<path
						d="M13.73 21.5C13.5542 21.8031 13.3018 22.0547 12.9982 22.2295C12.6946 22.4044 12.3504 22.4965 12 22.4965C11.6496 22.4965 11.3054 22.4044 11.0018 22.2295C10.6982 22.0547 10.4458 21.8031 10.27 21.5M18 8.5C18 6.9087 17.3679 5.38258 16.2426 4.25736C15.1174 3.13214 13.5913 2.5 12 2.5C10.4087 2.5 8.88258 3.13214 7.75736 4.25736C6.63214 5.38258 6 6.9087 6 8.5C6 15.5 3 17.5 3 17.5H21C21 17.5 18 15.5 18 8.5Z"
						stroke="#D1D1D1"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			</button>
			<Dialog
				fullscreen
				showTopbar
				className={styles.dialog}
				isOpen={showDialog}
				onClose={() => setShowDialog(false)}
				onBack={() => setShowDialog(false)}
				title={`Notifications ${
					hasNotifications ? `(${notifications.length})` : ""
				}`}
				actions={
					<Button block onClick={() => setShowDialog(false)}>
						Back
					</Button>
				}
			>
				{error ? (
					<ApiErrors errors={["Failed to load notifications"]} />
				) : hasNotifications ? (
					<NotificationList />
				) : (
					<NotificationsEmpty />
				)}
			</Dialog>
		</>
	);
};
