import useSWR from "swr";

export interface PaymentApn {
	reference_number: string;
	issue_date: string;
	is_editable: boolean;
}

export const usePaymentApn = (paymentId?: number) => {
	return useSWR<PaymentApn>(
		paymentId ? `/payments/${paymentId}/apn-details/` : null,
	);
};
