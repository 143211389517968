import { fetcher } from "@app/fetcher";
import { useClients } from "@app/hooks/use-clients";
import useSWR, { preload } from "swr";

export type OnboardingStepStatus = "Completed" | "Current" | "To do";
export type OnboardingStatus =
	| "onboarding_individual"
	| "onboarding_business"
	| "processing_submission"
	| "awaiting_shareholder_information"
	| "processing_shareholder_information"
	| "awaiting_e_signature"
	| "sending_documents_to_bank"
	| "awaiting_bank_account_opening"
	| "awaiting_account_opening_confirmation"
	| "complete";

type ClientOnboardingStatus = {
	status: OnboardingStatus;
	subtext: Array<string>;
	need_help: Array<string>;
	steps: Array<{
		status: OnboardingStepStatus;
		label: string;
	}>;
};

export const preloadClientOnboardingStatus = (clientId: number | string) =>
	preload<ClientOnboardingStatus>(`/onboarding/${clientId}/status/`, fetcher);

export const useClientOnboardingStatus = () => {
	const { activeClientId } = useClients();
	return useSWR<ClientOnboardingStatus>(
		activeClientId ? `/onboarding/${activeClientId}/status/` : null,
	);
};
