import { api } from "@app/services";
import { EntityType } from "@app/types";
import { getApiError } from "@app/utils/get-api-error";
import { getFormErrors } from "@app/utils/get-form-errors";
import { useState } from "react";

export const useRegister = () => {
	const [email, setEmail] = useState("");
	return {
		email,
		onRegister: async (data: {
			email: string;
			password: string;
			entity_type: EntityType;
		}) => {
			try {
				await api.post("/users/register/", data);
				setEmail(data.email);
				window.localStorage.setItem("register-email", data.email);
			} catch (error) {
				return getFormErrors(error);
			}
		},
		onResendEmail: async () => {
			try {
				await api.post(`/users/resend-confirmation-email/${email}/`);
			} catch (error) {
				return getApiError(error);
			}
		},
	};
};
