import { clsx } from "clsx";
import { ComponentPropsWithoutRef, ReactNode } from "react";

import styles from "./index.module.css";

export const Row = ({
	children,
	className,
	...rest
}: ComponentPropsWithoutRef<"div">) => {
	return (
		<div className={clsx(styles.row, className)} {...rest}>
			{children}
		</div>
	);
};
