import { useEffect } from "react";

import { useMediaQuery } from "@app/hooks/use-media-query";
import { useStateWithCallback } from "@app/hooks/use-state-with-callback";

import { PaginatorView } from "./paginator-view";
import type { Properties, ViewProperties } from "./properties";

const LOAD_MORE_COUNT = 10;

interface PaginatorState {
	blockSize: number;
	currentPage: number;
	totalRecords: number;
	numberPerPage: number;
	mobileNumberPerPage: number;
}

export const Paginator = (props: Properties) => {
	const isMobile = useMediaQuery();
	const [state, setState] = useStateWithCallback<PaginatorState>({
		blockSize: 3,
		currentPage: props.currentPage || 1,
		totalRecords: props.totalRecords,
		numberPerPage: props.numberPerPage ?? 10,
		mobileNumberPerPage: props.loadMoreCount ?? LOAD_MORE_COUNT,
	});

	const totalPages = Math.ceil(props.totalRecords / state.numberPerPage);

	const handleLoadMore = () => {
		setState(
			{
				...state,
				mobileNumberPerPage: Math.min(
					props.totalRecords,
					state.mobileNumberPerPage + (props.loadMoreCount ?? LOAD_MORE_COUNT),
				),
				currentPage: Math.max(1, Math.min(totalPages, state.currentPage + 1)),
			},
			(values) => {
				props.onNavigatePage(0, values.mobileNumberPerPage);
			},
		);
	};

	const onNavigatePage = (page: number, preventPropagation?: boolean) => {
		const viablePage = Math.max(1, Math.min(totalPages, page));

		setState(
			{
				...state,
				currentPage: viablePage,
				mobileNumberPerPage: Math.min(
					state.totalRecords,
					viablePage * state.numberPerPage,
				),
			},
			(values) => {
				if (!preventPropagation) {
					props.onNavigatePage(values.currentPage, values.numberPerPage);
				}
			},
		);
	};

	const onChangePageSize = (newPageSize: number) => {
		const newState = {
			...state,
			numberPerPage: newPageSize,
			currentPage: 1,
			mobileNumberPerPage: Math.min(state.totalRecords, newPageSize),
		};

		setState(newState);

		props.onNavigatePage(newState.currentPage, newPageSize);
	};

	const onNavigateNextPage = () => {
		onNavigatePage(state.currentPage + 1);
	};

	const onNavigatePreviousPage = () => {
		onNavigatePage(state.currentPage - 1);
	};

	useEffect(() => {
		if (props.currentPage && props.currentPage !== state.currentPage) {
			setState({ ...state, currentPage: props.currentPage }, (values) => {
				props.onNavigatePage(values.currentPage, values.numberPerPage);
			});
		}
	}, [props.currentPage]);

	useEffect(() => {
		if (
			props.numberPerPage !== undefined &&
			props.numberPerPage !== state.numberPerPage
		) {
			onChangePageSize(props.numberPerPage);
		}
	}, [props.numberPerPage, state.numberPerPage]);

	const viewProps: ViewProperties = {
		...props,
		totalPages: totalPages,
		currentPage: state.currentPage,
		safeBlockSize: state.blockSize,
		pageSize: state.numberPerPage,
		mobileNumberPerPage: state.mobileNumberPerPage,
		onNavigatePage: onNavigatePage,
		onNavigateNextPage: onNavigateNextPage,
		onNavigatePreviousPage: onNavigatePreviousPage,
		onChangePageSize: onChangePageSize,
		onLoadMore: handleLoadMore,
	};

	return <PaginatorView {...viewProps} isMobile={isMobile} />;
};
