import { api } from "@app/services";
import { getFormErrors } from "@app/utils/get-form-errors";
import useSWR from "swr";
import { RelatedEntityType } from "./use-related-entities";

export type Entity = {
	contact_number: string;
	country_of_birth: string;
	email_address: string;
	entity_type: RelatedEntityType;
	first_name: string;
	id_document: string | null;
	id_selfie: string | null;
	last_name: string;
	middle_names: string;
	nationality: string;
	residential_address: {
		address_1: string;
		address_2: string;
		city: string;
		province: string;
		country: string;
		postal_code: string;
		other_province: string;
	};
	entity_name?: string;
	registration_number?: string;
	registration_document?: string;
};

export type EntityUpdate = {
	entity_type: RelatedEntityType;
	contact_number: string;
	country_of_birth: string;
	email_address: string;
	first_name: string;
	id_document: string | null;
	id_selfie: string | null;
	last_name: string;
	middle_names: string;
	nationality: string;
	residential_address: {
		address_1: string;
		address_2: string;
		city: string;
		province: string | null;
		other_province: string;
		postal_code: string;
		country: string;
	};
	entity_name?: string;
	registration_number?: string;
	registration_document?: string;
};

const mapFieldNames = (key: string, parts: Array<string>) => {
	if (
		parts.length > 2 &&
		["residential", "residential_address"].includes(parts[parts.length - 2])
	) {
		return `residential_address.${key}`;
	}

	return key;
};

export const getRelatedEntity = async (id: number) =>
	api.get<Entity>(`/onboarding/related-entity/${id}/`);

export const getRelatedEntityLink = async (id: number) =>
	api.get<{
		designation: string;
		id_selfie_required: boolean;
		related_entity_id: number;
		relation: string;
		shareholding_percent: number;
	}>(`/onboarding/related-entity-link/${id}/`);

export const useEntity = (entityId?: number, linkId?: number) => {
	const { data, error, isLoading } = useSWR<Entity>(
		entityId ? `/onboarding/related-entity/${entityId}/` : null,
	);

	return {
		data,
		error,
		isLoading,
		update: async (newData: Partial<EntityUpdate>) => {
			try {
				await api.patch(`/onboarding/related-entity/${entityId}/`, newData);
			} catch (error) {
				return getFormErrors(error, mapFieldNames);
			}
		},
		updateLink: async (
			data: Partial<{
				shareholding_percent: number | null;
				designation: string;
			}>,
		) => {
			try {
				await api.patch(`/onboarding/related-entity-link/${linkId}/`, data);
			} catch (error) {
				return getFormErrors(error, mapFieldNames);
			}
		},
		submitLink: async (
			data: Partial<{
				shareholding_percent: number;
				designation: string;
			}>,
		) => {
			try {
				await api.put(`/onboarding/related-entity-link/${linkId}/`, data);
			} catch (error) {
				return getFormErrors(error, mapFieldNames);
			}
		},
		submit: async (newData: EntityUpdate) => {
			try {
				await api.put(`/onboarding/related-entity/${entityId}/`, newData);
			} catch (error) {
				return getFormErrors(error, mapFieldNames);
			}
		},
	};
};
