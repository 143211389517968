import useSWR from "swr";

type CompletedTransactionQuery = {
	ordering: string;
	country: string;
	start_date: string;
	end_date: string;
	direction: string;
	currencies: string;
	recipients: string;
	search: string;
	limit: number;
	offset: number;
};

export type CompletedTransaction = {
	transaction_id: number;
	fx_amount: string;
	zar_amount: string;
	currency_code: string;
	direction: string;
	payment_ids: number[];
	transaction_date: string;
	recipient_name: string;
};

export const tempCompletedTransactionMapping = (
	value: CompletedTransaction,
) => {
	return {
		id: value.transaction_id,
		date: value.transaction_date,
		zarAmount: value.zar_amount,
		fxAmount: value.fx_amount,
		fxCurrency: value.currency_code,
		paymentType: value.direction,
		recipient: value.recipient_name,
		paymentIds: value.payment_ids,
	};
};

export const useCompletedTransactions = (
	clientId?: number,
	query?: Partial<CompletedTransactionQuery>,
) =>
	useSWR<{
		items: CompletedTransaction[];
		count: number;
	}>(
		clientId
			? [`/clients/${clientId}/complete-transactions/`, { params: query }]
			: null,
	);
