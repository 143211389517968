const PROD_HOST_NAME = "account.futureforex.co.za";
const DEMO_HOST_NAME = "demo-account.futureforex.co.za";
const DEV_HOST_NAME = "test-account.futureforex.co.za";

export const isLocalhost = window.location.hostname === "localhost";
export const isProd = window.location.hostname === PROD_HOST_NAME;
export const isDemo = window.location.hostname === DEMO_HOST_NAME;
export const isDev = window.location.hostname === DEV_HOST_NAME;
export const isDevServer = import.meta.env.DEV;

export const apiUrl = isProd
	? "https://main.futureforex.co.za/"
	: isDemo
		? "https://demo.futureforex.co.za/"
		: "https://test.futureforex.co.za/";
export const wsUrl = isProd
	? "wss://main.futureforex.co.za/"
	: isDemo
		? "wss://demo.futureforex.co.za/"
		: "wss://test.futureforex.co.za/";
