import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

import type { ViewProperties } from "./properties";

import styles from "./index.module.css";

export const SelectButtonView = <T,>(props: ViewProperties<T>) => {
	const getContent = () => {
		const optionsContent = getOptionsContent();

		if (props.optionsWrappingTemplate) {
			return (
				<div className={props.className}>
					{props.optionsWrappingTemplate(optionsContent)}
				</div>
			);
		}

		return (
			<div className={twMerge("flex flex-row gap-2", props.className)}>
				{getOptionsContent()}
			</div>
		);
	};

	const getOptionsContent = (): ReactNode[] => {
		return props.options
			? props.options?.map((x, i) => (
					<button
						type="button"
						className={twMerge(
							styles.button,
							"w-full rounded-4px",
							props.selectedIndices &&
								props.selectedIndices?.findIndex((y) => y === x) > -1 &&
								twMerge("border-teal-550", props.selectedClassName),
							props.optionClassName?.(x, i),
						)}
						disabled={props.disabled?.(x)}
						key={`select-button-${props.name}-${i}`}
						onClick={() => props.onChanged?.(x)}
					>
						{props.optionTemplate?.(x, i)}
					</button>
				))
			: [];
	};

	return <>{getContent()}</>;
};
