import { fetcher } from "@app/fetcher";
import { useClients } from "@app/hooks/use-clients";
import useSWR, { preload } from "swr";

export type InitialInformation = {
	type: string;
	first_name: string;
	last_name: string;
	name: string;
	contact_person_first_name: string;
	contact_person_last_name: string;
	contact_number: string;
};

export const preloadOnboardingInitialInformation = async (
	activeClientId: number,
) => preload(`/onboarding/${activeClientId}/initial-information/`, fetcher);

export const useOnboardingInitialInformation = () => {
	const { activeClientId } = useClients();
	return useSWR<InitialInformation>(
		activeClientId
			? `/onboarding/${activeClientId}/initial-information/`
			: null,
		{
			keepPreviousData: true,
		},
	);
};
