import { Button } from "@app/components/button";
import { Dialog } from "@app/components/dialog";
import { useAuth } from "@app/hooks/use-auth";

import { ApiErrors } from "@app/components/api-errors";
import { COUNTRY_CODE_OPTIONS } from "@app/components/country-option/country-code-options";
import { Label } from "@app/components/label";
import { preloadOnboardingInitialInformation } from "@app/components/signed-in-layout/use-onboarding-initial-information";
import { Clients, useClients } from "@app/hooks/use-clients";
import { useMediaQuery } from "@app/hooks/use-media-query";
import { api } from "@app/services";
import { EntityType } from "@app/types";
import { FormErrors } from "@app/utils/get-form-errors";
import { clsx } from "clsx";
import { ReactNode, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { ContactNumberField } from "../contact-number-field";
import { Row } from "../row";
import { TextField } from "../text-field";
import styles from "./index.module.css";
import { useInitialInformation } from "./use-initial-information";

type Inputs = {
	// Individual
	first_name: string;
	last_name: string;
	// Legal entity
	name: string;
	contact_person_first_name: string;
	contact_person_last_name: string;

	// Shared
	contact_number: string;
};

export const InboundModal = ({
	onComplete,
	variant = "individual",
}: {
	onComplete: (data: Inputs) => void;
	variant?: EntityType;
}) => {
	const { activeClientId } = useClients();
	const { submit } = useInitialInformation();
	const [isSubmitting, setSubmitting] = useState(false);
	const isMobile = useMediaQuery();
	const [apiErrors, setApiErrors] = useState<ReactNode[]>([]);
	const { onLogout } = useAuth();

	const methods = useForm<Inputs>({
		defaultValues: {
			contact_number: COUNTRY_CODE_OPTIONS[0].code,
		},
	});

	const isIndividual = variant === "individual";

	const {
		register,
		handleSubmit,
		setError,
		clearErrors,
		formState: { errors },
	} = methods;

	const clearApiFieldErrors = () => {
		for (const key of Object.keys(errors)) {
			if (errors[key as keyof Inputs]?.type === "api")
				clearErrors(key as keyof Inputs);
		}
	};

	const validFields = isIndividual
		? ["first_name", "last_name", "contact_number"]
		: [
				"name",
				"contact_person_first_name",
				"contact_person_last_name",
				"contact_number",
			];

	const handleSetErrors = (errors: FormErrors) => {
		for (const current of errors.fieldErrors) {
			if (!validFields.includes(current.name)) continue;
			setError(current.name as keyof Inputs, {
				type: "api",
				message: current.message,
			});
		}
		setApiErrors(errors.apiErrors);
	};

	const onSubmit: SubmitHandler<Inputs> = async (data) => {
		clearApiFieldErrors();
		setSubmitting(true);
		const errors = await submit(data);
		if (errors) {
			handleSetErrors(errors);
		} else {
			if (activeClientId) {
				await preloadOnboardingInitialInformation(activeClientId);
			}
			onComplete(data);
		}
		setSubmitting(false);
	};

	const clearApiFieldError = (field: keyof Inputs) => {
		if (errors[field]?.type === "api") clearErrors(field);
	};

	return (
		<Dialog
			fullscreen={isMobile}
			showTopbar={isMobile}
			isOpen
			onBack={isMobile ? onLogout : undefined}
			size="md"
			title="Get started"
			classNameDescription={styles.description}
			description="Provide your contact details so that we know how to get in touch."
			actions={
				<>
					{!isMobile && (
						<Button variant="secondary" onClick={onLogout}>
							Logout
						</Button>
					)}
					<Button type="submit" form="inbound" disabled={isSubmitting}>
						Continue
					</Button>
				</>
			}
		>
			<FormProvider {...methods}>
				<form id="inbound" onSubmit={handleSubmit(onSubmit)}>
					{!isIndividual && (
						<>
							<Row className={styles.row}>
								<TextField
									label="Company name*"
									error={errors.name}
									placeholder="Enter your company name"
									{...register("name", {
										required: "This field is required",
										onBlur: () => clearApiFieldError("name"),
									})}
								/>
							</Row>
							<p className={styles.subtitle}>Contact Person</p>
						</>
					)}
					<Label htmlFor="firstname">Full name*</Label>
					<Row className={clsx(styles.row, styles.nameRow)}>
						<TextField
							autoComplete="given-name"
							error={
								isIndividual
									? errors.first_name
									: errors.contact_person_first_name
							}
							placeholder={
								isIndividual
									? "Enter your first name"
									: "Enter their first name"
							}
							{...register(
								isIndividual ? "first_name" : "contact_person_first_name",
								{
									required: "This field is required",
									onBlur: () =>
										clearApiFieldError(
											isIndividual ? "first_name" : "contact_person_first_name",
										),
								},
							)}
						/>

						<TextField
							autoComplete="family-name"
							error={
								isIndividual
									? errors.last_name
									: errors.contact_person_last_name
							}
							placeholder={
								isIndividual ? "Enter your last name" : "Enter their last name"
							}
							{...register(
								isIndividual ? "last_name" : "contact_person_last_name",
								{
									required: "This field is required",
									onBlur: () =>
										clearApiFieldError(
											isIndividual ? "last_name" : "contact_person_last_name",
										),
								},
							)}
						/>
					</Row>
					<Row>
						<ContactNumberField
							error={errors.contact_number}
							placeholder={!isIndividual ? "Enter their number" : undefined}
							onBlur={() => clearApiFieldError("contact_number")}
						/>
					</Row>
				</form>
			</FormProvider>
			<ApiErrors errors={apiErrors} />
		</Dialog>
	);
};
