import type {
	CreateWithdrawal,
	CreateWithdrawalBankAccount,
	UpdateWithdrawalBankAccount,
	WithdrawOptions,
	WithdrawalBankAccount,
	WithdrawalBankAccounts,
} from "@app/entities";
import { concatenateArrays, getMappedReasons } from "@app/utils";

import { getReasons } from "@app/utils/get-reasons";
import { api } from "./api";
import type { GenericFailureResponse, GenericResponse } from "./models";
import type {
	CreateWithdrawalBankAccountRequest,
	CreateWithdrawalRequest,
	GetWithdrawalBankAccountResponse,
	UpdateWithdrawalBankAccountRequest,
	WithdrawOptionsResponse,
} from "./models/withdrawals";

export async function getWithdrawalBankAccounts(
	clientId: number,
): Promise<WithdrawalBankAccounts | GenericResponse> {
	try {
		const { data } = await api.get(
			`clients/${clientId}/withdrawal-bank-accounts/`,
		);

		return {
			items: data.map((x: GetWithdrawalBankAccountResponse) =>
				mapToGetWithdrawalBankAccount(x),
			),
		};
	} catch (exception: any) {
		const error = exception.data;
		const reasons = concatenateArrays([(error.detail as string) ?? ""]);

		return {
			reasons,
		};
	}
}

export async function getWithdrawalBankAccount(
	bankAccountId: number,
): Promise<WithdrawalBankAccount | GenericResponse> {
	try {
		const { data } = await api.get(
			`withdrawal-bank-accounts/${bankAccountId}/`,
		);
		return mapToGetWithdrawalBankAccount(data);
	} catch (exception: any) {
		const error = exception.data;
		const reasons = concatenateArrays([(error.detail as string) ?? ""]);

		return {
			reasons,
		};
	}
}

export async function getWithdrawOptions(): Promise<
	WithdrawOptions | GenericResponse
> {
	try {
		const { data } = await api.get("withdrawal-bank-accounts/options/");
		return mapToWithdrawOptions(data);
	} catch (exception: any) {
		const error = exception.data;
		const reasons = concatenateArrays([(error.detail as string) ?? ""]);

		return {
			reasons,
		};
	}
}

export async function createWithdrawalBankAccount(
	clientId: number,
	withdrawalBankAccount: CreateWithdrawalBankAccount,
) {
	try {
		const { status } = await api.post(
			`clients/${clientId}/withdrawal-bank-accounts/`,
			mapToCreateWithdrawalBankAccountRequest(withdrawalBankAccount),
		);

		return status;
	} catch (exception: any) {
		const error = exception.data as GenericFailureResponse;
		const reasons = getReasons(exception.data);
		const mappedReasons = getMappedReasons(error);

		return {
			reasons,
			mappedReasons,
		};
	}
}

export async function createWithdrawalRequest(withdrawal: CreateWithdrawal) {
	try {
		const { status } = await api.post(
			"withdrawals/request/",
			mapToCreateWithdrawalRequest(withdrawal),
		);

		return status;
	} catch (exception: any) {
		const error = exception.data;
		const reasons = concatenateArrays([(error.detail as string) ?? ""]);

		return {
			reasons,
		};
	}
}

export async function deleteWithdrawalBankAccount(bankAccountId: number) {
	try {
		const { status } = await api.delete(
			`withdrawal-bank-accounts/${bankAccountId}/`,
		);

		return status;
	} catch (exception: any) {
		const error = exception.data;
		const reasons = concatenateArrays([(error.detail as string) ?? ""]);

		return {
			reasons,
		};
	}
}

export async function updateWithdrawalBankAccount(
	withdrawalBankAccount: UpdateWithdrawalBankAccount,
) {
	try {
		const { status } = await api.put(
			`withdrawal-bank-accounts/${withdrawalBankAccount.bankAccountId}/`,
			mapToUpdateWithdrawalBankAccountRequest(withdrawalBankAccount),
		);
		return status;
	} catch (exception: any) {
		const error = exception.data as GenericFailureResponse;
		const reasons = getReasons(exception.data);
		const mappedReasons = getMappedReasons(error);

		return {
			reasons,
			mappedReasons,
		};
	}
}

function mapToGetWithdrawalBankAccount(
	value: GetWithdrawalBankAccountResponse,
): WithdrawalBankAccount {
	return {
		id: value.id,
		bank: value.bank,
		accountNumber: value.account_number,
		accountType: value.account_type,
	};
}

function mapToWithdrawOptions(value: WithdrawOptionsResponse): WithdrawOptions {
	return {
		withdrawalTypes: value.withdrawal_types,
	};
}

function mapToCreateWithdrawalBankAccountRequest(
	value: CreateWithdrawalBankAccount,
): CreateWithdrawalBankAccountRequest {
	return {
		bank_id: value.bankId,
		account_number: value.accountNumber,
		account_type: value.accountType,
		client_id: value.clientId,
	};
}

function mapToCreateWithdrawalRequest(
	value: CreateWithdrawal,
): CreateWithdrawalRequest {
	return {
		client_id: value.clientId,
		bank_id: value.bankId,
		amount: value.amount,
		withdrawal_type: value.withdrawalType,
	};
}

function mapToUpdateWithdrawalBankAccountRequest(
	value: UpdateWithdrawalBankAccount,
): UpdateWithdrawalBankAccountRequest {
	return {
		bank_id: value.bankId,
		account_number: value.accountNumber,
		account_type: value.accountType,
	};
}
