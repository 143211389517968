import background from "@app/assets/images/background.jpg";
import { joinClasses } from "@app/utils";

export const Graphic = () => {
	return (
		<div
			className={joinClasses(
				"flex",
				"flex-col",
				"grow",
				"lg:overflow-y-auto",
				"w-full",
				"h-full",
				"justify-center",
				"hidden",
				"lg:flex",
				"lg:h-full",
			)}
		>
			<div
				className={joinClasses(
					"flex",
					"flex-col",
					"bg-cover",
					"bg-center",
					"justify-start",
					"items-center",
					"h-full",
					"px-6",
					"mobile:px-0",
					"bg-gray-850",
				)}
				style={{ backgroundImage: `url(${background})` }}
			/>
		</div>
	);
};
