import useSWR from "swr";

type InProgressQuery = {
	ordering: string;
	country: string;
	start_date: string;
	end_date: string;
	direction: string;
	currencies: string;
	recipients: string;
	search: string;
};

export type InProgressTransaction = {
	transaction_id: number;
	fx_amount: string;
	zar_amount: string;
	currency_code: string;
	direction: string;
	payment_ids: number[];
	transaction_date: string;
	recipient_name: string;
	status: {
		id: number;
		description: string;
		stage: number;
		add_information: boolean;
	};
	progress_status: {
		id: number;
		description: string;
		stage: number;
		add_information: boolean;
	};
	zar_amount_tooltip: string;
};

export const tempInProgressTransactionMapping = (
	value: InProgressTransaction,
) => {
	return {
		id: value.transaction_id,
		date: value.transaction_date,
		zarAmount: value.zar_amount,
		fxAmount: value.fx_amount,
		fxCurrency: value.currency_code,
		paymentType: value.direction,
		recipient: value.recipient_name,
		paymentIds: value.payment_ids,
		zarAmountTooltip: value.zar_amount_tooltip,
		status: {
			addInformation: value.progress_status?.add_information,
			...value.progress_status,
		},
	};
};

export const useInProgressTransactions = (
	clientId?: number,
	query?: Partial<InProgressQuery>,
) =>
	useSWR<InProgressTransaction[]>(
		clientId
			? [`/clients/${clientId}/in-progress-transactions/`, { params: query }]
			: null,
	);
