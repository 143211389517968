import styles from "./entity-layout.module.css";

import { ReactNode } from "react";
import addSrc from "./add.svg";
import trashSrc from "./trash.svg";

export const EntityLayout = ({
	id,
	index,
	hasName,
	displayName,
	onRemove,
	isRemoving,
	children,
	footer,
}: {
	id: number;
	index: number;
	hasName: boolean;
	displayName: string;
	onRemove?: () => void;
	isRemoving?: boolean;
	children: ReactNode;
	footer?: ReactNode;
}) => {
	return (
		<>
			<header id={`entity-${id}`} className={styles.header}>
				<div className={styles.headerInner}>
					<div className={styles.iconContainer}>
						{hasName ? (
							index + 1
						) : (
							<img src={addSrc} width={18} height={18} alt="" />
						)}
					</div>
					<h2>{displayName}</h2>
				</div>
				{onRemove && (
					<button
						type="button"
						className={styles.removeButton}
						onClick={onRemove}
					>
						{isRemoving ? "Removing" : "Remove"}
						<img src={trashSrc} alt="" width={20} height={20} />
					</button>
				)}
			</header>
			<div className={styles.content}>
				<div className={styles.lineContainer}>
					<div className={styles.line} />
				</div>
				{children}
			</div>
			{footer && (
				<div className={styles.content}>
					<div className={styles.lineContainer} />
					<div className={styles.cards}>{footer}</div>
				</div>
			)}
		</>
	);
};
