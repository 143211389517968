import useSWR from "swr";

export type RegistrationReferrer = {
	id: number;
	name: string;
	requires_detail: boolean;
};

export const useRegistrationReferrers = () =>
	useSWR<Array<RegistrationReferrer>>("/onboarding/registration-referrers/");
