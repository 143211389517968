export const getLastSixMonths = (): string[] => {
	const date = new Date();
	const lastSixMonths = [];

	for (let i = 0; i < 6; i++) {
		const monthYear = date.toLocaleString("default", {
			month: "long",
			year: "numeric",
		});
		lastSixMonths.push(monthYear);
		date.setMonth(date.getMonth() - 1);
	}

	return lastSixMonths;
};
