import { CircleFlag, type CircleFlagProps } from "react-circle-flags";

const currencyMapping = [
	{
		currencyCode: "USD",
		countryCode: "US",
		verboseName: "US Dollar",
	},
	{
		currencyCode: "EUR",
		countryCode: "EU",
		verboseName: "Euro",
	},
	{
		currencyCode: "GBP",
		countryCode: "GB",
		verboseName: "British Pound Sterling",
	},
	{
		currencyCode: "AUD",
		countryCode: "AU",
		verboseName: "Australian Dollar",
	},
	{
		currencyCode: "CNY",
		countryCode: "CN",
		verboseName: "Chinese Yuan",
	},
	{
		currencyCode: "CHF",
		countryCode: "CH",
		verboseName: "Swiss Franc",
	},
	{
		currencyCode: "JPY",
		countryCode: "JP",
		verboseName: "Japanese Yen",
	},
	{
		currencyCode: "CAD",
		countryCode: "CA",
		verboseName: "Canadian Dollar",
	},
	{
		currencyCode: "NZD",
		countryCode: "NZ",
		verboseName: "New Zealand Dollar",
	},
	{
		currencyCode: "HKD",
		countryCode: "HK",
		verboseName: "Hong Kong Dollar",
	},
	{
		currencyCode: "DKK",
		countryCode: "DK",
		verboseName: "Danish Krone",
	},
	{
		currencyCode: "BWP",
		countryCode: "BW",
		verboseName: "Botswanan Pula",
	},
	{
		currencyCode: "SGD",
		countryCode: "SG",
		verboseName: "Singapore Dollar",
	},
	{
		currencyCode: "NOK",
		countryCode: "NO",
		verboseName: "Norwegian Krone",
	},
	{
		currencyCode: "SEK",
		countryCode: "SE",
		verboseName: "Swedish Krona",
	},
	{
		currencyCode: "NAD",
		countryCode: "NA",
		verboseName: "Namibian Dollar",
	},
	{
		currencyCode: "LSL",
		countryCode: "LS",
		verboseName: "Lesotho Loti",
	},
	{
		currencyCode: "SZL",
		countryCode: "SZ",
		verboseName: "Swazi Lilangeni",
	},
	{
		currencyCode: "MUR",
		countryCode: "MU",
		verboseName: "Mauritian Rupee",
	},
	{
		currencyCode: "ZAR",
		countryCode: "ZA",
		verboseName: "South African Rand",
	},
];

export const CountryIcon = ({
	currencyCode,
	...rest
}: { currencyCode?: string } & Omit<CircleFlagProps, "countryCode">) => {
	if (!currencyCode) return null;
	const countryCode = currencyMapping.find(
		(current) =>
			current.currencyCode.toLowerCase() === currencyCode.toLowerCase(),
	)?.countryCode;
	if (!countryCode) return null;
	return (
		<CircleFlag
			{...rest}
			cdnUrl="/flags/"
			data-testid="country-icon"
			data-country={countryCode}
			countryCode={countryCode.toLowerCase()}
		/>
	);
};
