import { fetcher } from "@app/fetcher";
import { useClients } from "@app/hooks/use-clients";
import { api } from "@app/services";
import { getFormErrors } from "@app/utils/get-form-errors";
import useSWR, { preload } from "swr";

export type CompanyBasics = {
	name: string;
	registration_number: string;
	tax_number: string;
	vat_number: string;
	registration_document: string;
	contact_person_first_name: string;
	contact_person_last_name: string;
	email_address: string;
	contact_number: string;
	address: {
		same_addresses: boolean;
		registered_address: {
			address_1: string;
			address_2: string;
			city: string;
			province: string;
			country: string;
			postal_code: string;
			other_province: string;
		};
		trading_address: {
			proof_of_address: string;
			address_1: string;
			address_2: string;
			city: string;
			province: string;
			country: string;
			postal_code: string;
			other_province: string;
		} | null;
	};
};

export type CompanyBasicsUpdate = {
	name: string;
	registration_number: string;
	tax_number: string;
	vat_number: string;
	contact_person_first_name: string;
	contact_person_last_name: string;
	email_address: string;
	contact_number: string;
	address: {
		same_addresses: boolean;
		registered_address: {
			address_1: string;
			address_2: string;
			city: string;
			province: string | null;
			other_province: string;
			postal_code: string;
			country: string;
		};
		trading_address: {
			proof_of_address: string;
			address_1: string;
			address_2: string;
			city: string;
			province: string | null;
			other_province: string;
			postal_code: string;
			country: string;
		} | null;
	};
};

const mapFieldNames = (key: string, parts: Array<string>) => {
	const keys = ["registered_address", "trading_address"];
	const sectionIndex = parts.findIndex((part) => keys.includes(part));
	if (sectionIndex !== -1) {
		return `address.${parts[sectionIndex]}.${key}`;
	}
	return key;
};

export const preloadCompanyBasics = async (clientId: string | number) =>
	preload<CompanyBasics>(`/onboarding/${clientId}/company-basics/`, fetcher);

export const useCompanyBasics = () => {
	const { activeClientId } = useClients();
	const { data } = useSWR<CompanyBasics>(
		activeClientId ? `/onboarding/${activeClientId}/company-basics/` : null,
	);

	return {
		data,
		update: async (newData: Partial<CompanyBasicsUpdate>) => {
			try {
				await api.patch(
					`/onboarding/${activeClientId}/company-basics/`,
					newData,
				);
			} catch (error) {
				return getFormErrors(error, mapFieldNames);
			}
		},
		submit: async (newData: CompanyBasicsUpdate) => {
			try {
				await api.put(`/onboarding/${activeClientId}/company-basics/`, newData);
			} catch (error) {
				return getFormErrors(error, mapFieldNames);
			}
		},
	};
};
