import { Button } from "../button";
import { Dialog } from "../dialog";
import { useOnboardingInitialInformation } from "./use-onboarding-initial-information";

import { paths } from "@app/constants/paths";
import { useNavigate } from "react-router-dom";
import styles from "./outbound-modal.module.css";

export const OutboundModal = () => {
	const navigate = useNavigate();
	const { data } = useOnboardingInitialInformation();

	const isBusiness = data?.type === "Business";

	const name = isBusiness ? data?.name : data?.first_name;

	return (
		<Dialog
			isOpen
			size="md"
			title="Get started"
			classNameDescription={styles.description}
			description={
				<>
					Welcome <strong>{name}</strong> to your Future Forex account!
				</>
			}
			actions={
				<Button
					onClick={() => {
						if (isBusiness) {
							navigate(paths().onboarding.business.companyBasics);
						} else {
							navigate(paths().onboarding.individual.personalInformation);
						}
					}}
					centered
				>
					Continue
				</Button>
			}
		>
			<p className={styles.description}>
				Complete your onboarding by providing us with some further information.
			</p>
		</Dialog>
	);
};
