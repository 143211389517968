import { Button } from "@app/components/button";
import { useState } from "react";

import { MoreInfoTooltip } from "@app/components/more-info-tooltip";
import { useBalance } from "../use-balance";
import { AddFundsModal } from "./add-funds-modal";
import { DownloadStatementModal } from "./download-statement-modal";
import styles from "./index.module.css";
import { toDayjs } from "@app/lib/date";

export const BalanceCard = ({
	onWithdraw,
	backAccountId,
	isLoading = false,
}: {
	backAccountId?: number;
	onWithdraw?: () => void;
	isLoading?: boolean;
}) => {
	const balanceResult = useBalance(backAccountId);
	const [showAddFundsModal, setShowAddFundsModal] = useState(false);
	const [showStatementModal, setShowStatementModal] = useState(false);

	const balance = balanceResult.data?.balance;
	const balanceRands = balance?.split(".")[0];
	const balanceCents = balanceRands?.includes("-")
		? ""
		: balance?.includes(".")
			? balance?.split(".")[1]
			: "00";

	const isCardLoading = isLoading || balanceResult.isLoading;
	return (
		<>
			<div className={styles.container}>
				<div>
					<h2 className={styles.title}>Balance</h2>
					<p className={styles.rands}>
						{isCardLoading ? (
							"-"
						) : (
							<>
								{balanceRands ?? "0"}
								{balanceCents && (
									<span className={styles.cents}>.{balanceCents}</span>
								)}
							</>
						)}
					</p>
					{isCardLoading ? (
						<p className={styles.lastUpdated}>-</p>
					) : balanceResult.data?.last_updated_message ? (
						<p className={styles.lastUpdated}>
							{balanceResult.data?.last_updated_message}
							{balanceResult.data?.warning_message && (
								<MoreInfoTooltip
									variant="alert"
									name={
										balanceResult.data?.warning_message.includes("last updated")
											? "Last updated"
											: "Balance"
									}
								>
									<p className={styles.warningMessage}>
										{balanceResult.data?.warning_message}
									</p>
								</MoreInfoTooltip>
							)}
						</p>
					) : null}
				</div>
				<div>
					<div className={styles.buttons}>
						<Button
							className={styles.button}
							block
							disabled={isCardLoading}
							onClick={() => setShowAddFundsModal(true)}
						>
							Add funds
						</Button>
						<Button
							className={styles.button}
							block
							disabled={isCardLoading}
							variant="secondary"
							onClick={onWithdraw}
						>
							Withdraw ZAR
						</Button>
					</div>
					<Button
						block
						variant="secondary"
						disabled={isCardLoading}
						onClick={() => setShowStatementModal(true)}
					>
						<svg
							role="presentation"
							xmlns="http://www.w3.org/2000/svg"
							width="21"
							height="21"
							viewBox="0 0 21 21"
							fill="none"
						>
							<path
								d="M17.9984 12.5131L17.9981 13.5131C17.9976 14.9132 17.9974 15.6133 17.7247 16.148C17.4848 16.6183 17.1023 17.0006 16.6318 17.2401C16.0969 17.5124 15.3968 17.5122 13.9967 17.5117L6.99669 17.5092C5.59656 17.5087 4.8965 17.5085 4.36181 17.2358C3.89149 16.996 3.50918 16.6134 3.26966 16.1429C2.99736 15.608 2.99761 14.9079 2.9981 13.5078L2.99845 12.5078M14.6666 8.34524L10.4984 12.5104M10.4984 12.5104L6.33324 8.34231M10.4984 12.5104L10.502 2.51044"
								stroke="currentColor"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
						Download statement
					</Button>
				</div>
			</div>
			{showAddFundsModal && (
				<AddFundsModal
					bankAccountId={backAccountId}
					onClose={() => setShowAddFundsModal(false)}
				/>
			)}
			{showStatementModal && (
				<DownloadStatementModal onClose={() => setShowStatementModal(false)} />
			)}
		</>
	);
};
