import CheckCircle from "@app/assets/images/check-circle.svg";
import { Button } from "@app/components/button";
import { Dialog } from "@app/components/dialog";

import styles from "./index.module.css";

export const TransactionSubmittedModal = (props: {
	isOpen: boolean;
	onClose: () => void;
}) => {
	return (
		<Dialog
			size="lg"
			isOpen={props.isOpen}
			actions={
				<Button centered onClick={props.onClose} type="button">
					Close
				</Button>
			}
		>
			<div className={styles.content}>
				<img alt="" src={CheckCircle} width={80} />

				<h1 className={styles.title}>Transaction submitted</h1>

				<p className={styles.description}>
					{" "}
					You can track its status on the Transactions page.
				</p>
			</div>
		</Dialog>
	);
};
