import { type ReactNode, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { Button } from "@app/components/button";
import { FadeIn } from "@app/components/fade-in";
import { pathParams, paths } from "@app/constants/paths";
import { useResetPassword } from "@app/helpers/auth";
import type { RootState } from "@app/redux";
import type { MappedReasons } from "@app/services";

import { ApiErrors } from "@app/components/api-errors";
import { Title } from "@app/components/title";
import { useMediaQuery } from "@app/hooks/use-media-query";
import { LockIcon } from "../login/lock-icon";
import { PasswordToggleButton } from "../password-toggle-button";
import { TextField } from "../text-field";
import { ConfirmationModal } from "./confirmation-modal";
import styles from "./index.module.css";

const PasswordReset = () => {
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const { resetPasswordLoading } = useSelector(
		(state: RootState) => state.auth,
	);
	const isMobile = useMediaQuery();

	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm<{
		password: string;
		confirmPassword: string;
	}>({
		defaultValues: {},
		mode: "onChange",
	});

	const navigate = useNavigate();

	const [resetPassword] = useResetPassword();

	const params = useParams();
	const paramUid = params[pathParams.uid];
	const paramToken = params[pathParams.token];

	const [apiErrors, setApiErrors] = useState<ReactNode[]>([]);

	const [showConfirmationModal, setShowConfirmationModal] = useState(false);

	const onCancel = () => {
		navigate(paths().login);
	};

	const onClosePasswordConfirmationModal = () => navigate(paths().login);

	const onResetPassword = (formData: {
		password: string;
		confirmPassword: string;
	}) => {
		if (!paramUid || !paramToken) return;
		resetPassword(
			paramUid,
			paramToken,
			formData.confirmPassword,
			(errors?: string[], mappedReasons?: MappedReasons) => {
				if (!errors || (errors.length === 0 && !mappedReasons)) {
					setApiErrors([]);
					setShowConfirmationModal(true);
				} else {
					const apiErrors = [
						...errors,
						...Object.keys(mappedReasons || {})
							.filter((key) => mappedReasons?.[key])
							.flatMap((key) => mappedReasons![key]),
					];
					setApiErrors(apiErrors);
					setShowConfirmationModal(false);
				}
			},
		);
	};

	return (
		<form
			onSubmit={handleSubmit((data) => {
				onResetPassword(data);
			})}
			noValidate
		>
			<>
				<Title>Create new password</Title>
				<div className={styles.form}>
					<TextField
						register={register}
						name="password"
						label="Password"
						type={showPassword ? "text" : "password"}
						required
						validate={(value) => {
							if (value.length < 8) {
								return "Password must contain at least 8 characters";
							}
							const confirmPassword = watch("confirmPassword");
							if (confirmPassword && confirmPassword !== value) {
								return "Password doesn’t match";
							}

							if (!/[A-Z]/.test(value))
								return "Password must contain at least one upper-case character";

							if (!/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(value))
								return "Password must contain at least one special character";

							return true;
						}}
						leftSection={<LockIcon />}
						rightSection={
							<PasswordToggleButton
								value={showPassword}
								onChange={setShowPassword}
							/>
						}
						error={errors.password}
					/>
					<TextField
						register={register}
						name="confirmPassword"
						label="Confirm password"
						type={showConfirmPassword ? "text" : "password"}
						required
						leftSection={<LockIcon />}
						validate={(value) => {
							if (watch("password") !== value) {
								return "Password doesn’t match";
							}
							return true;
						}}
						rightSection={
							<PasswordToggleButton
								value={showConfirmPassword}
								onChange={setShowConfirmPassword}
							/>
						}
						error={errors.confirmPassword}
					/>
					<FadeIn show={apiErrors.length > 0}>
						<ApiErrors errors={apiErrors} />
					</FadeIn>
				</div>
				<div className={styles.buttons}>
					<Button disabled={resetPasswordLoading} type="submit">
						{resetPasswordLoading ? "Submitting" : "Submit"}
					</Button>
					<Button onClick={onCancel} variant="tertiary">
						Cancel
					</Button>
				</div>
			</>
			{showConfirmationModal && (
				<ConfirmationModal
					heading="Password changed"
					buttonText="Continue to login"
					onClose={onClosePasswordConfirmationModal}
					description={
						<>
							{isMobile
								? "Your password has been changed."
								: "Your password has been successfully changed."}
							<br />
							Please login to your account with your new password.
						</>
					}
				/>
			)}
		</form>
	);
};

export default PasswordReset;
