import { type ReactNode, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import type { FormBuilderProps } from "@app/components";
import { pathParams, paths } from "@app/constants/paths";
import type {
	CurrencyOption,
	RecipientForm,
	RecipientType,
	SwiftCodeCountry,
	UpdateRecipient,
} from "@app/entities";
import {
	useCreateRecipient,
	useShowToast,
	useUpdateRecipient,
} from "@app/helpers";
import { useMediaQuery } from "@app/hooks/use-media-query";
import type { RootState } from "@app/redux";
import type { MappedReasons } from "@app/services";

import { AddEditRecipientsViewResponsive } from "./add-edit-recipients-responsive";
import { AddEditRecipientsView } from "./add-edit-recipients-view";

import { MoreInfoTooltip } from "@app/components/more-info-tooltip";
import { useClientProfile } from "@app/hooks/use-client-profile";
import { useClients } from "@app/hooks/use-clients";
import { useCountries } from "@app/hooks/use-countries";
import {
	tempCurrenciesMapping,
	useCurrencies,
} from "@app/hooks/use-currencies";
import { handleScrollToFirstError } from "@app/hooks/use-form-errors";
import { tempMapRecipient, useRecipient } from "@app/hooks/use-recipient";
import { EntityType } from "@app/types";
import { useRecipientTypeOptions } from "./use-recipient-type-options";

type SwiftCodeMode = "sortCode" | "routingNumber" | "none";

interface RecipientState {
	mappedReasons?: MappedReasons;
	recipientType?: string;
	paramId?: number;
}

const AddEditRecipients = () => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const isMobile = useMediaQuery();

	const {
		control,
		handleSubmit,
		formState: { errors, isValid, isDirty },
		reset,
		trigger,
		getValues,
	} = useForm<RecipientForm>({
		defaultValues: {},
		mode: "onChange",
	});

	const [showToast] = useShowToast();

	const params = useParams();

	const paramId = params[pathParams.id];

	const isEdit = paramId !== undefined;

	const [createRecipient] = useCreateRecipient();
	const [updateRecipient] = useUpdateRecipient();
	const navigate = useNavigate();

	const { activeClientId } = useClients();
	const {
		data: rawRecipient,
		isLoading: isRecipientLoading,
		mutate,
	} = useRecipient(paramId);
	const { data: clientProfile } = useClientProfile();

	const { data, isLoading: isRecipientTypeOptionsLoading } =
		useRecipientTypeOptions(activeClientId);
	const entityType = clientProfile?.entity_type;

	const recipient = useMemo(
		() => (rawRecipient ? tempMapRecipient(rawRecipient) : undefined),
		[rawRecipient],
	);

	const recipientTypeOptions = useMemo(() => {
		if (!data) return [];
		return Object.keys(data).map((key) => {
			return {
				type: key,
				label: data[key].label,
				icon: data[key].icon,
			};
		});
	}, [data]);

	const { createRecipientLoading, updateRecipientLoading } = useSelector(
		(rootState: RootState) => rootState.recipients,
	);

	const { data: countries } = useCountries();
	const { data: currencies } = useCurrencies();

	const [state, setState] = useState<RecipientState>({
		recipientType: undefined,
		paramId: undefined,
	});

	const [apiErrors, setApiErrors] = useState<ReactNode[]>([]);
	const [swiftCodeMode, setSwiftCodeMode] = useState<SwiftCodeMode>("none");

	const getAccountNumberFieldLabel = () => {
		if (isEdit && recipient?.bankDetails?.accountNumber) {
			return "Account Number";
		}
		if (isEdit && recipient?.bankDetails?.iban) {
			return "IBAN";
		}
		return "Account number / IBAN";
	};

	const getAddressFormInputs = () =>
		state.recipientType ? addressInputFields : emptyInputFields;

	const getDetailsFormInputs = () => {
		switch (state.recipientType) {
			case "someone_else":
				switch (swiftCodeMode) {
					case "routingNumber": {
						return someoneElseRoutingInputFields;
					}
					case "sortCode": {
						return someoneElseSortInputFields;
					}
					default: {
						return someoneElseInputFields;
					}
				}
			case "another_company":
			case "your_company":
			case "a_company":
				switch (swiftCodeMode) {
					case "routingNumber": {
						return aCompanyRoutingInputFields;
					}
					case "sortCode": {
						return aCompanySortInputFields;
					}
					default: {
						return aCompanyInputFields;
					}
				}
			case "an_individual":
			case "myself":
				switch (swiftCodeMode) {
					case "routingNumber": {
						return myselfInputRoutingFields;
					}
					case "sortCode": {
						return myselfInputSortFields;
					}
					default: {
						return myselfInputFields;
					}
				}
			default:
				return emptyInputFields;
		}
	};

	const getUserNameAndSurname = () => {
		if (clientProfile) {
			const values = getValues();
			reset({
				...values,
				firstName: clientProfile.first_name,
				lastName: clientProfile.last_name,
			});
		}

		return undefined;
	};

	const formatRecipient = (values: RecipientForm) => {
		const companyTypes: string[] = [
			"a_company",
			"another_company",
			"your_company",
		];

		const recipient: UpdateRecipient = {
			address: {
				addressLine1: values.addressLine1,
				addressLine2: values.addressLine2,
				city: values.city,
				country: values.country,
				postalCode: values.postalCode,
				province: values.province,
			},
			bankDetails: {
				accountNumber: values.accountNumberIban,
				routingNumber: values.routingNumber ?? undefined,
				sortCode: values.sortCode ?? undefined,
				swiftCode: values.swiftCode,
			},
			currencies: values.currencies
				? values.currencies.map((x) => x.currencyCode.toLowerCase()).join(",")
				: "",
			entityType: companyTypes.some((x) => x === state.recipientType)
				? "legal_entity"
				: "individual",
			recipientId: paramId ? +paramId : undefined,
		};

		if (recipient.bankDetails) {
			if (swiftCodeMode === "sortCode") {
				recipient.bankDetails.sortCode = values.sortCode;
			} else if (swiftCodeMode === "routingNumber") {
				recipient.bankDetails.routingNumber = values.routingNumber;
			}
		}

		if (
			state.recipientType === "a_company" ||
			state.recipientType === "another_company" ||
			state.recipientType === "your_company"
		) {
			recipient.legalEntityName = values.companyName;
		} else if (
			state.recipientType === "an_individual" ||
			state.recipientType === "myself" ||
			state.recipientType === "someone_else"
		) {
			recipient.firstName = values.firstName;
			recipient.lastName = values.lastName;
		}

		const recipientTypeConverted: RecipientType =
			state.recipientType as RecipientType;

		if (
			recipientTypeConverted === "myself" ||
			recipientTypeConverted === "your_company"
		) {
			recipient.isClient = true;
		} else {
			recipient.isClient = false;
		}

		return recipient;
	};

	const onCreateRecipient = (values: RecipientForm) => {
		const recipient = formatRecipient(values);
		createRecipient(recipient, onSaveRecipient);
	};

	const onSaveRecipient = (
		apiErrors?: string[],
		mappedReasons?: MappedReasons,
	) => {
		const errors = mappedReasons ? Object.values(mappedReasons).flat() : [];
		const hasErrors = errors.length > 0 || (apiErrors && apiErrors?.length > 0);
		setIsSubmitting(false);
		if (!hasErrors) {
			setApiErrors([]);
			setState({
				...state,
				mappedReasons: undefined,
			});
			showToast(
				isEdit ? "Changes saved" : "Recipient successfully added",
				"success",
			);
			mutate();
			navigate(paths().recipients);
		} else {
			if (apiErrors) setApiErrors(apiErrors);
			setState({
				...state,
				mappedReasons,
			});
			handleScrollToFirstError();
		}
	};

	const onBack = () => {
		navigate(paths().recipients);
	};

	const onCancel = () => {
		navigate(-1);
	};

	const onClearError = () => setApiErrors([]);

	const onUpdateRecipient = (values: RecipientForm) => {
		const paramIdExists = paramId !== undefined;

		if (
			paramIdExists &&
			state.paramId &&
			!Number.isNaN(state.paramId) &&
			state.paramId > -1
		) {
			const recipient = formatRecipient(values);
			updateRecipient(recipient, onSaveRecipient);
		} else {
			setIsSubmitting(false);
		}
	};

	const onSelectRecipientType = (value: string) => {
		setState({ ...state, recipientType: value });
	};

	const onChangeSwiftCode = (swiftCode: string) => {
		const swiftCodeCountry: SwiftCodeCountry = (
			swiftCode.substring(4, 6) ?? ""
		).toLowerCase() as SwiftCodeCountry;

		let swiftCodeMode: SwiftCodeMode = "none";

		switch (swiftCodeCountry) {
			case "gb": {
				swiftCodeMode = "sortCode";
				break;
			}
			case "us": {
				swiftCodeMode = "routingNumber";
				break;
			}
			default: {
				swiftCodeMode = "none";
				break;
			}
		}
		setSwiftCodeMode(swiftCodeMode);
	};

	useEffect(() => {
		const values = getValues();

		if (state.recipientType === "myself") {
			getUserNameAndSurname();
		} else if (state.recipientType === "your_company") {
			reset({
				...values,
				companyName: clientProfile?.display_name ?? "",
			});
		} else if (
			state.recipientType === "a_company" ||
			state.recipientType === "another_company"
		) {
			reset({
				...values,
				companyName: isEdit ? recipient?.companyName : "",
			});
		} else {
			if (!(values.firstName && values.lastName)) {
				reset({
					...values,
					firstName: isEdit ? recipient?.firstName : "",
					lastName: isEdit ? recipient?.lastName : "",
				});
			}
		}
	}, [state.recipientType]);

	useEffect(() => {
		const paramIdAsNumber = paramId !== undefined ? +paramId : -1;
		if (Number.isNaN(paramIdAsNumber) || paramIdAsNumber < 0) {
		} else {
			setState((state) => ({ ...state, paramId: paramIdAsNumber }));
		}
	}, [paramId]);

	useEffect(() => {
		if (recipient && currencies && entityType) {
			const fetchedCurrencies: CurrencyOption[] = [];
			const mapped =
				currencies?.currency_mapping.map(tempCurrenciesMapping) ?? [];
			recipient.currencies?.split(",").map((x) => {
				const foundCurrency = mapped.find(
					(y) => y.currencyCode.toLowerCase() === x.toLowerCase(),
				);

				if (foundCurrency) {
					fetchedCurrencies.push({
						...foundCurrency,
					});
				}
			});

			reset({
				accountNumberIban:
					recipient?.bankDetails?.accountNumber ?? recipient?.bankDetails?.iban,
				addressLine1: recipient?.address?.addressLine1,
				addressLine2: recipient?.address?.addressLine2,
				city: recipient?.address?.city,
				companyName: recipient?.companyName,
				country: recipient?.address?.country,
				currencies: fetchedCurrencies,
				firstName: recipient?.firstName,
				lastName: recipient?.lastName,
				postalCode: recipient?.address?.postalCode,
				province: recipient?.address?.province,
				routingNumber: recipient?.bankDetails?.routingNumber,
				sortCode: recipient?.bankDetails?.sortCode,
				swiftCode: recipient?.bankDetails?.swiftCode,
			});

			let recipientType: RecipientType | undefined;

			switch (entityType) {
				case "individual": {
					if (recipient.entityType === "individual") {
						if (recipient.isClient) {
							recipientType = "myself";
						} else {
							recipientType = "someone_else";
						}
					} else if (recipient.entityType === "legal_entity") {
						recipientType = "a_company";
					}
					break;
				}
				case "legal_entity": {
					if (recipient.entityType === "individual") {
						recipientType = "an_individual";
					} else if (recipient.entityType === "legal_entity") {
						if (recipient.isClient) {
							recipientType = "your_company";
						} else {
							recipientType = "another_company";
						}
					}
					break;
				}
				default: {
					break;
				}
			}

			setState((state) => ({ ...state, recipientType }));
		}
	}, [recipient, currencies, entityType, reset]);

	const accountNumberField: FormBuilderProps.FormInputProps = {
		className: "add-recipient-input py-3 px-3 rounded",
		iconSize: 24,
		maxLength: 50,
		name: "accountNumberIban",
		mappedName: "accountNumber",
		placeholder: "Enter an account number / IBAN",
		required: true,
		showLabel: true,
		theme: "none",
		title: getAccountNumberFieldLabel(),
		type: "text",
		onChange: onClearError,
	};

	const addressLine1Field: FormBuilderProps.FormInputProps = {
		className: "add-recipient-input py-3 px-3 rounded",
		iconSize: 24,
		maxLength: 50,
		name: "addressLine1",
		placeholder: "Enter address",
		required: true,
		showLabel: true,
		theme: "none",
		title: "Address Line 1",
		type: "text",
		onChange: onClearError,
	};

	const addressLine2Field: FormBuilderProps.FormInputProps = {
		className: "add-recipient-input py-3 px-3 rounded",
		iconSize: 24,
		maxLength: 50,
		name: "addressLine2",
		placeholder: "Enter address (optional)",
		required: false,
		showLabel: true,
		theme: "none",
		title: "Address Line 2",
		type: "text",
		onChange: onClearError,
	};

	const cityField: FormBuilderProps.FormInputProps = {
		className: "add-recipient-input py-3 px-3 rounded",
		iconSize: 24,
		maxLength: 50,
		name: "city",
		placeholder: "Enter a city",
		required: true,
		showLabel: true,
		theme: "none",
		title: "City",
		type: "text",
		onChange: onClearError,
	};

	const companyNameField: FormBuilderProps.FormInputProps = {
		className: "add-recipient-input py-3 px-3 rounded",
		disabled: state.recipientType === "your_company",
		hideAsterisk: state.recipientType === "your_company",
		iconSize: 24,
		maxLength: 50,
		name: "companyName",
		placeholder: "Enter a company name",
		required: true,
		showLabel: true,
		theme: "none",
		title: "Company Name",
		type: "text",
		onChange: onClearError,
	};

	const countryField: FormBuilderProps.FormInputProps = {
		filter: true,
		name: "country",
		options: countries ?? [],
		placeholder: "Select a country",
		required: true,
		showLabel: true,
		title: "Country",
		type: "dropdown",
		panelClassName: "country-dropdown-panel",
		onChange: onClearError,
	};

	const currencyField: FormBuilderProps.FormInputProps = {
		name: "currencies",
		optionLabel: "code",
		placeholder: "Select currencies (optional)",
		popover: (
			<MoreInfoTooltip hasIcon maxWidth={300} name="Currency">
				Select currencies typically sent to this recipient to easily identify
				and find them.
			</MoreInfoTooltip>
		),
		showLabel: true,
		title: "Preferred Currencies",
		type: "multiselect-currency",
		onChange: onClearError,
	};

	const firstNameField: FormBuilderProps.FormInputProps = {
		className: "add-recipient-input py-3 px-3 rounded",
		disabled: state.recipientType === "myself",
		hideAsterisk: state.recipientType === "myself",
		iconSize: 24,
		maxLength: 50,
		name: "firstName",
		placeholder: "Enter first name",
		required: true,
		showLabel: true,
		theme: "none",
		title: "First Name",
		type: "text",
		onChange: onClearError,
	};

	const lastNameField: FormBuilderProps.FormInputProps = {
		className: "add-recipient-input py-3 px-3 rounded",
		disabled: state.recipientType === "myself",
		hideAsterisk: state.recipientType === "myself",
		iconSize: 24,
		maxLength: 50,
		name: "lastName",
		placeholder: "Enter last name",
		required: true,
		showLabel: true,
		theme: "none",
		title: "Last Name",
		type: "text",
		onChange: onClearError,
	};

	const postalCodeField: FormBuilderProps.FormInputProps = {
		className: "add-recipient-input py-3 px-3 rounded",
		iconSize: 24,
		maxLength: 50,
		name: "postalCode",
		placeholder: "Enter postal code",
		required: true,
		showLabel: true,
		theme: "none",
		title: "Postal code",
		type: "text",
		onChange: onClearError,
	};

	const provinceField: FormBuilderProps.FormInputProps = {
		className: "add-recipient-input py-3 px-3 rounded",
		name: "province",
		placeholder: "Enter a State / Province",
		required: true,
		showLabel: true,
		theme: "none",
		title: "State / Province",
		type: "text",
		onChange: onClearError,
	};

	const routingNumberField: FormBuilderProps.FormInputProps = {
		className: "add-recipient-input py-3 px-3 rounded",
		iconSize: 24,
		maxLength: 50,
		name: "routingNumber",
		placeholder: "Enter a routing number",
		required: true,
		showLabel: true,
		theme: "none",
		title: "Routing Number",
		type: "text",
		onChange: onClearError,
	};

	const spacer: FormBuilderProps.FormInputProps = {
		title: "",
		name: "",
		type: "spacer",
	};

	const sortCodeField: FormBuilderProps.FormInputProps = {
		name: "sortCode",
		title: "Sort Code",
		type: "text",
		showLabel: true,
		required: true,
		maxLength: 50,
		className: "add-recipient-input py-3 px-3 rounded",
		iconSize: 24,
		placeholder: "Enter a sort code",
		theme: "none",
		onChange: onClearError,
	};

	const swiftCodeField: FormBuilderProps.FormInputProps = {
		className: "add-recipient-input py-3 px-3 rounded",
		iconSize: 24,
		maxLength: 50,
		name: "swiftCode",
		mappedName: "bankDetails.swiftCode",
		placeholder: "Enter a SWIFT code",
		required: true,
		showLabel: true,
		theme: "none",
		title: "SWIFT Code",
		onCustomValidationRule: (value) => {
			const isSwiftCodeValid = value.length === 8 || value.length === 11;
			return isSwiftCodeValid;
		},
		customErrorMessage: "SWIFT code can only be 8 or 11 letters/numbers",
		type: "text",
		onChange: onChangeSwiftCode,
	};

	const addressInputFields: FormBuilderProps.FormInputProps[][] = [
		[addressLine1Field, addressLine2Field],
		[cityField, provinceField],
		[postalCodeField, countryField],
	];

	const emptyInputFields: FormBuilderProps.FormInputProps[][] = [];

	const myselfInputFields: FormBuilderProps.FormInputProps[][] = [
		[firstNameField, lastNameField],
		[currencyField, accountNumberField],
		isMobile ? [swiftCodeField] : [swiftCodeField, spacer],
	];

	const myselfInputRoutingFields: FormBuilderProps.FormInputProps[][] = [
		[firstNameField, lastNameField],
		[currencyField, accountNumberField],
		[swiftCodeField, routingNumberField],
	];

	const myselfInputSortFields: FormBuilderProps.FormInputProps[][] = [
		[firstNameField, lastNameField],
		[currencyField, accountNumberField],
		[swiftCodeField, sortCodeField],
	];

	const someoneElseInputFields: FormBuilderProps.FormInputProps[][] = [
		[firstNameField, lastNameField],
		[currencyField, accountNumberField],
		[swiftCodeField, spacer],
	];

	const someoneElseRoutingInputFields: FormBuilderProps.FormInputProps[][] = [
		[firstNameField, lastNameField],
		[currencyField, accountNumberField],
		[swiftCodeField, routingNumberField],
	];

	const someoneElseSortInputFields: FormBuilderProps.FormInputProps[][] = [
		[firstNameField, lastNameField],
		[currencyField, accountNumberField],
		[swiftCodeField, sortCodeField],
	];

	const aCompanyInputFields: FormBuilderProps.FormInputProps[][] = [
		[companyNameField, currencyField],
		[accountNumberField, swiftCodeField],
	];

	const aCompanyRoutingInputFields: FormBuilderProps.FormInputProps[][] = [
		[companyNameField, currencyField],
		[accountNumberField, swiftCodeField],
		[routingNumberField, spacer],
	];

	const aCompanySortInputFields: FormBuilderProps.FormInputProps[][] = [
		[companyNameField, currencyField],
		[accountNumberField, swiftCodeField],
		[sortCodeField, spacer],
	];

	const detailsFormInputs = getDetailsFormInputs();

	const viewProps = {
		recipientTypeOptions,
		addressFormInputs: getAddressFormInputs(),
		apiErrors,
		detailsFormInputs: detailsFormInputs,
		errors: errors,
		trigger,
		formControl: control,
		isEdit,
		isSubmitting,
		isIndividual: (entityType as EntityType) === "individual",
		isValid: isValid,
		isDirty: isDirty,
		loading:
			createRecipientLoading ||
			isRecipientLoading ||
			updateRecipientLoading ||
			isRecipientTypeOptionsLoading,
		mappedReasons: state.mappedReasons,
		recipientType: state.recipientType,
		title: "addEditRecipient",
		onBack,
		onCancel,
		onSelectRecipientType,
	};

	return (
		<form
			id="add-edit-recipient-form"
			onSubmit={handleSubmit((data) => {
				setIsSubmitting(true);
				state.paramId !== undefined
					? onUpdateRecipient(data)
					: onCreateRecipient(data);
			})}
			noValidate
		>
			{isMobile ? (
				<AddEditRecipientsViewResponsive {...viewProps} />
			) : (
				<AddEditRecipientsView {...viewProps} />
			)}
		</form>
	);
};

export default AddEditRecipients;
