import { NotificationIndicator } from "@app/components/notification-indicator";
import { useNotifications } from "@app/components/notifications/use-notifications";
import * as Popover from "@radix-ui/react-popover";

import { NotificationList } from "@app/components/notifications/notifcation-list";
import { NotificationsEmpty } from "@app/components/notifications/notifications-empty";
import styles from "./index.module.css";

export const NotificationsItem = () => {
	const { hasNotifications, isLoading } = useNotifications();
	if (isLoading) return null;
	return (
		<>
			<Popover.Root>
				<Popover.Trigger aria-label="Notifications">
					<NotificationIndicator />
				</Popover.Trigger>
				<Popover.Content align="end" className={styles.popover}>
					{hasNotifications ? <NotificationList /> : <NotificationsEmpty />}
				</Popover.Content>
			</Popover.Root>

			<div className={styles.seperator} />
		</>
	);
};
