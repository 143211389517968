import { Button } from "@app/components/button";

import { Dialog } from "@app/components/dialog";
import "./remove-recipient-modal.css";

export const RemoveRecipientModal = (props: {
	onClose: () => void;
	onRemove: () => void;
	isOpen?: boolean;
}) => {
	return (
		<Dialog
			isOpen={props.isOpen || false}
			onClose={props.onClose}
			title="Remove recipient"
			description="Are you sure you want to remove this recipient?"
			actions={
				<>
					<Button variant="secondary" onClick={props.onClose}>
						Cancel
					</Button>
					<Button onClick={props.onRemove}>Remove</Button>
				</>
			}
		/>
	);
};
