import { Label } from "@app/components/label";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Card } from "../card";
import { Row } from "../row";

import { ApiErrors } from "@app/components/api-errors";
import { Dropdown } from "@app/components/dropdown";
import { FieldError } from "@app/components/field-error";
import { useCountries } from "@app/hooks/use-countries";
import { FormErrors } from "@app/utils/get-form-errors";
import { clsx } from "clsx";
import isEqual from "deep-equal";
import {
	ReactNode,
	forwardRef,
	useCallback,
	useImperativeHandle,
	useMemo,
	useState,
} from "react";
import { ContactNumberField } from "../contact-number-field";
import { DocumentField } from "../document-field";
import { ProvinceField } from "../province-field";
import { RadioGroupField } from "../radio-group-field";
import { TextField } from "../text-field";
import styles from "./entity-form.module.css";
import {
	Entity,
	EntityUpdate,
	getRelatedEntity,
	getRelatedEntityLink,
	useEntity,
} from "./use-entity";
import {
	LinkableRelatedEntities,
	RelatedEntityType,
	RelatedEntityVariant,
	useRelatedEntities,
} from "./use-related-entities";

import { Input } from "@app/components/input";
import { Note } from "@app/components/note";
import { paths } from "@app/constants/paths";
import { useFormErrors } from "@app/hooks/use-form-errors";
import { useNavigate } from "react-router-dom";
import { EntityLayout } from "./entity-layout";
import { getDataForEntityVariant } from "./get-data-for-entity-variant";
import { isEntityEmpty } from "./is-empty-entity";

export type EntityFormInputs = {
	entity_type: RelatedEntityType;
	is_linked_entity: string | undefined;
	linked_entity_id: number | undefined;
	id_selfie_required: boolean;
	shareholding_percent: number | undefined;
	designation: string | undefined;
	contact_number: string;
	country_of_birth: string;
	email_address: string;
	first_name: string;
	id_document: string | null;
	id_selfie: string | null;
	last_name: string;
	middle_names: string;
	nationality: string;
	residential_address: {
		address_1: string;
		address_2: string;
		city: string;
		province: string;
		other_province: string;
		postal_code: string;
		country: string;
	};
};

const mapFormToDTO = (data: Entity): EntityUpdate => {
	return {
		...data,
		residential_address: {
			...data.residential_address,
			province: data.residential_address.province || null,
		},
	};
};

export const EntityForm = forwardRef<
	any,
	{
		id: number;
		linkId: number;
		index: number;
		variant: RelatedEntityVariant;
		onSavingChanges: (isSavingChanges: boolean) => void;
		onSaved: (isSaved: boolean) => void;
		isLink?: boolean;
		onParentSave: () => void;
		entitiesAddedThisSession: Array<number>;
		linkableEntities?: Array<LinkableRelatedEntities>;
		allLinkableEntities?: Array<LinkableRelatedEntities>;
		onRemove?: (isDirtyForm: boolean) => void;
		activeClientId: number;
		footer?: ReactNode;
		onRelink: (entityId?: number) => void;
		onCreateEntity: () => Promise<void>;
		onGoToEntity: (entityId: number) => void;
		onGeneralError: () => void;
	}
>(
	(
		{
			id,
			linkId,
			index,
			variant,
			onRelink,
			onSavingChanges,
			isLink = false,
			onCreateEntity,
			onSaved,
			entitiesAddedThisSession = [],
			linkableEntities = [],
			allLinkableEntities = [],
			onRemove,
			activeClientId,
			onGoToEntity,
			footer,
			onParentSave,
			onGeneralError,
		},
		ref,
	) => {
		const navigate = useNavigate();
		const { mutate } = useRelatedEntities(variant);
		const [isRemoving, setRemoving] = useState(false);
		const { data: countries } = useCountries();
		const { submit, update, updateLink, submitLink } = useEntity(id, linkId);

		const { name, linkedEntityLabel, linkedDropdownLabel, placeholder } =
			getDataForEntityVariant(variant);

		const methods = useForm<EntityFormInputs>({
			shouldFocusError: false,
			defaultValues: async () => {
				try {
					const { data: relatedEntity } = await getRelatedEntity(id);
					const { data: relatedEntityLink } =
						await getRelatedEntityLink(linkId);
					return {
						id_selfie_required: relatedEntityLink.id_selfie_required,
						linked_entity_id: relatedEntityLink.related_entity_id,
						shareholding_percent: relatedEntityLink.shareholding_percent,
						designation: relatedEntityLink.designation,
						is_linked_entity: isLink ? "yes" : "no",
						...relatedEntity,
					} as EntityFormInputs;
				} catch {
					onGeneralError();
					return {} as EntityFormInputs;
				}
			},
		});
		const { handleSubmit, register, getValues, setValue, watch, reset } =
			methods;
		const {
			errors,
			apiErrors,
			setError,
			onErrors,
			clearApiFieldErrors,
			clearErrors,
			onInvalid,
		} = useFormErrors(methods);

		const handleSetErrors = useCallback(
			(errors: FormErrors, shouldFocusError = false) => {
				onErrors(errors, shouldFocusError);
			},
			[onErrors],
		);

		const onSubmit: SubmitHandler<EntityFormInputs> = useCallback(
			async (data) => {
				clearApiFieldErrors();

				const linkErrors = await submitLink({
					designation: data.designation,
					shareholding_percent: Number.parseFloat(
						`${data.shareholding_percent}`,
					),
				});

				const entityErrors = isLink ? undefined : await submit(data);

				if (linkErrors || entityErrors) {
					if (!data.id_document) {
						setError("id_document", {
							type: "required",
							message: "This field is required",
						});
					}

					if (!data.id_selfie && variant !== "shareholder") {
						setError("id_selfie", {
							type: "required",
							message: "This field is required",
						});
					}
					handleSetErrors(
						{
							apiErrors: [
								...(linkErrors?.apiErrors ?? []),
								...(entityErrors?.apiErrors ?? []),
							],
							fieldErrors: [
								...(linkErrors?.fieldErrors ?? []),
								...(entityErrors?.fieldErrors ?? []),
							],
						},
						true,
					);
					return false;
				}
				return true;
			},
			[
				clearApiFieldErrors,
				submit,
				handleSetErrors,
				isLink,
				submitLink,
				setError,
				variant,
			],
		);

		useImperativeHandle(
			ref,
			() => {
				return {
					id,
					submit: async () => onSubmit(getValues()),
				};
			},
			[onSubmit, getValues, id],
		);

		const handleEntityPartialSave = async () => {
			const data = mapFormToDTO(getValues());
			onSavingChanges(true);
			const errors = await update(data);
			clearApiFieldErrors(data);
			if (errors) {
				handleSetErrors(errors);
			} else {
				onSaved(true);
				onParentSave();
			}
			onSavingChanges(false);
			onSaved(true);
		};

		const handleRemove = async () => {
			if (!onRemove) return;
			setRemoving(true);
			const isDirty = !isEntityEmpty(getValues());
			await onRemove(isDirty || isLinkedEntity);
			setRemoving(false);
		};

		const firstname = watch("first_name");
		const lastname = watch("last_name");

		const hasName = !!firstname || !!lastname;

		const displayName = useMemo(() => {
			let newName = `${name} ${index + 1}`;
			if (firstname || lastname) {
				newName = `${firstname} ${lastname}`.trim();
			}
			return newName;
		}, [firstname, lastname, index, name]);

		const isLinkedEntityValue = watch("is_linked_entity");
		const isLinkedEntity =
			isLinkedEntityValue === undefined || isLinkedEntityValue === null
				? isLink
				: isLinkedEntityValue === "yes";
		const linkedEntityId = watch("linked_entity_id");

		const showLinkOrEntityRadio =
			isLinkedEntity ||
			(entitiesAddedThisSession.includes(id) && linkableEntities.length > 0);

		const currentEntityLink = isLink
			? allLinkableEntities.find((current) => current.related_entity_id === id)
			: undefined;

		const linkOptions = currentEntityLink
			? [
					currentEntityLink.name,
					...linkableEntities.map((current) => current.name),
				]
			: linkableEntities.map((current) => current.name);

		register("id_document", {
			required: "This field is required",
		});

		register("id_selfie", {
			required: "This field is required",
		});

		return (
			<FormProvider {...methods}>
				<EntityLayout
					id={id}
					index={index}
					footer={footer}
					hasName={hasName}
					displayName={displayName}
					onRemove={onRemove ? handleRemove : undefined}
					isRemoving={isRemoving}
				>
					<form
						onSubmit={handleSubmit(onSubmit, onInvalid)}
						className={styles.cards}
					>
						<Card
							disableAnimation
							className={clsx(isLinkedEntity && styles.card)}
						>
							{showLinkOrEntityRadio && (
								<Row>
									<RadioGroupField
										name="is_linked_entity"
										label={linkedEntityLabel ?? ""}
										onChange={() => {
											if (isLink) {
												clearApiFieldErrors();
												reset();
												onCreateEntity();
											}
										}}
									/>
								</Row>
							)}

							{isLinkedEntity ? (
								<>
									<Row style={{ marginBottom: 0 }}>
										<div>
											<Label
												htmlFor="linked_entity_id"
												actions={
													isLink &&
													linkedEntityId && (
														<button
															type="button"
															className={styles.viewButton}
															onClick={() => onGoToEntity(linkedEntityId)}
														>
															View details
														</button>
													)
												}
											>
												{linkedDropdownLabel}
											</Label>
											{linkableEntities.length > 0 ? (
												<Dropdown
													{...register("linked_entity_id", {
														required: "This field is required",
														onChange: (event) => {
															if (!event.value) return;
															const matching = linkableEntities.find(
																(entity) => entity.name === event.value,
															);
															if (matching?.id) {
																setValue(
																	"linked_entity_id",
																	matching.related_entity_id,
																);
																clearApiFieldErrors();
																onRelink(matching.related_entity_id);
															}
														},
													})}
													loading={!linkableEntities}
													value={currentEntityLink?.name}
													invalid={!!errors.linked_entity_id}
													placeholder={placeholder}
													options={linkOptions}
												/>
											) : (
												<Input
													name="linked_entity_id"
													value={currentEntityLink?.name}
													disabled
													ref={ref}
													aria-invalid={!!errors.linked_entity_id}
												/>
											)}
											{errors.linked_entity_id && (
												<FieldError>
													{errors.linked_entity_id.message}
												</FieldError>
											)}
										</div>

										{variant === "authorised_signatory" && (
											<TextField
												error={errors.designation}
												label="Capacity /​ Designation*"
												placeholder="Enter their position in the company"
												{...register("designation", {
													required: "This field is required",
													onBlur: async () => {
														const errors = await updateLink({
															designation: watch("designation"),
														});
														if (errors) {
															handleSetErrors(errors);
														} else {
															clearErrors("designation");
														}
													},
												})}
											/>
										)}

										{variant === "shareholder" && (
											<TextField
												error={errors.shareholding_percent}
												label="Shareholding %*"
												placeholder="Enter a percentage"
												{...register("shareholding_percent", {
													required: "This field is required",
													onBlur: async () => {
														const value = watch("shareholding_percent");
														const parsed = value
															? Number.parseFloat(`${value}`)
															: null;
														const errors = await updateLink({
															shareholding_percent: parsed,
														});
														if (errors) {
															handleSetErrors(errors);
														} else {
															clearErrors("shareholding_percent");
														}
													},
												})}
											/>
										)}
									</Row>
									{variant !== "shareholder" && watch("id_selfie_required") && (
										<>
											<DocumentField
												className={styles.selfieRequired}
												type="id_selfie"
												relatedEntityId={id}
												tooltip={`Upload a picture of the ${name} holding the uploaded ID (must be legible and clear).`}
												tooltipWidth={320}
												value={watch("id_selfie")}
												onChange={async () => {
													clearErrors("id_selfie");
													onParentSave();
												}}
												label="Selfie with ID"
												error={errors.id_selfie}
											/>

											<Note variant="full" showBreak>
												A selfie with ID is required for all Directors and
												Signatories. As this person was uploaded as a
												Shareholder, we do not have this yet.
											</Note>
										</>
									)}
								</>
							) : (
								<>
									<Label htmlFor="first_name">Full name*</Label>
									<Row
										className={styles.nameRow}
										style={{
											marginBottom: variant === "director" ? 0 : undefined,
										}}
									>
										<TextField
											error={errors.first_name}
											autoComplete="given-name"
											placeholder="Enter your first name"
											{...register("first_name", {
												required: "This field is required",
												onBlur: async () => {
													await handleEntityPartialSave();
													await mutate();
												},
											})}
										/>

										<TextField
											error={errors.middle_names}
											placeholder="Enter middle name (optional)"
											{...register("middle_names", {
												onBlur: handleEntityPartialSave,
											})}
										/>

										<TextField
											error={errors.last_name}
											autoComplete="family-name"
											placeholder="Enter your last name"
											{...register("last_name", {
												required: "This field is required",
												onBlur: async () => {
													await handleEntityPartialSave();
													await mutate();
												},
											})}
										/>
									</Row>
									{variant !== "director" && (
										<Row style={{ marginBottom: 0 }}>
											{variant === "authorised_signatory" && (
												<TextField
													error={errors.designation}
													label="Capacity /​ Designation*"
													placeholder="Enter their position in the company"
													{...register("designation", {
														required: "This field is required",
														onBlur: async () => {
															const errors = await updateLink({
																designation: watch("designation"),
															});
															if (errors) {
																handleSetErrors(errors);
															} else {
																clearErrors("designation");
															}
														},
													})}
												/>
											)}

											{variant === "shareholder" && (
												<TextField
													error={errors.shareholding_percent}
													label="Shareholding %*"
													placeholder="Enter a percentage"
													{...register("shareholding_percent", {
														required: "This field is required",
														onBlur: async () => {
															const value = watch("shareholding_percent");
															const parsed = value
																? Number.parseFloat(`${value}`)
																: null;
															const errors = await updateLink({
																shareholding_percent: parsed,
															});
															if (errors) {
																handleSetErrors(errors);
															} else {
																clearErrors("shareholding_percent");
															}
														},
													})}
												/>
											)}
										</Row>
									)}
								</>
							)}
						</Card>
						{!isLinkedEntity && (
							<>
								<Card title="Personal Details">
									<Row>
										<TextField
											label="Email*"
											placeholder="Enter their email address"
											error={errors.email_address}
											{...register("email_address", {
												required: "This field is required",
												onBlur: () => handleEntityPartialSave(),
											})}
										/>

										<ContactNumberField
											placeholder="Enter number"
											error={errors.contact_number}
											onBlur={() => handleEntityPartialSave()}
										/>
									</Row>

									<Row>
										<div>
											<Label htmlFor="nationality">Nationality*</Label>
											<Dropdown
												{...register("nationality", {
													required: "This field is required",
													onChange: (event) => {
														setValue("nationality", event.value);
														handleEntityPartialSave();
														if (event.value) clearErrors("nationality");
													},
												})}
												loading={!countries}
												value={watch("nationality")}
												invalid={!!errors.nationality}
												placeholder="Select an country"
												options={countries ?? []}
											/>
											{errors.nationality && (
												<FieldError>{errors.nationality.message}</FieldError>
											)}
										</div>

										<div>
											<Label htmlFor="country_of_birth">
												Country of birth*
											</Label>
											<Dropdown
												{...register("country_of_birth", {
													required: "This field is required",
													onChange: (event) => {
														setValue("country_of_birth", event.value);
														handleEntityPartialSave();
														if (event.value) clearErrors("country_of_birth");
													},
												})}
												loading={!countries}
												value={watch("country_of_birth")}
												invalid={!!errors.country_of_birth}
												placeholder="Select an country"
												options={countries ?? []}
											/>
											{errors.country_of_birth && (
												<FieldError>
													{errors.country_of_birth.message}
												</FieldError>
											)}
										</div>
									</Row>

									<DocumentField
										type="id_document"
										relatedEntityId={id}
										tooltip="Upload a copy of their ID Book or both sides of their ID Card."
										value={watch("id_document")}
										tooltipWidth={282}
										onChange={async () => {
											if (!activeClientId) return;
											const result = await getRelatedEntity(id);
											setValue("id_document", result.data.id_document);
											clearErrors("id_document");
											onParentSave();
										}}
										label="ID Document"
										error={errors.id_document}
									/>

									{variant !== "shareholder" && (
										<DocumentField
											type="id_selfie"
											relatedEntityId={id}
											tooltip={`Upload a picture of the ${name} holding the uploaded ID (must be legible and clear).`}
											tooltipWidth={320}
											value={watch("id_selfie")}
											onChange={async () => {
												if (!activeClientId) return;
												const result = await getRelatedEntity(id);
												setValue("id_selfie", result.data.id_selfie);
												clearErrors("id_selfie");
												onParentSave();
											}}
											label="Selfie with ID"
											error={errors.id_selfie}
										/>
									)}
								</Card>
								<Card title="Residential Address" className={styles.card}>
									<Row>
										<TextField
											label="Address line 1*"
											placeholder="Enter address"
											error={errors.residential_address?.address_1}
											{...register("residential_address.address_1", {
												required: "This field is required",
												onBlur: () => handleEntityPartialSave(),
											})}
										/>

										<TextField
											label="Address line 2"
											placeholder="Enter address (optional)"
											error={errors.residential_address?.address_2}
											{...register("residential_address.address_2", {
												onBlur: () => handleEntityPartialSave(),
											})}
										/>
									</Row>

									<Row>
										<TextField
											label="City*"
											placeholder="Enter a city"
											error={errors.residential_address?.city}
											{...register("residential_address.city", {
												required: "This field is required",
												onBlur: () => handleEntityPartialSave(),
											})}
										/>

										<ProvinceField
											provinceFieldName="residential_address.province"
											otherFieldName="residential_address.other_province"
											countryFieldName="residential_address.country"
											error={
												errors.residential_address?.province ||
												errors.residential_address?.other_province
											}
											onChange={handleEntityPartialSave}
										/>
									</Row>

									<Row style={{ marginBottom: 0 }}>
										<TextField
											label="Postal code*"
											placeholder="Enter postal code"
											error={errors.residential_address?.postal_code}
											{...register("residential_address.postal_code", {
												required: "This field is required",
												onBlur: () => handleEntityPartialSave(),
											})}
										/>

										<div>
											<Label htmlFor="residential_address.country">
												Country*
											</Label>
											<Dropdown
												{...register("residential_address.country", {
													required: "This field is required",
													onChange: (event) => {
														setValue(
															"residential_address.country",
															event.value,
														);
														handleEntityPartialSave();
														if (event.value)
															clearErrors("residential_address.country");
													},
												})}
												loading={!countries}
												value={watch("residential_address.country")}
												invalid={!!errors.residential_address?.country}
												placeholder="Select an country"
												options={countries ?? []}
											/>
											{errors.residential_address?.country && (
												<FieldError>
													{errors.residential_address?.country.message}
												</FieldError>
											)}
										</div>
									</Row>
									<ApiErrors className={styles.apiError} errors={apiErrors} />
								</Card>
							</>
						)}
					</form>
				</EntityLayout>
			</FormProvider>
		);
	},
);
