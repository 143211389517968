import useSWR from "swr";

export const useRecipientTypeOptions = (clientId?: number) => {
	return useSWR<{
		[type: string]: {
			label: string;
			icon: string;
		};
	}>(clientId ? `/clients/${clientId}/recipients/type_options/` : null);
};
