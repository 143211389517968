import useSWR from "swr";

export type BankAccount = {
	account_holder: string;
	bank: string;
	branch_code: string;
	account_number: string;
	account_type: string;
	international_details: {
		swift: string;
		line_1: string;
		line_2: string;
		city: string;
		country: string;
		postal_code: string;
	};
};

export const useBankAccountDetails = (bankAccountId?: number) =>
	useSWR<BankAccount>(`/bank-accounts/${bankAccountId}/details/`);
