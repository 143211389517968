import { useDispatch } from "react-redux";

import type { SetProfilePictureParams } from "@app/entities";
import type { AppDispatch } from "@app/redux";
import { setProfilePictureAction } from "@app/redux/clients/clients-actions";
import type { MappedReasons } from "@app/services";

export const useSetProfilePicture = () => {
	const dispatch: AppDispatch = useDispatch();

	const setProfilePicture = (
		params: SetProfilePictureParams,
		callback?: (errors?: string[], mappedReasons?: MappedReasons) => void,
	) => {
		dispatch(setProfilePictureAction(params, callback));
	};

	return [setProfilePicture];
};
