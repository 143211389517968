import styles from "./notifications-empty.module.css";

export const NotificationsEmpty = () => {
	return (
		<div className={styles.container}>
			<h2 className={styles.title}>Notifications</h2>
			<hr className={styles.hr} />
			<p>No notifications to display</p>
		</div>
	);
};
