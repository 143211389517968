import { AxiosResponseHeaders } from "axios";

export const getFilenameFromHeader = (headers: any, fallback: string) => {
	try {
		const contentDisposition =
			headers["Content-Disposition"] || headers["content-disposition"];
		const fileName = contentDisposition
			?.split(";")
			.find((current: string) => current.includes("filename="))
			?.split("=")[1]
			.replaceAll('"', "")
			.replaceAll("%20", " ");
		return fileName ? decodeURIComponent(fileName) : fallback;
	} catch {
		return fallback;
	}
};
