import { api } from "@app/services";
import { getFormErrors } from "@app/utils/get-form-errors";

export type InitialInformation = {
	type: string;
	name: string;
	contact_person_first_name: string;
	contact_person_last_name: string;
	contact_number: string;
};

export type CreateInitialInformation = {
	first_name: string;
	last_name: string;
	name: string;
	contact_person_first_name: string;
	contact_person_last_name: string;
	contact_number: string;
};

export const useInitialInformation = () => {
	return {
		submit: async (data: Partial<CreateInitialInformation>) => {
			try {
				await api.post<InitialInformation>(
					"/onboarding/initial-information/",
					data,
				);
			} catch (error) {
				return getFormErrors(error);
			}
		},
	};
};
