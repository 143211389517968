import moment, { type Moment } from "moment";
import type {
	CalendarDateTemplateEvent,
	CalendarPassThroughMethodOptions,
	CalendarPassThroughType,
	CalendarProps,
	CalendarViewChangeEvent,
} from "primereact/calendar";
import type { FormEvent } from "primereact/ts-helpers";
import { HTMLAttributes, useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";

import { Typography } from "@app/components";

import { FiCalendar } from "react-icons/fi";
import { DatePickerView } from "./date-picker-view";
import type { DatePickerLogicState } from "./models/date-picker-state";
import type { Properties, ViewProperties } from "./properties";

interface DatePickerState {
	selection: Date[];
	viewSelection?: Date;
}

export const DatePicker = (props: Properties) => {
	const defaultState: DatePickerState = {
		selection: [],
		viewSelection: undefined,
	};

	const defaultLogicState: DatePickerLogicState = {
		isShown: true,
	};

	const [state, setState] = useState<DatePickerState>(defaultState);
	const [logicState, setLogicState] =
		useState<DatePickerLogicState>(defaultLogicState);

	const getCalendarProps = (): CalendarProps => {
		const basicCalendarProps = {
			onChange: onChangeSelection,
		};

		switch (props.selectionMode) {
			case "multiple":
				return {
					...basicCalendarProps,
					selectionMode: "multiple",
					value:
						state.selection && state.selection.length > 0
							? state.selection
							: [],
				};
			case "range":
				return {
					...basicCalendarProps,
					selectionMode: "range",
					value:
						state.selection && state.selection.length > 0
							? state.selection
							: [],
				};
			default:
				return {
					...basicCalendarProps,
					selectionMode: "single",
					value:
						state.selection && state.selection.length > 0
							? state.selection[0]
							: undefined,
				};
		}
	};

	const getIsCurrentMonthDay = (date: Moment) => {
		return date.isBetween(
			moment(state.viewSelection),
			moment(state.viewSelection).add(
				Math.max(
					0,
					props.numberOfMonths !== undefined ? props.numberOfMonths - 1 : 0,
				),
				"month",
			),
			"month",
			"[]",
		);
	};

	const getIsDateSelectedDate = (date: Moment) => {
		return (
			state.selection.find((x) => moment(x).isSame(date, "date")) !== undefined
		);
	};

	const getIsWeekendDay = (date: Moment) => date.weekday() % 6 === 0;

	const getDateTemplate = (
		calendarDateTemplateEvent: CalendarDateTemplateEvent,
	) => {
		const calendarDateAsMoment = moment();
		calendarDateAsMoment.date(calendarDateTemplateEvent.day);
		calendarDateAsMoment.month(calendarDateTemplateEvent.month);
		calendarDateAsMoment.year(calendarDateTemplateEvent.year);

		const isCurrentMonthDay = getIsCurrentMonthDay(calendarDateAsMoment);

		const isWeekendDay = getIsWeekendDay(calendarDateAsMoment);

		const isSelectedDay = getIsDateSelectedDate(calendarDateAsMoment);

		return props.dateTemplate ? (
			props.dateTemplate(calendarDateTemplateEvent)
		) : (
			<Typography
				theme="textSm"
				className={twMerge(
					"font-medium",
					"text-gray-1100",
					// If not using weekend graying by 26th Jan, remove
					isWeekendDay && "date-picker-weekend-day",
					isCurrentMonthDay && props.showNonMonthDays && "text-gray-300",
					isSelectedDay && "text-white",
				)}
			>
				{calendarDateTemplateEvent.day}
			</Typography>
		);
	};

	const getIcon = () => {
		return (
			props.icon ?? (
				<FiCalendar className={props.iconClassName} color="#888" size={20} />
			)
		);
	};

	const getPassThroughForDay = (
		calendarPassthroughMethodOptions?: CalendarPassThroughMethodOptions,
	): CalendarPassThroughType<HTMLAttributes<HTMLTableCellElement>> => {
		const dayValue = calendarPassthroughMethodOptions?.context.date as Date;

		if (props.selectionMode === "range") {
			if (dayValue && state.selection) {
				const dayAsDate = moment(dayValue);

				const isStartDate =
					state.selection[0] && dayAsDate.isSame(state.selection[0], "day");

				const isEndDate =
					state.selection[1] && dayAsDate.isSame(state.selection[1], "day");

				const isSelected =
					state.selection.length === 2 &&
					dayAsDate.isBetween(
						state.selection[0],
						state.selection[1],
						"day",
						"[]",
					);

				return {
					className: twMerge(
						isStartDate && "date-picker-start-day",
						isEndDate && "date-picker-end-day",
						isSelected && "date-picker-range-day",
						(isSelected || isStartDate || isEndDate) &&
							state.selection &&
							state.selection.length === 1 &&
							"date-picker-start-end-day",
						"date-picker-day",
					),
				};
			}
		} else {
			const dayValue = moment(
				calendarPassthroughMethodOptions?.context.date as Date,
			);

			const isCurrentMonthDay = getIsCurrentMonthDay(dayValue);
			const isSelectedDay = getIsDateSelectedDate(dayValue);

			return {
				className:
					!(props.showNonMonthDays || isCurrentMonthDay || isSelectedDay) &&
					"date-picker-non-month-day",
			};
		}
	};

	const onChangeSelection = (
		event?: FormEvent<Date> | FormEvent<(Date | null)[]> | FormEvent<Date[]>,
	) => {
		let dates: Date[] = [];

		if (event) {
			if (Array.isArray(event?.value)) {
				dates = event.value.filter((x) => x != null).map((x) => x as Date);
			} else {
				if (event?.value) {
					dates = [event.value];
				}
			}
		}

		setState({ ...state, selection: dates });
		if (props.onChange) props.onChange(dates);
	};

	const onHide = () => {
		setLogicState({ ...logicState, isShown: true });
		if (props.onHide) props.onHide();
	};

	const onShow = () => {
		setLogicState({ ...logicState, isShown: false });
		if (props.onShow) props.onShow();
	};

	const onViewSelectionChange = (
		calendarViewChangeEvent: CalendarViewChangeEvent,
	) => {
		setState((prevState) => {
			return {
				...prevState,
				viewSelection: calendarViewChangeEvent.value,
			};
		});
		if (props.onViewDateChange)
			props.onViewDateChange(calendarViewChangeEvent.value);
	};

	useEffect(() => {
		if (props.value && props.value !== state.selection) {
			if (props.value === undefined) {
				setState({ ...state, selection: [] });
			} else {
				setState({ ...state, selection: props.value });
			}
		}
	}, [props.value]);

	const viewProps: ViewProperties = {
		...props,
		calendarTypeProps: getCalendarProps(),
		icon: getIcon(),
		iconPosition: props.iconPosition ?? "left",
		isShown: logicState.isShown,
		selection: state.selection,
		viewDate: state.viewSelection ?? new Date(),
		dateTemplate: (calendarDateTemplateEvent: CalendarDateTemplateEvent) =>
			getDateTemplate(calendarDateTemplateEvent),
		getPassThroughForDay,
		onChangeSelection,
		onHide,
		onShow,
		onViewSelectionChange,
	};

	return <DatePickerView {...viewProps} />;
};
