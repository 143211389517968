export const links = {
	website: "https://futureforex.co.za/",
	contact: "https://futureforex.co.za/contact/",
	termsOfService: "https://futureforex.co.za/forex-terms-of-service/",
	privacyPolicy: "https://futureforex.co.za/privacy-policy/",
	registerIndividual: "https://futureforex.co.za/individuals-registration/",
	registerBusiness: "https://futureforex.co.za/business-onboarding-capitec/",
	arbRegister: "https://dashboard.futureforex.co.za/register",
	supportedCurrencies:
		"https://futureforex.co.za/forex-faq/#what-currencies-can-i-send-or-receive-through-future-forex",
	dealingRoom: {
		emailAddress: "mailto:deal.room@futureforex.co.za",
		phoneNumber: "tel:0215180559",
	},
	forexFaq: "https://futureforex.co.za/forex-faq/",
	getInTouch: {
		emailAddress: "mailto:info@futureforex.co.za",
		phoneNumber: "tel:+27 (0)21 518 0558",
		physicalAddress:
			"https://www.google.com/maps/place/66+Roeland+St,+Gardens,+Cape+Town,+8001,+South+Africa/" +
			"@-33.93014,18.4202713,17z/data=!3m1!4b1!4m5!3m4!1s0x1dcc677c6dee2f25:0xfc54dc852efce006!8m2!3d-33.93014!4d18.42246",
	},
	home: "https://futureforex.co.za/",
	legal: {
		privacyPolicy: "https://futureforex.co.za/privacy-policy/",
		termsAndConditions: "https://futureforex.co.za/terms-and-conditions/",
		termsOfUse: "https://futureforex.co.za/website-terms-of-use/",
	},
	sourceOfFundsFaq:
		"https://www.resbank.co.za/en/home/quick-links/frequently-asked-questions#finsurv-item-1869cf2e7d-button",
	quickLinks: {
		about: "https://futureforex.co.za/about/",
		careers: "https://futureforex.co.za/careers/",
		faqs: "https://futureforex.co.za/faq/",
	},
};
