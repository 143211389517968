import { FieldError } from "@app/components/field-error";
import { Input } from "@app/components/input";
import { Label } from "@app/components/label";
import { ComponentPropsWithoutRef, ReactNode, forwardRef } from "react";
import type { FieldError as RHFFieldError } from "react-hook-form";

type TextFieldProps = {
	name: string;
	error?: RHFFieldError;
	label?: string;
	placeholder: string;
	tooltip?: ReactNode;
	tooltipWidth?: number;
} & Omit<ComponentPropsWithoutRef<"input">, "name">;

export const TextField = forwardRef<any, TextFieldProps>(
	(
		{ label, name, error, tooltip, tooltipWidth, ...rest }: TextFieldProps,
		ref,
	) => {
		return (
			<div>
				{label && (
					<Label htmlFor={name} tooltip={tooltip} tooltipWidth={tooltipWidth}>
						{label}
					</Label>
				)}

				<Input name={name} ref={ref} aria-invalid={!!error} {...rest} />
				{error && <FieldError>{error?.message}</FieldError>}
			</div>
		);
	},
);
