import useSWR from "swr";

export interface TransactionRecipientNames {
	shortlist: {
		label: string;
		currency: string;
		list: {
			id: number;
			name: string;
		}[];
	};
	other_recipients: {
		label: string;
		currency: string;
		list: {
			id: number;
			name: string;
		}[];
	};
}

export const useTransactionRecipientNames = (transactionId?: number) => {
	return useSWR<TransactionRecipientNames>(
		transactionId ? `/transactions/${transactionId}/recipient-names/` : null,
	);
};
