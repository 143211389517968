import { ReactNode, createContext, useContext, useMemo, useState } from "react";

const statusContext = createContext<{
	error: any;
	onError: (error: any) => void;
} | null>(null);

export const StatusProvider = ({ children }: { children: ReactNode }) => {
	const [error, setError] = useState(null);
	const value = useMemo(() => ({ error, onError: setError }), [error]);
	return (
		<statusContext.Provider value={value}>{children}</statusContext.Provider>
	);
};

export const useStatus = () => {
	const context = useContext(statusContext);
	if (!context) {
		throw new Error("useStatus must be used within a StatusProvider");
	}
	return context;
};
