import { Dropdown } from "@app/components/controls";

import { Typography } from "../typography";
import type { ViewProperties } from "./properties";

import "./paginator.css";

import { useMediaQuery } from "@app/hooks/use-media-query";
import { Button } from "../button";
import styles from "./index.module.css";

const ROW_OPTIONS = [10, 25, 50, 100];

export const PaginatorView = (props: ViewProperties) => {
	const isMobile = useMediaQuery();

	if (props.totalPages <= 0) return null;

	if (isMobile) {
		if (
			!props.mobileNumberPerPage ||
			props.totalRecords <= props.mobileNumberPerPage
		)
			return null;
		return (
			<Button onClick={props.onLoadMore} variant="tertiary" centered>
				Load more
			</Button>
		);
	}

	const firstRecord = props.currentPage * props.pageSize - props.pageSize + 1;
	const lastRecord = Math.min(
		props.currentPage * props.pageSize,
		props.totalRecords,
	);
	const startPage = 0;
	const endPage = props.totalPages;

	const items = [...Array(endPage - startPage)].map((item, index) => {
		const pageNumber = index + startPage + 1;
		return pageNumber;
	});

	return (
		<div className="text-body paginator-row max-h-full w-full flex-row text-base lg:gap-x-2.5">
			<div className="my-auto text-3.5">
				Showing results {firstRecord}-{lastRecord} of {props.totalRecords}
			</div>
			<div className="center-paginator-section">
				<div className="my-auto">
					{
						<Button
							disabled={props.currentPage <= 1}
							size="sm"
							variant="secondary"
							onClick={props.onNavigatePreviousPage}
							className={styles.button}
						>
							Previous
						</Button>
					}
				</div>
				<div className="paginator-dropdown-section">
					<Typography className="text-gray-1100" theme="textSm">
						Page
					</Typography>
					<Dropdown
						value={props.currentPage}
						options={items}
						panelClassName="paginator-dropdown-section-panel"
						onChange={(value) => {
							props.onNavigatePage(value);
						}}
					/>
					of {props.totalPages}
				</div>
				<div className="my-auto">
					{
						<Button
							disabled={props.currentPage >= props.totalPages}
							size="sm"
							onClick={props.onNavigateNextPage}
							variant="secondary"
							className={styles.button}
						>
							Next
						</Button>
					}
				</div>
			</div>
			<div className="paginator-dropdown-section">
				Rows per page
				<Dropdown
					value={props.pageSize}
					options={ROW_OPTIONS}
					panelClassName="paginator-dropdown-section-panel"
					onChange={(value) => {
						props.onChangePageSize(value);
					}}
				/>
			</div>
		</div>
	);
};
