import { Dialog } from "@app/components/dialog";
import Lottie from "react-lottie";
import loaderData from "./loader.json";
import successData from "./success.json";

import styles from "./index.module.css";

export const ProcessingModal = ({
	showSuccess,
	isOpen,
	successText = "Transaction confirmed",
	processingText = "Processing transaction",
}: {
	showSuccess: boolean;
	isOpen: boolean;
	successText?: string;
	processingText?: string;
}) => {
	return (
		<Dialog isOpen={isOpen} size="sm">
			<div className={styles.container}>
				<Lottie
					options={{
						loop: !showSuccess,
						autoplay: true,
						animationData: showSuccess ? successData : loaderData,
					}}
					height={120}
					width={120}
				/>
				<p>{showSuccess ? successText : processingText}</p>
			</div>
		</Dialog>
	);
};
