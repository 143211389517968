import { Dialog } from "@app/components/dialog";

import { Button } from "@app/components";
import { useNavigate } from "react-router-dom";
import styles from "./index.module.css";

export const IncompleteInformationModal = ({
	onCancel,
	path,
}: {
	onCancel: () => void;
	path: string | null;
}) => {
	const navigate = useNavigate();
	return (
		<Dialog
			title="Incomplete information"
			size="sm"
			isOpen
			classNameDescription={styles.description}
			actions={
				<>
					<Button variant="secondary" onClick={onCancel}>
						Back
					</Button>
					{path && <Button onClick={() => navigate(path)}>Continue</Button>}
				</>
			}
			description={
				<>
					Please note, there is missing or incomplete information within this
					section of the form.
				</>
			}
		>
			<p className={styles.description}>
				You can go back to resolve this or continue and resolve it later.
			</p>
		</Dialog>
	);
};
