import { createRef } from "react";

import { Menu, type MenuPassThroughOptions } from "primereact/menu";
import type { MenuItem } from "primereact/menuitem";

import "primereact/resources/themes/lara-light-indigo/theme.css";
import { FiMoreVertical } from "react-icons/fi";
import { IconButton } from "../icon-button";
import "./hamburger-menu.css";

export const HamburgerMenu = (props: { items: MenuItem[] }) => {
	const menu = createRef<Menu>();

	return (
		<>
			<Menu
				className="hamburger-menu"
				pt={
					{
						menuitem: "hamburger-menu-menuitem",
						menu: "hamburger-menu-menu",
						root: "hamburger-menu-root",
					} as MenuPassThroughOptions
				}
				popup
				popupAlignment="right"
				ref={menu}
				model={props.items}
			/>
			<IconButton
				size="sm"
				aria-controls="popup_menu_left"
				aria-haspopup
				variant="borderless"
				onClick={(event) => {
					menu?.current?.toggle(event);
				}}
			>
				<FiMoreVertical size={20} color="#262626" />
			</IconButton>
		</>
	);
};
