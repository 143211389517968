import type {
	CreateWithdrawal,
	CreateWithdrawalBankAccount,
	UpdateWithdrawalBankAccount,
	WithdrawOptions,
	WithdrawalBankAccount,
	WithdrawalBankAccounts,
} from "@app/entities";
import { type MappedReasons, apiWithdrawals } from "@app/services";

import { getActiveClientFromSession } from "@app/hooks/use-clients";
import type { AppThunk } from "../store";
import {
	setCreateWithdrawalBankAccountError,
	setCreateWithdrawalBankAccountLoading,
	setCreateWithdrawalRequestError,
	setCreateWithdrawalRequestLoading,
	setDeleteWithdrawalBankAccountError,
	setDeleteWithdrawalBankAccountLoading,
	setUpdateWithdrawalBankAccountError,
	setUpdateWithdrawalBankAccountLoading,
	setWithdrawOptions,
	setWithdrawOptionsError,
	setWithdrawOptionsLoading,
	setWithdrawalBankAccount,
	setWithdrawalBankAccountError,
	setWithdrawalBankAccountLoading,
	setWithdrawalBankAccounts,
	setWithdrawalBankAccountsError,
	setWithdrawalBankAccountsLoading,
} from "./withdrawals-slice";

export const getWithdrawalBankAccountsAction =
	(
		callback?: (response?: WithdrawalBankAccounts | string[]) => void,
	): AppThunk =>
	async (dispatch) => {
		let results: WithdrawalBankAccounts | string[] | undefined = undefined;

		try {
			dispatch(setWithdrawalBankAccountsLoading(true));
			dispatch(setWithdrawalBankAccountsError(null));

			const activeClientId = getActiveClientFromSession();

			if (activeClientId) {
				const response =
					await apiWithdrawals.getWithdrawalBankAccounts(activeClientId);

				if ("reasons" in response) {
					dispatch(setWithdrawalBankAccountsError(response.reasons));
					results = response.reasons;
				} else {
					dispatch(setWithdrawalBankAccounts(response));
					results = response;
				}
			}
		} catch (error: any) {
			dispatch(setWithdrawalBankAccountsError(error));
		} finally {
			if (callback) callback(results);
			dispatch(setWithdrawalBankAccountsLoading(false));
		}
	};

export const getWithdrawalBankAccountAction =
	(
		bankAccountId: number,
		callback?: (response?: WithdrawalBankAccount | string[]) => void,
	): AppThunk =>
	async (dispatch) => {
		let results: WithdrawalBankAccount | string[] | undefined = undefined;

		try {
			dispatch(setWithdrawalBankAccountLoading(true));
			dispatch(setWithdrawalBankAccountError(null));

			const response =
				await apiWithdrawals.getWithdrawalBankAccount(bankAccountId);

			if ("reasons" in response) {
				dispatch(setWithdrawalBankAccountError(response.reasons));
				results = response.reasons;
			} else {
				dispatch(setWithdrawalBankAccount(response));
				results = response;
			}
		} catch (error: any) {
			dispatch(setWithdrawalBankAccountError(error));
		} finally {
			if (callback) callback(results);

			dispatch(setWithdrawalBankAccountLoading(false));
		}
	};

export const getWithdrawOptionsAction =
	(callback?: (response?: WithdrawOptions | string[]) => void): AppThunk =>
	async (dispatch) => {
		let results: WithdrawOptions | string[] | undefined = undefined;

		try {
			dispatch(setWithdrawOptionsLoading(true));
			dispatch(setWithdrawOptionsError(null));

			const response = await apiWithdrawals.getWithdrawOptions();

			if ("reasons" in response) {
				dispatch(setWithdrawOptionsError(response.reasons));
				results = response.reasons;
			} else {
				dispatch(setWithdrawOptions(response));
				results = response;
			}
		} catch (error: any) {
			dispatch(setWithdrawOptionsError(error));
		} finally {
			callback?.(results);
			dispatch(setWithdrawOptionsLoading(false));
		}
	};

export const createWithdrawalBankAccountAction =
	(
		withdrawalBankAccount: CreateWithdrawalBankAccount,
		callback?: (errors?: string[], mappedReasons?: MappedReasons) => void,
	): AppThunk =>
	async (dispatch, getState) => {
		let reasons: string[] | undefined = undefined;
		let mappedReasons: MappedReasons | undefined;

		try {
			dispatch(setCreateWithdrawalBankAccountLoading(true));
			dispatch(setCreateWithdrawalBankAccountError(null));

			const activeClientId = getActiveClientFromSession();

			if (activeClientId) {
				withdrawalBankAccount.clientId = activeClientId;
				const response = await apiWithdrawals.createWithdrawalBankAccount(
					activeClientId,
					withdrawalBankAccount,
				);

				if (typeof response === "object" && "reasons" in response) {
					dispatch(setCreateWithdrawalBankAccountError(response.reasons));
					reasons = response.reasons;
					if (response.mappedReasons) {
						mappedReasons = response.mappedReasons;
					}
				}
			}
		} catch (error: any) {
			dispatch(setCreateWithdrawalBankAccountError(error));
		} finally {
			callback?.(reasons, mappedReasons);
			dispatch(setCreateWithdrawalBankAccountLoading(false));
		}
	};

export const createWithdrawalRequestAction =
	(
		withdrawal: CreateWithdrawal,
		callback?: (response?: number | string[]) => void,
	): AppThunk =>
	async (dispatch, getState) => {
		let results: number | string[] | undefined = undefined;

		try {
			dispatch(setCreateWithdrawalRequestLoading(true));
			dispatch(setCreateWithdrawalRequestError(null));

			const activeClientId = getActiveClientFromSession();

			if (activeClientId) {
				withdrawal.clientId = activeClientId;
				const response =
					await apiWithdrawals.createWithdrawalRequest(withdrawal);

				if (typeof response === "object" && "reasons" in response) {
					dispatch(setCreateWithdrawalRequestError(response.reasons));
					results = response.reasons;
				} else {
					results = response;
				}
			}
		} catch (error: any) {
			dispatch(setCreateWithdrawalRequestError(error));
		} finally {
			callback?.(results);
			dispatch(setCreateWithdrawalRequestLoading(false));
		}
	};

export const deleteWithdrawalBankAccountAction =
	(
		bankAccountId: number,
		callback?: (response?: number | string[]) => void,
	): AppThunk =>
	async (dispatch) => {
		let results: number | string[] | undefined = undefined;

		try {
			dispatch(setDeleteWithdrawalBankAccountLoading(true));
			dispatch(setDeleteWithdrawalBankAccountError(null));

			const response =
				await apiWithdrawals.deleteWithdrawalBankAccount(bankAccountId);

			if (typeof response === "object" && "reasons" in response) {
				dispatch(setDeleteWithdrawalBankAccountError(response.reasons));
				results = response.reasons;
			} else {
				results = response;
			}
		} catch (error: any) {
			dispatch(setDeleteWithdrawalBankAccountError(error));
		} finally {
			callback?.(results);
			dispatch(setDeleteWithdrawalBankAccountLoading(false));
		}
	};

export const updateWithdrawalBankAccountAction =
	(
		withdrawalBankAccount: UpdateWithdrawalBankAccount,
		callback?: (errors?: string[], mappedReasons?: MappedReasons) => void,
	): AppThunk =>
	async (dispatch) => {
		let reasons: string[] | undefined = undefined;
		let mappedReasons: MappedReasons | undefined;

		try {
			dispatch(setUpdateWithdrawalBankAccountLoading(true));
			dispatch(setUpdateWithdrawalBankAccountError(null));

			const response = await apiWithdrawals.updateWithdrawalBankAccount(
				withdrawalBankAccount,
			);

			if (typeof response === "object" && "reasons" in response) {
				dispatch(setUpdateWithdrawalBankAccountError(response.reasons));
				reasons = response.reasons;
				if (response.mappedReasons) {
					mappedReasons = response.mappedReasons;
				}
			}
		} catch (error: any) {
			dispatch(setUpdateWithdrawalBankAccountError(error));
		} finally {
			callback?.(reasons, mappedReasons);
			dispatch(setUpdateWithdrawalBankAccountLoading(false));
		}
	};
