import type { TypographyTheme } from "@app/entities";
import React, { ReactNode } from "react";

import { fontSizesPredefined } from "@app/constants/font-sizes-predefined";

export const Typography = (props: {
	children?: ReactNode;
	theme?: TypographyTheme;
	className?: any;
}) => {
	const fontSize = fontSizesPredefined[props.theme || "textMd"];

	return (
		<div className={props.className ?? ""} style={{ ...fontSize }}>
			{props.children}
		</div>
	);
};
