import { Dropdown } from "@app/components/dropdown";
import { FieldError } from "@app/components/field-error";
import { Label } from "@app/components/label";
import { Note } from "@app/components/note";
import { paths } from "@app/constants/paths";
import { useCountries } from "@app/hooks/use-countries";
import { useFormErrors } from "@app/hooks/use-form-errors";
import { forwardRef, useCallback, useImperativeHandle, useMemo } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Card } from "../card";
import { ContactNumberField } from "../contact-number-field";
import { DocumentField } from "../document-field";
import { ProvinceField } from "../province-field";
import { EntityLayout } from "../related-entities/entity-layout";
import {
	getRelatedEntity,
	getRelatedEntityLink,
	useEntity,
} from "../related-entities/use-entity";
import { RelatedEntityType } from "../related-entities/use-related-entities";
import { Row } from "../row";
import { TextField } from "../text-field";
import { entityTypes } from "./entity-types";
import styles from "./primary-shareholder-form.module.css";

export type Inputs = {
	entity_type: RelatedEntityType;
	shareholding_percent: number | undefined;
	contact_number: string;
	country_of_birth: string;
	entity_name: string;
	registration_number: string;
	email_address: string;
	first_name: string;
	id_document: string | null;
	id_selfie: string | null;
	last_name: string;
	middle_names: string;
	nationality: string;
	registration_document: string;
	residential_address: {
		address_1: string;
		address_2: string;
		city: string;
		province: string;
		other_province: string;
		postal_code: string;
		country: string;
	};
};

type PrimaryShareholderFormProps = {
	id: number;
	linkId: number;
	index: number;
	onSavingChanges: (isSavingChanges: boolean) => void;
	onSaved: (isSaved: boolean) => void;
	activeClientId: number;
	isDisabled?: boolean;
	onPartialSave: () => void;
};

const mapFormToDTO = (data: Inputs) => {
	if (data.entity_type === "individual") {
		return {
			entity_type: data.entity_type,
			email_address: data.email_address,
			contact_number: data.contact_number,
			first_name: data.first_name,
			middle_names: data.middle_names,
			last_name: data.last_name,
			nationality: data.nationality,
			country_of_birth: data.country_of_birth,
			residential_address: {
				address_1: data.residential_address.address_1,
				address_2: data.residential_address.address_2,
				city: data.residential_address.city,
				province: data.residential_address.province,
				other_province: data.residential_address.other_province,
				postal_code: data.residential_address.postal_code,
				country: data.residential_address.country,
			},
		} as any;
	}

	return {
		entity_type: data.entity_type,
		email_address: data.email_address,
		contact_number: data.contact_number,
		entity_name: data.entity_name,
		registration_number: data.registration_number,
	} as any;
};

export const PrimaryShareholderForm = forwardRef<
	any,
	PrimaryShareholderFormProps
>(
	(
		{
			id,
			linkId,
			index,
			onSavingChanges,
			onSaved,
			activeClientId,
			isDisabled,
			onPartialSave,
		},
		ref,
	) => {
		const navigate = useNavigate();
		const { submit, update, updateLink, submitLink } = useEntity(id, linkId);
		const { data: countries } = useCountries();

		const methods = useForm<Inputs>({
			shouldFocusError: false,
			defaultValues: async () => {
				try {
					const { data: relatedEntity } = await getRelatedEntity(id);
					const { data: relatedEntityLink } =
						await getRelatedEntityLink(linkId);
					return {
						linked_entity_id: relatedEntityLink.related_entity_id,
						shareholding_percent: relatedEntityLink.shareholding_percent,
						...relatedEntity,
					} as Inputs;
				} catch {
					navigate(paths().error.generalError());
					return {} as Inputs;
				}
			},
		});

		const { handleSubmit, watch, register, setValue, getValues } = methods;
		const { errors, onErrors, clearErrors, clearApiFieldErrors, onInvalid } =
			useFormErrors(methods);

		const onSubmit: SubmitHandler<Inputs> = useCallback(
			async (data) => {
				clearApiFieldErrors();
				const linkErrors = await submitLink({
					shareholding_percent: data.shareholding_percent
						? Number.parseFloat(`${data.shareholding_percent}`)
						: 0,
				});

				const entityErrors = await submit(mapFormToDTO(data));

				if (linkErrors || entityErrors) {
					onErrors(
						{
							apiErrors: [
								...(linkErrors?.apiErrors ?? []),
								...(entityErrors?.apiErrors ?? []),
							],
							fieldErrors: [
								...(linkErrors?.fieldErrors ?? []),
								...(entityErrors?.fieldErrors ?? []),
							],
						},
						true,
					);
					return false;
				}
				return true;
			},
			[clearApiFieldErrors, submit, onErrors, submitLink],
		);

		useImperativeHandle(
			ref,
			() => {
				return {
					id,
					submit: async () => onSubmit(getValues()),
				};
			},
			[onSubmit, getValues, id],
		);

		const handleEntityPartialSave = async () => {
			const values = getValues();
			const data = mapFormToDTO(values);
			if (Object.keys(data).length === 0) return;
			onSavingChanges(true);
			const errors = await update(data);
			clearApiFieldErrors(data);
			if (errors) {
				onErrors(errors);
			} else {
				onSaved(true);
				onPartialSave();
			}
			onSavingChanges(false);
			onSaved(true);
		};

		const entityType = watch("entity_type");
		const entityName = watch("entity_name");
		const firstname = watch("first_name");
		const lastname = watch("last_name");

		const hasName = !!firstname || !!lastname || !!entityName;

		const displayName = useMemo(() => {
			let newName = `Shareholder ${index + 1}`;
			if (firstname || lastname) {
				newName = `${firstname} ${lastname}`.trim();
			}

			if (entityName) {
				newName = entityName;
			}

			return newName;
		}, [firstname, lastname, index, entityName]);

		return (
			<FormProvider {...methods}>
				<EntityLayout
					id={id}
					index={index}
					hasName={hasName}
					displayName={displayName}
				>
					<form
						onSubmit={handleSubmit(onSubmit, onInvalid)}
						className={styles.cards}
					>
						<Card>
							<Row style={{ marginBottom: 0 }}>
								<div>
									<Label htmlFor="entity_type">Entity type*</Label>
									<Dropdown
										disabled={isDisabled}
										{...register("entity_type", {
											required: "This field is required",
											onChange: (event) => {
												const name = event.target.value;
												const value = entityTypes.find(
													(current) => current.name === name,
												)?.value;
												if (value) {
													setValue("entity_type", value as RelatedEntityType);
													handleEntityPartialSave();
												}
											},
										})}
										value={
											entityTypes.find(
												(current) => current.value === entityType,
											)?.name
										}
										invalid={!!errors.entity_type}
										placeholder="Select the entity type of the shareholder"
										options={entityTypes.map((current) => current.name)}
									/>
									{errors.entity_type && (
										<FieldError>{errors.entity_type.message}</FieldError>
									)}
								</div>

								<TextField
									disabled={isDisabled}
									tooltip="This is the effective ownership of the company registering with Future Forex."
									error={errors.shareholding_percent}
									label="Shareholding %*"
									placeholder="Enter a percentage"
									{...register("shareholding_percent", {
										required: "This field is required",
										onBlur: async () => {
											const errors = await updateLink({
												shareholding_percent: watch("shareholding_percent"),
											});
											if (errors) {
												onErrors(errors);
											} else {
												clearErrors("shareholding_percent");
											}
										},
									})}
								/>
							</Row>
						</Card>
						{entityType === "individual" ? (
							<>
								<Card title="Personal Details">
									<Label htmlFor="first_name">Full name*</Label>
									<Row className={styles.nameRow}>
										<TextField
											disabled={isDisabled}
											error={errors.first_name}
											autoComplete="given-name"
											placeholder="Enter your first name"
											{...register("first_name", {
												required: "This field is required",
												onBlur: handleEntityPartialSave,
											})}
										/>

										<TextField
											disabled={isDisabled}
											error={errors.middle_names}
											placeholder="Enter middle name (optional)"
											{...register("middle_names", {
												onBlur: handleEntityPartialSave,
											})}
										/>

										<TextField
											disabled={isDisabled}
											error={errors.last_name}
											autoComplete="family-name"
											placeholder="Enter your last name"
											{...register("last_name", {
												required: "This field is required",
												onBlur: handleEntityPartialSave,
											})}
										/>
									</Row>
									<Row>
										<TextField
											disabled={isDisabled}
											label="Email*"
											placeholder="Enter their email address"
											error={errors.email_address}
											{...register("email_address", {
												required: "This field is required",
												onBlur: handleEntityPartialSave,
											})}
										/>

										<ContactNumberField
											disabled={isDisabled}
											placeholder="Enter number"
											error={errors.contact_number}
											onBlur={handleEntityPartialSave}
										/>
									</Row>

									<Row>
										<div>
											<Label htmlFor="nationality">Nationality*</Label>
											<Dropdown
												disabled={isDisabled}
												{...register("nationality", {
													required: "This field is required",
													onBlur: handleEntityPartialSave,
													onChange: (event) => {
														setValue("nationality", event.value);
														if (event.value) clearErrors("nationality");
													},
												})}
												loading={!countries}
												value={watch("nationality")}
												invalid={!!errors.nationality}
												placeholder="Select an country"
												options={countries ?? []}
											/>
											{errors.nationality && (
												<FieldError>{errors.nationality.message}</FieldError>
											)}
										</div>

										<div>
											<Label htmlFor="country_of_birth">
												Country of birth*
											</Label>
											<Dropdown
												disabled={isDisabled}
												{...register("country_of_birth", {
													required: "This field is required",
													onBlur: handleEntityPartialSave,
													onChange: (event) => {
														setValue("country_of_birth", event.value);
														if (event.value) clearErrors("country_of_birth");
													},
												})}
												loading={!countries}
												value={watch("country_of_birth")}
												invalid={!!errors.country_of_birth}
												placeholder="Select an country"
												options={countries ?? []}
											/>
											{errors.country_of_birth && (
												<FieldError>
													{errors.country_of_birth.message}
												</FieldError>
											)}
										</div>
									</Row>

									<DocumentField
										disabled={isDisabled}
										relatedEntityId={id}
										type="id_document"
										tooltip="Upload a copy of their ID Book or both sides of their ID Card."
										value={watch("id_document")}
										tooltipWidth={282}
										onChange={async () => {
											if (!activeClientId) return;
											const result = await getRelatedEntity(id);
											setValue("id_document", result.data.id_document);
											clearErrors("id_document");
										}}
										label="ID Document"
										error={errors.id_document}
									/>

									<DocumentField
										type="id_selfie"
										relatedEntityId={id}
										disabled={isDisabled}
										tooltip="Upload a picture of the director holding the uploaded ID (must be legible and clear)."
										tooltipWidth={320}
										value={watch("id_selfie")}
										onChange={async () => {
											if (!activeClientId) return;
											const result = await getRelatedEntity(id);
											setValue("id_selfie", result.data.id_selfie);
											clearErrors("id_selfie");
										}}
										label="Selfie with ID"
										error={errors.id_selfie}
									/>
								</Card>
								<Card title="Residential Address" className={styles.card}>
									<Row>
										<TextField
											disabled={isDisabled}
											label="Address line 1*"
											placeholder="Enter address"
											error={errors.residential_address?.address_1}
											{...register("residential_address.address_1", {
												onBlur: handleEntityPartialSave,
												required: "This field is required",
											})}
										/>

										<TextField
											disabled={isDisabled}
											label="Address line 2"
											placeholder="Enter address (optional)"
											error={errors.residential_address?.address_2}
											{...register("residential_address.address_2", {
												onBlur: handleEntityPartialSave,
											})}
										/>
									</Row>

									<Row>
										<TextField
											disabled={isDisabled}
											label="City*"
											placeholder="Enter a city"
											error={errors.residential_address?.city}
											{...register("residential_address.city", {
												onBlur: handleEntityPartialSave,
												required: "This field is required",
											})}
										/>

										<ProvinceField
											disabled={isDisabled}
											provinceFieldName="residential_address.province"
											otherFieldName="residential_address.other_province"
											countryFieldName="residential_address.country"
											error={
												errors.residential_address?.province ||
												errors.residential_address?.other_province
											}
											onChange={handleEntityPartialSave}
										/>
									</Row>

									<Row style={{ marginBottom: 0 }}>
										<TextField
											disabled={isDisabled}
											label="Postal code*"
											placeholder="Enter postal code"
											error={errors.residential_address?.postal_code}
											{...register("residential_address.postal_code", {
												required: "This field is required",
												onBlur: handleEntityPartialSave,
											})}
										/>

										<div>
											<Label htmlFor="residential_address.country">
												Country*
											</Label>
											<Dropdown
												disabled={isDisabled}
												{...register("residential_address.country", {
													required: "This field is required",
													onBlur: handleEntityPartialSave,
													onChange: (event) => {
														setValue(
															"residential_address.country",
															event.value,
														);
														if (event.value)
															clearErrors("residential_address.country");
													},
												})}
												loading={!countries}
												value={watch("residential_address.country")}
												invalid={!!errors.residential_address?.country}
												placeholder="Select an country"
												options={countries ?? []}
											/>
											{errors.residential_address?.country && (
												<FieldError>
													{errors.residential_address?.country.message}
												</FieldError>
											)}
										</div>
									</Row>
								</Card>
							</>
						) : (
							<Card className={styles.card} title="Entity Details">
								<Row>
									<TextField
										disabled={isDisabled}
										label="Entity name*"
										placeholder="Enter the name of the entity"
										error={errors.entity_name}
										{...register("entity_name", {
											onBlur: handleEntityPartialSave,
											required: "This field is required",
										})}
									/>

									<TextField
										disabled={isDisabled}
										label="Registration number*"
										placeholder="Enter your registration number"
										error={errors.registration_number}
										{...register("registration_number", {
											onBlur: handleEntityPartialSave,
											required: "This field is required",
										})}
									/>
								</Row>
								<Row>
									<TextField
										disabled={isDisabled}
										label="Email*"
										placeholder="Enter their email address"
										error={errors.email_address}
										{...register("email_address", {
											onBlur: handleEntityPartialSave,
											required: "This field is required",
										})}
									/>

									<ContactNumberField
										disabled={isDisabled}
										placeholder="Enter their number"
										error={errors.contact_number}
										onBlur={handleEntityPartialSave}
									/>
								</Row>

								{entityType === "trust" && (
									<Note variant="full" className={styles.note}>
										For Trusts, please upload the Trust Deed and letter of
										authority stamped by the master of the high court.
									</Note>
								)}

								<DocumentField
									disabled={isDisabled}
									relatedEntityId={id}
									style={{ marginBottom: 0 }}
									type="registration_document"
									value={watch("registration_document")}
									onChange={async () => {
										if (!activeClientId) return;
										const result = await getRelatedEntity(id);
										if (result.data.registration_document) {
											setValue(
												"registration_document",
												result.data.registration_document,
											);
											clearErrors("registration_document");
										}
									}}
									tooltipWidth={320}
									tooltip={
										<>
											Examples include:
											<br />
											Certificate, Memorandum of Incorporation, and, if
											applicable, change of name or change of directors
											cerificate.
										</>
									}
									label="Registration document"
									error={errors.registration_document}
								/>
							</Card>
						)}
					</form>
				</EntityLayout>
			</FormProvider>
		);
	},
);
