import { Button } from "@app/components/button";

import styles from "./footer.module.css";

export const Footer = (props: {
	step: number;
	onBack: () => void;
	onNext: () => void;
	isSubmitting?: boolean;
}) => {
	return (
		<div className={styles.footer}>
			<Button
				variant="secondary"
				onClick={(e) => {
					e.preventDefault();
					props.onBack();
				}}
			>
				Back
			</Button>
			<Button
				form="add-edit-recipient-form"
				type={props.step === 1 ? "submit" : "button"}
				disabled={props.isSubmitting}
				onClick={(e) => {
					if (props.step !== 1) {
						e.preventDefault();
					}
					props.onNext();
				}}
			>
				{props.step === 1 ? (props.isSubmitting ? "Saving" : "Save") : "Next"}
			</Button>
		</div>
	);
};
