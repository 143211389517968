import { useClients } from "@app/hooks/use-clients";
import { RelatedEntities } from "../related-entities";

export const BusinessCompanyShareholders = () => {
	const { activeClientId } = useClients();
	if (!activeClientId) return null;
	return (
		<RelatedEntities variant="shareholder" activeClientId={activeClientId} />
	);
};
