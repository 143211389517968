import { useNavigate } from "react-router-dom";

import { paths } from "@app/constants/paths";

import styles from "./carousel-end-card.module.css";

export const CarouselEndCard = () => {
	const navigate = useNavigate();

	return (
		<button
			type="button"
			className={styles.card}
			onClick={() => {
				navigate(paths().sendReceiveFunds());
			}}
		>
			<svg
				role="presentation"
				xmlns="http://www.w3.org/2000/svg"
				width="32"
				height="33"
				viewBox="0 0 32 33"
				fill="none"
			>
				<path
					d="M5.33366 9.88346H26.667M26.667 9.88346L21.3337 4.55013M26.667 9.88346L21.3337 15.2168"
					stroke="#D1D1D1"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M26.6673 23.2181H5.33398M5.33398 23.2181L10.6673 28.5514M5.33398 23.2181L10.6673 17.8848"
					stroke="#D1D1D1"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
			<p>Need to book a transaction?</p>
			<p className={styles.sendReceiveText}>Send/Receive Funds</p>
		</button>
	);
};
