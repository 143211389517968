export const getApiError = (error: any): Array<null | string> => {
	if (!error) return ["An unexpected error occurred. Please try again."];

	if (!error.data.detail) {
		if (error.message) {
			return [error.message];
		}
		return ["An unexpected error occurred. Please try again."];
	}

	if (Array.isArray(error.data.detail)) {
		return error.data.detail.map(
			(current: { loc: string[]; msg: string; type: string }) => current.msg,
		);
	}

	try {
		const errorDetail = JSON.parse(error.data.detail);
		return [null, errorDetail];
	} catch {
		return [null, error.data?.detail ?? error.message];
	}
};
